import React, { useState } from 'react';
import { Button } from '@mui/material';
import { styles } from '../styles';
import CacheDialog from './CacheDialog';
import config from '../../../../config/envConfig';
import { fetchAxios } from '../../../../functions/axiosRequests';

const initState = {
    open: false,
    type: ''
};

const ClearCache = () => {
    const [openDialog, setOpenDialog] = useState(initState);
    const classes = styles();

    const handleClick = () => {
        clearCache();
    };

    const handleCloseDialog = (type) => {
        setOpenDialog({open: false, type});
    };

    const clearCache = async () => {
        try {
            const url = `${config.serverLink}/api/admin/clearCache`;
            const response = await fetchAxios(url, {
                method: 'GET',
                headers: {
                    Secret: config.REACT_APP_FRONTEND_SECRET_HEADER,
                },
            });
    
            if (response.status === 200) {
                setOpenDialog({ open: true, type: 'success' });
            } else {
                setOpenDialog({ open: true, type: 'fail' });
            }
        } catch (error) {
            console.error('Error clearing cache:', error);
            setOpenDialog({ open: true, type: 'fail' });
        }
    };

    return (
        <>
            <Button className={classes.clearCache} onClick={handleClick}>
                Clear cache
            </Button>
            <CacheDialog handleCloseDialog={handleCloseDialog} openDialog={openDialog} />
        </>

    );
};

export default ClearCache;
