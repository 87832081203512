// Utility functions for cookie manipulation
function setCookie(name, value, days) {
    var expires = '';
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

function getCookie(name) {
    // console.log('[getCookie]', 'cookie',document.cookie, 'cookies',document.cookies);
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const auth = {

    setInstanceId(INSTANCE_ID) {
        sessionStorage.setItem(`t_cookie`, INSTANCE_ID);
    },

    getCookieName() {
        return sessionStorage.getItem('t_cookie');
    },

    isAuthenticated() {
        if (typeof window === 'undefined') return false;
        const cookieName = this.getCookieName();
        // Check if the cookie named cookieName exists
        if (getCookie(cookieName)) {    
            return true;
        };
        return false;
    },
    authenticate(jwt, cb) {
        if (typeof window !== 'undefined') {
            const cookieName = this.getCookieName();
            // Store JWT in the cookie named cookieName
            setCookie(cookieName, JSON.stringify(jwt), 1); // Expires in 1 day, adjust as needed
        }
        if (cb) cb();
    },
    signout(cb) {
        if (typeof window !== 'undefined') {
            const cookieName =  this.getCookieName();
            // Remove the cookie named cookieName
            eraseCookie(cookieName);
        }
        if (cb) cb();
    }
};

export default auth;
