import { makeStyles } from '@mui/styles';

export const styles = makeStyles(() =>({
    container: {
        height: '100%',
        maxHeight: '100%'
    },
    item: {
        position: 'relative',
        height: '100%',
        overflowY: 'overlay',
        maxHeight: '80vh',
    },
    formControl: {
        width: 350,
        margin: '15px 0px 8px 15px'
    },
    searchSelect: {
        width: '350px',
        marginTop: '15px',
        display: 'block',
        backgroundColor: 'white !important',
        '& > div': {
            width: '100%',
        },
        '& input:-internal-autofill-selected': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            // fontFamily: 'Inter, Verdana',
            fontSize: '16px'
        }
    },
}));