import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import TableUsers from '../../components/Admin/Users/TableUsers';
import TableRoles from '../../components/Admin/Roles/TableRoles';
import { styles } from './styles';
import { useSelector } from 'react-redux';

export const AdminPanel = () => {
    const [value, setValue] = React.useState(0);
    const classes = styles();
    const adminRights = useSelector((state) => state.user.permissions.adminRights);
  
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
  
    if (!adminRights) return null;
  
    return (
        <Box sx={{ width: '100%', height: 'calc(100vh - 60px)' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Users" />
                    <Tab label="Roles" />
                </Tabs>
            </Box>
  
            {value === 0 && (
                <Grid container>
                    <Grid size={12} className={classes.item}>
                        <TableUsers />
                    </Grid>
                </Grid>
            )}
  
            {value === 1 && (
                <Grid container>
                    <Grid size={12} className={classes.item}>
                        <TableRoles />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};
