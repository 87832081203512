import {
    FETCH_FITNESS_MODEL_REQUEST,
    FETCH_FITNESS_MODEL_SUCCESS,
    FETCH_FITNESS_MODEL_ERROR,
    SET_FITNESS_HIDDEN_ALPHA_CLADE,
    SET_FITNESS_HIDDEN_RHO_CLADE,
    UNHIDE_RHO_CLADE_FITNESS,
    RESET_FITNESS_HIDDEN_CLADES,
    UNHIDE_ALPHA_CLADE_FITNESS,
    RESET_ANTIGENIC_FITNESS_MODEL
} from './actionTypes';
import { received } from '../baseActions';

const fetchFitnessModel = (payload) => received(FETCH_FITNESS_MODEL_REQUEST, payload);
const fetchFitnessModelSuccess = (payload) => received(FETCH_FITNESS_MODEL_SUCCESS, payload);
const fetchFitnessModelError = (payload) => received(FETCH_FITNESS_MODEL_ERROR, payload);
const setFitnessHiddenAlphaClade = (alpha) => received(SET_FITNESS_HIDDEN_ALPHA_CLADE, { alpha });
const setFitnessHiddenRhoClade = (rho) => received(SET_FITNESS_HIDDEN_RHO_CLADE, { rho });
const unhideRhoCladeFitness = (rho) => received(UNHIDE_RHO_CLADE_FITNESS, { rho });
const unhideAlphaCladeFitness = (rho) => received(UNHIDE_ALPHA_CLADE_FITNESS, { rho });
const resetFitnessHiddenClades = () => received(RESET_FITNESS_HIDDEN_CLADES, {});
const resetAntigenicFitnessModel = (payload) => received(RESET_ANTIGENIC_FITNESS_MODEL, payload);

export { 
    fetchFitnessModel, 
    fetchFitnessModelSuccess, 
    fetchFitnessModelError, 
    setFitnessHiddenAlphaClade, 
    setFitnessHiddenRhoClade ,
    unhideRhoCladeFitness,
    resetFitnessHiddenClades,
    unhideAlphaCladeFitness,
    resetAntigenicFitnessModel
};
