import React, { useState, useEffect } from 'react';
import { Dialog, Switch, IconButton, InputAdornment, FormGroup, Typography, FormControlLabel, DialogTitle, MenuItem, DialogActions, DialogContent, DialogContentText, Button, FormControl } from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import CustomLabel from '../../../assets/GlobalStyles/CustomLabel';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import { connect } from 'react-redux';
import CustomSelect from '../../../assets/GlobalStyles/CustomSelect';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import { setUserPreferences } from '../../../redux/actions/userActions';
import config from '../../../config/envConfig';
import { postAxios } from '../../../functions/axiosRequests';
import { ValidateIcon } from '../../../components/SvgIcons/SequencesInputIcons';

const initStatePassword = {
    length: false,
    special: false,
    capital: false,
    number: false,
    match: true,
};

const userInitState = {
    email: '',
    menuRight: false,
    username: '',
    defaultLineage: '',
    currentPassword: '',
    newPassword: '',
    availableLineages: []
};

const errorState = {
    username: {
        status: false,
        message: ''
    },
    currentPassword: {
        status: false,
        message: ''
    },
    newPassword: {
        status: false,
        message: ''
    },
    other: {
        status: false,
        message: ''
    }
};

const PreferencesDialog = (props) => {
    const { handleCloseDialog, openDialog, userProps, allLineages, setUserPreferences } = props;
    const [user, setUser] = useState(userInitState);
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(errorState);
    const [passworValidation, setPasswordValidation] = useState(initStatePassword);
    const classes = useStyles();

    useEffect(() => {
        const availableLineages = userProps.lineages.length === 0 ? allLineages : userProps.lineages;

        setError({
            ...error,
            other:{ status: false, message: '' }
        });
        if (!userProps) return;
        if (!openDialog) {
            setUser({ availableLineages, ...userInitState });
            return;
        }

        const userState = {
            email: userProps.email,
            menuRight: userProps.menuRight,
            defaultLineage: userProps.defaultLineage,
            username: userProps.username,
            availableLineages,
            currentPassword: '',
            newPassword: ''
        };
        setUser(userState);
    }, [openDialog]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const validate = () => {
        setError({
            username: !user.username ? { status: true, message: 'Username cannot be empty' } : errorState.username,
            currentPassword: user.newPassword && !user.currentPassword ? { status: true, message: 'This field cannot be empty' } : errorState.currentPassword,
            newPassword: !user.newPassword && user.currentPassword ? { status: true, message: 'This field cannot be empty' } : errorState.newPassword
        });
        const state = !user.username || (!user.newPassword && user.currentPassword) || (user.newPassword && !user.currentPassword);
        return state;
    };

    const checkPasswordInputs = () => {
        if (!user.currentPassword || !user.newPassword) return false;
        return true;
    };

    const validatePasswords = (updatedNewPassword) => {
        const containsNumber = /\d/.test(updatedNewPassword);
        const containsCapitalLetter = /[A-Z]/.test(updatedNewPassword);
        const containsSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(updatedNewPassword);
        const length = updatedNewPassword.length >= 8;

        setPasswordValidation({
            length,
            special: containsSpecialChar,
            capital: containsCapitalLetter,
            number: containsNumber,
        });

        return (containsNumber && containsCapitalLetter && containsSpecialChar && length);
    };

    const submitChanges = () => {
        const validateFields = validate();
        if (validateFields) return;
        const passwordCheck = checkPasswordInputs();
        const valid = validatePasswords(user.newPassword);

        if (passwordCheck && !valid) {
            setError({
                ...error,
                newPassword: { status: true, message: 'Password is not valid' }
            });
            return;
        }
        if (passwordCheck && user.newPassword.length < 5) {
            setError({
                ...error,
                newPassword: { status: true, message: 'Password must me longer than 4 characters' }
            });
            return;
        }

        const url = `${config.serverLink}/api/user/updatePreferences`;
        const data = {
            user: {
                email: user.email,
                defaultLineage: user.defaultLineage,
                username: user.username,
                menuRight: user.menuRight,
                currentPassword: user.currentPassword,
                newPassword: user.newPassword
            },
            isNewPassword: passwordCheck
        };

        postAxios(url, data)
            .then(function (response) {
                // if (response.data.updatedUser) {
                //     setError({
                //         ...error,
                //         currentPassword: { status: true, message: 'Password is invalid' }
                //     });
                //     return;
                // }
                setUserPreferences({
                    menuRight: user.menuRight,
                    defaultLineage: user.defaultLineage,
                    name: user.username
                });
                handleCloseDialog();
            })
            .catch(err => {
                const message = err.response?.data?.message || 'unknown_error'; 
                if (message === 'wrong_password') {
                    setError({
                        ...error,
                        currentPassword: { status: true, message: 'Password is invalid' }
                    });
                    return;
                }
                setError({
                    ...error,
                    other:{ status: true, message }
                });
            });
    };

    const handleChange = (e, type) => {
        switch (type) {
            case 'username': {
                setUser({ ...user, username: e.target.value });
                if (error.username.status && e.target.value)
                    setError({ ...error, username: { status: false, message: '' } });
                return;
            }
            case 'defaultLineage': {
                setUser({ ...user, defaultLineage: e.target.value });
                return;
            }
            case 'menuRight': {
                setUser({ ...user, menuRight: e.target.checked });
                return;
            }
            case 'newPassword': {
                setUser({ ...user, newPassword: e.target.value });
                validatePasswords(e.target.value);

                if (error.newPassword.status && e.target.value)
                    setError({ ...error, newPassword: { status: false, message: '' } });
                return;
            }
            case 'currentPassword': {
                setUser({ ...user, currentPassword: e.target.value });
                if (error.currentPassword.status && e.target.value)
                    setError({ ...error, currentPassword: { status: false, message: '' } });
                return;
            }
        }
    };

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle id="preferences-dialog-title">
                Preferences
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Please change the fields you wish to change:
                </DialogContentText>
                <StyledTextField
                    id="username"
                    type="text"
                    label="Username"
                    autoComplete='off'
                    className={classes.textField}
                    value={user.username}
                    onChange={(e) => handleChange(e, 'username')}
                    error={error.username.status}
                    helperText={error.username.message}
                />
                <FormControl className={classes.selectControl} fullWidth>
                    <CustomLabel id={'defaultLineage'} label={'Default lineage'} />
                    <CustomSelect
                        value={user.defaultLineage}
                        onChange={(e) => handleChange(e, 'defaultLineage')}
                        className={classes.textField}
                        inputProps={{
                            name: 'defaultLineage',
                            id: 'defaultLineage',
                        }}
                    >

                        {user.availableLineages.length > 0 ?
                            user.availableLineages.map((lineage) => (
                                <MenuItem key={lineage} value={lineage}>{lineage}</MenuItem>)) :
                            <MenuItem key='empty' value={''}></MenuItem>
                        }
                    </CustomSelect>
                </FormControl>
                <FormGroup className={classes.switch}>
                    <FormControlLabel control={
                        <Switch color="secondary"
                            classes={{
                                track: classes.switch_track,
                                switchBase: classes.switch_base,
                            }}
                            onChange={(e) => handleChange(e, 'menuRight')}
                            checked={user.menuRight}
                        />}
                    label={user.menuRight ? 'Menu on the right' : 'Menu on the left'}
                    />
                </FormGroup>
                <DialogContentText>
                    If you wish to change the password, fill in the fields
                    < br />
                    below:
                </DialogContentText>
                <StyledTextField
                    id="current-password"
                    type={showPassword ? 'text' : 'password'}
                    label="Current password"
                    className={classes.textField}
                    value={user.currentPassword}
                    onChange={(e) => handleChange(e, 'currentPassword')}
                    error={error.currentPassword.status}
                    helperText={error.currentPassword.message}
                    autoComplete="new-password"
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                </IconButton>
                            </InputAdornment>
                    }}
                />
                <StyledTextField
                    id="new-password"
                    type={showPassword ? 'text' : 'password'}
                    label="New password"
                    className={classes.textField}
                    value={user.newPassword}
                    onChange={(e) => handleChange(e, 'newPassword')}
                    error={error.newPassword.status}
                    helperText={error.newPassword.message}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                </IconButton>
                            </InputAdornment>
                    }}
                />
                <div className={classes.validationWrapper}>
                    <Typography component="p" className={classes.error}>
                        <ValidateIcon color={passworValidation.length ? '#0000FF' : '#888888'} className={classes.icon} />
                        <span className={classes.validationText}>at least 8 characters</span>
                    </Typography>
                    <Typography component="p" className={classes.error}>
                        <ValidateIcon color={passworValidation.capital ? '#0000FF' : '#888888'} className={classes.icon} />
                        <span className={classes.validationText}>at least 1 capital letter</span>
                    </Typography>
                    <Typography component="p" className={classes.error}>
                        <ValidateIcon color={passworValidation.number ? '#0000FF' : '#888888'} className={classes.icon} />
                        <span className={classes.validationText}>at least 1 digit</span>
                    </Typography>
                    <Typography component="p" className={classes.error}>
                        <ValidateIcon color={passworValidation.special ? '#0000FF' : '#888888'} className={classes.icon} />
                        <span className={classes.validationText}>at least 1 special character</span>
                    </Typography>
                </div>
                {error.other?.status && (
                    <div className={classes.errorTxt}>
                        <div>Error: {error.other.message}</div>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button className={classes.cancel}
                    onClick={handleCloseDialog}>
                    Cancel
                </Button>
                <Button className={classes.save}
                    onClick={submitChanges}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>);
};

PreferencesDialog.propTypes = {
    openDialog: PropTypes.bool,
};

const mapStateToProps = state => {
    const userProps = state.user;
    const allLineages = state.lineages.lineages;
    return {
        userProps,
        allLineages
    };
};

const mapDispatchToProps = {
    setUserPreferences
};

export default connect(mapStateToProps, mapDispatchToProps)(PreferencesDialog);
