import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { getSelectedBins } from '../../../redux/selectors/metadataSelector';
import { toggleFrequenciesOption, revertSelectedFrequenciesBins } from '../../../redux/actions/frequenciesActions';
import ScalesLegend from './ScalesLegend';
import { handleMouseLeaveAll } from '../Elements/functions';
import { getScaleNameForFreqCategory } from '../../../redux/selectors/parametersSelector';

 
const FrequenciesLegend = (props) => {
        
    const { freqCategory, chartLayout, selectedBins, visibleBins, 
        toggleFrequenciesOption, revertSelectedFrequenciesBins, valueGetter, fullHeight, isScaleDiscrete, mouseEventsEnabled } = props;
       
    // console.log('FrequenciesLegend', { selectedBins });
    // Handle the focus-out event
    const handleMouseLeave = () => {
        handleMouseLeaveAll(chartLayout);
    };

    const handleDoubleClick = () => {
        revertSelectedFrequenciesBins();
    };

    if (!isScaleDiscrete) return null;

    return (
        <ScalesLegend
            measureName={freqCategory}
            ticks={3}
            inContainer
            precision={3}
            colorLegendVisible={true}
            includeEmptyBin={true}
            selectedBins={chartLayout !== 'stacked' ? selectedBins : null}
            visibleBins={visibleBins || {}}
            handleClick={chartLayout !== 'stacked' ? toggleFrequenciesOption : null}
            handleDoubleClick={chartLayout !== 'stacked' ? handleDoubleClick : null}
            selectable={chartLayout !== 'stacked'}
            valueGetter={valueGetter}
            fullHeight={fullHeight || false}
            mouseEventsEnabled={mouseEventsEnabled || false}
            type='frequencies'
            onMouseLeave={handleMouseLeave} // Pass the onMouseOut handler to ScalesLegend
        />
    );
};


const mapStateToProps = (state) => {
    const { freqCategory, chartLayout } = state.parameters;
    const scaleName = getScaleNameForFreqCategory(state);
    const isScaleDiscrete = state.metadata.scales[scaleName]?.discrete || false;

    return {
        freqCategory,
        isScaleDiscrete,
        chartLayout,
        visibleBins: getSelectedBins(state),
        selectedBins: state.parameters.visibleBins,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ toggleFrequenciesOption, revertSelectedFrequenciesBins }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FrequenciesLegend);

FrequenciesLegend.propTypes = {
    freqCategory: PropTypes.string,
    chartLayout: PropTypes.string,
    selectedBins: PropTypes.objectOf(PropTypes.any),
    visibleBins: PropTypes.objectOf(PropTypes.any),
    valueGetter: PropTypes.func,
    toggleFrequenciesOption: PropTypes.func,
    revertSelectedFrequenciesBins: PropTypes.func,

};
