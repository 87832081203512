import { produce } from 'immer';
import {
    FETCH_HUMAN_POOLS_REQUEST,
    FETCH_HUMAN_POOLS_SUCCESS,
    FETCH_HUMAN_POOLS_ERROR,
    SIGNOUT_REQUEST,
    RESET_SESSION,
    RESET_HUMAN_SEROLOGY_DATA,
    FETCH_HUMAN_SEROLOGY_SUCCESS,
    FETCH_HUMAN_SEROLOGY_ERROR,
    FETCH_HUMAN_SEROLOGY_REQUEST,
    INIT_STRAIN_TREE_REQUEST,
    FETCH_SELECTED_STRAIN_REQUEST,
    ZOOM_TREE_REQUEST,
    FETCH_VP_VALUES_REQUEST,
    FETCH_SUBSET_TREE_REQUEST,
    FETCH_RECALCULATED_TREE_REQUEST
} from '../actions/actionTypes';


let humanSerologyInitialState = {};
export const setHumanSerologyInitialState = (state) => {
    humanSerologyInitialState = state;
};

export const humanSerologyReducer = (state = humanSerologyInitialState, action) => 
    produce(state, (draft) => {
        switch (action.type) {
            case RESET_SESSION:
            case SIGNOUT_REQUEST: {
                return humanSerologyInitialState;
            }

            case RESET_HUMAN_SEROLOGY_DATA: {
                draft.humanSerologyDataStatus = 'none';
                draft.humanSerologyData = {};
                break;
            }
            case FETCH_HUMAN_POOLS_REQUEST: {
                draft.humanPoolsStatus = 'loading';
                break;
            }
            case FETCH_HUMAN_POOLS_SUCCESS: {
                const { humanPools } = action.payload;
                draft.humanPools = humanPools;
                draft.humanPoolsStatus = 'loaded';
                draft.humanSerologyDataStatus = 'refetchNeeded';
                break;
            }
            case FETCH_HUMAN_POOLS_ERROR: {
                draft.humanPoolsStatus = 'error';
                break;
            }

            case FETCH_HUMAN_SEROLOGY_REQUEST: {
                draft.humanSerologyData = {};
                draft.humanSerologyDataStatus = 'loading';
                break;
            }
            case FETCH_HUMAN_SEROLOGY_SUCCESS: {
                const { humanSerologyData, humanSerologyRefStrains } = action.payload;
                draft.humanSerologyData = humanSerologyData;
                draft.humanSerologyRefStrains = humanSerologyRefStrains;
                draft.humanSerologyDataStatus = 'loaded';
                break;
            }

            case FETCH_HUMAN_SEROLOGY_ERROR: {
                draft.humanSerologyDataStatus = 'error';
                break;
            }

            case INIT_STRAIN_TREE_REQUEST:
            case FETCH_SELECTED_STRAIN_REQUEST:
            case ZOOM_TREE_REQUEST:
            case FETCH_VP_VALUES_REQUEST:
            case FETCH_SUBSET_TREE_REQUEST:
            case FETCH_RECALCULATED_TREE_REQUEST:
            {
                draft.humanSerologyRefStrains = [];
                draft.humanSerologyDataStatus = 'refetchNeeded';
                break;
            }
           
            default:
                break;
        };
    });

