import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid2';

// Custom components
import SelectInput from '../Common/SelectInput';

// Redux actions
import { setParameters } from '../../redux/actions/parametersActions';
import { resetHumanSerologyData } from '../../redux/actions/humanSerologyActions';


const humanSerologyDataTypes = [
    { id: 'observed', label: 'Observed' },
    { id: 'inferred', label: 'Inferred' },
];

const HumanSerologySelector = props => {

    const {
        humanPool,
        humanPools,
        humanPoolsStatus,
        humanSerologyDataType,
        setParameters,
        resetData,
        resetHumanSerologyData,
    } = props;


    const handleChange = name => value => {
        setParameters({ [name]: value });
        resetHumanSerologyData();

        if (resetData) {
            resetData();
        }

    };

    return (
        <Grid container spacing={2}>
            {humanPoolsStatus === 'loaded' && (
                <>
                    <Grid size={6}>
                        <SelectInput
                            id='humanPool'
                            label='Human pool'
                            value={humanPool}
                            onChange={handleChange('humanPool')}
                            options={humanPools}
                            getOptionValue={el => el}
                            getOptionLabel={el => el}
                        />
                    </Grid>

                    <Grid size={6}>
                        <SelectInput
                            id='humanSerologyDataType'
                            label='Data type'
                            value={humanSerologyDataType}
                            onChange={handleChange('humanSerologyDataType')}
                            options={humanSerologyDataTypes}
                            getOptionValue={el => el.id}
                            getOptionLabel={el => el.label}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};



HumanSerologySelector.propTypes = {
    humanPool: PropTypes.string,
    humanPools: PropTypes.array,
    humanPoolsStatus: PropTypes.string.isRequired,
    humanSerologyDataType: PropTypes.string,
    setParameters: PropTypes.func.isRequired,
    resetData: PropTypes.func,
    resetHumanSerologyData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const humanPools = state.humanSerology.humanPools;
    const humanPoolsStatus = state.humanSerology.humanPoolsStatus;
    const humanPool = state.parameters.humanPool;
    const humanSerologyDataType = state.parameters.humanSerologyDataType;

    return {
        humanPool,
        humanPools,
        humanPoolsStatus,
        humanSerologyDataType,

    };
};

const mapDispatchToProps = dispatch => ({
    setParameters: payload => dispatch(setParameters(payload)),
    resetHumanSerologyData: () => dispatch(resetHumanSerologyData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HumanSerologySelector);
