import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { emptyObject, isNull } from '../../functions/functions';
import { getScaledValue } from '../../functions/scales';
import { matrixStyles } from './styles/matrixStyles';

const Matrix = (props) => {
    const { rows, columns, data, dataScale, clades, lastColumnLabel, lastColumnScale, lastColumnValues, lastRowLabel, lastRowScale, lastRowValues, hiddenColumns, hiddenRows,
        loading, detailsOpen, showValues, exportMode, componentId,
        setDeletedRows, setDeletedColumns, matrixCellHandleClick, matrixCellHandleMouseOver, wrapTableHeaders
    } = props;

    const getClasses = () => {
        const { classes, exportMode } = props;
        const exportPropsMap = Object.keys(classes)
            .filter((key) => key.match('Export$'))
            .reduce((tmp, key) => {
                tmp[key] = key;
                return tmp;
            }, {});
        const regProps = Object.keys(classes)
            .filter((key) => !key.match('Export$'))
            .map((key) => ({ key, exportKey: exportPropsMap[`${key}Export`] }));
        return regProps.reduce((tmpProps, prop) => {
            tmpProps[prop.key] =
                exportMode && exportPropsMap[prop.exportKey] ? classes[prop.exportKey] : classes[prop.key];
            return tmpProps;
        }, {});
    };

    const classes = getClasses();

    if (emptyObject(data) && !loading)
        return <div className={classes.root}>No data for this combination of parameters</div>;
    if (emptyObject(clades) || !columns || !rows) {
        return <div />;
    }

    const getValue = (col, row) => {
        return emptyObject(data) || loading ? null : data?.[col]?.[row]?.val; //|| data?.[col]?.[row];
    };
    const getLastColumnValue = (i) => (loading ? null : lastColumnValues?.[i]);
    const getLastRowValue = (j) => (loading ? null : lastRowValues?.[j].value);

    function getOppositeColorFromHex(hexcolor) {
        const r = parseInt(hexcolor.substring(1, 3), 16);
        const g = parseInt(hexcolor.substring(3, 5), 16);
        const b = parseInt(hexcolor.substring(5, 7), 16);
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    function getOppositeColorFromRGB(color) {
        const colorArr = color.slice(
            color.indexOf('(') + 1,
            color.indexOf(')')
        ).split(', ');
        const r = colorArr[0];
        const g = colorArr[1];
        const b = colorArr[2];
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    function getOppositeColor(color) {
        return color.includes('rgb') ? getOppositeColorFromRGB(color) : getOppositeColorFromHex(color);
    }

    // console.log(data);
    // console.log(data, rows?.map(c => clades[c].label), columns?.map(c => clades[c].label))
    // console.log(rows?.map(cr => ({ 
    //     clade: cr, 
    //     label: clades[cr].label, 
    //     row: data[cr], 
    //     columns: columns?.map(cc => ({
    //         clade: cc, 
    //         label: clades[cc].label, 
    //         val: data?.[cr]?.[cc]
    //     }))
    // })));
    return (
        // <div className={`${classes.rootData} ${exportMode ? classes.rootEx : ''}`} ref={_element} width="100%" height="100%">
        <div id={`${componentId}_data`} style={{ display: 'inline-block', position: 'sticky' }}>
            <table id={`${componentId}_table`} className={classes.table} aria-busy={loading}>
                <thead>
                    <tr>
                        <th key={`1`} className={`${classes.emptyHeader} ${exportMode ? classes.emptyHeaderEx : ''}`} />
                        {columns.map((col, j) => (
                            <th
                                className={`${classes.headerTh} ${wrapTableHeaders ? classes.wrapped : ''} ${detailsOpen ? '' : classes.stickyHeader} ${exportMode ? classes.headerThEx : ''}`}
                                key={`1_aHeaderCol${j}`}
                                style={{ display: !hiddenColumns[col] ? 'table-cell' : 'none' }}
                                onClick={() => {
                                    if (setDeletedColumns) setDeletedColumns(col);
                                }}
                            >
                                <div className={classes.headerDiv}>
                                    {clades[col].label}
                                </div>
                            </th>
                        ))}
                        {lastColumnValues && (
                            <th className={`${classes.headerTh} ${classes.stickyHeader} ${exportMode ? classes.headerThEx : ''}`}>
                                <div className={classes.headerDiv}>{lastColumnLabel}</div>
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody >
                    {rows.map((row, i) => {
                        const lastColumnValue = getLastColumnValue(i);
                        const lastColumnBackground = getScaledValue(lastColumnScale, lastColumnValue) || '#FFFFFF';
                        const lastColumnTextColor = getOppositeColor(lastColumnBackground);
                        return (
                            <tr
                                key={`aRow${i}`}
                                style={{
                                    display: !hiddenRows?.[row] ? 'table-row' : 'none',
                                }}
                            >
                                <td
                                    className={`${classes.name} ${wrapTableHeaders ? classes.wrappedName : ''} ${exportMode ? classes.nameEx : ''}`}
                                    onClick={() => {
                                        if (setDeletedRows) setDeletedRows(row);
                                    }}
                                >
                                    {clades[row].label}
                                </td>
                                {columns.map((col, j) => {
                                    const value = getValue(j, i); 
                                    // console.log(col, row);
                                    const backgroundColor = getScaledValue(dataScale, value) || '#FFFFFF';
                                    const textColor = getOppositeColor(backgroundColor);
                                    return (
                                        <td
                                            id={`cell_${col}_${row}`}
                                            key={`aVal${i}_${j}`}
                                            onMouseOver={() => {
                                                if (matrixCellHandleMouseOver) matrixCellHandleMouseOver(col, row, value);
                                            }}
                                            onMouseOut={() => {
                                                if (matrixCellHandleMouseOver) matrixCellHandleMouseOver(null, null, null);
                                            }}
                                            onClick={() => { if (matrixCellHandleClick) matrixCellHandleClick(col, row); }}
                                            className={classes.val}
                                            style={{
                                                backgroundColor,
                                                display: !hiddenColumns?.[col] ? 'table-cell' : 'none',
                                                textAlign: 'center',
                                                fontSize: '10px',
                                                alignItems: 'center',
                                                color: textColor
                                            }}
                                            title={value}
                                        >
                                            {showValues && !isNull(value) ? value.toFixed(1) : ''}
                                        </td>
                                    );
                                })}
                                {lastColumnValues && (
                                    <td
                                        className={classes.val}
                                        style={{
                                            backgroundColor: lastColumnBackground,
                                            textAlign: 'center',
                                            fontSize: '10px',
                                            alignItems: 'center',
                                            color: lastColumnTextColor
                                        }}
                                        title={lastColumnValues?.[i]}
                                    >
                                        {showValues && !isNull(lastColumnValue) ? lastColumnValue.toFixed(2) : ''}
                                    </td>)}
                            </tr>
                        );
                    }
                    )}
                    {lastRowValues && (
                        <tr>
                            <td className={`${classes.name} ${exportMode ? classes.nameEx : ''}`}>{lastRowLabel}</td>
                            {lastRowValues.map(({ id }, j) => {
                                const value = getLastRowValue(j);
                                const backgroundColor = getScaledValue(lastRowScale, value);
                                const textColor = getOppositeColor(backgroundColor);
                                const fixedValue = isNull(value) ? '' : value > 99 ? value.toFixed(0) : value.toFixed(1);
                                return (
                                    <td
                                        key={`rhoR_${j}`}
                                        className={classes.val}
                                        style={{
                                            backgroundColor,
                                            display: !hiddenColumns?.[id] ? 'table-cell' : 'none',
                                            textAlign: 'center',
                                            fontSize: '10px',
                                            alignItems: 'center',
                                            color: textColor
                                        }}
                                        title={value}
                                    >
                                        {showValues ? fixedValue : ''}
                                    </td>
                                );
                            })}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};


Matrix.propTypes = {
    exportMode: PropTypes.bool,
};


export default withStyles(matrixStyles)(Matrix);
