import { useEffect, useRef, useCallback } from 'react';

// Hook
export const usePrevious = (value) => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
};


// // Hook
// export const useEventListener = (eventName, handler, element = window) => {
//     // Create a ref that stores handler
//     const savedHandler = useRef();
//     // Update ref.current value if handler changes.
//     // This allows our effect below to always get latest handler ...
//     // ... without us needing to pass it in effect deps array ...
//     // ... and potentially cause effect to re-run every render.
//     useEffect(() => {
//       savedHandler.current = handler;
//     }, [handler]);

//     useEffect(
//       () => {
//         // Make sure element supports addEventListener
//         // On
//         const isSupported = element && element.addEventListener;
//         if (!isSupported) return;
//         // Create event listener that calls handler function stored in ref
//         const eventListener = (event) => savedHandler.current(event);
//         // Add event listener
//         element.addEventListener(eventName, eventListener);
//         // Remove event listener on cleanup
//         return () => {
//           element.removeEventListener(eventName, eventListener);
//         };
//       },
//       [eventName, element] // Re-run if eventName or element changes
//     );
//   }

export const useIsMountedRef = () => {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => isMountedRef.current = false;
    });
    return isMountedRef;
};


export const useEffectAfterMount = (cb, dependencies) => {
    const mounted = useRef(true);

    useEffect(() => {
        if (!mounted.current) {
            return cb();
        }
        mounted.current = false;
    }, dependencies);
};



/**
 * Returns a memoized function that will only call the passed function when it hasn't been called for the wait period
 * @param func The function to be called
 * @param wait Wait period after function hasn't been called for
 * @returns A memoized function that is debounced
 */
export const useDebouncedCallback = (func, wait) => {
    // Use a ref to store the timeout between renders
    // and prevent changes to it from causing re-renders
    const timeout = useRef();

    return useCallback(
        (...args) => {
            const later = () => {
                clearTimeout(timeout.current);
                func(...args);
            };

            clearTimeout(timeout.current);
            timeout.current = setTimeout(later, wait);
        },
        [func, wait]
    );
};


// import { useRef, useEffect } from 'react';

export const useWhyDidYouUpdate = (name, props) => {
    const previousProps = useRef();

    useEffect(() => {
        if (previousProps.current) {
            const allKeys = Object.keys({ ...previousProps.current, ...props });
            const changesObj = {};

            allKeys.forEach(key => {
                if (previousProps.current[key] !== props[key]) {
                    changesObj[key] = {
                        from: previousProps.current[key],
                        to: props[key]
                    };
                }
            });

            if (Object.keys(changesObj).length) {
                console.log(`[${name}] props changed:`, changesObj);
            }
        }

        previousProps.current = props;
    });
};

