export const parametersToEdit = {
    antigenicDataType: 'select',
    antigenicTiterType: 'select',
    regionId: 'select',
    modelRegionId: 'select',
    colorBy: 'select',
    freqCategory: 'select',
    geoMapColorBy: 'select',
    frequencyType: 'select',
    cladeType: 'select',
    cladeBarType: 'select',
    treeScaleTypeX: 'select',
    treeScaleTypeY: 'select',
    vpMethod: 'select',
    layout: 'select',
    mutationsType: 'select',
    displayOrder: 'select',
    modelType: 'modelType',
    mutationsGroup: 'select',
    vaccinesSerumType: 'select',
    mutgene: 'genotype',
    mutposition: 'genotype',
   

    showPrediction: 'boolean',
    displayErrorBars: 'boolean',
    displayGreyZone: 'boolean',
    showCladeLabels: 'boolean',
    showLeaves: 'boolean',
    showInternalNodes: 'boolean',
    showColoredNodesOnly: 'boolean',
    showMutations: 'boolean',
    showMutationsGroups: 'boolean',
    showVaccines: 'boolean',
    showReassortments: 'boolean',
    showLinks: 'boolean',
    showCladeBar: 'boolean',
    showCladeBarLabels: 'boolean',
    ignoreStrainCutOffDate: 'boolean',
    useRegionalCorrections: 'boolean',
    nodeLevelMutiplicities: 'boolean',
    subsetBySubmission: 'boolean',

    // float parameters
    sigmaAg: 'float',
    tau: 'float',
    cladeFrequencyThreshold: 'float',
    minLogSpace: 'float',
   
    // number parameters
    deltaT: 'number',
    freqSpan: 'number', 
    stdDays: 'number',
    seqSpan: 'number',
    seqStdDays: 'number',
    caseSpan: 'number',
    caseStdDays: 'number',
    cladeActiveDays: 'number',
    showLeafNumber: 'number',
    mutationsThreshold: 'number',
    
    zoomNodeId: 'nodeId',

    strainCutOffDate: 'date',
    predictionBaseline: 'date',
    trackingFrom: 'date',
    trackingTo: 'date',
    submissionDate: 'date',
    vaccinesTrackedProtectionDate: 'date',
    vaccinesPredictedProtectionDate: 'date',

    modules: 'modules',
    modelId: 'modelId',
    antigenicModelId: 'antigenicModelId',
    antigenicFitnessModelId: 'antigenicFitnessModelId'
};
