import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableRow, IconButton } from '@mui/material';
import _ from 'lodash';
import { getIsMobile } from '../../../redux/selectors/statusSelector';
import { selectPoint } from '../../../redux/actions/frequenciesActions';
import { emptyObject } from '../../../functions/functions';
import { HideIcon } from '../../Alerts/styles';
import { bindActionCreators } from 'redux';
import ScalesLegend from '../../ColorLegend/Legends/ScalesLegend';
import { getSelectedBins } from '../../../redux/selectors/metadataSelector';
import { usePrevious } from '../../../functions/customHooks';
import { printWhatChanged } from '../../../functions/utils';
import FrequenciesLegend from '../../ColorLegend/Legends/FrequenciesLegend';

const styles = theme => ({
    root: {
        backgroundColor: '#fff',
        height: 'calc(100vh - 70px)',
        maxHeight: 'calc(100vh - 70px)',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'none',
        paddingTop: '5px',
        width: '100%'
    },
    table: {
        borderCollapse: 'collapse',
        padding: '10px 14px',
        height: 'auto',
        '& tbody': {
            display: 'table',
        },
        width: '100%'
    },
    row: {
        fontSize: 12,
        height: 21,
    },
    headRow: {
        fontSize: 12,
        height: 21,
        position: 'relative',
        width: '100%'
    },
    cell: {
        border: 'none',
        // whiteSpace: 'nowrap',
        verticalAlign: 'top',
        lineHeight: '21px',
        fontFamily: 'Inter Bold',
    },
    cellValue: {
        fontWeight: 'normal',
        border: 'none',
        padding: '0 40px 0 15px',
        lineHeight: '21px',
        width: '100%',
        verticalAlign: 'top',
        textAlign: 'left',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
    },
    legendWrapper: {
        padding: '20px 15px 0 15px',
        overflowY: 'overlay',
    },
    divPadding: {
        height: '5px'
    },
    button: {
        position: 'absolute',
        padding: '0 22px 0 0 ',
        right: 0
    },
    infoMobile: {
        position: 'absolute',
        left: '17px',
        width: '90%',
        top: '142px',
        backgroundColor: 'white',
        boxShadow: '0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
        borderRadius: '10px'
    }
});

const useStyles = makeStyles(styles);

let rowId = 0;

const createData = (rowName, value) => {
    if (value !== undefined && value !== '') {
        rowId += 1;
        return { rowId: `${rowId}_${rowName}`, rowName, value };
    }
    return null;
};

const PointInfo = (props) => {
    const { pointData, freqCategory, isMobile, selectPoint, isStacked, visibleBins, selectedBins } = props;
    const { time, freqCategoryValue, freqCategoryLabel, x, y, mx, modelType, modelId, regionId } = pointData;
    const classes = useStyles();
    
    // const prevProps = usePrevious(props); 
    // printWhatChanged(props.pointData, null, prevProps?.pointData, null, 'PointInfo');
    // console.log('[PointInfo]', props);
    if (emptyObject(x)) {
        return null;
    }

    const rows = [
        createData('Time', time),
        createData('Category', freqCategoryLabel),
        createData('Freq X', x),
        createData('Freq Y', y),
        createData('Multiplicity Y', mx),
        createData('Model type', modelType),
        createData('Model id', modelId),
        createData('Region', regionId),
    ].filter(d => d !== null && d !== undefined); // .filter(row => !emptyObject(row.value));

    const handleClick = () => {
        selectPoint();
    };

    return (
        <>
            {!emptyObject(pointData) && (
                isMobile ?
                    <div className={classes.infoMobile}>
                        <Table className={classes.table}>
                            <TableBody >
                                <TableRow className={classes.divPadding} />
                                <TableRow className={classes.row}>
                                        <IconButton className={classes.button} onClick={handleClick} >
                                            <HideIcon />
                                        </IconButton>
                                </TableRow>
                                {rows.map(row => (
                                    <TableRow className={classes.row} key={row.rowId}>
                                        <TableCell className={classes.cellValue} component="td" scope="row">
                                            <span className={classes.cell}>
                                                {`${row.rowName}: `}
                                            </span>
                                            <span >
                                                {row.value}
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                    :
                    <div className={classes.root}>
                        <Table className={classes.table}>
                            <TableBody style={{ width: '100%' }}>
                                <TableRow className={classes.divPadding} />
                                <TableRow className={classes.headRow}>
                                    <TableCell className={classes.cellValue} colSpan={2}>
                                        <IconButton className={classes.button} onClick={handleClick}>
                                            <HideIcon />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                                {rows.map(row => (
                                    <TableRow className={classes.row} key={row.rowId}>
                                        <TableCell className={classes.cellValue} component="td" scope="row">
                                            <span className={classes.cell}>
                                                {`${row.rowName}: `}
                                            </span>
                                            <span >
                                                {row.value}
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                     
                        <div className={classes.legendWrapper}> 
                            <FrequenciesLegend fullHeight={true} valueGetter={() => freqCategoryValue}/>
                        </div>
                    </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    const { parameters, frequenciesData } = state;
    const { pointInfo } = frequenciesData;
    const { chartLayout } = parameters;

    // console.log({pointInfo});
    return ({
        pointData: pointInfo,
        // chartLayout,
        isStacked: chartLayout === 'stacked',
        isMobile: getIsMobile(),
        visibleBins: getSelectedBins(state),
        selectedBins: state.parameters.visibleBins,
        freqCategory: state.parameters.freqCategory
    });
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        selectPoint,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PointInfo);
