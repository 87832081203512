import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getX, getY } from './CladeLabel';

const CladeLabelLink = (props) => {
    const { xMod, yMod, xPos, x, yPos, y, labelWidth, labelHeight, id, onElementRendered, rerenderLabels } = props;

    // const branchWidth = (getScaledValue('freqScale', treeD3.nodeFrequency(id)) || 2) / 2;
    const getClosestCorner = () => {

        const x0 = xMod + x - xPos; // /*- labelWidth - branchWidth*/ + r + rMargin; //branchWidth;
        const y0 = yMod + y - yPos - labelHeight; //- labelHeight/2; //+ branchWidth;
        const corners = [
            { x: x0, y: y0 },
            { x: x0, y: y0 + labelHeight / 2 },
            { x: x0, y: y0 + labelHeight },
            { x: x0 + labelWidth / 2, y: y0 },
            { x: x0 + labelWidth / 2, y: y0 + labelHeight / 2 },
            { x: x0 + labelWidth / 2, y: y0 + labelHeight },
            { x: x0 + labelWidth, y: y0 },
            { x: x0 + labelWidth, y: y0 + labelHeight / 2 },
            { x: x0 + labelWidth, y: y0 + labelHeight },
        ];

        const getDistance = (p1, p2) => Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);
        const anchorPoint = { x: 0, y: 0 }; // this.getAnchorPoint(d);
        const { selected } = corners.reduce((acc, c, index) => {
            const dist = getDistance(c, anchorPoint);
            if (!acc.dist || dist < acc.dist) {
                acc.selected = index;
                acc.dist = dist;
            }
            return acc;
        }, {});
        return corners[selected];
    };

    const linkDiagonal = () => {
        const corner = getClosestCorner();
        return `M${corner.x},${corner.y},L0,0`;
    };

    useEffect(() => {
        //console.log('[CladeLabelLink].rerenderLabels', rerenderLabels)
        if (rerenderLabels) {
            //console.log('[CladeLabelLink].back')
            onElementRendered();
        }
    }, [onElementRendered]);

    return (
        <path
            transform={`translate(${xPos}, ${yPos})`}
            id={`${id}_cladeLabelLink`}
            style={{
                stroke: '#000000',
                strokeWidth: 1
            }}
            d={linkDiagonal()} />

    );
};


const mapStateToProps = (state, ownProps) => {
    //const treeAttrs = getTreeNodeAttrs(state);

    const labelPos = state.render.labels?.[ownProps.classNamePrefix]?.[ownProps.id] || {};
    const xPos = getX(ownProps.id);
    const yPos = getY(ownProps.id);
    return {
        xPos,
        yPos,
        ...labelPos,
        x: labelPos?.x || xPos,
        y: labelPos?.y || yPos,
        labelWidth: labelPos?.labelWidth || 0,
        labelHeight: labelPos?.labelHeight || 0,
        xMod: labelPos?.xMod || 0,
        yMod: labelPos?.yMod || 0,
    };
};

export default connect(mapStateToProps)(CladeLabelLink);
