export const stratify = (data, rootId) => {
    const hashTable = {};
    data.forEach((aData) => (hashTable[aData.id] = { ...aData, children: [] }));
    const dataTree = [];
    data.forEach((aData) => {
        //if (hashTable[aData.p] || aData.id == rootId) {
        if (aData.p && aData.id != rootId && hashTable[aData.p]) hashTable[aData.p].children.push(hashTable[aData.id]);
        else {
            if (rootId && aData.id == rootId) dataTree.push(hashTable[aData.id]);
            else if (!aData.p && !rootId) dataTree.push(hashTable[aData.id]);
            // console.log(aData);
        }
        //} else console.log('EEERRR', aData.id, 'rootId', rootId);
    });
    //console.log(`[stratify], rootId = ${rootId}`);
    // console.log(dataTree, data);
    return dataTree.length > 0 ? dataTree[0] : null;
};
