import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const styles = {
    openMenuMobile: {
        position: 'absolute',
        marginLeft: '80vw',
        marginTop: '1vh',
        color: 'red'
    },
};
const useStyles = makeStyles(styles);

const MenuMobileIcon = ({handleMenuOpenMobile}) => {
    const classes = useStyles();
    return <IconButton className={classes.openMenuMobile} onClick={handleMenuOpenMobile} >
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="47" viewBox="0 0 46 47" fill="none">
            <g filter="url(#filter0_d_5_3805)">
                <ellipse cx="23" cy="19.4218" rx="19" ry="19.045" fill="#F1F1F1" />
                <path d="M41.5 19.4218C41.5 29.665 33.2162 37.9668 23 37.9668C12.7838 37.9668 4.5 29.665 4.5 19.4218C4.5 9.17854 12.7838 0.87677 23 0.87677C33.2162 0.87677 41.5 9.17854 41.5 19.4218Z" stroke="#DCDCDC" />
            </g>
            <line x1="17" y1="15.4147" x2="23" y2="15.4147" stroke="#636262" strokeWidth="2" strokeLinecap="round" />
            <line x1="24" y1="23.4336" x2="30" y2="23.4336" stroke="#636262" strokeWidth="2" strokeLinecap="round" />
            <path d="M30.35 15.4123C30.35 16.7156 29.2964 17.7694 28 17.7694C26.7036 17.7694 25.65 16.7156 25.65 15.4123C25.65 14.1091 26.7036 13.0552 28 13.0552C29.2964 13.0552 30.35 14.1091 30.35 15.4123Z" stroke="#636262" strokeWidth="1.3" />
            <path d="M21.35 23.4313C21.35 24.7345 20.2964 25.7884 19 25.7884C17.7036 25.7884 16.65 24.7345 16.65 23.4313C16.65 22.128 17.7036 21.0742 19 21.0742C20.2964 21.0742 21.35 22.128 21.35 23.4313Z" stroke="#636262" strokeWidth="1.3" />
            <defs>
                <filter id="filter0_d_5_3805" x="0" y="0.37677" width="46" height="46.09" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_3805" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_3805" result="shape" />
                </filter>
            </defs>
        </svg>
    </IconButton>;
};

export default MenuMobileIcon;
