import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const ErrorIcon = ({ isLine }) => {
    const paddingVal = isLine ? '2px 7px 0px 0px' : '8px 7px 0px 0px';
    const color = isLine ? '#FF4E54' : '#B0B0B0';

    return (
        <span style={{ padding: paddingVal, color: color }}>
            <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.7885 14.4783H2.4043C1.42795 14.4783 0.646868 14.0911 0.256328 13.433C-0.134212 12.7749 -0.0756309 11.9233 0.412544 11.0716L6.13396 1.31621C6.62213 0.483899 7.3251 0 8.10618 0C8.88726 0 9.59023 0.483899 10.0784 1.31621L15.7608 11.091C16.2489 11.9233 16.3075 12.7943 15.917 13.4524C15.546 14.0911 14.7649 14.4783 13.7885 14.4783ZM8.10618 1.35492C7.83281 1.35492 7.55943 1.58719 7.30558 1.99366L1.62322 11.7684C1.38889 12.1749 1.33031 12.5427 1.467 12.7749C1.60369 13.0072 1.95518 13.1427 2.42383 13.1427H13.8081C14.2767 13.1427 14.6282 13.0072 14.7649 12.7749C14.9016 12.5427 14.843 12.1749 14.6087 11.7684L8.88726 1.99366C8.65294 1.58719 8.36003 1.35492 8.10618 1.35492Z" fill={color} />
                <path d="M8.10618 11.8265C8.7856 11.8265 9.33638 11.2806 9.33638 10.6071C9.33638 9.93365 8.7856 9.3877 8.10618 9.3877C7.42676 9.3877 6.87598 9.93365 6.87598 10.6071C6.87598 11.2806 7.42676 11.8265 8.10618 11.8265Z" fill={color} />
                <path d="M8.37962 4.4906C8.2234 4.4906 7.96955 4.4906 7.83286 4.4906H7.4228C7.26658 4.4906 7.16895 4.60674 7.16895 4.76158L7.28611 5.84552C7.30563 6.00037 7.32516 6.23264 7.34469 6.38748L7.57901 8.30373C7.59854 8.45857 7.7157 8.57471 7.85239 8.57471C7.98908 8.57471 8.20388 8.57471 8.34057 8.57471C8.47726 8.57471 8.59442 8.45857 8.61394 8.30373L8.84827 6.38748C8.8678 6.23264 8.88732 6.00037 8.90685 5.84552L9.02401 4.76158C9.04354 4.60674 8.92638 4.4906 8.77016 4.4906H8.37962Z" fill={color} />
            </svg>
        </span>
    );
};

const ExpandIcon = ({ color = '#555454' }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="7.61133" y1="0.5" x2="7.61133" y2="15.5" stroke={color} strokeLinecap="round" />
            <line x1="0.5" y1="8.38889" x2="15.5" y2="8.38889" stroke={color} strokeLinecap="round" />
        </svg>
    );
};

const HideIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="6.42195" y1="6.97156" x2="17.0286" y2="17.5782" stroke="#555454" strokeLinecap="round" />
            <line x1="6.97168" y1="17.5783" x2="17.5783" y2="6.97169" stroke="#555454" strokeLinecap="round" />
        </svg>
    );
};

const InfoIcon = ({ isLine }) => {
    const paddingVal = isLine ? '2px 7px 0px 0px' : '8px 7px 0px 0px';
    const color = isLine ? '#008AF2' : '#B0B0B0';

    return (
        <span style={{
            padding: paddingVal,
            color: color
        }}>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.23913 6.31157C7.94074 6.31157 8.5095 5.7428 8.5095 5.04119C8.5095 4.33958 7.94074 3.77081 7.23913 3.77081C6.53752 3.77081 5.96875 4.33958 5.96875 5.04119C5.96875 5.7428 6.53752 6.31157 7.23913 6.31157Z" fill={color} />
                <path d="M6.95635 11.4132C7.11767 11.4132 7.37981 11.4132 7.52097 11.4132H7.94442C8.10574 11.4132 8.20657 11.2922 8.20657 11.1309L8.06541 10.0017C8.04525 9.84035 8.02508 9.59838 8.00492 9.43706L7.76294 7.44075C7.74278 7.27944 7.62179 7.15845 7.48064 7.15845C7.33948 7.15845 7.11767 7.15845 6.97652 7.15845C6.83536 7.15845 6.71438 7.27944 6.69421 7.44075L6.45224 9.43706C6.43207 9.59838 6.41191 9.84035 6.39174 10.0017L6.27075 11.1309C6.25059 11.2922 6.37158 11.4132 6.53289 11.4132H6.95635Z" fill={color} />
                <path d="M7.23913 14.4783C3.24652 14.4783 0 11.2317 0 7.23913C0 3.24652 3.24652 0 7.23913 0C11.2317 0 14.4783 3.24652 14.4783 7.23913C14.4783 11.2317 11.2317 14.4783 7.23913 14.4783ZM7.23913 1.41153C4.03294 1.41153 1.41153 4.03294 1.41153 7.23913C1.41153 10.4453 4.03294 13.0667 7.23913 13.0667C10.4453 13.0667 13.0667 10.4453 13.0667 7.23913C13.0667 4.03294 10.4453 1.41153 7.23913 1.41153Z" fill={color} />
            </svg>
        </span>
    );
};

const styles = makeStyles({
    root: {
        position: 'relative',
        height: '47px'
    },
    rootNotExpanded: {
        backgroundColor: '#F0F0F0',
        borderRadius: '4px',
        zIndex: 100,
        padding: 0,
        marginBottom: '15px',
        minWidth: 120,
        height: '32px',
        // boxShadow: '0 !important'
    },
    rootExpandedTree: {
        position: 'absolute',
        backgroundColor: '#F2F2F2',
        zIndex: 99,
        padding: 0,
        marginBottom: '15px',
        minWidth: 120,
        // boxShadow: 0,
        width: '100%',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    rootExpandedAntigenic: {
        position: 'absolute',
        backgroundColor: '#F2F2F2',
        zIndex: 99,
        padding: 0,
        marginBottom: '15px',
        minWidth: 120,
        // boxShadow: 0,
        width: '100%',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    cardActions: {
        padding: '0px 7px 0px 10px'
    },
    cardActionsExpanded: {
        padding: '0px 4px 0px 10px'
    },
    content: {
        paddingTop: 0,
        paddingBottom: '8px'
    },
    close: {
        marginTop: '-10px',
        marginRight: '-20px'
    },
    add: {
        padding: 0
    },
    typography: {
        display: 'inline-flex',
        VerticalAlign: 'text-bottom',
        BoxSizing: 'inherit',
        paddingBottom: '7px'
    },
    colorError: {
        color: '#ff3333'
    },
    colorInfo: {
        color: '#4666d0'
    },
    span: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        paddingTop: '1px'
    }
});

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;

    return <IconButton style={!expand ? { padding: '0 0 0 0' } : {}} {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : '"translate(20, rotate(180)"',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export {
    ExpandMore,
    ErrorIcon,
    InfoIcon,
    ExpandIcon,
    HideIcon,
    styles
};
