export const schemaWidth = 600;
export const schemaWidthInit = 700;
export const schemaHeightMultiplierInit = 25;
export const schemaHeightMultiplierWithMutations = 40;
export const maxBranchWidth = 100;
export const schemaHeight = 300;
export const schemaPadding = 20;
export const findEdgeValues = (nodes) => {
    let max = 0;
    let min = nodes[0].data.startTime;
    nodes.forEach((node) => {
        max = max > node.data.endTime ? max : node.data.endTime;
        min = min < node.data.startTime ? min : node.data.startTime;
    });
    return [min, max];
};

export const linkGenerator = (scaleTime, scaleOrder, cladeDict) => (d) => {
    const parent = d.parent;
    const endY = d.data.order;

    if (parent)
        return (
            `M${scaleTime(cladeDict[d.data.id].startTime)},${scaleOrder(parent.data.order)}` +
            `L${scaleTime(cladeDict[d.data.id].startTime)},${scaleOrder(endY)}` +
            `L${scaleTime(cladeDict[d.data.id].endTime)},${scaleOrder(endY)}`
        );
    else
        return (
            `M${scaleTime(cladeDict[d.data.id].startTime)},${scaleOrder(endY)}` + `L${scaleTime(cladeDict[d.data.id].endTime)},${scaleOrder(endY)}`
        );
};

export const closureGenerator = (scaleTime, scaleOrder, cladeDict) => (d) => `M${scaleTime(cladeDict[d.data.id].endTime)},${scaleOrder(d.data.order)-6}L${scaleTime(cladeDict[d.data.id].endTime)},${scaleOrder(d.data.order)+6}`;

