import { produce } from 'immer';
import {
    FETCH_MODELS_REQUEST,
    FETCH_MODELS_SUCCESS,
    FETCH_MODEL_TYPES_REQUEST,
    FETCH_MODEL_TYPES_SUCCESS,
    FETCH_MODELS_ERROR,
    FETCH_MODEL_TYPES_ERROR,
    FETCH_MODEL_DATA_REQUEST,
    FETCH_MODEL_DATA_SUCCESS,
    FETCH_MODEL_DATA_ERROR,
    FETCH_SELECTED_STRAIN_REQUEST,
    SET_MODEL_TYPE,
    SIGNOUT_REQUEST,
    RESET_SESSION,
    ZOOM_TREE_REQUEST,
    INIT_STRAIN_TREE_REQUEST,
    FETCH_VP_VALUES_REQUEST,
    FETCH_SUBSET_TREE_REQUEST,
    FETCH_RECALCULATED_TREE_REQUEST,
    RESET_MODEL_DATA,
    SET_MODEL_REGION_ID,
    RESET_MODEL_TYPES
} from '../actions/actionTypes';

import { emptyObject, emptyObjectOrArray } from '../../functions/functions';

let modelsInitialState = { };
export const setModelsInitialState = (state) => {
    modelsInitialState = state;
};

let modelDataInitalState = { };
export const setModelDataInitalState = (state) => {
    modelDataInitalState = state;
};

export const modelsReducer = (state = modelsInitialState, action) => {
    if (action.payload && action.payload.settings) return state;
    
    return produce(state, draft => {
        switch (action.type) {
            case RESET_SESSION:
            case SIGNOUT_REQUEST: {
                return modelsInitialState;
            }
            case FETCH_MODEL_TYPES_REQUEST: {
                const { colorBy } = action.payload;
                draft.modelTypesStatus[colorBy] = 'loading';
                break;
            }
            case FETCH_MODELS_REQUEST: {
                const { colorBy } = action.payload;
                draft.modelsStatus[colorBy] = 'loading';
                break;
            }
            case FETCH_MODELS_SUCCESS: {
                const { models, colorBy } = action.payload;
                draft.models[colorBy] = emptyObject(models) ? [] : models;
                draft.modelsStatus[colorBy] = (!emptyObjectOrArray(models)) ? 'loaded' : 'nodata';
                break;
            }
            case FETCH_MODEL_TYPES_SUCCESS: {
                const { modelTypes, colorBy } = action.payload;
                draft.modelTypes[colorBy] = modelTypes;
                draft.modelTypesStatus[colorBy] = modelTypes && modelTypes.length > 0 ? 'loaded' : 'nodata';
                break;
            }
            case FETCH_MODEL_TYPES_ERROR:
            case FETCH_MODELS_ERROR: {
                const { colorBy } = action.payload;
                const modelsVar = colorBy === 'antigenic' ? 'antigenicModels': 'models';
                draft[`${modelsVar}Status`] = 'error';
                break;
            }
            case SET_MODEL_REGION_ID: {
                const { colorBy } = action.payload;
                draft.modelTypesStatus[colorBy] = 'none';
                draft.modelsStatus[colorBy] = 'none';
                break;
            }
            case SET_MODEL_TYPE: {
                const { colorBy } = action.payload;
                draft.modelsStatus[colorBy] = 'none';
                break;
            }
            case RESET_MODEL_TYPES: {
                draft.modelsStatus = modelsInitialState.modelsStatus;
                draft.modelTypesStatus = modelsInitialState.modelTypesStatus;
                break;
            }
            default: {
                break;
            }
        }
    });
};

export const modelDataReducer = (state = modelDataInitalState, action) => {
    if (action.payload && action.payload.settings) return state;

    return produce(state, draft => {
        switch (action.type) {
            case RESET_SESSION:
            case SIGNOUT_REQUEST: {
                return modelDataInitalState;
            }
            case FETCH_MODEL_DATA_REQUEST: {
                const { colorBy } = action.payload;
                draft.modelStatus[colorBy] = 'loading';
                break;
            }
            case FETCH_MODEL_DATA_SUCCESS: {
                const { treeModel, colorBy } = action.payload;
                draft.model[colorBy] = treeModel;
                draft.modelStatus[colorBy] = emptyObject(treeModel) ? 'nodata' : 'loaded';
                break;
            }
            case FETCH_MODEL_DATA_ERROR: {
                const { colorBy } = action.payload;
                draft.modelStatus[colorBy] = 'error';
                break;
            }
            case SET_MODEL_TYPE: {
                const { colorBy } = action.payload;
                draft.modelStatus[colorBy] = 'none';
                break;
            }
            case FETCH_MODELS_REQUEST: {
                return modelDataInitalState;
            }
            case INIT_STRAIN_TREE_REQUEST:
            case FETCH_SELECTED_STRAIN_REQUEST:
            case ZOOM_TREE_REQUEST:
            case FETCH_VP_VALUES_REQUEST:
            case FETCH_SUBSET_TREE_REQUEST:
            case FETCH_RECALCULATED_TREE_REQUEST:
            case RESET_MODEL_DATA: {
                const nodata = action.payload?.nodata;
                Object.keys(state.modelStatus).forEach(modelVar => {
                    draft.modelStatus[modelVar] = nodata ? 'nodata' : 'refetchNeeded';
                });
                draft.model = modelDataInitalState.model;
                break;
            }
            default:{
                break;
            }
        }
    });

};


