import { produce } from 'immer';
import { isNil } from 'lodash';

import { 
    SET_AUTOCOMPLETE_OPTIONS,
    RESET_SESSION,
    SIGNOUT_REQUEST
} from '../actions/actionTypes';



let autocompleteInitialState = { };

export const autocompleteReducer = (state = autocompleteInitialState, action) => 
    produce(state, (draft) => {
        switch (action.type) {
            case RESET_SESSION:
            case SIGNOUT_REQUEST: {
                return autocompleteInitialState;
            }
            case SET_AUTOCOMPLETE_OPTIONS: {
                const { autocompleteId, options } = action.payload;
                draft[autocompleteId] = options;
                break;
            }
            default:
                break;
        }
    });

