import { produce } from 'immer';
import { isNil } from 'lodash';

import { 
    FETCH_FITNESS_MODEL_REQUEST,
    FETCH_FITNESS_MODEL_SUCCESS,
    FETCH_FITNESS_MODEL_ERROR,
    RESET_SESSION,
    RESET_ANTIGENIC_FITNESS_MODEL,
    SIGNOUT_REQUEST
} from '../actions/actionTypes';



let fitnessInitialState = { };
export const setFitnessInitialState = (state) => {
    fitnessInitialState = state;
};

export const fitnessReducer = (state = fitnessInitialState, action) => 
    produce(state, (draft) => {
        switch (action.type) {
            case RESET_SESSION:
            case SIGNOUT_REQUEST: 
            case RESET_ANTIGENIC_FITNESS_MODEL: {
                return fitnessInitialState;
            }
            case FETCH_FITNESS_MODEL_REQUEST: {
                draft.fitnessModelStatus = 'loading';
                break;
            }
            case FETCH_FITNESS_MODEL_SUCCESS: {
                const { fitnessModel } = action.payload;
                draft.fitnessModel = fitnessModel;
                draft.fitnessModelStatus = isNil(fitnessModel) ? 'nodata' : 'loaded';
                break;
            }
            case FETCH_FITNESS_MODEL_ERROR: {
                draft.fitnessModelStatus = 'error';
                break;
            }
            default:
                break;
        }
    });

