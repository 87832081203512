import React from 'react';
import DeleteSubset from '../../../../pages/SettingsPanel/Subsets/Actions/Delete';
import EditSubset from '../../../../pages/SettingsPanel/Subsets/Actions/Edit';
import { TableCell } from '@mui/material';

const SubsetCells = (props) => {
    const { row, setInfo, setInfoDialog, setAction } = props;

    return (
        <>
            <TableCell component="th" scope="row">
                {row.key}
            </TableCell>
            <TableCell align="left">
                {row.lineage}
            </TableCell>
            <TableCell align="left">
                {row.label}
            </TableCell>
            <TableCell align="left">
                {row.rule}
            </TableCell>
            <TableCell align="left">
                <DeleteSubset
                    subset={row}
                    setInfo={setInfo}
                    setInfoDialog={setInfoDialog}
                    setAction={setAction}
                />
                <EditSubset
                    subset={row}
                    setInfo={setInfo}
                    setInfoDialog={setInfoDialog}
                    setAction={setAction}
                />
            </TableCell>
        </>
    );
};

export default SubsetCells;
