import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { RENDER_STATUS } from '../../../config/consts';
import { setComponentStatus } from '../../../redux/actions/renderActions';
import { getStrainTreeStatus } from '../../../redux/selectors/statusSelector';
import { treeD3 } from '../d3/TreeD3';
// import { printWhatChanged } from '../../../functions/utils';

const viewToRender = 'strainTree';
const componentId = 'vaccines';

const VaccinesLayer = (props) => {
    const _element = useRef();

    const { loading, showVaccines, renderStatus, setComponentStatus } = props;

    const startRender = async () => {
        if (renderStatus !== RENDER_STATUS.START) setComponentStatus(viewToRender, componentId, RENDER_STATUS.START);
    };

    const stopRender = () => {
        //if (renderStatus !== RENDER_STATUS.DONE)
        setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE);
    };

    useEffect(() => {
        if (renderStatus !== RENDER_STATUS.NONE) return;
        startRender()
            .then(() => treeD3.translateVaccines())
            .then(() => stopRender());
    }, [renderStatus]);

    useEffect(() => {
        if (loading) return;
        startRender()
            .then(() => treeD3.drawVaccinesLayer(_element.current))
            .then(() => stopRender());
    }, [loading, showVaccines]);
    return <g id="vaccines" transform={treeD3.translate(false)} ref={_element} />;
};

VaccinesLayer.propTypes = {
    layout: PropTypes.string,
    renderStatus: PropTypes.string,
    loading: PropTypes.bool,
    showVaccines: PropTypes.bool,
    setComponentStatus: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
    const loading = getStrainTreeStatus(state) || !ownProps.initialized;

    return {
        loading,
        renderStatus: state.render.viewToRender ? state.render.viewToRender.components[componentId] : null,
        layout: state.parameters.layout,
        showVaccines: state.parameters.showVaccines,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setComponentStatus,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(VaccinesLayer);
