
import React from 'react';
import { TableSortLabel } from '@mui/material';
import { StyledTableCell } from './styles';

const TableHeader = (props) => {
    const { handleSort, label, name, order } = props;
    
    const handleClick = () => {
        if (name !== 'actions')
            handleSort(name)
    };

    return (
        <StyledTableCell>
            <TableSortLabel
                active={order !== 'none'}
                direction={order !== 'none' ? order : 'asc'}
                onClick={handleClick}
            >
                {label}
            </TableSortLabel>
        </StyledTableCell>
    );
};

export default TableHeader;