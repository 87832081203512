import { produce } from 'immer';
import {
    SIGNOUT_REQUEST,
    RESET_SESSION,
    RESET_VACCINES_DATA,
    RESET_VACCINES_FREQUENCIES,
    RESET_VACCINES_PREDICTIONS,
    FETCH_VACCINE_PROTECTION_VALUES_REQUEST,
    FETCH_VACCINE_PROTECTION_VALUES_SUCCESS,
    FETCH_VACCINE_PROTECTION_VALUES_ERROR,
    FETCH_VACCINE_DEFAULT_SELECTIONS_REQUEST,
    FETCH_VACCINE_DEFAULT_SELECTIONS_SUCCESS,
    FETCH_VACCINE_DEFAULT_SELECTIONS_ERROR,
    FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_REQUEST,
    FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_SUCCESS,
    FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_ERROR,
    FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_REQUEST,
    FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_SUCCESS,
    FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_ERROR
} from '../actions/actionTypes';


let vaccinesInitialState = {};
export const setVaccinesInitialState = (state) => {
    vaccinesInitialState = state;
};

export const vaccinesReducer = (state = vaccinesInitialState, action) => 
    produce(state, (draft) => {
        switch (action.type) {
            case RESET_SESSION:
            case SIGNOUT_REQUEST: {
                return vaccinesInitialState;
            }

            case RESET_VACCINES_DATA: {
                draft.vaccinesDataStatus = 'none';
                draft.vaccinesData = {};
                break;
            }
            case RESET_VACCINES_FREQUENCIES: {
                draft.vaccinesFrequenciesStatus = 'none';
                draft.vaccinesFrequencies = {};
                break;
            }
            case RESET_VACCINES_PREDICTIONS: {
                draft.vaccinesPredictionsStatus = 'none';
                draft.vaccinesPredictions = {};
                break;
            }
            case FETCH_VACCINE_PROTECTION_VALUES_REQUEST: {
                draft.vaccinesDataStatus = 'loading';
                break;
            }
            case FETCH_VACCINE_PROTECTION_VALUES_SUCCESS: {
                const vaccineProtectionValues = action.payload;
                draft.vaccinesData = vaccineProtectionValues;
                draft.vaccinesDataStatus = 'loaded';
                break;
            }
            case FETCH_VACCINE_PROTECTION_VALUES_ERROR: {
                draft.vaccinesDataStatus = 'error';
                break;
            }

            case FETCH_VACCINE_DEFAULT_SELECTIONS_REQUEST: {
                // draft.vaccinesDefaults = {};
                draft.vaccinesDefaultsStatus = 'loading';
                break;
            }
            case FETCH_VACCINE_DEFAULT_SELECTIONS_SUCCESS: {
                // const vaccineDefaultSelections = action.payload;
                // draft.vaccinesDefaults = vaccineDefaultSelections;
                draft.vaccinesDefaultsStatus = 'loaded';
                break;
            }

            case FETCH_VACCINE_DEFAULT_SELECTIONS_ERROR: {
                draft.vaccinesDefaultsStatus = 'error';
                break;
            }

            case FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_REQUEST: {
                draft.vaccinesAntigenicCladeFrequenciesStatus = 'loading';
                break;
            }
            case FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_SUCCESS: {
                const vaccinesFrequencies = action.payload;
                draft.vaccinesFrequencies = vaccinesFrequencies;
                draft.vaccinesFrequenciesStatus = 'loaded';
                break;
            }
            case FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_ERROR: {
                draft.vaccinesFrequenciesStatus = 'error';
                break;
            }
            case FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_REQUEST: {
                draft.vaccinesPredictionsStatus = 'loading';
                break;
            }
            case FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_SUCCESS: {
                const vaccinesPredictions = action.payload;
                draft.vaccinesPredictions = vaccinesPredictions;
                draft.vaccinesPredictionsStatus = 'loaded';
                break;
            }
            case FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_ERROR: {
                draft.vaccinesPredictionsStatus = 'error';
                break;
            }
            default:
                break;
        };
    });

