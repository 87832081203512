import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useStyles } from './styles';
import Modal from '@mui/material/Modal';
import { Box, LinearProgress } from '@mui/material';
import {
    getStrainTreeStatus, getChartDataStatus, antigenicModelStatusSelector, antigenicRawModelStatusSelector,
    getStrainTreeErrorStatus,
    getGeoMapDataStatus
} from '../../redux/selectors/statusSelector';
import { RENDER_STATUS } from '../../config/consts';
import config from '../../config/envConfig';


const LoadProgress = props => {
    const { loadingStrainTree, loadingChartStatus, loadingAntigenicModelStatus, loadingRawAntigenicModelStatus,
        /*detailsOpen,*/loadingGeoMap, /*geoLoading, loadingTreeRenderStatus,*/ strainTreeErrorStatus, lineageCnt, renderStatus } = props;
    const location = useLocation();
    const { pathname } = location;

    const startTime = useRef();
    const endTime = useRef();

    const classes = useStyles();

    const isStrainTreeTab = (pathname === `${config.frontendPrefix}/strainTree` || pathname === `${config.frontendPrefix}/` || pathname === `${config.frontendPrefix}/intro`);
    const strainTreeLoading = (!strainTreeErrorStatus && isStrainTreeTab)
        ? (loadingStrainTree || renderStatus !== RENDER_STATUS.DONE)
        : false;

    // console.log(`loadingStrainTree = ${loadingStrainTree}`, {strainTreeErrorStatus, loadingStrainTree, strainTreeLoading, renderStatus});
    const frequenciesLoading = (pathname === `${config.frontendPrefix}/frequencies` || pathname === `${config.frontendPrefix}/intro-freq` || pathname === `${config.frontendPrefix}/prediction`)
        ? loadingChartStatus
        : false;

    const antigenicLoading = (pathname === `${config.frontendPrefix}/antigenic`)
        ? (loadingAntigenicModelStatus || (loadingRawAntigenicModelStatus /*&& detailsOpen*/))
        : false;

    // console.log(`antigenicLoading = ${antigenicLoading}
    // loadingAntigenicModelStatus = ${loadingAntigenicModelStatus}
    // loadingRawAntigenicModelStatus = ${loadingRawAntigenicModelStatus}`);
    const geomapLoading = (pathname === `${config.frontendPrefix}/geomap`)
        ? loadingGeoMap // (geoMapStatus === 'loading') // geoLoading) // || loadingStrainTree)
        : false;

    const loading = strainTreeLoading || frequenciesLoading || antigenicLoading || geomapLoading;


    const showLoadProgress = useMemo(() => { return loading && lineageCnt > 0; }, [loading, lineageCnt]);

    // console.log(`[LoadProgress] showLoadProgress = ${`${showLoadProgress}`.toUpperCase()}
    // // isStrainTreeTab = ${isStrainTreeTab}
    // // lineageCnt = ${lineageCnt}
    // // strainTreeLoading = ${strainTreeLoading}
    // // strainTreeErrorStatus = ${strainTreeErrorStatus}
    // // isStrainTreeTab = ${isStrainTreeTab}
    // // loadingStrainTree = ${loadingStrainTree} 
    // // antigenicLoading = ${antigenicLoading}
    // // renderStatus = ${renderStatus}
    // // frequenciesLoading = ${frequenciesLoading}
    // // loadingChartStatus = ${loadingChartStatus}`);

    useEffect(() => {
        if (loading) startTime.current = performance.now();
        else if (startTime.current) {
            endTime.current = performance.now();
            // console.log('renderTime', endTime.current - startTime.current);
        }
        // setShowLoadProgress(showLoadProgr);

    }, [loading]);

    //console.log('!!!!!!!![LoadProgress] LOAD_PROGRESS, LoadProgress: showLoadProgress', showLoadProgress, 'loading = ', loading);
    // useEffect(() => {
    //     console.log('LOAD_PROGRESS, LoadProgress: showLoadProgress', showLoadProgress, classes.root);

    // }, [showLoadProgress]);

    return (
        showLoadProgress && (
            <div className={classes.root} style={{ zIndex: 99, position: 'absolute', overflow: 'auto', width: '100%' }} id='loader'>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
                <Modal open >
                    <span></span>
                </Modal>
            </div>
        )
    );
};

LoadProgress.propTypes = {
    loadingStrainTree: PropTypes.bool,
    loadingChartStatus: PropTypes.bool,
    loadingAntigenicModelStatus: PropTypes.bool,
    firstRenderStatus: PropTypes.bool,
    location: PropTypes.shape({ pathname: PropTypes.string })
};

const mapStateToProps = (state) => {
    const loadingStrainTree = getStrainTreeStatus(state);
    const strainTreeErrorStatus = getStrainTreeErrorStatus(state);

    //console.log('mstp', loadingTreeRenderStatus)
    const loadingChartStatus = getChartDataStatus(state);
    const loadingAntigenicModelStatus = antigenicModelStatusSelector(state);
    // const detailsOpen = state.antigenic.detailsOpen;
    const loadingRawAntigenicModelStatus = antigenicRawModelStatusSelector(state);
    //const firstRenderStatus = getFirstRenderStatus(state);
    //const geoLoading = state.geomap.geoLoading;
    const geoMapStatus = state.geomap.geoMapStatus;
    const lineages = state.lineages.lineages;
  

    return {
        loadingStrainTree,
        strainTreeErrorStatus,
        loadingChartStatus,
        loadingAntigenicModelStatus,
        //firstRenderStatus,
        loadingRawAntigenicModelStatus,
        // loadingTreeRenderStatus,
        // detailsOpen,
        geoMapStatus,
        loadingGeoMap: getGeoMapDataStatus(state),

        // geoLoading,
        lineageCnt: (lineages || []).length,
        renderStatus: state.render.renderStatus

    };
};

export default connect(mapStateToProps, null)(LoadProgress);
