import axios from 'axios';
import config from '../config/envConfig';

export const fetchAxios = (url) => {
    const tabId = sessionStorage.getItem('tabId');
    // const token = sessionStorage.getItem('jwt');
    return axios({
        headers: {
            'X-Tab-Id': tabId,
            'Secret': config.REACT_APP_FRONTEND_SECRET_HEADER,
            // 'Authorization': `Bearer ${token}`
            
        },
        method: 'get',
        url,
        responseType: 'json',
        withCredentials: true,
    });
};

export const exportAxios = (url) => {
    const tabId = sessionStorage.getItem('tabId');
    return axios({
        headers: {
            'X-Tab-Id': tabId,
            'Secret': config.REACT_APP_FRONTEND_SECRET_HEADER
        },
        method: 'get',
        url,
        responseType: 'blob',
        withCredentials: true
    });
};

export const postAxios = (url, data) => {
    const tabId = sessionStorage.getItem('tabId');
    // console.log(url, data);
    // const token = sessionStorage.getItem('jwt');
    return axios({
        headers: {
            'X-Tab-Id': tabId,
            'Secret': config.REACT_APP_FRONTEND_SECRET_HEADER,
            // 'Authorization': `Bearer ${token}`
        },
        method: 'post',
        url,
        responseType: 'json',
        withCredentials: true,
        data
    });
};

export const login = (user, url) => {
    const tabId = sessionStorage.getItem('tabId');
    return axios({
        headers: {
            'X-Tab-Id': tabId,
            'Secret': config.REACT_APP_FRONTEND_SECRET_HEADER
        },
        method: 'post',
        url,
        responseType: 'json',
        withCredentials: true,
        data: {
            email: user.email,
            password: user.password
        }
    });
};
