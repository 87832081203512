import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { styles } from './styles';

let rowId = 0;

const createData = (id, rowName, value, measures) => {
    rowId += 1;
    return {
        rowId: `${rowId}_${id}`,
        rowName,
        value: (id === 'colorBy' && measures[value]) ? measures[value].label : value
    };
};

const ParametersInfo = (props) => {

    const { parameters, measures, classes } = props;
    rowId = 0;
    const rows = parameters.map(p => createData(p.id, p.name, p.value, measures));
    return (
        <div className={classes.tableRoot}>
            <Table className={classes.table}>
                <TableBody>
                    {rows.map(row => (
                        <TableRow className={classes.row} key={row.rowId}>
                            <TableCell className={classes.cell} component="th" scope="row">
                                {row.rowName}
                            </TableCell>
                            <TableCell className={classes.cellValue} align="right">
                                {row.value}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

ParametersInfo.propTypes = {
    parameters: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string, value: PropTypes.string })),
    measures: PropTypes.shape({ label: PropTypes.string }),
    classes: PropTypes.shape({
        cellValue: PropTypes.string,
        row: PropTypes.string,
        cell: PropTypes.string,
        table: PropTypes.string,
        tableRoot: PropTypes.string
    })
};

const mapStateToProps = ({ metadata }) => ({
    measures: metadata.measures
});

export default connect(mapStateToProps, null)(withStyles(styles)(ParametersInfo));
