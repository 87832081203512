import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { actionsStyles } from '../../Styles/actionsStyles';
import EditIcon from '@mui/icons-material/Edit';
import { postAxios } from '../../../../functions/axiosRequests';
import config from '../../../../config/envConfig';
import { fetchCustomTreeSubsets } from '../../../../redux/actions/settingsActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SubsetDialog from '../Dialogs/SubsetDialog';

const EditSubset = ({ subset, setInfoDialog, setInfo, setAction, fetchCustomTreeSubsets }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = actionsStyles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const updateSubset = async (newSubset) => {
        try {
            const url = `${config.serverLink}/api/admin/updateCustomTreeSubset`;
            const body = { oldKey: subset.key, newSubset, lineage: subset.lineage };
      
            console.log('[updateSubset]',{body});
            await postAxios(url, body);
            setInfo(`Subset ${newSubset.key} was updated sucessfully`);
        }
        catch (error) {
            console.error(error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during updating ${subset.key}.: ${message}`);
        }
        finally {
            handleCloseDialog();
            setAction('Update subset');
            setInfoDialog(true);
            await fetchCustomTreeSubsets();
        }
    };

    return <>
        <IconButton className={classes.button} onClick={handleClick}>
            <EditIcon />
        </IconButton>
        <SubsetDialog oldSubset={subset} openDialog={openDialog} handleCloseDialog={handleCloseDialog} submit={updateSubset} isEdit={true}/>
    </>;
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCustomTreeSubsets,
        },
        dispatch,
    );

export default connect(undefined, mapDispatchToProps)(EditSubset);
