import { catchError, of } from 'rxjs';
import { INTERNAL_SERVER_ERROR, NETWORK_ERROR, REQUEST_TIMEOUT, SESSION_TIMEOUT } from '../actions/actionTypes';

export const handleErrors = (errorAction, customErrorMessage, params) => catchError((error) => {
    const { status, data } = error.response || {};
    // console.log('[handleEpicError]', error, params);

    // console.log('[handleEpicError]', error, params);
    // Handle Axios network error (server down or no internet connection)
    if (error.code === 'ERR_NETWORK') {
        return of({
            type: NETWORK_ERROR,
            payload: {
                error: error.message, //'Network Error. Please check your internet connection or try again later.',
            },
        });
    }

    if (status === 401 && data.type === 'SessionTimeout') {
        // Dispatch an action to handle session timeout
        return of({
            type: SESSION_TIMEOUT,
            // payload: { error },
        });    
    }

    // Handle request timeout (408)
    if (status === 408) {
        return of({
            type: REQUEST_TIMEOUT,
            payload: {
                error: 'Request timed out. Please try again later.',
                message: 'Request timed out. Please log in again.',
            }
        });
    }
    
    // Handle internal server error (500)
    if (status === 500) {
        return of({
            type: INTERNAL_SERVER_ERROR,
            payload: {
                error: data?.message || 'Internal server error',
                errorWebsiteText: 'An internal server error occurred. Please try again later.',
                errorWebsiteType: 'server',
            },
        });
    }
    // Fallback for other statuses or network-related issues

  
    const { message, errors } = data;
    
    const errorMessage = message || errors?.map(({message}) =>message).join(',');
    const errorWebsiteText = `${customErrorMessage || 'An error occurred during the operation'}${errorMessage ? `: ${errorMessage}`:''}.`;
    // Dispatch specific error action
    return of(errorAction({
        error: message || errors,
        errorWebsiteText: errorWebsiteText,
        errorWebsiteType: 'fetch',
        ...params,
       
    }));

});

