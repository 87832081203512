import React from 'react';
import DeleteScale from '../../../../pages/SettingsPanel/Scales/Actions/DeleteScale';
import EditScale from '../../../../pages/SettingsPanel/Scales/Actions/EditScale';
import { connect } from 'react-redux';
import { TableCell } from '@mui/material';

const ScalesCells = (props) => {
    const { row, setInfo, setInfoDialog, setAction } = props;

    return (
        <>
            <TableCell component="th" scope="row">
                {row.scaleId}
            </TableCell>
            <TableCell align="left">
                {row.lineage}
            </TableCell>
            <TableCell align="left">
                {row.range}
            </TableCell>
            <TableCell align="left">
                {row.numeric}
            </TableCell>
            <TableCell align="left">
                {row.discrete}
            </TableCell>
            <TableCell align="left">
                <DeleteScale
                    scale={row.scale}
                    setInfo={setInfo}
                    setInfoDialog={setInfoDialog}
                    setAction={setAction} 
                />
                <EditScale
                    scale={row.scale}
                    setInfo={setInfo}
                    setInfoDialog={setInfoDialog}
                    setAction={setAction}
                />
            </TableCell>
        </>
    );
};

export default ScalesCells;