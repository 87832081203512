// External imports
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Internal imports - Components
import SelectInput from '../Common/SelectInput';

// Internal imports - Config & Utils
import appConfig from '../../config/appConfig';

// Internal imports - Actions
import { setMeasureScale, setParameters } from '../../redux/actions/parametersActions';
import { getComplexDomainParameterValues } from '../../redux/selectors/rangeDataSelector';

const AntigenicTiterTypeSelector = (props) => {
    const { colorBy, antigenicTiterType, measures, params, setParameters, setMeasureScale } = props;

    const handleChange = antigenicTiterType => {
        const colorScale = measures[colorBy].scale['antigenicTiterType'][antigenicTiterType];
        setMeasureScale({ measure: colorBy, scaleName: colorScale, parameters: { ...params, antigenicTiterType } });
        setParameters({ antigenicTiterType });
    };

    return  <SelectInput
        id="antigenicTiterType"
        label="Titer type"
        value={antigenicTiterType}
        onChange={handleChange}
        options={appConfig.titerTypes}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.label}
    />;
};

const mapStateToProps = (state) => ({
    colorBy: state.parameters.colorBy,
    antigenicTiterType: state.parameters.antigenicTiterType,
    refStrain: state.parameters.refStrain,
    measures: state.metadata.measures,
    params: getComplexDomainParameterValues(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setParameters,
            // setReferenceStrain
            setMeasureScale
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AntigenicTiterTypeSelector);
