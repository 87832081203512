import { createSelector } from 'reselect';
import { parametersToEdit } from '../../pages/SettingsPanel/Parameters/parametersToEdit';
import {
    getColorByOptionsArray,
    getIdsArray,
    getMutGeneOptions,
    getRegionsArray,
} from '../../pages/SettingsPanel/Parameters/utils/functions';
import appConfig from '../../config/appConfig';

const getParameters = ({ settings }) => settings.parameters;
const getRegions = ({ settings }) => settings.parameters.regions;
const getMapColorsOptions = ({ settings }) =>
    settings.metadata.mapColorsOptions;
const getGenotypeFilterList = ({ settings }) =>
    settings.genotype.genotypeFilterGenesList;
const getMutPositions = ({ settings }) => settings.genotype.mutPositions;
const getModelTypes = ({ settings }) => settings.models.modelTypes.fitness;
const getCladeTypes = ({ settings }) =>
    settings.metadata.cladeTypes || appConfig.cladeTypes;
const getModelIdOptions = ({ settings }) => settings.models.models.fitness;
const getAntigenicModelIdOptions = ({ settings }) =>
    settings.models.models.antigenic;
const getAntigenicFitnessModelIdOptions = ({ settings }) =>
    settings.models.models.antigenicFitness;
const getColorByOptions = ({ settings }) => settings.metadata.colorByOptions;
const getMeasures = ({ settings }) => settings.metadata.measures;
const getVpMethods = ({ settings }) => settings.metadata.getVpMethods;
const getMutationClasses = ({ settings }) => settings.metadata.mutationClasses;
const getDateFromString = (dateString) =>
    dateString ? new Date(dateString) : undefined;

const getCustomNodes = ({ settings }) => settings.metadata.customMeasures.node;
const getCustomColorByOptions = createSelector(getCustomNodes, (customNodes) =>
    Object.keys(customNodes)
);

export const getEditableParameters = createSelector(
    getParameters,
    (parameters) => {
        // console.log('parameters', parameters);
        const parametersToEditArray = Object.keys(parametersToEdit);
       
        const parametersArr = Object.entries(parameters);
        const filteredParameters = parametersArr.filter(function ([key]) {
            return parametersToEditArray.includes(key);
        });
        const fixedParameters = filteredParameters.map(([key, value]) => {
            if (value === null) {
                return [key, value];
            }
            return parametersToEdit[key] === 'date'
                ? [key, getDateFromString(value)]
                : [key, value];
        });
        return Object.fromEntries(fixedParameters);
    }
);

const getSortedMeasures = createSelector(getMeasures, (measures) => {
    const sVal = (v) => (v.key === 'none' ? -1 : v.custom ? 1 : 0);

    const sortedMeasures = Object.keys(measures)
        .map((key) => ({
            key,
            ...measures[key],
            discrete: measures[key].discrete === true || !measures[key].scale, //domain === undefined,
            // custom: !measures[key].standard,
            numeric: measures[key].numeric === true,
            frequenciesChart: measures[key].frequenciesChart === true,
        }))
        .sort(
            (c1, c2) => sVal(c1) - sVal(c2) || c1.label.localeCompare(c2.label)
        );
    return sortedMeasures;
});

const getTreeScaleYOptions = createSelector(
    getSortedMeasures,
    (sortedMeasures) => {
        const treeScaleYOptions = sortedMeasures
            .filter((k) => k.yScale)
            .map(({ key }) => key);
        //console.log(treeScaleYOptions);
        return treeScaleYOptions;
    }
);

const getTreeScaleXOptions = createSelector(
    getSortedMeasures,
    (sortedMeasures) => {
        const treeScaleXOptions = sortedMeasures
            .filter((k) => k.xScale)
            .map(({ key }) => key);
        //console.log(treeScaleXOptions);
        return treeScaleXOptions;
    }
);

export const getSelectSettingsOptions = createSelector(
    [
        getRegions,
        getColorByOptions,
        getCustomColorByOptions,
        getMapColorsOptions,
        getTreeScaleXOptions,
        getTreeScaleYOptions,
        getVpMethods,
        getGenotypeFilterList,
        getMutPositions,
        getModelTypes,
        getModelIdOptions,
        getAntigenicModelIdOptions,
        getAntigenicFitnessModelIdOptions,
        getCladeTypes,
        getMutationClasses,
    ],
    (
        regions,
        colorByOptions,
        customColorByOptions,
        mapColorsOptions,
        treeScaleXOptions,
        treeScaleYOptions,
        vpMethods,
        genotypeFilterList,
        mutPositions,
        modelTypes,
        modelIdOptions,
        antigenicModelIdOptions,
        antigenicFitnessModelIdOptions,
        cladeTypes,
        mutationClasses
    ) => {
        const options = {};

        const mutationsTypes = appConfig.mutationsTypes;
        const displayOrders = appConfig.displayOrderOptions;
        const titerTypes = appConfig.titerTypes;
        const antigenicDataTypes = appConfig.antigenicDataTypes;
        const layouts = appConfig.layouts;

        options.regionId = getRegionsArray(regions);
        options.modelRegionId = getRegionsArray(regions);
        options.colorBy = [
            ...getColorByOptionsArray(colorByOptions),
            ...customColorByOptions,
        ];
        options.freqCategory = getColorByOptionsArray(colorByOptions);
        options.geoMapColorBy = getColorByOptionsArray(mapColorsOptions);
        options.cladeType = cladeTypes.map(({ key }) => key);
        options.cladeBarType = cladeTypes.map(({ key }) => key);
        options.treeScaleTypeX = treeScaleXOptions;
        options.treeScaleTypeY = treeScaleYOptions;
        options.displayOrder = getIdsArray(displayOrders);
        options.antigenicTiterType = getIdsArray(titerTypes);
        options.mutationsType = getIdsArray(mutationsTypes);
        options.vpMethod = vpMethods;
        options.layout = layouts;
        options.mutgene = getMutGeneOptions(genotypeFilterList);
        options.mutposition = mutPositions;
        options.modelType = modelTypes;
        options.modelId = modelIdOptions;
        options.antigenicModelId = antigenicModelIdOptions;
        options.antigenicFitnessModelId = antigenicFitnessModelIdOptions;
        options.antigenicDataType = getIdsArray(antigenicDataTypes);
        options.mutationsGroup = Object.keys(mutationClasses).length
            ? ['', ...Object.keys(mutationClasses)]
            : [];

        options.vaccinesSerumType = ['CELL', 'EGG'];
        options.frequencyType = ['sublineage', 'clade'];
        return options;
    }

);

export const colorScalesSelector = createSelector(
    [
        ({settings}) => settings.colorScales, 
        ({settings}) => settings.scalesPalette
    ], 
    (colorScales, scalesPalette) => {
    
        return colorScales.reduce((acc, scale) => {
            const range = typeof scale.range === 'string' ?
                scalesPalette[scale.range] : scale.range;
        
            return {
                ...acc, 
                [scale.scaleId]: { 
                    id: scale.scaleId, 
                    lineage: scale.lineage, 
                    range,
                    discrete: scale.discrete,
                    numeric: scale.numeric,
                }
            };
        },{} );
    });
