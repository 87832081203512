import { createTheme } from '@mui/material/styles';
import { teal, orange } from '@mui/material/colors';

const theme = createTheme({
    typography: {
        fontFamily: ['"Inter"', 'Helvetica', 'Arial'].join(','),
    },
    palette: {
        primary: {
            light: '#52c7b8',
            main: '#504D4D',
            dark: '#504D4D', // '#00675b',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ffffff', // '#ffd95b',
            main: '#6F6CFF', // '#ffa726',
            dark: '#c77800',
            contrastText: '#000',
            gray: '#474747'
        },
        background: {
            dark: '#E0DCDC', // '#e5e5e5',
            default: '#F7F4F4', // '#f7f7f7'
        },
        openTitle: teal['700'],
        protectedTitle: orange['700'],
        type: 'light',
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
                variant: 'contained'
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                label: {
                    textTransform: 'none',
                },
                outlinedSecondary: {
                    borderColor: '#ffffff',
                },
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    minHeight: '32px',
                    height: '32px',
                    '&:hover:not(.Mui-selected)': {
                        backgroundColor: '#6F6CFF',
                        color: '#FFFFFF',
                    },
                    '&:hover.Mui-selected': {
                        color: '#000000',
                        // backgroundColor: '#474747',
                    }
                },
            }
        },
        MuiInputLabel: {
            defaultProps: {
                disableAnimation: true,
                shrink: true, // Disable label animation for all input types
            },
            styleOverrides: {
                position: 'relative',
            }
        },

        MuiFormControl: {
            defaultProps: {
                variant: 'standard', // or "standard" or "filled"
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard', // or "standard" or "filled"
                margin: 'normal',

            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard', // or "standard" or "filled"
            },
        },
        MuiInput: {
            defaultProps: {
                variant: 'standard', // or "standard" or "filled"
            },
            styleOverrides: {
                root: {
                    //borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                    transition: 'none',
                    '&::before': {
                        webkitTransition: 'none',
                        transition: 'none',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                        '&::hover': {
                            webkitTransition: 'none',
                            transition: 'none',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                        },
                    },
                    '&::after': {
                        webkitTransition: 'none',
                        transition: 'none',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                    },
                    '&&.Mui-disabled::before': {
                        borderBottom: '1px solid rgba(0, 0, 0, 0.38)',
                        '&::hover': {
                            webkitTransition: 'none',
                            transition: 'none',
                            borderBottom: '1px solid rgba(255, 0, 0, 0.38)',
                        },
                    },
                    '&&:hover::before': {
                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                        transition: 'none',
                    },
                },
                underline: {
                    '&:hover:not($disabled):not($focused):not($error):before': {
                        borderBottomWidth: '1px',
                    },
                    '&:after': {
                        borderBottomWidth: '1px',
                    },
                }
            }
        },

        MuiToolbar: {
            styleOverrides: {
                regular: {
                    '@media (min-width: 600px)': {
                        minHeight: '60px',
                    },
                },
            }
        },

        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-tag': {
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        display: 'flex',
                        lineHeight: 1.2,
                        alignItems: 'center',
                        padding: '24px 8px', // Added padding top and bottom
                        backgroundColor: 'rgba(0, 0, 0, 0.08)', // Ensure background color on hover
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.08)', // Maintain background color on hover
                        },
                    },
                    '& .MuiChip-root': {
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        maxWidth: '100%',
                        display: 'flex',
                        lineHeight: 1.2,
                        alignItems: 'center',
                        padding: '24px 8px', // Added padding top and bottom
                        backgroundColor: 'rgba(0, 0, 0, 0.08)', // Ensure background color on hover
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.08)', // Maintain background color on hover
                        },
                    },
                    '& .MuiChip-label': {
                        whiteSpace: 'normal',
                        lineHeight: 1.2,
                        wordBreak: 'break-word',
                        flex: 1,
                    },
                    '& .MuiChip-deleteIcon': {
                        marginLeft: '4px',
                    },
                },
                option: {
                    display: 'block',
                    whiteSpace: 'normal',
                    overflowWrap: 'anywhere',
                    lineHeight: 1.2,
                    fontSize: '14px',
                    cursor: 'pointer',
                    '&:nth-of-type(odd)': {
                        backgroundColor: '#F5F5F5',
                    },

                    // Hover or keyboard highlight
                    '&:hover, &[data-focus="true"]': {
                        backgroundColor: '#6F6CFF !important',
                        color: '#FFFFFF',
                    },

                    // The truly "selected" (as in chosen) item
                    '&[aria-selected="true"]': {
                        backgroundColor: '#5D5A5A !important',
                        color: '#FFFFFF',
                    },

                }

            }
        },
        // MuiGrid2: {
        //     defaultProps: {
        //         // all grids under this theme will apply
        //         // negative margin on the top and left sides.
        //         disableEqualOverflow: true,
        //     },
        // },
    },
});

export default theme;
