import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Custom components
import SelectInput from '../Common/SelectInput';

// Config
import appConfig from '../../config/appConfig';

// Redux actions
import { setParameters } from '../../redux/actions/parametersActions';

const plotTypeOptions = [
    { key: 'frequencies', label: 'Frequencies', introOption: true },
    { key: 'multiplicities', label: 'Multiplicities', introOption: false },
];

const PlotTypeSelector = props => {
    const { plotType, intro, setParameters } = props;

    const handlePlotTypeChange = value => {
        setParameters({ plotType: value });
    };

    const plotTypes = useMemo(() => {
        return plotTypeOptions.filter(({introOption}) => !intro || introOption);
    }, [intro]);

    return (
        <SelectInput
            id="plotType"
            label="Plot"
            value={plotType}
            onChange={handlePlotTypeChange}
            options={plotTypes}
            getOptionValue={el => el.key}
            getOptionLabel={el => el.label}
        />
    );
};

PlotTypeSelector.propTypes = {
    plotType: PropTypes.string.isRequired,
    intro: PropTypes.bool,
    setParameters: PropTypes.func.isRequired
};

const mapStateToProps = ({ parameters }) => ({
    plotType: parameters.plotType || appConfig.default.plotType,
    intro: parameters.intro
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlotTypeSelector);
