import {
    SET_RENDER_STATUS,
    SET_COMPONENT_STATUS,
    RESET_COMPONENTS_STATUS,
    SET_LABEL_MOVEMENT,
    CORRECT_LABEL_POSITIONS,
} from './actionTypes';

// Base Actions
import { received } from '../baseActions';

export const setRenderStatus = renderStatus => {
    // console.log(`[setRenderStatus] => ${renderStatus}`);
    return received(SET_RENDER_STATUS, { renderStatus });
};

export const setComponentStatus = (viewName, componentId, status, labels) => {
    // if (componentId === 'cladeLabels') console.log(`[setComponentStatus] => ${viewName} ${componentId} ${status}`);
    return received(SET_COMPONENT_STATUS, { viewName, componentId, status, labels });
};

export const resetComponentsStatus = (viewName, status) => received(RESET_COMPONENTS_STATUS, { viewName, status });

export const setLabelMovement = (payload) => received(SET_LABEL_MOVEMENT, payload);

export const correctLabelPositions = (payload) => received(CORRECT_LABEL_POSITIONS, payload);

