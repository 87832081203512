import { produce } from 'immer';
import {
    FETCH_FREQUENCIES_REQUEST,
    FETCH_FREQUENCIES_SUCCESS,
    FETCH_FREQUENCIES_ERROR,
    RESET_FREQUENCIES,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    SELECT_POINT,
    SET_ACTIVE_LEGEND_OPTION,
} from '../actions/actionTypes';


let frequenciesInitialState = { };
export const setFrequenciesInitialState = (state) => {
    frequenciesInitialState = state;
};

export const frequenciesDataReducer = (state = frequenciesInitialState, action) => 
    produce(state, (draft) => {
        switch (action.type) {
            case RESET_SESSION:
            case SIGNOUT_REQUEST: 
            case RESET_FREQUENCIES: {
                return frequenciesInitialState;
            }

            case FETCH_FREQUENCIES_REQUEST: {
                const { strainSubset, regions } = action.payload;
                const regionIds = Object.keys(regions || { [strainSubset]: true });
                regionIds.forEach(regionId => {
                    // Always mark the frequencies status as loading
                    draft.frequenciesStatus[regionId] = 'loading';
        
                    // Delete entries from frequencies, seqCaseCounts, and selectedBins if they exist
                    if (regionId in draft.frequencies) {
                        delete draft.frequencies[regionId];
                    }
                    if (regionId in draft.seqCaseCounts) {
                        delete draft.seqCaseCounts[regionId];
                    }
                    if (regionId in draft.selectedBins) {
                        delete draft.selectedBins[regionId];
                    }
                });
                break;
            }
        
            case FETCH_FREQUENCIES_SUCCESS: {
                const { frequencies, seqCaseCounts, selectedBins, dataPoints, parameters } = action.payload;
                const { freqCategory } = parameters;
                // Merge new values into existing state
                Object.assign(draft.frequencies, frequencies);
                Object.assign(draft.seqCaseCounts, seqCaseCounts);
                Object.assign(draft.selectedBins, selectedBins);

                // Mark each region's frequency status as loaded
                Object.keys(frequencies).forEach(regionId => {
                    draft.frequenciesStatus[regionId] = 'loaded';
                });

                // Update additional properties
                draft.freqCategory = freqCategory;
                draft.dataPoints = dataPoints;

                break;
            }
            case FETCH_FREQUENCIES_ERROR: {
                const { allRegions, strainSubset } = action.payload;
                

                const regionIds = allRegions ? Object.keys(draft.frequenciesStatus) : [strainSubset];
                regionIds.forEach(regionId => {
                    draft.frequenciesStatus[regionId] = 'error';
                    draft.frequencies[regionId] = [];
                    draft.seqCaseCounts[regionId] = [];
                });
                break;
            }
            case SELECT_POINT: {
                const { info } = action.payload;
                draft.pointInfo = info || {};
                break;
            }

            case SET_ACTIVE_LEGEND_OPTION: {
                const { type, value, option } = action.payload;
                if (type === 'frequencies')
                    draft.activeLegendOption = { value, option };
                else
                    draft.activeLegendOption = undefined;
                break;
            }

            default:
                break;
        }
    });

