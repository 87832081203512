import React, { useEffect } from 'react';
import { r, getX, getY } from './CladeLabel';
import { connect } from 'react-redux';

const CladeLabelAnchorPoint = (props) => {
    const { id, onElementRendered, rerenderLabels } = props;
    const xPos = getX(id);
    const yPos = getY(id);

    useEffect(() => {
        //console.log('[CladeLabelAnchorPoint].rerenderLabels', rerenderLabels)
        if (rerenderLabels) {
            //console.log('[CladeLabelAnchorPoint].back')
            onElementRendered();
        }
    }, [onElementRendered]);

    return (
        <circle
            transform={`translate(${xPos}, ${yPos})`} id={`${id}_cladeLabelAnchorPoint`}
            r={r}
            style={{
                fill: '#ffffff',
                stroke: '#4F4F4F',
                strokeWidth: 1
            }}
        ></circle>
    );
};

const mapStateToProps = (state, ownProps) => {
    //const treeAttrs = getTreeNodeAttrs(state);
    const labelPos = state.render.labels?.[ownProps.classNamePrefix]?.[ownProps.id] || {};
    const xPos = getX(ownProps.id);
    const yPos = getY(ownProps.id);

    return {
        strainTreeWidth: state.ui.strainTreeWidth,
        strainTreeHeight: state.ui.strainTreeHeight,
        xPos,
        yPos,
        x: labelPos?.x || xPos,
        y: labelPos?.y || yPos,
    };
};

export default connect(mapStateToProps)(CladeLabelAnchorPoint);
