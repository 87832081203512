import React from 'react';
import { prepareUrl } from '../../../../functions/functions';
import { fetchAxios } from '../../../../functions/axiosRequests';
import NumberInput from '../../../../components/Common/NumberInput';
import config from '../../../../config/envConfig';

const ZoomNodeIdSelect = ({ 
    lineage,
    ...rest
}) => {
  
    // const [zoomNodeInfoText, setZoomNodeInfoText] = useState('');

    const validateZoomNodeId = async (value) => {
        const url = prepareUrl(`${config.serverLink}/api/tree/checkNode`, {
            lineage,
            nodeId: value,
        });
        try {
            const response = await fetchAxios(url);
           
            if (response.data.error) {
                // setZoomNodeInfoText('');
                return { error: true, errorText: response.data.msg, helperText: '' };
            } else {
                // setZoomNodeInfoText(response.data.msg);
                return { error: false, helperText: response.data.msg };
            }
        } catch {
            return { error: true, errorText: 'Validation failed' };
        }
    };
    
    
    return (
        <NumberInput
            onValidate={validateZoomNodeId}
            {...rest}
        />
    );


    // return (
    //     <NumberInput  
    //         id="zoomNodeId"
    //         label="zoomNodeId"
    //         value={localZoomNodeId || undefined}
    //         onChange={handleChangeZoomNodeId}
    //         onAccept={handleAccept}
    //         // allowFloat={false}
    //         error={error}
    //         helperText={zoomNodeInfoText}
    //         // onBlur={() => validateZoomNodeId(localZoomNodeId)}
    //     />
    // );
};

export default ZoomNodeIdSelect;
