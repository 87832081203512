import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import appConfig from '../../config/appConfig';
import { setParameters } from '../../redux/actions/parametersActions';
import SelectInput from '../Common/SelectInput';


const DisplayOrderSelector = (props) => {

    const { displayOrder, setParameters } = props;

    const handleChange = async value => {
        setParameters({ displayOrder: value });
    };

    return (
        <SelectInput
            id='displayOrder'
            label='Display order'
            value={displayOrder}
            onChange={handleChange}
            options={appConfig.displayOrderOptions}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.label}
            // className={classes.formControl}
        />
    );
};

DisplayOrderSelector.propTypes = {
    displayOrder: PropTypes.string,
};

const mapStateToProps = ({ parameters }) => ({
    displayOrder: parameters.displayOrder,
});

const mapDispatchToProps = (dispatch) => ({
    setParameters: (payload) => dispatch(setParameters(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisplayOrderSelector);
