 
import React from 'react';

export const LogoIconDesktop = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="23" viewBox="0 0 100 23" fill="none">
        <path d="M32.1703 9.64562C31.8889 10.2206 31.4526 10.6663 30.8616 10.9825C30.2846 11.3131 29.5387 11.4713 28.6662 11.4713H25.528V3.91H28.6522C29.5387 3.91 30.2846 4.06812 30.8616 4.38438C31.4386 4.68625 31.8889 5.13187 32.1703 5.6925C32.4518 6.25312 32.5925 6.91437 32.5925 7.66187C32.6066 8.40937 32.4659 9.07062 32.1703 9.64562ZM33.7606 0.991875C32.6066 0.330625 31.1853 0 29.5247 0H20.9262V22.655H25.5421V15.3094H29.4262C31.1008 15.3094 32.5362 14.9788 33.7184 14.3463C34.9005 13.6994 35.8011 12.8081 36.4203 11.6581C37.0395 10.5081 37.3491 9.18563 37.3491 7.67625C37.3491 6.18125 37.0395 4.84438 36.4344 3.69438C35.8152 2.53 34.9286 1.63875 33.7606 0.991875Z" fill="white" />
        <path d="M47.4534 5.41937C46.5387 5.41937 45.7224 5.67812 45.0329 6.22437C44.3292 6.77062 43.8226 7.56125 43.5271 8.625H43.3582V5.66375H38.9534V22.655H43.4849V13.0381C43.4849 12.3481 43.6397 11.73 43.9352 11.1981C44.2307 10.6662 44.6388 10.2637 45.1736 9.9475C45.6943 9.66 46.2994 9.50187 46.9608 9.50187C47.2704 9.50187 47.6082 9.53062 48.0022 9.57375C48.3822 9.63125 48.6636 9.68875 48.8747 9.74625V5.5775C48.6777 5.52 48.4525 5.49125 48.1852 5.4625C47.9178 5.43375 47.6785 5.41937 47.4534 5.41937Z" fill="white" />
        <path d="M53.7298 12.4488C53.7579 11.845 53.8986 11.2844 54.1801 10.7669C54.4756 10.2206 54.8837 9.775 55.4326 9.45875C55.9533 9.12813 56.5725 8.97 57.262 8.97C57.9235 8.97 58.5145 9.11375 59.0071 9.41563C59.4996 9.70313 59.8936 10.12 60.1892 10.6519C60.4706 11.1838 60.6114 11.7731 60.6114 12.4631H53.7298V12.4488ZM62.6519 7.59C61.9623 6.87125 61.132 6.33938 60.2032 5.98C59.2744 5.62063 58.2612 5.44813 57.1776 5.44813C55.5733 5.44813 54.166 5.82188 52.9839 6.555C51.8018 7.28813 50.873 8.32313 50.2257 9.64563C49.5783 10.9681 49.2546 12.5063 49.2546 14.2456C49.2546 16.0425 49.5783 17.5806 50.2257 18.8888C50.873 20.1969 51.8159 21.2175 53.0121 21.9219C54.2223 22.6263 55.6718 23 57.3465 23C58.6975 23 59.8796 22.7844 60.921 22.3531C61.9623 21.9219 62.8067 21.3181 63.4541 20.5419C64.1014 19.7656 64.5517 18.8456 64.7628 17.8106L60.5691 17.5231C60.4143 17.9544 60.1892 18.3138 59.8936 18.6156C59.5981 18.9031 59.2463 19.1331 58.81 19.2769C58.3879 19.4206 57.9375 19.4925 57.4168 19.4925C56.6569 19.4925 55.9955 19.3344 55.4467 18.9894C54.8978 18.6444 54.4756 18.1844 54.152 17.5806C53.8564 16.9625 53.7016 16.2438 53.7016 15.41V15.3813H64.8473V14.0875C64.8473 12.65 64.6502 11.385 64.2703 10.2925C63.9044 9.2 63.3415 8.29438 62.6519 7.59Z" fill="white" />
        <path d="M73.2909 17.8106H73.122L69.7305 5.66375H64.9317L70.6593 22.655H75.7677L81.4953 5.66375H76.6825L73.2909 17.8106Z" fill="white" />
        <path d="M87.1667 5.66375H82.6353V22.655H87.1667V5.66375Z" fill="white" />
        <path d="M98.7063 5.4625C98.439 5.43375 98.1997 5.41937 97.9746 5.41937C97.0598 5.41937 96.2436 5.67812 95.554 6.22437C94.8504 6.77062 94.3438 7.56125 94.0483 8.625H93.8794V5.66375H89.4746V22.655H94.006V13.0381C94.006 12.3481 94.1608 11.73 94.4564 11.1981C94.7519 10.6662 95.16 10.2637 95.6948 9.9475C96.2155 9.66 96.8206 9.50187 97.482 9.50187C97.7916 9.50187 98.1294 9.53062 98.5234 9.57375C98.9034 9.63125 99.1848 9.68875 99.3959 9.74625V5.5775C99.213 5.53437 98.9737 5.49125 98.7063 5.4625Z" fill="white" />
        <path d="M10.8782 13.6706C11.3004 14.0013 11.8211 14.1738 12.3981 14.1738C13.8335 14.1738 14.9875 12.9663 14.9875 11.4856C14.9875 10.005 13.8195 8.78313 12.384 8.78313C10.9345 8.78313 9.78057 9.99063 9.78057 11.4713C9.78057 11.73 9.82279 11.9888 9.87908 12.2188L6.57198 14.7344L2.27979 11.4713L10.8501 4.95938C11.2723 5.29 11.793 5.4625 12.37 5.4625C13.8054 5.4625 14.9593 4.255 14.9593 2.77438C14.9593 1.29375 13.7913 0.0862503 12.37 0.0862503C10.9345 0.0862503 9.78057 1.29375 9.78057 2.77438C9.78057 3.03313 9.82279 3.29188 9.87908 3.52188L0.351819 10.7669C0.126655 10.925 0 11.1981 0 11.4713C0 11.7444 0.126655 12.0175 0.351819 12.1756L9.89315 19.4206C9.82279 19.6506 9.79464 19.9094 9.79464 20.1681C9.79464 21.6488 10.9627 22.8563 12.384 22.8563C13.8054 22.8563 14.9734 21.6488 14.9734 20.1681C14.9734 18.6875 13.8054 17.48 12.384 17.48C11.8211 17.48 11.2864 17.6669 10.8642 17.9831L8.02147 15.8269L10.8782 13.6706Z" fill="white" />
    </svg>
);

export const LogoIconMobile = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="79" height="19" viewBox="0 0 79 19" fill="none">
        <path d="M25.3283 7.59427C25.1067 8.04699 24.7632 8.39784 24.2979 8.64683C23.8436 8.90714 23.2564 9.03164 22.5694 9.03164H20.0986V3.07845H22.5583C23.2564 3.07845 23.8436 3.20295 24.2979 3.45194C24.7521 3.68962 25.1067 4.04047 25.3283 4.48186C25.5499 4.92326 25.6607 5.44388 25.6607 6.03241C25.6718 6.62094 25.561 7.14156 25.3283 7.59427ZM26.5803 0.780931C25.6718 0.26031 24.5527 0 23.2453 0H16.4755V17.8369H20.1097V12.0535H23.1677C24.4862 12.0535 25.6164 11.7932 26.5471 11.2952C27.4778 10.7859 28.1869 10.0842 28.6744 9.17877C29.1619 8.27334 29.4057 7.2321 29.4057 6.04373C29.4057 4.86667 29.1619 3.81411 28.6855 2.90869C28.198 1.99194 27.4999 1.29023 26.5803 0.780931Z" fill="white" />
        <path d="M37.3611 4.26649C36.6409 4.26649 35.9983 4.47021 35.4554 4.90029C34.9014 5.33036 34.5025 5.95285 34.2698 6.79037H34.1369V4.45889H30.6689V17.8366H34.2366V10.2649C34.2366 9.72169 34.3585 9.23502 34.5912 8.81626C34.8238 8.3975 35.1452 8.0806 35.5662 7.83161C35.9761 7.60525 36.4526 7.48075 36.9733 7.48075C37.2171 7.48075 37.483 7.50339 37.7932 7.53734C38.0924 7.58261 38.314 7.62789 38.4802 7.67316V4.39098C38.3251 4.34571 38.1478 4.32308 37.9373 4.30044C37.7268 4.2778 37.5384 4.26649 37.3611 4.26649Z" fill="white" />
        <path d="M42.3032 9.80134C42.3253 9.32599 42.4361 8.88459 42.6577 8.47715C42.8904 8.04707 43.2117 7.69622 43.6439 7.44723C44.0538 7.18692 44.5413 7.06242 45.0842 7.06242C45.605 7.06242 46.0703 7.1756 46.4581 7.41328C46.8459 7.63963 47.1562 7.96785 47.3889 8.38661C47.6105 8.80537 47.7213 9.2694 47.7213 9.81266H42.3032V9.80134ZM49.3278 5.97591C48.7849 5.41002 48.1312 4.99126 47.3999 4.70831C46.6687 4.42536 45.8709 4.28955 45.0178 4.28955C43.7547 4.28955 42.6467 4.58381 41.716 5.16102C40.7852 5.73823 40.054 6.55312 39.5443 7.59436C39.0346 8.6356 38.7798 9.84661 38.7798 11.2161C38.7798 12.6308 39.0346 13.8418 39.5443 14.8717C40.054 15.9017 40.7963 16.7052 41.7381 17.2598C42.691 17.8144 43.8322 18.1086 45.1507 18.1086C46.2144 18.1086 47.1451 17.9389 47.965 17.5993C48.7849 17.2598 49.4497 16.7844 49.9594 16.1733C50.4691 15.5621 50.8236 14.8378 50.9898 14.0229L47.688 13.7965C47.5661 14.1361 47.3889 14.419 47.1562 14.6567C46.9235 14.8831 46.6465 15.0641 46.303 15.1773C45.9706 15.2905 45.6161 15.3471 45.2061 15.3471C44.6078 15.3471 44.087 15.2226 43.6549 14.951C43.2228 14.6793 42.8904 14.3172 42.6356 13.8418C42.4029 13.3551 42.281 12.7893 42.281 12.1328V12.1102H51.0563V11.0916C51.0563 9.95979 50.9012 8.96382 50.602 8.10366C50.3139 7.24351 49.8707 6.53048 49.3278 5.97591Z" fill="white" />
        <path d="M57.7039 14.023H57.571L54.9007 4.45947H51.1225L55.632 17.8372H59.654L64.1635 4.45947H60.3742L57.7039 14.023Z" fill="white" />
        <path d="M68.6292 4.45947H65.0615V17.8372H68.6292V4.45947Z" fill="white" />
        <path d="M77.7143 4.30044C77.5037 4.2778 77.3154 4.26649 77.1381 4.26649C76.4179 4.26649 75.7753 4.47021 75.2324 4.90029C74.6784 5.33036 74.2795 5.95285 74.0468 6.79037H73.9139V4.45889H70.4459V17.8366H74.0136V10.2649C74.0136 9.72169 74.1355 9.23502 74.3681 8.81626C74.6008 8.3975 74.9221 8.0806 75.3432 7.83161C75.7531 7.60525 76.2296 7.48075 76.7503 7.48075C76.9941 7.48075 77.26 7.50339 77.5702 7.53734C77.8694 7.58261 78.091 7.62789 78.2572 7.67316V4.39098C78.1131 4.35703 77.9248 4.32308 77.7143 4.30044Z" fill="white" />
        <path d="M8.56475 10.763C8.89715 11.0233 9.3071 11.1591 9.76138 11.1591C10.8915 11.1591 11.8001 10.2084 11.8001 9.04271C11.8001 7.87697 10.8804 6.91495 9.7503 6.91495C8.60907 6.91495 7.70052 7.86565 7.70052 9.03139C7.70052 9.23511 7.73376 9.43883 7.77808 9.61992L5.1743 11.6005L1.79494 9.03139L8.54259 3.90441C8.87499 4.16472 9.28494 4.30053 9.73922 4.30053C10.8694 4.30053 11.7779 3.34983 11.7779 2.18409C11.7779 1.01836 10.8583 0.0676575 9.73922 0.0676575C8.60907 0.0676575 7.70052 1.01836 7.70052 2.18409C7.70052 2.38781 7.73376 2.59154 7.77808 2.77262L0.276997 8.47682C0.0997189 8.60131 0 8.81635 0 9.03139C0 9.24643 0.0997189 9.46147 0.276997 9.58596L7.78916 15.2902C7.73376 15.4712 7.7116 15.675 7.7116 15.8787C7.7116 17.0444 8.63123 17.9951 9.7503 17.9951C10.8694 17.9951 11.789 17.0444 11.789 15.8787C11.789 14.7129 10.8694 13.7622 9.7503 13.7622C9.3071 13.7622 8.88607 13.9094 8.55367 14.1584L6.31553 12.4607L8.56475 10.763Z" fill="white" />
    </svg>
);