import React, { useState, useEffect } from 'react';
import { styles } from '../Styles/panelStyles';
import { MenuItem, FormControl, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Table from '../../../components/Table/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchLineageRegions } from '../../../redux/actions/settingsActions';
import CustomLabel from '../../../assets/GlobalStyles/CustomLabel';
import CustomSelect from '../../../assets/GlobalStyles/CustomSelect';

const headers = [
    { name: 'key', label: 'Key' },
    { name: 'label', label: 'Label' },
    { name: 'color', label: 'Color' },
    { name: 'actions', label: 'Actions' },
];

const orderInitState = {
    key: 'asc',
    label: 'none',
    color: 'none',
    actions: 'none',
};

const searchState = {
    key: '',
    label: '',
};

const searchLabels = {
    key: 'Region key',
    label: 'Label',
};

const searchOptionsState = {
    key: [],
    label: [],
};

function createData(lineageRegion) {
    const { key, label, color } = lineageRegion;
    return { key, label, color };
}

const RegionsPanel = (props) => {
    const { lineageRegions, fetchLineageRegions, lineageRegionsLength, status, lineages } = props;
    const [currentLineage, setCurrentLineage] = useState('');
    const [tableData, setTableData] = useState([]);
    const [searchOptions, setSearchOptions] = useState(searchOptionsState);
    const classes = styles();

    useEffect(() => {
        fetchLineageRegions();
    }, []);

    useEffect(() => {
        if (currentLineage === '' || status !== 'loaded') return;

        setTableRegions();
    }, [currentLineage, lineageRegions]);

    const setTableRegions = () => {
        const rows = lineageRegions[currentLineage].map((lineageRegion) => {
            return createData(lineageRegion);
        });

        const newOptions = {
            key: [...new Set(lineageRegions[currentLineage].map(el => el.key))],
            label: [...new Set(lineageRegions[currentLineage].map(el => el.label))],
        };
        setSearchOptions({ ...newOptions });
        setTableData(rows);
    };

    const handleCurrentLineageChange = (e) => { setCurrentLineage(e.target.value); };

    return (

        <Grid container className={classes.container}>
            <Grid size={12} className={classes.item}>
                <Paper sx={{ marginRight: '15px', marginLeft: '15px' }}>
                    {lineageRegionsLength > 0 &&
                        <FormControl className={classes.formControl} fullWidth>
                            <CustomLabel id={'currentLineage'} label={'Lineage'} />
                            <CustomSelect
                                value={currentLineage}
                                defaultValue={''}
                                onChange={handleCurrentLineageChange}
                                className={classes.searchSelect}
                                inputProps={{
                                    name: 'currentLineage',
                                    id: 'currentLineage',
                                }}
                            >
                                <MenuItem key={''} value={''}>none</MenuItem>
                                {lineages.map((t) => (
                                    <MenuItem key={t} value={t}>{t}</MenuItem>
                                ))}
                            </CustomSelect>
                        </FormControl>
                    }
                    {currentLineage && <Table
                        name='regions'
                        searchOptions={searchOptions}
                        orderInitState={orderInitState}
                        headers={headers}
                        tableData={tableData}
                        searchState={searchState}
                        searchLabels={searchLabels}
                        currentLineage={currentLineage}
                    />}
                </Paper>
            </Grid>
        </Grid>

    );
};

const mapStateToProps = (state) => {
    const { lineageRegions } = state.settings;
    const { lineages } = state.lineages;

    return {
        status: lineageRegions.status,
        lineageRegions: lineageRegions.data,
        lineageRegionsLength: Object.keys(lineageRegions).length,
        lineages
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchLineageRegions,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(RegionsPanel);  
