// Start of Selection
import React from 'react';
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    // root: {
    //     fontSize: '14px',
    //     letterSpacing: '-0.1px',
    //     paddingBottom: '3px',
    // },
    dropdown: {
        shadow: 'none',
        border: '1px #C4C4C4',
        maxHeight: '250px',
        backgroundColor: 'white',
        '& ul': {
            '& li': {
                padding: '4px 12px',
                cursor: 'pointer',
                fontSize: '14px',
                '&:hover': {
                    background: '#5094f8',
                    color: 'white'
                },
            },
        }
    },
}));

const CustomSelect = (props) => {
    const { children, id, MenuProps = {}, ...rest } = props;
    const classes = useStyles();
    
    const menuProps = {
        ...MenuProps,
        slotProps: {
            ...MenuProps.slotProps,
            paper: {
                ...MenuProps.slotProps?.paper,
                className: classes.dropdown
            }
        },
        PaperProps: {
            sx: { 
                '&.MuiPopover-paper': {
                    '&[aria-hidden]': {
                        removeProperty: 'aria-hidden'
                    }
                }
            }
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
        }
    };

    return (
        <Select
            {...rest}
            MenuProps={menuProps}
            id={`${id}-select`}
            sx={{
                [`& #${id}-select`]: {
                    fontSize: '14px',
                    letterSpacing: '-0.1px',
                    paddingBottom: '3px',
                    height: props.value ? 'auto' : '22px'
                }
            }}
            displayEmpty
        >
            {children}
        </Select>
    );
};

CustomSelect.propTypes = {
    children: PropTypes.node,
};

export default CustomSelect;
