import { createSelector } from 'reselect';
import {
    getTcellAntigenicityStatus,
    tcellAntigenicityScoresSelector,
    getStrainsLists,
} from './treeDataSelector';

import { emptyObject } from '../../functions/functions';
import appConfig from '../../config/appConfig';

// import config from '../../config/envConfig';

const getMeasures = ({ metadata }) => metadata.measures;
const getMeasure = ({ metadata }, measureName) =>
    metadata.measures[measureName];
const getMetaCustomMeasures = (state) => state.metadata.customMeasures;
const getLineages = (state) => state.lineages.lineages;
const getUserLineages = (state) =>
    state.user && state.user.status === 'loaded' ? state.user.lineages : null;
const getUserDefaultLineage = (state) =>
    state.user && state.user.status === 'loaded'
        ? state.user.defaultLineage
        : null;
const getLineage = (state) => state.parameters.lineage;
const emptyBranchNodes = [];
export const getBranchNodes = ({ parameters }) =>
    parameters.branchNodes instanceof String
        ? emptyBranchNodes
        : parameters.branchNodes;
const getColorByOptionsFilter = ({ metadata }) => metadata.colorByOptions;
const getVaccineCandidates = ({ metadata }) => metadata.vaccineCandidates;
const getGeoMapColorByOptionsFilter = ({ metadata }) =>
    metadata.mapColorsOptions;
const getColorBy = ({ parameters }) => parameters.colorBy;
const getParameters = ({ parameters }) => parameters;
const getMeasureScalesDomains = ({ metadata }) => metadata.measureScalesDomains;
const getScales = ({ metadata }) => metadata.scales;
const getMutationClasses = ({ metadata }) => metadata.mutationClasses;
const getMutationsGroup = ({ parameters }) => parameters.mutationsGroup;

const getMeasuresFilter = createSelector(
    getColorByOptionsFilter,
    (colorByOptionsFilter) => ({
        ...colorByOptionsFilter,
        order: true,
        AADivergence: true,
        NLDivergence: true,
    })
);
const getNestedValue = (obj, parameters, defaultVal) => {
    const key = Object.keys(obj || {})[0];
    if (!obj || emptyObject(key)) return defaultVal;
    return obj[key][parameters[key]] || defaultVal;
};

const getShowRuleForColorBy = createSelector(
    [getMeasures, getColorBy, getParameters],
    (measures, colorBy, parameters) => {
        const selectedMeasure = measures[colorBy] || {};
        const showRule =
            selectedMeasure && typeof selectedMeasure.show === 'string'
                ? selectedMeasure.show
                : getNestedValue(
                    selectedMeasure.show,
                    parameters,
                    appConfig.default.showRule
                );

        return showRule;
    }
);

export const getIgnoreStrainCutOffDateForColorBy = createSelector(
    [getMeasures, getColorBy, getParameters],
    (measures, colorBy, parameters) => {
        const showRule =
            measures[colorBy] &&
            typeof measures[colorBy]?.ignoreStrainCutOffDate === 'string'
                ? measures[colorBy].ignoreStrainCutOffDate
                : getNestedValue(
                    (measures[colorBy] || {}).ignoreStrainCutOffDate,
                    parameters,
                    appConfig.default.ignoreStrainCutOffDate
                );

        return showRule;
    }
);

const getCustomMeasures = createSelector(getMeasures, (measures) =>
    Object.keys(measures).reduce((acc, k) => {
        if (measures[k].custom) acc[k] = measures[k];
        return acc;
    }, {})
);

const getCustomTreeAttrsOptions = createSelector(
    getMetaCustomMeasures,
    ({ branch, node }) => ({
        branch: Object.values(branch),
        node: Object.values(node),
    })
);

const getCurrentColorByCustomTreeAttr = createSelector(
    [getMetaCustomMeasures, getColorBy],
    ({ node }, colorBy) => (node && node[colorBy] ? [node[colorBy]] : [])
);

const branchNodesSelector = createSelector(
    [getBranchNodes, getMetaCustomMeasures],
    (branchNodes, { branch }) =>
        branchNodes && branch
            ? branchNodes.map((key) => ({ key, label: branch[key].label }))
            : []
);

// const branchNodesArraySelector = createSelector(getBranchNodes, branchNodes => branchNodes);

const getCustomTreeBranchOptions = createSelector(
    getMetaCustomMeasures,
    ({ branch }) => (branch ? Object.values(branch) : [])
);

const getColorScaleRange = ({ metadata, parameters }) =>
    metadata.measures[parameters.colorBy].color;

const getColorByMeasure = ({ metadata, parameters }) =>
    metadata.measures[parameters.colorBy];

const getUserFilteredLineages = createSelector(
    [getLineages, getUserLineages],
    (lineages, userLineages) => {
        if (!userLineages || userLineages.length === 0)
            return lineages.map((name) => ({ id: name, label: name }));
        const lineagesDict = lineages.reduce((acc, lineage) => {
            acc[lineage] = true;
            return acc;
        }, {});

        return userLineages
            .filter((l) => lineagesDict[l])
            .map((name) => ({ id: name, label: name }));
    }
);

export const lineagesSelector = createSelector([getLineages], (lineages) => {
    return lineages.map((name) => ({ id: name, label: name }));
});

const getUserLineage = createSelector(
    [getUserLineages, getUserDefaultLineage, getLineage],
    (userLineages, defaultLineage, lineage) => {
        if (!userLineages) return lineage;
        // return userLineages
        return defaultLineage;
    }
);

const getSelectedMutationGroups = createSelector(
    [getMutationClasses, getMutationsGroup],
    (mutationsClasses, mutationsGroup) => {
        if (
            !mutationsClasses ||
            !mutationsGroup ||
            !mutationsClasses[mutationsGroup]
        )
            return null;
        return Object.entries(mutationsClasses[mutationsGroup]).map(
            ([key, value]) => ({
                key,
                ...value,
            })
        );
    }
);

const getMutationsGroupsForDivergence = createSelector(
    getSelectedMutationGroups,
    (mutationsGroups) => {
        const res = (mutationsGroups || []).map(({ key, label }) => ({
            key: `${key}_divergence`,
            label: `${label} divergence`,
            custom: false,
            discrete: false,
            discreteScale: false,
            frequenciesChart: false,
            numeric: true,
            scale: `${key}_divergence.default`,
            xScale: true,
            yScale: true,
        }));
        // console.log('[getMutationsGroupsForDivergence]', res);
        return res;
    }
);

const getSortedMeasures = createSelector(
    [getMeasures, getMutationsGroupsForDivergence],
    (measures, divergenceMeasures) => {
        const sVal = (v) => (v.key === 'none' ? -1 : v.custom ? 1 : 0);

        const sortedMeasures = [
            ...Object.keys(measures).map((key) => ({
                key,
                ...measures[key],
                discrete:
                    measures[key].discrete === true || !measures[key].scale, //domain === undefined,
                numeric: measures[key].numeric === true,
                frequenciesChart: measures[key].frequenciesChart === true,
            })),
            ...divergenceMeasures,
        ].sort(
            (c1, c2) => sVal(c1) - sVal(c2) || c1.label.localeCompare(c2.label)
        );
        return sortedMeasures;
    }
);

export const measuresSelector = createSelector(
    getSortedMeasures,
    (sortedMeasures) => sortedMeasures.reduce((acc, m) => {
        acc[m.key] = m;
        return acc;
    }, {})
);

const getColorOptions = createSelector(
    [getSortedMeasures, getColorByOptionsFilter],
    (sortedMeasures, colorByOptionsFilter) => {
        const colorOptions = sortedMeasures.filter(
            ({ key, custom, branch }) =>
                !colorByOptionsFilter ||
                colorByOptionsFilter[key] ||
                (custom && !branch) ||
                key === 'none'
        );
        const colorOptionsMap = colorOptions.reduce((_colorOptionsMap, c) => {
            _colorOptionsMap[c.key] = c;
            return _colorOptionsMap;
        }, {});
        // console.log(colorOptions);
        return { colorOptions, colorOptionsMap };
    }
);

const getGeoMapColorsOptions = createSelector(
    [getSortedMeasures, getGeoMapColorByOptionsFilter, getColorOptions],
    (sortedMeasures, mapColorByOptionsFilter, { colorOptionsMap }) => {
        const colorOptions = sortedMeasures.filter(
            ({ key }) => mapColorByOptionsFilter[key] && colorOptionsMap[key]
        );
        const colorOptionsMapResult = colorOptions.reduce(
            (_colorOptionsMap, c) => {
                _colorOptionsMap[c.key] = c;
                return _colorOptionsMap;
            },
            {}
        );
        return { colorOptions, colorOptionsMap: colorOptionsMapResult };
    }
);

const getFrequencyCategories = createSelector(
    [getSortedMeasures, getMeasuresFilter],
    (sortedMeasures, measuresFilter) => {
        // console.log(sortedMeasures);
        const freqCategoriesOptions = sortedMeasures.filter(
            ({ key, custom, frequenciesChart, discreteScale }) =>
                discreteScale &&
                (!measuresFilter ||
                    custom ||
                    (measuresFilter[key] && frequenciesChart))
        );

        // console.log(freqCategoriesOptions);

        const freqCategoriesMap = freqCategoriesOptions.reduce(
            (_freqCategoriesMap, c) => {
                _freqCategoriesMap[c.key] = c;
                return _freqCategoriesMap;
            },
            {}
        );
        return { freqCategoriesOptions, freqCategoriesMap };
    }
);

const getSelectedMutationGroupsDict = createSelector(
    [getMutationClasses, getMutationsGroup],
    (mutationClasses, mutationsGroup) =>
        (mutationClasses &&
            mutationsGroup &&
            mutationClasses[mutationsGroup]) ||
        {}
);

const getMutationsGroupsOptions = createSelector(
    getMutationClasses,
    (mutationClasses) => ['', ...Object.keys(mutationClasses || {})]
);

const getTreeScaleYOptions = createSelector(
    getSortedMeasures,
    (sortedMeasures) => {
        const treeScaleYOptions = sortedMeasures.filter((k) => k.yScale);
        return treeScaleYOptions;
    }
);

const getTreeScaleXOptions = createSelector(
    getSortedMeasures,
    (sortedMeasures) => {
        const treeScaleXOptions = sortedMeasures.filter((k) => k.xScale);
        return treeScaleXOptions;
    }
);

// const getScaleNameForColorBy =
//     (colorBy) =>
//         ({ parameters, metadata }) =>
//             parameters.colorScales[colorBy] ||
//         metadata.measures[colorBy]?.scale ||
//         `${colorBy}.default`;


const getScaleNameForColorBy = (colorBy) =>
    createSelector(
        [
            state => state.parameters.colorScales,
            state => state.metadata.measures
        ],
        (colorScales, measures) =>
            colorScales[colorBy] ||
            measures[colorBy]?.scale ||
            `${colorBy}.default`
    );

// const getBins = (_colorBy) => (state) => {
//     let bins = state.metadata.measureBins?.[_colorBy]?.[scaleName];

//     //console.log(scaleId, value, domain, getScaledValue(`${scaleId}ValueScale`, value), getScaledValue(`${scaleId}ColorScale`, value), _domain);

//     const precision = 3;
//     const isIntervalScale =
//         !(state.metadata.measures[_colorBy]?.discrete || false) &&
//         state.metadata.scales[scaleName]?.discrete;
//     // console.log({ scaleName, isIntervalScale });
//     if (isIntervalScale) {
//         const domain = Object.values(bins || {}); //.filter(el => mode === 'frequencies' || el.bin !== -1);
//         bins = domain.reduce((acc, el, index) => {
//             const { bin, v } = el;

//             const _v0 =
//                 bin !== -1 && index > 0 ? domain[index - 1].v : undefined;
//             const _v1 = bin !== -1 && v !== undefined ? v : undefined;

//             const v0 = numFormat(_v0, precision); //(bin !== -1 && index > 0) ? formatLabel(_domain[index-1].v, precision) : undefined;
//             const v1 = numFormat(_v1, precision); //(bin !== -1 && v !== undefined) ? formatLabel(v, precision) : undefined;

//             // const visible = mode !== 'frequencies' || chartLayout === 'stacked' || visibleBins[bin];
//             const label =
//                 bin === -1
//                     ? "Missing value"
//                     : v0 && v1
//                     ? `${v0} < x < ${v1}`
//                     : !v0 && v1
//                     ? `x < ${v1}`
//                     : `x > ${v0}`;

//             acc[bin] = { ...el, label };
//             return acc;
//         }, {});
//     }

//     // console.log(bins);
//     return bins; //customTreeAttrsBins[freqCategory];
// };

// const getAntigenicColorBins = createSelector(
//     [
//         (state) => getScaleNameForColorBy("antigenic")(state),
//         (state) => get
//     ],
//     (scaleName) => {}
// );

// const getColorBins = (colorByMeasure) => (state) => {
//     const { regions } = state.parameters;
//     if (colorByMeasure === 'loc') {
//         return regions;
//     }
//     if (colorByMeasure === 'tcellAntigenicity') {
//         const tcellStatus = getTcellAntigenicityStatus(state);
//         const tcellAntigenicityScoresBins =
//             tcellAntigenicityScoresSelector(state);
//         return tcellStatus === 'loaded' ? tcellAntigenicityScoresBins : null;
//     }

//     const scaleName = getScaleNameForColorBy(/*_colorBy*/ colorByMeasure)(
//         state
//     );

//     let bins =
//         state.metadata.measureBins?.[/*_colorBy*/ colorByMeasure]?.[scaleName];

//     const precision = 3;
//     const isIntervalScale =
//         !(state.metadata.measures[colorByMeasure]?.discrete || false) &&
//         state.metadata.scales[scaleName]?.discrete;
//     // console.log({ scaleName, isIntervalScale });
//     if (isIntervalScale) {
//         const domain = Object.values(bins || {}); //.filter(el => mode === 'frequencies' || el.bin !== -1);
//         bins = domain.reduce((acc, el, index) => {
//             const { bin, v } = el;

//             const _v0 =
//                 bin !== -1 && index > 0 ? domain[index - 1].v : undefined;
//             const _v1 = bin !== -1 && v !== undefined ? v : undefined;

//             const v0 = numFormat(_v0, precision); //(bin !== -1 && index > 0) ? formatLabel(_domain[index-1].v, precision) : undefined;
//             const v1 = numFormat(_v1, precision); //(bin !== -1 && v !== undefined) ? formatLabel(v, precision) : undefined;

//             // const visible = mode !== 'frequencies' || chartLayout === 'stacked' || visibleBins[bin];
//             const label =
//                 bin === -1
//                     ? 'Missing value'
//                     : v0 && v1
//                         ? `${v0} < x < ${v1}`
//                         : !v0 && v1
//                             ? `x < ${v1}`
//                             : `x > ${v0}`;

//             acc[bin] = { ...el, label };
//             return acc;
//         }, {});
//     }

//     return bins;
// };


// Selector to get regions from state
const getRegions = state => state.parameters.regions;

// // Selector to get tcell antigenicity status from state
// const getTcellAntigenicityStatus = state => state.tcellAntigenicityStatus;

// Selector to get tcell antigenicity scores bins
const getTcellAntigenicityScoresBins = state => state.tcellAntigenicityScoresBins;

// Selector to get metadata related to color bins
const getMeasureBins = state => state.metadata.measureBins;

// Selector to get measure scales
const getMeasureScales = state => state.metadata.scales;

// // Selector to get measures
// const getMeasures = state => state.metadata.measures;

// // Helper function for number formatting
// const numFormat = (value, precision) => (value ? value.toFixed(precision) : value);

// Create selector for color bins

// Cache for memoized selectors
const selectorCache = {};

const getColorBins = (colorByMeasure) => {
    if (!selectorCache[colorByMeasure]) {
        // Create a new selector and store it in the cache
        selectorCache[colorByMeasure] = createSelector(
            [
                getTcellAntigenicityStatus,
                getTcellAntigenicityScoresBins,
                getMeasureBins,
                getMeasureScales,
                getMeasures,
                getScaleNameForColorBy(colorByMeasure)
            ],
            ( tcellStatus, tcellAntigenicityScoresBins, measureBins, scales, measures, scaleName) => {
         
                if (colorByMeasure === 'tcellAntigenicity') {
                    return tcellStatus === 'loaded' ? tcellAntigenicityScoresBins : null;
                }

                const bins = measureBins?.[colorByMeasure]?.[scaleName];

                // const precision = 3;
                // const isIntervalScale =
                // !(measures[colorByMeasure]?.discrete || false) &&
                // scales[scaleName]?.discrete;

                // if (isIntervalScale) {
                //     const domain = Object.values(bins || {});
                //     console.log(domain);
                //     bins = domain.reduce((acc, el) => {
                //         const { bin } = el;

                //         const v0 = numFormat(el.v0, precision);
                //         const v1 = numFormat(el.v1, precision);

                //         const label =
                //         bin === -1
                //             ? 'Missing value'
                //             : v0 && v1
                //                 ? `${v0} < x < ${v1}`
                //                 : !v0 && v1
                //                     ? `x < ${v1}`
                //                     : `x > ${v0}`;

                //         acc[bin] = { ...el, label };
                //         return acc;
                //     }, {});
                // }

                return bins;
            }
        );
    }
    
    return selectorCache[colorByMeasure];
};



const getSelectedBins = createSelector(
    (state) => state.frequenciesData.selectedBins, // Inputs needed for fallback
    (state) => state.parameters.strainSubset, // Inputs needed for fallback
    (
        selectedBins,
        strainSubset
    ) => {
        // console.log('[getSelectedBins]', {selectedBins ,strainSubset});
        let bins = {};

        if (selectedBins && strainSubset)
            bins = selectedBins[strainSubset] || {};

        return bins; //Object.keys(bins).sort();
    }
);

// const getSelectedBins = createSelector(_getSelectedBins, (selectedBins) =>
//     Object.keys(selectedBins).sort()
// );

const getLabeledVaccineCandidates = createSelector(
    getVaccineCandidates,
    (vaccineCandidates) =>
        vaccineCandidates
            .map((v) => ({ ...v, vaccine: true }))
            .sort((s1, s2) => s1.n.localeCompare(s2.n))
);

const getLabeledStrainsListWithVaccineCandidates = createSelector(
    [getVaccineCandidates, getStrainsLists],
    (vaccineCandidates, strainsLists) => {
        const vcDict = (vaccineCandidates || []).reduce((acc, v) => {
            acc[v.id] = 1;
            return acc;
        }, {});

        const list = Object.keys(strainsLists).reduce(
            (_strainsLists, searchId) => {
                _strainsLists[searchId] = strainsLists[searchId]
                    ? strainsLists[searchId]
                        .map((s) => ({
                            ...s,
                            vaccine: !emptyObject(vcDict[s.id]),
                        }))
                        .sort((s1, s2) => s1.n.localeCompare(s2.n))
                    : undefined;

                return _strainsLists;
            },
            {}
        );

        return list;
    }
);

const discreteMeasureScalesDomainsSelector = createSelector(
    [getMeasureScalesDomains, getScales],
    (measureScalesDomains, scales) => {
        const res = Object.keys(measureScalesDomains).reduce((acc, m) => {
            const measureScales = measureScalesDomains[m];
            acc[m] = Object.keys(measureScales).reduce((sacc, s) => {
                if (scales[s]?.discrete) sacc[s] = measureScales[s];
                return sacc;
            }, {});
            return acc;
        }, {});
        // console.log(res);
        return res;
    }
);

const getAntigenicModelComponents = ({ metadata }) =>
    metadata.modelsConfig.antigenicModelIdComponents;

export const getAntigenicSegmentsFields = createSelector(
    getAntigenicModelComponents,
    (antigenicModelComponents) => {
        const antigenicSegmentsLabels = [
            { name: 'assay', label: 'Assay' },
            { name: 'collaboratingCenter', label: 'Collaborating center' },
            { name: 'strainPropagation', label: 'Strain propagation' },
            { name: 'refStrainPropagation', label: 'Ref. strain propagation' },
        ];
        const res = Object.keys(antigenicModelComponents).reduce(
            (acc, modelVar) => {
                const antigenicSegmentsNames =
                    antigenicModelComponents[modelVar];
                acc[modelVar] = antigenicSegmentsLabels.filter(({ name }) =>
                    antigenicSegmentsNames.includes(name)
                );
                return acc;
            },
            {}
        );
        //console.log(res);
        return res;
    }
);

const getAntigenicSegmentsNamesForFitness = ({ metadata }) =>
    metadata?.modelsConfig.antigenicModelIdComponents['fitness'];

const getAntigenicModelTypes = ({ metadata }) =>
    metadata?.modelsConfig.antigenicModelTypes;

export {
    getMeasures,
    getMeasure,
    getCustomTreeAttrsOptions,
    getUserFilteredLineages,
    getUserLineage,
    // getTreeBranchAttrs,
    getCustomTreeBranchOptions,
    branchNodesSelector,
    // getNodeCustomTreeAttrs,
    getColorOptions,
    getGeoMapColorsOptions,
    getFrequencyCategories,
    getColorByMeasure,
    getMutationClasses,
    getColorScaleRange,
    getSelectedBins,
    // branchNodesArraySelector,
    //getSortedBins,
    getColorBins,
    getCustomMeasures,
    //getContinousMeasures,
    getTreeScaleXOptions,
    getTreeScaleYOptions,
    getCurrentColorByCustomTreeAttr,
    getShowRuleForColorBy,
    //getScaleNameForColorBy,
    //getScaleNameForFreqCategory,
    //getScaleNameForGeoMapColorBy,
    getLabeledVaccineCandidates,
    getLabeledStrainsListWithVaccineCandidates,
    getMutationsGroupsOptions,
    getMutationsGroup,
    getVaccineCandidates,
    discreteMeasureScalesDomainsSelector,
    getSelectedMutationGroupsDict,
    getSelectedMutationGroups,
    getAntigenicModelTypes,
    getAntigenicSegmentsNamesForFitness,
};
