import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Custom components
import SelectInput from '../Common/SelectInput';

// Config
import { LAYOUT } from '../../config/dictionaries';

// Redux actions
import { setLayoutParameters } from '../../redux/actions/parametersActions';

const LayoutSelector = props => {
    const { layout, setLayoutParameters } = props;

    const handleLayoutChange = value => {
        setLayoutParameters({ layout: value });
    };

    return (
        <SelectInput
            id="layout"
            label="Layout"
            value={layout}
            onChange={handleLayoutChange}
            options={Object.values(LAYOUT)}
            getOptionValue={el => el.value}
            getOptionLabel={el => el.label}
        />
    );
};

LayoutSelector.propTypes = {
    layout: PropTypes.string.isRequired,
    setLayoutParameters: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    layout: state.parameters.layout
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setLayoutParameters
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSelector);
