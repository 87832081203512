// External imports
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

// Internal imports - Components
import SelectInput from '../Common/SelectInput';

// Internal imports - Redux
import { fetchSubsetTree, fetchTreeFreqs } from '../../redux/actions/treeDataActions';
import { setParameters } from '../../redux/actions/parametersActions';

// Internal imports - Utils
import { emptyObject } from '../../functions/functions';
import { predictionBaselineSelector, submissionDateSelector } from '../../redux/selectors/parametersSelector';

const StrainsSubsetSelector = ({
    setParameters,
    fetchSubsetTree,
    fetchTreeFreqs,
    zoomNodeId,
    strainHighlight,
    lineage,
    predictionBaseline, submissionDate,
    context,
    strainSubset,
    strainSubsetOptions,
}) => {
    const handleChange = async strainSubset => {
        setParameters({ strainSubset });
        if (context === 'strainTree') {
            setParameters({ strainSubset });
            fetchSubsetTree({ lineage, strainSubset, zoomNodeId, strainHighlight });
            fetchTreeFreqs({ lineage, predictionBaseline, submissionDate, strainSubset });
        }
        // else if (context === 'frequencies') {
        // setParameters({ strainSubset });
        // }
    };

    return (
        <>
            {!emptyObject(strainSubsetOptions) && (
                <SelectInput
                    id="strainSubset"
                    value={strainSubset}
                    onChange={handleChange}
                    options={strainSubsetOptions}
                    label="Subset strains"
                    getOptionValue={option => option.key}
                    getOptionLabel={option => option.label}
                />
            )}
        </>
    );
};

StrainsSubsetSelector.propTypes = {
    // Required props
    setParameters: PropTypes.func.isRequired,
    fetchSubsetTree: PropTypes.func.isRequired,
    strainSubsetOptions: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string
    })).isRequired,
    
    // Optional props
    context: PropTypes.string,
    lineage: PropTypes.string,
    strainSubset: PropTypes.string,
    strainHighlight: PropTypes.string,
    zoomNodeId: PropTypes.number,
};

const mapStateToProps = (state) => ({
    // Parameters
    lineage: state.parameters.lineage,
    strainSubset: state.parameters.strainSubset,
    strainHighlight: state.parameters.strainHighlight,
    zoomNodeId: state.parameters.zoomNodeId,
    predictionBaseline: predictionBaselineSelector(state),
    submissionDate: submissionDateSelector(state),
    // Metadata
    strainSubsetOptions: state.metadata.strainSubsetOptions,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
    fetchSubsetTree,
    fetchTreeFreqs
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StrainsSubsetSelector);
