// Action Types
import {
    RESET_SESSION,
    INTERNAL_SERVER_ERROR,
    SET_SESSION_TIMEOUT
} from './actionTypes';
// Base Actions
import { received } from '../baseActions';




export const resetSession = (payload) => received(RESET_SESSION, payload);
// In lineagesActions.js
export const internalServerError = (payload) => ({ type: INTERNAL_SERVER_ERROR, payload });

export const setSessionTimeout = (payload) => received(SET_SESSION_TIMEOUT, payload);


