import React from 'react';
import Delete from '../../../../pages/SettingsPanel/MutationClasses/Actions/Delete';
import Edit from '../../../../pages/SettingsPanel/MutationClasses/Actions/Edit';
import { SymbolComponent } from '../../../../pages/SettingsPanel/MutationClasses/Elements/Symbol';
import { connect } from 'react-redux';
import { TableCell } from '@mui/material';

const MutationClassesCells = (props) => {
    const { row, setInfo, setInfoDialog, setAction, lineageMutationGenes } = props;

    return (
        <>
            <TableCell component="th" scope="row">
                {row.name}
            </TableCell>
            <TableCell align="left">
                {row.label}
            </TableCell>
            <TableCell align="left">
                {row.lineage}
            </TableCell>
            <TableCell align="left">
                {row.mutGroup}
            </TableCell>
            <TableCell align="left">
                <SymbolComponent color={row.color} shape={row.symbol_sign} />
            </TableCell>
            <TableCell align="left">
                <Edit
                    mutationGenes={lineageMutationGenes || {}}
                    setInfo={setInfo}
                    setInfoDialog={setInfoDialog}
                    setAction={setAction}
                    mutClass={row.mutClass}
                />
                <Delete
                    setInfo={setInfo}
                    setInfoDialog={setInfoDialog}
                    setAction={setAction}
                    name={row.name}
                    lineage={row.lineage}
                    mutGroup={row.mutGroup}
                />
            </TableCell>
        </>

    );
};

const mapStateToProps = (state) => {
    const { data } = state.settings.lineageMutationGenes;

    return {
        lineageMutationGenes: data
    };
};

export default connect(mapStateToProps)(MutationClassesCells);
