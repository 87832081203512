import { createSelector } from 'reselect';
import { getMetadataMeasuresWithScales } from './rangeDataSelector';
import { reduxDateFormatToDate } from '../../functions/functions';

const getTrackingTo = ({ parameters }) => parameters.trackingTo;
const getTrackingFrom = ({ parameters }) => parameters.trackingFrom;
const getPredictionBaseline = ({ parameters }) => parameters.predictionBaseline;
const getStrainCutOffDate = ({ parameters }) => parameters.strainCutOffDate;
const getFreqsFrom = ({ parameters }) => parameters.freqsFrom;
const getFreqsTo = ({ parameters }) => parameters.freqsTo;
const getSubmissionDate = ({ parameters }) => parameters.submissionDate;
// const getStartTime = ({ parameters }) => parameters.startTime;
// const getEndTime = ({ parameters }) => parameters.endTime;

const getColorBy = ({ parameters }) => parameters.colorBy;
const getRegions = ({ parameters }) => parameters.regions;
const getColorScales = ({ parameters }) => parameters.colorScales;
const getFreqCategory = ({ parameters }) => parameters.freqCategory;
const getGeoMapColorBy = ({ parameters }) => parameters.geoMapColorBy;

export const getStrainSubset = ({ parameters }) => parameters.strainSubset;
export const getStrainSubsetsOptions = ({ metadata }) =>
    metadata.strainSubsetOptions;

export const trackingToSelector = createSelector(getTrackingTo, (trackingTo) =>
    reduxDateFormatToDate(trackingTo)
);

export const trackingFromSelector = createSelector(
    getTrackingFrom,
    (trackingFrom) => reduxDateFormatToDate(trackingFrom)
);

export const predictionBaselineSelector = createSelector(
    getPredictionBaseline,
    (predictionBaseline) => reduxDateFormatToDate(predictionBaseline)
);

export const strainCutOffDateSelector = createSelector(
    getStrainCutOffDate,
    (strainCutOffDate) => {
        return reduxDateFormatToDate(strainCutOffDate);
    }
);

export const freqsFromSelector = createSelector(getFreqsFrom, (freqsFrom) =>
    reduxDateFormatToDate(freqsFrom)
);

export const freqsToSelector = createSelector(getFreqsTo, (freqsTo) =>
    reduxDateFormatToDate(freqsTo)
);

export const submissionDateSelector = createSelector(
    getSubmissionDate,
    (submissionDate) => reduxDateFormatToDate(submissionDate)
);
export const vaccinesTrackedProtectionDateSelector = createSelector(
    (state) => state.parameters.vaccinesTrackedProtectionDate,
    (vaccinesTrackedProtectionDate) => reduxDateFormatToDate(vaccinesTrackedProtectionDate)
);
export const vaccinesPredictedProtectionDateSelector = createSelector(
    (state) => state.parameters.vaccinesPredictedProtectionDate,
    (vaccinesPredictedProtectionDate) => reduxDateFormatToDate(vaccinesPredictedProtectionDate)
);

export const strainSubsetsOptionsDictSelector = createSelector(
    getStrainSubsetsOptions,
    (strainSubsetOptions) => {
        const res = strainSubsetOptions.reduce((acc, option) => {
            acc[option.key] = option;
            return acc;
        }, {});
        //console.log(res);
        return res;
    }
);

const getRegionsArr = createSelector(getRegions, (regions) => {
    const res = regions ? Object.keys(regions) : [];
    return res;
});

export const getRegionsArrWithAll = createSelector(
    [getRegionsArr, getRegions],
    (regionsArr) => {
        const res = ['ALL', ...regionsArr];
        return res;
    }
);

export const getRegionsLabelsArr = createSelector(
    [getRegionsArr, getRegions],
    (regionsArr, regions) => {
        const res = regionsArr.map((region) => regions[region].label);
        return res;
    }
);

export const getRegionsWithLabels = createSelector(
    getRegions,
    (regions) => {
        const res = ['ALL', ...Object.keys(regions||{})].map((region) => ({
            id: region,
            label: region === 'ALL' ? 'All' : regions[region].label,
        }));
        return res;
    }
);

export const getIgnoreStrainCutOffDate = createSelector(
    [strainSubsetsOptionsDictSelector, getStrainSubset],
    (strainSubsetOptionsDict, strainSubset) => {
        const res =
            strainSubsetOptionsDict?.[strainSubset]?.ignoreStrainCutOffDate ||
            false;
        //console.log(res);
        return res;
    }
);

export const getColorScaleForColorBy = createSelector(
    [getColorBy, getColorScales, getMetadataMeasuresWithScales],
    (colorBy, colorScales, measures) =>
        colorScales[colorBy] ||
        measures[colorBy]?.scale?.scaleName ||
        `${colorBy}.default`
);

export const getCurrentColorScalesForMeasures = createSelector(
    [getColorScales, getMetadataMeasuresWithScales],
    (colorScales, measures) => {
        const res = Object.keys(measures).reduce((acc, m) => {
            acc[m] =
                colorScales[m] ||
                measures[m]?.scale?.scaleName ||
                `${m}.default`;
            return acc;
        }, {});
        // console.log('[getCurrentColorScalesForMeasures]',res);
        return res;
    }
);

export const getScaleNameForColorBy = createSelector(
    [getCurrentColorScalesForMeasures, getColorBy],
    (measuresScales, colorBy) => measuresScales[colorBy]
);

export const getScaleNameForFreqCategory = createSelector(
    [getCurrentColorScalesForMeasures, getFreqCategory],
    (measuresScales, freqCategory) => measuresScales[freqCategory]
);
// const selectedMeasure = measures[freqCategory] || {};
// return getScaleNameForMeasure(selectedMeasure, parameters);

export const getScaleNameForGeoMapColorBy = createSelector(
    [getCurrentColorScalesForMeasures, getGeoMapColorBy],
    (measuresScales, geoMapColorBy) => measuresScales[geoMapColorBy]
);
