import React from 'react';
import Grid from '@mui/material/Grid2';
import TrackingFromPicker from '../DatePickers/TrackingFromPicker';
import TrackingToPicker from '../DatePickers/TrackingToPicker';

const DatesSelector = () => {
    return (
        <Grid container columnSpacing={2}>
            <Grid size={6}>
                <TrackingFromPicker />
            </Grid>
            <Grid size={6}>
                <TrackingToPicker />
            </Grid>
        </Grid>
    );
};

export default React.memo(DatesSelector);
