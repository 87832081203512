import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { correctLabelPositions, setComponentStatus } from '../../../redux/actions/renderActions';
import labeler from '../../../functions/labeler';
import MutationsClassesLayer from './MutationsClassesLayer';
import CladeLabelsLayer from './CladeLabelsLayer';
import { RENDER_STATUS } from '../../../config/consts';
import { renderedMutationClassesLabelsSelector } from '../../../redux/selectors/treeDataSelector';

const viewToRender = 'strainTree';
const cladeLabelsComponentId = 'cladeLabels';
const mutationsClassesLabelsComponentId = 'mutationsClasses';
const LabelsLayer = (props) => {

    const {
        initialized,
        strainTreeWidth,
        strainTreeHeight,
        cladeLabels,
        cladeLabelsRenderStatus,
        mutationsClassesLabels,
        mutationsClassesLabelsRenderStatus,
        setComponentStatus,
        correctLabelPositions,

    } = props;


    // console.log(`[LabelsLayer]`, { cladeLabelsRenderStatus, mutationsClassesLabelsRenderStatus});

    // const prevProps = useRef(props);

    const getCladeLabelNodes = () => Object.entries(cladeLabels || {}).map(([id, val]) => {
        const { labelWidth, labelHeight, x, y, xAnchor, yAnchor, minY, maxY } = val; //this.texts[this.getNodeId(node)].height; // texts[this.getNodeId(node)].height + node.h+2;
        return {
            id,
            x,
            y, // + this.texts[this.getNodeId(node)].height,
            _x: x,
            _y: y,
            xAnchor,
            yAnchor,
            width: labelWidth,
            height: labelHeight,
            minY,
            maxY,
            //classNamePrefix: id.split('_')[1],
            type: 'cladeLabel'
        };
    });

    const getMutationsClassesLabelNodes = () => mutationsClassesLabels.map(label => {
        // const {id = key.split('_')[0];
        // const mutClass = key.split('_')[1];
        const { id, mutClass, labelWidth, labelHeight, x, y, xAnchor, yAnchor, symbolWidth, symbolHeight, showLabel } = label; //this.texts[this.getNodeId(node)].height; // texts[this.getNodeId(node)].height + node.h+2;
        return {
            id,
            mutClass,
            x,
            y,
            _x: x,
            _y: y,
            xAnchor,
            yAnchor,
            width: labelWidth,
            height: labelHeight,
            //minY,
            //maxY,
            //xSymbol, 
            symbolWidth, symbolHeight,
            type: 'mutationLabel',
            showLabel
        };
    });




    // useEffect(() => {
    //     const changedProps = Object.entries(props).reduce((acc, [key, value]) => {
    //         if (prevProps.current[key] !== value) {
    //             acc.push({ name: key, prevValue: prevProps.current[key], nextValue: value });
    //         }
    //         return acc;
    //     }, []);

    //     if (changedProps.length > 0) {
    //         // console.log('[LabelsLayer] Changed props:', changedProps)
    //     }

    //     prevProps.current = props;
    // });

    useEffect(() => {
        // console.log(`[LabelsLayer] useEffect`, { initialized });
        if (!initialized) return;
       
        const cladeLabelsDone = cladeLabelsRenderStatus === RENDER_STATUS.DONE;
        const mutationLabelsDone = mutationsClassesLabelsRenderStatus === RENDER_STATUS.DONE;
        const cladeLabelsPrepositioned = cladeLabelsRenderStatus === RENDER_STATUS.DONE_PREPOSITIONING;
        const mutationLabelsPrepositioned = mutationsClassesLabelsRenderStatus === RENDER_STATUS.DONE_PREPOSITIONING;

        const cladeLabelsReady = cladeLabelsDone || cladeLabelsPrepositioned;
        const mutationLabelsReady = mutationLabelsDone || mutationLabelsPrepositioned;
       

        if ((cladeLabelsDone && mutationLabelsDone) || !cladeLabelsReady || !mutationLabelsReady) {
            // console.log(`[LabelsLayer] RETURN`, {cladeLabelsDone, mutationLabelsDone, cladeLabelsReady, mutationLabelsReady});
            return;
        }
        // console.log('START POSITIONING CORRECTION');
        const cladeLabelNodes = getCladeLabelNodes();
        const mutationClassesNodes = getMutationsClassesLabelNodes();

        //console.log('mutationClassesNodes', mutationClassesNodes);
        const nodes = [...cladeLabelNodes, ...mutationClassesNodes];

        const labelNodes = nodes.filter(({ showLabel }) => showLabel !== false);

        const anchorPoints = labelNodes.map(({ id, xAnchor, yAnchor, symbolWidth, symbolHeight }) => ({
            id,
            r: 2 + 3,
            x: xAnchor, // + 4,
            y: yAnchor, // + 4,
            symbolWidth: symbolWidth && (symbolWidth + 4),
            symbolHeight: symbolHeight && (symbolHeight + 4)
        }));

        if (labelNodes.length > 0) {
            labeler()
                .label(labelNodes)
                .anchor(anchorPoints)
                .width(strainTreeWidth)
                .height(strainTreeHeight)
                .start(1000);
            // console.log('FINISHED POSITIONING CORRECTION', labelNodes);
            correctLabelPositions(labelNodes);
        } else {
            // console.log(`setComponentStatus(${viewToRender}, ${cladeLabelsComponentId}, ${RENDER_STATUS.DONE})`);
            //console.log('[LabelsLayer.useEffect] renderStatus changed', 'changing status to DONE, mutationsClassesLabelsRenderStatus = ', mutationsClassesLabelsRenderStatus, 'mutationLabelsPrepositioned = ', mutationLabelsPrepositioned)
            setComponentStatus(viewToRender, cladeLabelsComponentId, RENDER_STATUS.DONE);
            setComponentStatus(viewToRender, mutationsClassesLabelsComponentId, RENDER_STATUS.DONE);
        }
    }, [cladeLabelsRenderStatus, mutationsClassesLabelsRenderStatus]);

    // let mr = useRef(0);
    // console.log('[LabelsLayer]: render', mr.current++);
    return (
        <>
            <MutationsClassesLayer initialized={initialized} />
            <CladeLabelsLayer initialized={initialized} />
        </>
    );
};

LabelsLayer.propTypes = {
    setComponentStatus: PropTypes.func,
    initialized: PropTypes.bool,
    strainTreeWidth: PropTypes.number,
    strainTreeHeight: PropTypes.number,
    correctLabelPositions: PropTypes.func,
    // cladeLabels,
    cladeLabelsRenderStatus: PropTypes.string,
    // mutationsClassesLabels,
    // mutationsClassesLabelsRenderStatus,
};

//let mi = 0;
const mapStateToProps = (state) => {
    // const loading = getStrainTreeStatus(state) || !ownProps.initialized;
    // console.log('labelsChanged', labelsChanged, state.render.labelsCount);

    //console.log('[LabelsLayer]: mapStateToProps', mi++);
    const { mutationsClassesLabels, mutationsClassesLabelsRenderStatus } = renderedMutationClassesLabelsSelector(state);

    // console.log(`[LabelsLayer]`, {cladeLabelsRenderStatus: state.render.viewToRender.components[cladeLabelsComponentId]});
    return {
        strainTreeWidth: state.ui.strainTreeWidth,
        strainTreeHeight: state.ui.strainTreeHeight,
        cladeLabels: state.render.labels?.cladeLabel,
        cladeLabelsRenderStatus: state.render.viewToRender
            ? state.render.viewToRender.components[cladeLabelsComponentId]
            : null,
        mutationsClassesLabels,
        mutationsClassesLabelsRenderStatus
        //: state.render.viewToRender
        //  ? state.render.viewToRender.components[mutationsClassesLabelsComponentId]
        //: null,
    };
};

const mapDispatchToProps = (dispatch) => ({
    correctLabelPositions: (payload) => {
        dispatch(correctLabelPositions(payload));
        dispatch(setComponentStatus(viewToRender, cladeLabelsComponentId, RENDER_STATUS.DONE));
        dispatch(setComponentStatus(viewToRender, mutationsClassesLabelsComponentId, RENDER_STATUS.DONE));
    },
    setComponentStatus: (viewName, componentId, status) => dispatch(setComponentStatus(viewName, componentId, status)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LabelsLayer);
