import React from 'react';
import {
    Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle,
    Button
} from '@mui/material';
import { styles, dialog } from './styles';
import { connect } from 'react-redux';
import { setErrorWebsite } from '../../redux/actions/uiActions';
import { /*signOut, */signOutSuccess } from '../../redux/actions/userActions';
// import { resetSession } from '../../redux/actions/sessionActions';
import { bindActionCreators } from 'redux';

const ErrorAlert = (props) => {
    const { errorWebsite, setErrorWebsite, errorWebsiteText, /*signOut,*/ signOutSuccess, errorWebsiteType } = props;
    const classes = styles();

    // console.log('[ErrorAlert]', props);
    const handleClose = () => {
        setErrorWebsite({ errorWebsite: false });
        errorWebsiteType !== 'fetch' && signOutSuccess();// && resetSession({lineage});//signOut({ timeout: true });
    };

    return (
        <>
            {errorWebsite &&
                <Dialog
                    open={errorWebsite}
                    onClose={() => { }}
                    aria-labelledby="session-timeout-title"
                    aria-describedby="session-timeout-description"
                    sx={dialog}
                >
                    <DialogTitle id="session-timeout">
                        Error
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="session-timeout-description">
                            {errorWebsiteText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.confirm} onClick={() => handleClose()}>
                            {errorWebsiteType === 'fetch' || errorWebsiteType === 'sessionPublic' ? 'OK' : 'Sign in'}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
};

const mapStateToProps = (state) => {
    const { lineage } = state.parameters;
    const { errorWebsite, errorWebsiteText, errorWebsiteType } = state.ui;
    return {
        lineage,
        errorWebsite,
        errorWebsiteText,
        errorWebsiteType
    };

};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setErrorWebsite,
    // signOut,
    // resetSession,
    signOutSuccess
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ErrorAlert);
