import { produce } from 'immer';
import {
    FETCH_GENOTYPE_DATA_REQUEST,
    FETCH_GENOTYPE_DATA_SUCCESS,
    FETCH_GENOTYPE_DATA_ERROR,
    RESET_GENOTYPE_STATUS,
    FETCH_MUT_POS_DICT_REQUEST,
    FETCH_MUT_POS_DICT_SUCCESS,
    FETCH_MUT_POS_DICT_ERROR,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    FETCH_MUTATION_GROUP_REQUEST,
    FETCH_MUTATION_GROUP_SUCCESS,
    FETCH_MUTATION_GROUP_ERROR,
    ZOOM_TREE_REQUEST,
    FETCH_SUBSET_TREE_REQUEST,
    // FETCH_VISIBLE_NODES_REQUEST,
    FETCH_RECALCULATED_TREE_REQUEST
} from '../actions/actionTypes';

let genotypeInitialState = {};
export const setGenotypeInitialState = (state) => {
    genotypeInitialState = state;
};

const GENES_LABELS = {
    genome: 'Nucleotide'
};

// don't use zero as a value
const GENES_ORDER = {
    genome: 1,
    default: 2
};

export const genotypeReducer = (state = genotypeInitialState, action) => {
    if (action.payload && action.payload.settings) return state;
    
    return produce(state, (draft) => {
        switch (action.type) {
            case RESET_SESSION:
            case SIGNOUT_REQUEST: {
                return genotypeInitialState;
            }
            case FETCH_GENOTYPE_DATA_REQUEST: {
                draft.genotypeDataStatus = 'loading';
                draft.geneMutationValues = {};
                break;
            }
            case FETCH_GENOTYPE_DATA_SUCCESS: {
                const { genotypeData } = action.payload;
                draft.genotypeData = genotypeData;
                draft.genotypeDataStatus ='loaded';
                break;
            }

            case FETCH_GENOTYPE_DATA_ERROR: {
                draft.genotypeDataStatus = 'error';
                break;
            }
            case RESET_GENOTYPE_STATUS: {
                draft.genotypeData = {};
                draft.genotypeDataStatus = 'none';
                break;
            }
            case FETCH_MUT_POS_DICT_REQUEST: {
                draft.mutationsPositionsDictStatus = 'loading';
                break;
            }
            case FETCH_MUT_POS_DICT_SUCCESS: {
                const { mutPositions } = action.payload;
    
                // Generate and sort genes list based on labels and order
                draft.genotypeFilterGenesList = Object.keys(mutPositions)
                    .map(gene => ({
                        label: GENES_LABELS[gene] || gene,
                        val: gene,
                        order: GENES_ORDER[gene] || GENES_ORDER.default
                    }))
                    .sort((a, b) => 
                        a.order === b.order ? a.label.localeCompare(b.label) : a.order - b.order
                    );
            
                // Update mutPositions and status
                draft.mutPositions = mutPositions;
                draft.mutationsPositionsDictStatus = 'loaded';
                break;
            }

            case FETCH_MUT_POS_DICT_ERROR: {
                draft.mutationsPositionsDictStatus = 'error';
                break;
            }

            case FETCH_MUTATION_GROUP_REQUEST: {
                draft.mutationGroupValues = {};
                draft.mutationGroupValuesStatus = 'loading';
                break;
            }

            case FETCH_MUTATION_GROUP_SUCCESS: {
                const { mutationGroupValues } = action.payload;
                draft.mutationGroupValues = mutationGroupValues;
                draft.mutationGroupValuesStatus = 'loaded';
                break;
            }

            case FETCH_MUTATION_GROUP_ERROR: {
                draft.mutationGroupValuesStatus = 'error';
                break;
            }

            case ZOOM_TREE_REQUEST:
            case FETCH_SUBSET_TREE_REQUEST:
            case FETCH_RECALCULATED_TREE_REQUEST: {
                draft.mutationGroupValues = {};
                draft.mutationGroupValuesStatus = 'none';
                break;
            }
            default:
                break;
        }
    });   
};
