// External imports
import React from 'react';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid2';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

// Internal imports - Components
import SelectInput from '../Common/SelectInput';

// Internal imports - Redux
import { setLayoutParameters } from '../../redux/actions/parametersActions';
import { getTreeScaleXOptions, getTreeScaleYOptions } from '../../redux/selectors/metadataSelector';

const TreeScaleTypeSelector = props => {
    const { showCladeBar, treeScaleTypeX, treeScaleTypeY, treeScaleXOptions, treeScaleYOptions, setLayoutParameters } = props;

    const handleChange = name => async value => {
    
        if (value !== 'order' && name === 'treeScaleTypeY' && showCladeBar) {
            setLayoutParameters({ showCladeBar: false, showCladeBarLabels: false });
        }
        await setLayoutParameters({ [name]: value });
    };

    return (
        <Grid container columnSpacing={2}>
            <Grid size={6}>
                <SelectInput
                    id='treeScaleTypeX'
                    label='X axis'
                    value={treeScaleTypeX}
                    onChange={handleChange('treeScaleTypeX')}
                    options={treeScaleXOptions}
                    getOptionValue={option => option.key}
                    getOptionLabel={option => option.label}
                />
            </Grid>
            <Grid size={6}>
                <SelectInput
                    id='treeScaleTypeY'
                    label='Y axis'
                    value={treeScaleTypeY}
                    onChange={handleChange('treeScaleTypeY')}
                    options={treeScaleYOptions}
                    getOptionValue={option => option.key}
                    getOptionLabel={option => option.label}
                />
            </Grid>
        </Grid>
    );
};

TreeScaleTypeSelector.propTypes = {
    showCladeBar: PropTypes.bool.isRequired,
    treeScaleTypeX: PropTypes.string.isRequired,
    treeScaleTypeY: PropTypes.string.isRequired,
    treeScaleXOptions: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string
    })).isRequired,
    treeScaleYOptions: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string
    })).isRequired,
    setLayoutParameters: PropTypes.func.isRequired
};

const mapStateToProps = (state, { intro }) => {
    const Xoptions = getTreeScaleXOptions(state);
    const Yoptions = getTreeScaleYOptions(state);
    const introOptions = ['AADivergence', 'NLDivergence', 'order', 'time'];

    const treeScaleXOptions = intro ? 
        Xoptions.filter(el => introOptions.includes(el.key)) : Xoptions;
    const treeScaleYOptions = intro ?
        Yoptions.filter(el => introOptions.includes(el.key)) : Yoptions;

    return {
        treeScaleTypeX: state.parameters.treeScaleTypeX,
        treeScaleTypeY: state.parameters.treeScaleTypeY,
        showCladeBar: state.parameters.showCladeBar,
        treeScaleXOptions,
        treeScaleYOptions
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setLayoutParameters
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TreeScaleTypeSelector);
