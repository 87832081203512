const button = {
    marginLeft: 10,
    marginRight: 0,
    width: 220,
    height: 38,
    display: 'flex',
    padding: '2px',
    maxHeight: 42,
    minHeight: 32,
    boxShadow: 'none',
    textTransform: 'none',
    border: '1px solid #b5b3b3',
    color: '#FFFFFF',
    alignItems: 'center'
};

const buttonMobile = {
    // marginLeft: 8,
    // marginRight: 5,
    marginBottom: '10px',
    width: '100%',
    height: 32,
    display: 'flex',
    padding: '2px',
    fontSize: '10px',
    maxHeight: 32,
    minHeight: 32,
    boxShadow: 'none',
    textTransform: 'none',
    border: '1px solid #b5b3b3',
    color: '#FFFFFF',
    alignItems: 'center'
};

const styles = () => ({
    container: {
        paddingTop: '7px',
        display: 'flex',
        flexDirection: 'row',
    },
    containerMobile: {
        width: '50%',
        marginLeft: '5px',
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'row',
        marginRight: '10px',
    },
    blue: {
        ...button,
        backgroundColor: '#6F6CFF',
        border: '1px solid #6F6CFF',
        paddingTop: '5px',
        alignItems: 'baseline',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            border: '1px solid #6F6CFF',
            color: '#FFFFFF'
        },
    },
    blueOneline: {
        ...button,
        border: '1px solid #6F6CFF',
        backgroundColor: '#6F6CFF',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            border: '1px solid #6F6CFF',
            color: '#FFFFFF'
        },
    },
    blueMobile: {
        ...buttonMobile,
        backgroundColor: '#6F6CFF',
        border: '1px solid #6F6CFF',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            border: '1px solid #6F6CFF',
            color: '#FFFFFF'
        },
    },
    list: {
        listStyleType: 'none',
        borderRadius: '5px',
        padding: 0,
        top: 40,
        marginTop: '-4px',
        position: 'absolute',
        width: '220px',
        backgroundColor: '#323232',
        border: '1px solid lightgrey',
        zIndex: 1000,
        left: '100%',
        transform: 'translateX(-100%)'
    },
    listMobile: {
        listStyleType: 'none',
        borderRadius: '5px',
        padding: 0,
        marginTop: 0,
        top: 30,
        position: 'absolute',
        width: '100%',
        backgroundColor: '#323232',
        border: '1px solid lightgrey',
        zIndex: 1000,
        fontSize: '10px',
        left: '0%',
        transform: 'translateX(-5%)'
    },
    listItem: {
        height: 42,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '1px solid lightgrey',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'lightgrey',
        },
        '&:last-child': {
            borderBottom: 'none',
        }
    },
    buttonLabelOneLine: {
        lineHeight: '14px',
        margin: 0,
        paddingTop: '6px',
        paddingLeft: '4px',
        height: '17px',
        display: 'flex',
        alignItems: 'center'
    },
    buttonLabelMobileOneLine: {
        fontSize: '10px',
        lineHeight: '12px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center'
    },
    label: {
        fontSize: '14px',
        marginLeft: '20px',
        marginTop: '2px',
    },
    labelMobile: {
        fontSize: '10px',
        marginLeft: '10px',
        marginTop: '2px',
        marginRight: '10px',
    },
    arrowIcon: {
        transition: 'transform 0.3s',
    },
    arrowIconOpen: {
        transform: 'rotate(180deg)',
    }
});

export { styles };