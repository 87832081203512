import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid2';
import { antigenicDetailStyles } from '../styles/antigenicDetailsStyles';
import ExportableComponent from '../../../pages/Export/ExportableComponent';
import AntigenicDetailsData from './AntigenicDetailsData';
import AntigenicDetailsInfo from './AntigenicDetailsInfo';
import { getMeasures } from '../../../redux/selectors/metadataSelector';
import { getRanges } from '../../../redux/selectors/rangeDataSelector';

const useStyles = makeStyles(antigenicDetailStyles);

const AntigenicDetails = (props) => {
    const { detailsCloseHandleClick } = props;

    const [cellInfo, setCellInfo] = useState();

    const classes = useStyles();

    const matrixCellHandleMouseOver = (strain, refStrain, titer) => {
        setCellInfo({strain, refStrain, titer});
    };
 
    useEffect(() => {
        return () => {
            setCellInfo(null);
        };
    }, []);

    return (
        <div className={classes.root}>
            <Grid container className={classes.container}>
                <Grid size='grow' className={classes.dataContainer}>
                    <div className={classes.closeBtn} onClick={() => { detailsCloseHandleClick(); /*toggleAntigenicDetails(false);*/ }} />
                    <ExportableComponent filename="antigenicDetails">
                        <AntigenicDetailsData matrixCellHandleMouseOver={matrixCellHandleMouseOver} />
                    </ExportableComponent>
                </Grid>
                <Grid size={3} >
                    <AntigenicDetailsInfo cellInfo={cellInfo}/>
                </Grid>
            </Grid>
        </div>
    );
};

AntigenicDetails.propTypes = {
    exportMode: PropTypes.bool,
    classes: PropTypes.shape({
        root: PropTypes.string,
    }),
    rho: PropTypes.number,
    alpha: PropTypes.number,
};

const mapStateToProps = (state) => {
    const { exportMode, antigenicTiterType } = state.parameters;
    const { rawAntigenicCladeStrains, alpha, rho } = state.antigenic;
    const measures = getMeasures(state);
    const ranges = getRanges(state);

    return {
        measures,
        exportMode,
        antigenicTiterType,
        rawAntigenicCladeStrains,
        alpha,
        rho,
        ranges
    };
};

// const mapDispatchToProps = (dispatch) => bindActionCreators({ toggleAntigenicDetails }, dispatch);

// const AntigenicWithStyles = withStyles(antigenicDetailStyles)(AntigenicDetails);

export default connect(mapStateToProps /*, mapDispatchToProps*/)(AntigenicDetails);
// export { AntigenicExport };
