import React, { useState, useEffect, useMemo } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, FormControl, MenuItem, DialogContentText } from '@mui/material';
import { styles, ScaleDialogSX } from '../../Styles/dialogStyles';
import CustomLabel from '../../../../assets/GlobalStyles/CustomLabel';
import CustomSelect from '../../../../assets/GlobalStyles/CustomSelect';
import DraggableList from '../Elements/DraggableList';
import ScaleIdConfigurator from '../Elements/ScaleIdConfigurator';
import { checkDomainDiscrete, checkDomainLinear, checkId, spliceScaleId } from '../Elements/functions';
import { connect } from 'react-redux';
import { ColorScaleSelectorBase } from '../../../../components/OptionsSelector/ColorScaleSelector/ColorScaleSelectorBase';
import { capitalize } from 'lodash';
import CheckboxInput from '../../../../components/Common/CheckboxInput';

const initState = {
    scaleId: '',
    discrete: false,
    numeric: true,
    palette: 'custom',
    lineage: 'none',
    assignColors: false,
};

const domainInitState = [
    {
        value: 0,
        quantile: true,
        color: '#FBFBFF'
    },
    {
        value: 1,
        quantile: true,
        color: 'red'
    }
];

const additionalDomainDiscrete = {
    value: Infinity,
    color: '#FBFBFF'
};

const errorInitState = { scaleType: { status: false, message: '' }, antigenicScaleType: { status: false, message: '' }, id: { status: false, message: '' } };

const reorder = (
    list,
    startIndex,
    endIndex
) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const ScaleDialog = ({ scale, lineages, scalesPalette, openDialog, handleCloseDialog, submit }) => {
    const classes = styles();
    const [currentScale, setCurrentScale] = useState(scale ? undefined : initState);
    const [currentDomain, setCurrentDomain] = useState(scale ? [] : domainInitState);
    const [colorBy, setColorBy] = useState(false);

    const paletteOptions = useMemo(() => ['custom'].concat(Object.keys(scalesPalette)), [scalesPalette]);
    
    const scales = useMemo(() => Object.keys(scalesPalette).reduce((acc, key) => {
        acc[key] = { range: scalesPalette[key] };
        return acc;
    }, {}), [scalesPalette]);

    useEffect(() => {
        if (!scale) return;

        const isCustomScale = typeof scale.range !== 'string';
        const isDomain = scale.domain || false;

        const initState = {
            scaleId: scale.scaleId,
            discrete: scale.discrete,
            numeric: scale.numeric,
            palette: isCustomScale ? 'custom' : scale.range,
            lineage: scale.lineage || 'none',
            assignColors: scale.assignColors || false
        };
        setCurrentScale(initState);

        if (!isDomain)
            return;

        let domainInitState = [];
        if (!scale.discrete) {
            const scaleColors = !isCustomScale ? scalesPalette[scale.range] : scale.range;
            if (scaleColors){
                const lastIndex = scaleColors.length - 1;
                const middleColors = scaleColors.slice(1, lastIndex).map(el => { return { color: el, value: 0, quantile: false }; });
                domainInitState = [{
                    value: scale.domain[0].value,
                    quantile: scale.domain[0].quantile,
                    color: scaleColors[0]
                },
                ...middleColors,
                {
                    value: scale.domain[1].value,
                    quantile: scale.domain[1].quantile,
                    color: scaleColors[lastIndex]
                }];
                setColorBy(true);
            } else{
                domainInitState = scale.domain.map(el => { return { value: el.value, quantile: el?.quantile || false }; });
            }
        } else {
            const scaleColors = !isCustomScale ? scalesPalette[scale.range] : scale.range;
            setColorBy(true);
            if (scaleColors){
                const lastIndex = scale.domain.length;
                const begin = scale.domain.map((el, index) => {
                    return {
                        value: el.value,
                        color: scaleColors[index],
                        quantile: el.quantile
                    };
                });
                domainInitState = [...begin, { value: Infinity, color: scaleColors[lastIndex] }];
            } else {
                domainInitState = scale.domain.map(el => { return { value: el.value, quantile: el?.quantile || false }; });
            }
        }
        setCurrentDomain(domainInitState);
    }, [scale]);

    const handleChangeLineage = (e) => setCurrentScale({ ...currentScale, lineage: e.target.value });

    const handleValueChange = (index) => (e) => {
        const newDomain = [...currentDomain];
        const isQuantile = currentDomain[index].quantile;
        const newValue = e.target.value;
        newDomain[index].value = isQuantile && (newValue > 1 || newValue < 0) ? 1 : newValue;
        console.log('[handleValueChange] newDomain', newDomain);
        setCurrentDomain(newDomain);
    };

    const handleColorChange = (value, index) => {
        const newDomain = [...currentDomain];
        newDomain[index].color = value.hex;
        setCurrentDomain(newDomain);
    };

    const handleQuantileChange = (index) => (checked) => {
        const newDomain = [...currentDomain];
        newDomain[index].quantile = checked;
        if (checked && (newDomain[index].value > 1 || newDomain[index].value < 0))
            newDomain[index].value = '';
        setCurrentDomain(newDomain);
    };

    const addNewElement = () => {
        const newDomain = [...currentDomain];
        const newElement = currentDomain[0].color 
            ? { ...domainInitState[0] }
            : { value: domainInitState[0].value, quantile: domainInitState[0].quantile };
        newDomain.splice(1, 0, newElement);
        setCurrentDomain(newDomain);
    };

    const removeElement = (index) => {
        const newDomain = [...currentDomain];
        newDomain.splice(index, 1);
        setCurrentDomain(newDomain);
    };

    const onEndDrag = ({ destination, source }) => {
        if (!destination) return;
        const newDomain = reorder(currentDomain, source.index, destination.index);
        setCurrentDomain(newDomain);
    };

    const handleChange = (palette) => {
        let newDomain = [];

        if (palette === 'custom') {
            newDomain = [...domainInitState];
        } else {
            const colorsArray = scalesPalette[palette];
            newDomain = colorsArray.map((col, index) => {
                return {
                    value: !index ? 0 : 1,
                    quantile: true,
                    color: col
                };
            });
        }
        setCurrentScale({ ...currentScale, palette });
        setCurrentDomain(newDomain);
    };

    const handleSubmit = () => {
        if (scale) {
            const hasLineageChanged = currentScale.lineage === 'none' ?
                scale.lineage !== undefined :
                currentScale.lineage !== scale.lineage;
            const hasIdChanged = currentScale.scaleId !== scale.scaleId;
            submit(currentScale, currentDomain, hasLineageChanged, hasIdChanged, scale.scaleId);
        } else {
            const isValid = checkId(currentScale.scaleId) && 
                          (currentScale.discrete ? checkDomainDiscrete(currentDomain) : checkDomainLinear(currentDomain));
            if (isValid) {
                submit(currentScale, currentDomain);
            }
        }
    };

    const handleScaleIdChange = (scaleId) => {
        setCurrentScale({ ...currentScale, scaleId });
    };

    const handleCheckboxChange = (discrete) => {
        if (!discrete) {
            const newDomain = currentDomain.slice(0, currentDomain.length - 1);
            setCurrentDomain(newDomain);
            setCurrentScale({ ...currentScale, discrete, assignColors: false });
        }
        else {
            // console.log('[handleCheckboxChange] discrete', currentDomain);
            const newDomain = [...currentDomain];
            const newElement = currentDomain[0].color 
                ? { ...additionalDomainDiscrete }
                : { value: additionalDomainDiscrete.value, quantile: additionalDomainDiscrete.quantile };
            newDomain.push(newElement);
            setCurrentDomain(newDomain);
            setCurrentScale({ ...currentScale, discrete });
        }
    };

    const handleColorByChange = (value) => {
        setColorBy(value);
        if (!value) {
            setCurrentScale({ ...currentScale});
            const newDomain = currentDomain.map(d => ({ value: d.value, quantile: d.quantile }));
            setCurrentDomain(newDomain);
        }
        else {
            setCurrentScale({ ...currentScale, palette: 'custom' });
            const newDomain = currentDomain.map(d => ({ value: d.value, quantile: d.quantile, color: 'white'}));
            setCurrentDomain(newDomain);
        }
    };

    const handleAssignColorsChange = (value) => {
        setCurrentScale({ ...currentScale, assignColors: value });
    };

    if (!currentScale)
        return <></>;

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={ScaleDialogSX}>
            <DialogTitle id="confirm-dialog-title">
                {scale ? `Edit scale: ${scale.scaleId}` : 'Add new scale'}
            </DialogTitle>
            <DialogContent style={{ height: '450px', overflow: 'overlay' }}>
                <DialogContentText className={classes.helpText}>
                    ScaleId is a combination of measure and its name,<br /> 
                    {scale ? `current scale id: ${currentScale.scaleId}` : ''}
                </DialogContentText>
                <ScaleIdConfigurator
                    handleScaleIdChange={handleScaleIdChange}
                    newScale={currentScale}
                    error={errorInitState}
                    edit={!!scale}
                />
                <FormControl fullWidth className={classes.formControlLineage}>
                    <CustomLabel id={'lineage'} label={'Lineage'} />
                    <CustomSelect
                        value={currentScale.lineage}
                        onChange={handleChangeLineage}
                        inputProps={{
                            name: 'lineage',
                            id: 'lineage',
                        }}
                    >
                        {lineages.length > 0 && lineages.map(option =>
                            (<MenuItem key={option} value={option} >{option}</MenuItem>)
                        )}
                        <MenuItem key={'empty'} value={'none'} >{`None (all lineages)`}</MenuItem>

                    </CustomSelect>
                </FormControl>
                <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    
                    <CheckboxInput
                        name="discrete"
                        label="Discrete"
                        value={currentScale.discrete}
                        onChange={handleCheckboxChange}
                    />
                    <CheckboxInput
                        name="colorBy"
                        label="Color scale"
                        value={colorBy}
                        onChange={handleColorByChange}
                    />
                    { currentScale.discrete && colorBy && 
                        <CheckboxInput
                            name="assignColors"
                            label="Colors assigned from measure"
                            value={currentScale.assignColors}
                            onChange={handleAssignColorsChange}
                        />}
                </div>

                <div style={{ height: '14px' }} />
                
                {!currentScale.assignColors && colorBy && 
                    <ColorScaleSelectorBase
                        id={'palette'}
                        label={'Scale palette'}
                        value={currentScale.palette}
                        onChange={handleChange}
                        options={paletteOptions}
                        scales={scales}
                        getOptionLabel={(scale)=> capitalize(scale.split('.').at(-1))}
                        getOptionValue={scale => scale}
                        classTooltipName={classes.tooltip}
                    />
                }

                { !currentScale.assignColors &&
                    <DraggableList
                        domain={currentDomain}
                        newScale={currentScale}
                        handleValueChange={handleValueChange}
                        handleColorChange={handleColorChange}
                        handleQuantileChange={handleQuantileChange}
                        addNewElement={addNewElement}
                        onEndDrag={onEndDrag}
                        removeElement={removeElement}
                        colorBy={colorBy}
                    />
                }
                

            </DialogContent>

            <DialogActions>
                <Button className={classes.cancel}
                    onClick={handleCloseDialog}>Cancel</Button>
                <Button className={classes.confirm}
                    onClick={handleSubmit}>
                    {scale ? 'Update' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    const { lineages } = state.lineages;

    return {
        lineages
    };
};

export default connect(mapStateToProps)(ScaleDialog);
