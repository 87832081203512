export const FETCH_TREES_REQUEST = 'FETCH_TREES_REQUEST';
export const FETCH_TREES_SUCCESS = 'FETCH_TREES_SUCCESS';
export const FETCH_TREES_ERROR = 'FETCH_TREES_ERROR';

export const FETCH_CUSTOM_TREE_ATTRS_REQUEST = 'FETCH_CUSTOM_TREE_ATTRS_REQUEST';
export const FETCH_CUSTOM_TREE_ATTRS_SUCCESS = 'FETCH_CUSTOM_TREE_ATTRS_SUCCESS';
export const FETCH_CUSTOM_TREE_ATTRS_ERROR = 'FETCH_CUSTOM_TREE_ATTRS_ERROR';

export const FETCH_BRANCH_NODES_REQUEST = 'FETCH_BRANCH_NODES_REQUEST';
export const FETCH_BRANCH_NODES_SUCCESS = 'FETCH_BRANCH_NODES_SUCCESS';
export const FETCH_BRANCH_NODES_ERROR = 'FETCH_BRANCH_NODES_ERROR';

export const FETCH_MODELS_REQUEST = 'FETCH_MODELS_REQUEST';
export const FETCH_MODELS_SUCCESS = 'FETCH_MODELS_SUCCESS';
export const FETCH_MODELS_ERROR = 'FETCH_MODELS_ERROR';


export const FETCH_CUSTOM_TREE_SUBSETS_SUCCESS = 'FETCH_CUSTOM_TREE_SUBSETS_SUCCESS';
export const FETCH_CUSTOM_TREE_SUBSETS_ERROR = 'FETCH_CUSTOM_TREE_SUBSETS_ERROR';
export const FETCH_CUSTOM_TREE_SUBSETS_REQUEST = 'FETCH_CUSTOM_TREE_SUBSETS_REQUEST';

export const FETCH_LINEAGE_REGIONS_SUCCESS = 'FETCH_LINEAGE_REGIONS_SUCCESS';
export const FETCH_LINEAGE_REGIONS_ERROR = 'FETCH_LINEAGE_REGIONS_ERROR';
export const FETCH_LINEAGE_REGIONS_REQUEST = 'FETCH_LINEAGE_REGIONS_REQUEST';

export const RESET_TREE = 'RESET_TREE';

export const FETCH_MODEL_TYPES_REQUEST = 'FETCH_MODEL_TYPES_REQUEST';
export const FETCH_MODEL_TYPES_SUCCESS = 'FETCH_MODEL_TYPES_SUCCESS';
export const FETCH_MODEL_TYPES_ERROR = 'FETCH_MODEL_TYPES_ERROR';

export const RESET_ANTIGENIC_MODEL = 'RESET_ANTIGENIC_MODEL';
export const RESET_ANTIGENIC_FITNESS_MODEL = 'RESET_ANTIGENIC_FITNESS_MODEL';

export const FETCH_ANTIGENIC_RAW_MODEL_REQUEST = 'FETCH_ANTIGENIC_RAW_MODEL_REQUEST';
export const FETCH_ANTIGENIC_RAW_MODEL_SUCCESS = 'FETCH_ANTIGENIC_RAW_MODEL_SUCCESS';
export const FETCH_ANTIGENIC_RAW_MODEL_ERROR = 'FETCH_ANTIGENIC_RAW_MODEL_ERROR';

export const FETCH_ANTIGENIC_OBSERVED_DATA_REQUEST = 'FETCH_ANTIGENIC_OBSERVED_DATA_REQUEST';
export const FETCH_ANTIGENIC_OBSERVED_DATA_SUCCESS = 'FETCH_ANTIGENIC_OBSERVED_DATA_SUCCESS';
export const FETCH_ANTIGENIC_OBSERVED_DATA_ERROR = 'FETCH_ANTIGENIC_OBSERVED_DATA_ERROR';

export const FETCH_MODEL_DATA_REQUEST = 'FETCH_MODEL_DATA_REQUEST';
export const FETCH_MODEL_DATA_SUCCESS = 'FETCH_MODEL_DATA_SUCCESS';
export const FETCH_MODEL_DATA_ERROR = 'FETCH_MODEL_DATA_ERROR';

export const FETCH_CLADES_REQUEST = 'FETCH_CLADES_REQUEST';
export const FETCH_CLADES_SUCCESS = 'FETCH_CLADES_SUCCESS';
export const FETCH_CLADES_ERROR = 'FETCH_CLADES_ERROR';

export const FETCH_ACTIVE_CLADES_REQUEST = 'FETCH_ACTIVE_CLADES_REQUEST';
export const FETCH_ACTIVE_CLADES_SUCCESS = 'FETCH_ACTIVE_CLADES_SUCCESS';
export const FETCH_ACTIVE_CLADES_ERROR = 'FETCH_ACTIVE_CLADES_ERROR';
export const RESET_ACTIVE_CLADES = 'RESET_ACTIVE_CLADES';

export const FETCH_FREQUENCIES_REQUEST = 'FETCH_FREQUENCIES_REQUEST';
export const FETCH_FREQUENCIES_SUCCESS = 'FETCH_FREQUENCIES_SUCCESS';
export const FETCH_FREQUENCIES_ERROR = 'FETCH_FREQUENCIES_ERROR';
export const RESET_FREQUENCIES = 'RESET_FREQUENCIES';
export const RESET_VISIBLE_BINS = 'RESET_VISIBLE_BINS';

export const SELECT_NODE_DATA = 'SELECT_NODE_DATA';
export const SET_REFERENCE_STRAIN = 'SET_REFERENCE_STRAIN';
export const SET_ACTIVE_LEGEND_OPTION = 'SET_ACTIVE_LEGEND_OPTION';
export const SET_SELECTED_MODELS = 'SET_SELECTED_MODELS';

export const SELECT_ZOOM_NODE = 'SELECT_ZOOM_NODE';
export const ZOOM_TREE_REQUEST = 'ZOOM_TREE_REQUEST';
export const ZOOM_TREE_SUCCESS = 'ZOOM_TREE_SUCCESS';
export const ZOOM_TREE_ERROR = 'ZOOM_TREE_ERROR';

export const SET_COLOR_BY = 'SET_COLOR_BY';
export const SET_BRANCH_NODES = 'SET_BRANCH_NODES';

export const SET_MODEL_REGION_ID = 'SET_MODEL_REGION_ID';
export const SET_MODEL_TYPE = 'SET_MODEL_TYPE';

export const SET_REFERENCE_CLADE = 'SET_REFERENCE_CLADE';

export const SET_PARAMETERS = 'SET_PARAMETERS';
export const SET_SETTINGS_PARAMETERS = 'SET_SETTINGS_PARAMETERS';
export const SET_LAYOUT_PARAMETERS = 'SET_LAYOUT_PARAMETERS';
export const SET_SETTINGS_METADATA = 'SET_SETTINGS_METADATA';


export const SET_SHOW_MUTATIONS_GROUPS = 'SET_SHOW_MUTATIONS_GROUPS';
export const SET_SHOW_CLADE_LABELS = 'SET_SHOW_CLADE_LABELS';

export const FETCH_PREDICTIONS_REQUEST = 'FETCH_PREDICTIONS_REQUEST';
export const FETCH_PREDICTIONS_SUCCESS = 'FETCH_PREDICTIONS_SUCCESS';
export const FETCH_PREDICTIONS_ERROR = 'FETCH_PREDICTIONS_ERROR';

export const FETCH_MUTATION_CLASSES_ARRAY_REQUEST = 'FETCH_MUTATION_CLASSES_ARRAY_REQUEST';
export const FETCH_MUTATION_CLASSES_ARRAY_SUCCESS = 'FETCH_MUTATION_CLASSES_ARRAY_SUCCESS';
export const FETCH_MUTATION_CLASSES_ARRAY_ERROR = 'FETCH_MUTATION_CLASSES_ARRAY_ERROR';

export const FETCH_LINEAGE_MUTATION_GENES_REQUEST = 'FETCH_LINEAGE_MUTATION_GENES_REQUEST';
export const FETCH_LINEAGE_MUTATION_GENES_SUCCESS = 'FETCH_LINEAGE_MUTATION_GENES_SUCCESS';
export const FETCH_LINEAGE_MUTATION_GENES_ERROR = 'FETCH_LINEAGE_MUTATION_GENES_ERROR';

export const FETCH_ALL_MEASURES_REQUEST = 'FETCH_ALL_MEASURES_REQUEST';
export const FETCH_ALL_MEASURES_SUCCESS = 'FETCH_ALL_MEASURES_SUCCESS';
export const FETCH_ALL_MEASURES_ERROR = 'FETCH_ALL_MEASURES_ERROR';

export const RESET_PREDICTIONS = 'RESET_PREDICTIONS';

export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_ERROR = 'SIGNIN_ERROR';

export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_ERROR = 'SIGNOUT_ERROR';

export const SET_USER_PREFERENCES = 'SET_USER_PREFERENCES';

export const GET_SIGNED_USER_REQUEST = 'GET_SIGNED_USER_REQUEST';
export const GET_SIGNED_USER_SUCCESS = 'GET_SIGNED_USER_SUCCESS';
export const GET_SIGNED_USER_ERROR = 'GET_SIGNED_USER_ERROR';

// export const SET_USERNAME_SUCCESS = 'SET_USERNAME_SUCCESS';
// export const SET_USERNAME_ERROR = 'SET_USERNAME_ERROR';

export const SET_RENDER_STATUS = 'SET_RENDER_STATUS';

export const TOGGLE_FREQUENCIES_OPTION = 'TOGGLE_FREQUENCIES_OPTION';
export const REVERT_SELECTED_FREQUENCIES_BINS = 'REVERT_SELECTED_FREQUENCIES_BINS';

export const SELECT_POINT = 'SELECT_POINT';

export const TOGGLE_MUTATION_CLASS_LABEL_VISIBILITY = 'TOGGLE_MUTATION_CLASS_LABEL_VISIBILITY';

export const INIT_STRAIN_TREE_REQUEST = 'INIT_STRAIN_TREE_REQUEST';
export const INIT_STRAIN_TREE_SUCCESS = 'INIT_STRAIN_TREE_SUCCESS';
export const INIT_STRAIN_TREE_ERROR = 'INIT_STRAIN_TREE_ERROR';

export const RESET_SESSION = 'RESET_SESSION';

export const SET_COLORING = 'SET_COLORING';

export const INIT_PREDICTIONS_REQUEST = 'INIT_PREDICTIONS_REQUEST';
export const INIT_PREDICTIONS_SUCCESS = 'INIT_PREDICTIONS_SUCCESS';
export const INIT_PREDICTIONS_ERROR = 'INIT_PREDICTIONS_ERROR';

export const FETCH_ANTIGENIC_MODEL_REQUEST = 'FETCH_ANTIGENIC_MODEL_REQUEST';
export const FETCH_ANTIGENIC_MODEL_SUCCESS = 'FETCH_ANTIGENIC_MODEL_SUCCESS';
export const FETCH_ANTIGENIC_MODEL_ERROR = 'FETCH_ANTIGENIC_MODEL_ERROR';

export const FETCH_ANTIGENIC_CLADES_REQUEST = 'FETCH_ANTIGENIC_CLADES_REQUEST';
export const FETCH_ANTIGENIC_CLADES_SUCCESS = 'FETCH_ANTIGENIC_CLADES_SUCCESS';
export const FETCH_ANTIGENIC_CLADES_ERROR = 'FETCH_ANTIGENIC_CLADES_ERROR';
export const RESET_ANTIGENIC_CLADES = 'RESET_ANTIGENIC_CLADES';

export const SELECT_ANTIGENIC_VALUE = 'SELECT_ANTIGENIC_VALUE';
export const SELECT_ANTIGENIC_VALUES = 'SELECT_ANTIGENIC_VALUES';

export const SET_ANTIGENIC_HIDDEN_ALPHA_CLADE = 'SET_ANTIGENIC_HIDDEN_ALPHA_CLADE';
export const SET_ANTIGENIC_HIDDEN_RHO_CLADE = 'SET_ANTIGENIC_HIDDEN_RHO_CLADE';
export const SET_FITNESS_HIDDEN_ALPHA_CLADE = 'SET_FITNESS_HIDDEN_ALPHA_CLADE';
export const SET_FITNESS_HIDDEN_RHO_CLADE = 'SET_FITNESS_HIDDEN_RHO_CLADE';
export const UNHIDE_ALPHA_CLADE = 'UNHIDE_ALPHA_CLADE';
export const UNHIDE_RHO_CLADE = 'UNHIDE_RHO_CLADE';
export const RESET_ANTIGENIC_HIDDEN_CLADES = 'RESET_ANTIGENIC_HIDDEN_CLADES';
export const UNHIDE_RHO_CLADE_FITNESS = 'UNHIDE_RHO_CLADE_FITNESS';
export const RESET_FITNESS_HIDDEN_CLADES = 'RESET_FITNESS_HIDDEN_CLADES';
export const UNHIDE_ALPHA_CLADE_FITNESS = 'UNHIDE_ALPHA_CLADE_FITNESS';

export const SET_MEASURE_DOMAIN = 'SET_MEASURE_DOMAIN';
export const SET_MEASURE_SCALE = 'SET_MEASURE_SCALE';

export const FETCH_LINEAGES_REQUEST = 'FETCH_LINEAGES_REQUEST';
export const FETCH_LINEAGES_SUCCESS = 'FETCH_LINEAGES_SUCCESS';
export const FETCH_LINEAGES_ERROR = 'FETCH_LINEAGES_ERROR';

export const FETCH_LINEAGE_REQUEST = 'FETCH_LINEAGE_REQUEST';
export const FETCH_LINEAGE_SUCCESS = 'FETCH_LINEAGE_SUCCESS';
export const FETCH_LINEAGE_ERROR = 'FETCH_LINEAGE_ERROR';

export const FETCH_SUBSET_TREE_REQUEST = 'FETCH_SUBSET_TREE_REQUEST';
export const FETCH_SUBSET_TREE_SUCCESS = 'FETCH_SUBSET_TREE_SUCCESS';
export const FETCH_SUBSET_TREE_ERROR = 'FETCH_SUBSET_TREE_ERROR';

export const FETCH_REFERENCE_STRAINS_REQUEST = 'FETCH_REFERENCE_STRAINS_REQUEST';
export const FETCH_REFERENCE_STRAINS_SUCCESS = 'FETCH_REFERENCE_STRAINS_SUCCESS';
export const FETCH_REFERENCE_STRAINS_ERROR = 'FETCH_REFERENCE_STRAINS_ERROR';
export const RESET_REFERENCE_STRAINS = 'RESET_REFERENCE_STRAINS';

export const FETCH_TREE_FREQS_REQUEST = 'FETCH_TREE_FREQS_REQUEST';
export const FETCH_TREE_FREQS_SUCCESS = 'FETCH_TREE_FREQS_SUCCESS';
export const FETCH_TREE_FREQS_ERROR = 'FETCH_TREE_FREQS_ERROR';

export const FETCH_TCELL_ANTIGENICITY_OPTIONS_REQUEST = 'FETCH_TCELL_ANTIGENICITY_OPTIONS_REQUEST';
export const FETCH_TCELL_ANTIGENICITY_OPTIONS_SUCCESS = 'FETCH_TCELL_ANTIGENICITY_OPTIONS_SUCCESS';
export const FETCH_TCELL_ANTIGENICITY_OPTIONS_ERROR = 'FETCH_TCELL_ANTIGENICITY_OPTIONS_ERROR';

export const FETCH_VP_METHODS_REQUEST = 'FETCH_VP_METHODS_REQUEST';
export const FETCH_VP_METHODS_SUCCESS = 'FETCH_VP_METHODS_SUCCESS';
export const FETCH_VP_METHODS_ERROR = 'FETCH_VP_METHODS_ERROR';

export const FETCH_MUTATION_CLASSES_REQUEST = 'FETCH_MUTATION_CLASSES_REQUEST';
export const FETCH_MUTATION_CLASSES_SUCCESS = 'FETCH_MUTATION_CLASSES_SUCCESS';
export const FETCH_MUTATION_CLASSES_ERROR = 'FETCH_MUTATION_CLASSES_ERROR';

export const SET_MUTATION_CLASSES = 'SET_MUTATION_CLASSES';

export const FETCH_MUTATION_GROUP_REQUEST = 'FETCH_MUTATION_GROUP_REQUEST';
export const FETCH_MUTATION_GROUP_SUCCESS = 'FETCH_MUTATION_GROUP_SUCCESS';
export const FETCH_MUTATION_GROUP_ERROR = 'FETCH_MUTATION_GROUP_ERROR';

export const FETCH_TCELL_ANTIGENICITY_SCORES_REQUEST = 'FETCH_TCELL_ANTIGENICITY_SCORES_REQUEST';
export const FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS = 'FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS';
export const FETCH_TCELL_ANTIGENICITY_SCORES_ERROR = 'FETCH_TCELL_ANTIGENICITY_SCORES_ERROR';

// export const FETCH_VISIBLE_NODES_REQUEST = 'FETCH_VISIBLE_NODES_REQUEST';
// export const FETCH_VISIBLE_NODES_SUCCESS = 'FETCH_VISIBLE_NODES_SUCCESS';
// export const FETCH_VISIBLE_NODES_ERROR = 'FETCH_VISIBLE_NODES_ERROR';

export const FETCH_RECALCULATED_TREE_REQUEST = 'FETCH_RECALCULATED_TREE_REQUEST';
export const FETCH_RECALCULATED_TREE_SUCCESS = 'FETCH_RECALCULATED_TREE_SUCCESS';
export const FETCH_RECALCULATED_TREE_ERROR = 'FETCH_RECALCULATED_TREE_ERROR';

export const FETCH_FITNESS_MODEL_REQUEST = 'FETCH_FITNESS_MODEL_REQUEST';
export const FETCH_FITNESS_MODEL_SUCCESS = 'FETCH_FITNESS_MODEL_SUCCESS';
export const FETCH_FITNESS_MODEL_ERROR = 'FETCH_FITNESS_MODEL_ERROR';

// export const FETCH_ANTIGENIC_OBSERVED_STRAIN_REQUEST = 'FETCH_ANTIGENIC_OBSERVED_STRAIN_REQUEST';
// export const FETCH_ANTIGENIC_OBSERVED_STRAIN_SUCCESS = 'FETCH_ANTIGENIC_OBSERVED_STRAIN_SUCCESS';
// export const FETCH_ANTIGENIC_OBSERVED_STRAIN_ERROR = 'FETCH_ANTIGENIC_OBSERVED_STRAIN_ERROR';

export const FETCH_SELECTED_STRAIN_REQUEST = 'FETCH_SELECTED_STRAIN_REQUEST';
export const FETCH_SELECTED_STRAIN_SUCCESS = 'FETCH_SELECTED_STRAIN_SUCCESS';
export const FETCH_SELECTED_STRAIN_ERROR = 'FETCH_SELECTED_STRAIN_ERROR';
export const SET_SELECTED_STRAIN = 'SET_SELECTED_STRAIN';

export const FETCH_ANTIGENIC_REFERENCE_STRAIN_REQUEST = 'FETCH_ANTIGENIC_REFERENCE_STRAIN_REQUEST';
export const FETCH_ANTIGENIC_REFERENCE_STRAIN_SUCCESS = 'FETCH_ANTIGENIC_REFERENCE_STRAIN_SUCCESS';
export const FETCH_ANTIGENIC_REFERENCE_STRAIN_ERROR = 'FETCH_ANTIGENIC_REFERENCE_STRAIN_ERROR';

export const SET_SEARCH_STRAIN_MODE = 'SET_SEARCH_STRAIN_MODE';
export const SET_STRAIN_SEARCH_STATUS = 'SET_STRAIN_SEARCH_STATUS';

export const SET_COMPONENT_STATUS = 'SET_COMPONENT_STATUS';
export const RESET_COMPONENTS_STATUS = 'RESET_COMPONENTS_STATUS';

export const FETCH_VACCINE_CANDIDATES_REQUEST = 'FETCH_VACCINE_CANDIDATES_REQUEST';
export const FETCH_VACCINE_CANDIDATES_SUCCESS = 'FETCH_VACCINE_CANDIDATES_SUCCESS';
export const FETCH_VACCINE_CANDIDATES_ERROR = 'FETCH_VACCINE_CANDIDATES_ERROR';

export const FETCH_VP_VALUES_REQUEST = 'FETCH_VP_VALUES_REQUEST';
export const FETCH_VP_VALUES_SUCCESS = 'FETCH_VP_VALUES_SUCCESS';
export const FETCH_VP_VALUES_ERROR = 'FETCH_VP_VALUES_ERROR';
export const RESET_VP_VALUES = 'RESET_VP_VALUES';

// export const SHOW_ALERT = 'SHOW_ALERT';
// export const CLOSE_ALERT = 'CLOSE_ALERT';
export const SET_ALERT_STATUS = 'SET_ALERT_STATUS';

export const FETCH_GENOTYPE_DATA_REQUEST = 'FETCH_GENOTYPE_DATA_REQUEST';
export const FETCH_GENOTYPE_DATA_SUCCESS = 'FETCH_GENOTYPE_DATA_SUCCESS';
export const FETCH_GENOTYPE_DATA_ERROR = 'FETCH_GENOTYPE_DATA_ERROR';
export const RESET_GENOTYPE_STATUS = 'RESET_GENOTYPE_STATUS';

export const FETCH_MUT_POS_DICT_REQUEST = 'FETCH_MUT_POS_DICT_REQUEST';
export const FETCH_MUT_POS_DICT_SUCCESS = 'FETCH_MUT_POS_DICT_SUCCESS';
export const FETCH_MUT_POS_DICT_ERROR = 'FETCH_MUT_POS_DICT_ERROR';


export const FETCH_STRAINS_LIST_REQUEST = 'FETCH_STRAINS_LIST_REQUEST';
export const FETCH_STRAINS_LIST_SUCCESS = 'FETCH_STRAINS_LIST_SUCCESS';
export const FETCH_STRAINS_LIST_ERROR = 'FETCH_STRAINS_LIST_ERROR';

export const SET_STRAINS_LIST = 'SET_STRAINS_LIST';

export const FETCH_NODE_REQUEST = 'FETCH_NODE_REQUEST';
export const FETCH_NODE_SUCCESS = 'FETCH_NODE_SUCCESS';
export const FETCH_NODE_ERROR = 'FETCH_NODE_ERROR';

export const FETCH_GEO_DATA_REQUEST = 'FETCH_GEO_DATA_REQUEST';
export const FETCH_GEO_DATA_SUCCESS = 'FETCH_GEO_DATA_SUCCESS';
export const FETCH_GEO_DATA_ERROR = 'FETCH_GEO_DATA_ERROR';

export const FETCH_STRAINS_GEO_REQUEST = 'FETCH_STRAINS_GEO_REQUEST';
export const FETCH_STRAINS_GEO_SUCCESS = 'FETCH_STRAINS_GEO_SUCCESS';
export const FETCH_STRAINS_GEO_ERROR = 'FETCH_STRAINS_GEO_ERROR';

export const SET_LABEL_MOVEMENT = 'SET_LABEL_MOVEMENT';
// export const SET_SYMBOL_POSITION = 'SET_SYMBOL_POSITION';
// export const SET_SYMBOL_DIMENSIONS = 'SET_SYMBOL_DIMENSIONS';
export const CORRECT_LABEL_POSITIONS = 'CORRECT_LABEL_POSITIONS';
export const SET_SCALE_INITIALIZED = 'SET_SCALE_INITIALIZED';
export const SET_STRAINS_TREE_DIMENSIONS = 'SET_STRAINS_TREE_DIMENSIONS';

export const SET_LINEAGE_SETTINGS_REQUEST = 'SET_LINEAGE_SETTINGS_REQUEST';
export const SET_LINEAGE_SETTINGS_SUCCESS = 'SET_LINEAGE_SETTINGS_SUCCESS';
export const SET_LINEAGE_SETTINGS_ERROR = 'SET_LINEAGE_SETTINGS_ERROR';

export const FETCH_ALL_SCALES_REQUEST = 'FETCH_ALL_SCALES_REQUEST';
export const FETCH_ALL_SCALES_SUCCESS = 'FETCH_ALL_SCALES_SUCCESS';
export const FETCH_ALL_SCALES_ERROR = 'FETCH_ALL_SCALES_ERROR';

// export const FETCH_SCALE_DOMAIN_REQUEST = 'FETCH_SCALE_DOMAIN_REQUEST';
// export const FETCH_SCALE_DOMAIN_SUCCESS = 'FETCH_SCALE_DOMAIN_SUCCESS';
// export const FETCH_SCALE_DOMAIN_ERROR = 'FETCH_SCALE_DOMAIN_ERROR';

export const FETCH_MEASURE_SCALES_DOMAINS_REQUEST = 'FETCH_MEASURE_SCALES_DOMAINS_REQUEST';
export const FETCH_MEASURE_SCALES_DOMAINS_SUCCESS = 'FETCH_MEASURE_SCALES_DOMAINS_SUCCESS';
export const FETCH_MEASURE_SCALES_DOMAINS_ERROR = 'FETCH_MEASURE_SCALES_DOMAINS_ERROR';

export const FETCH_HUMAN_POOLS_REQUEST = 'FETCH_HUMAN_POOLS_REQUEST';
export const FETCH_HUMAN_POOLS_SUCCESS = 'FETCH_HUMAN_POOLS_SUCCESS';
export const FETCH_HUMAN_POOLS_ERROR = 'FETCH_HUMAN_POOLS_ERROR';

export const FETCH_HUMAN_SEROLOGY_REQUEST = 'FETCH_HUMAN_SEROLOGY_REQUEST';
export const FETCH_HUMAN_SEROLOGY_SUCCESS = 'FETCH_HUMAN_SEROLOGY_SUCCESS';
export const FETCH_HUMAN_SEROLOGY_ERROR = 'FETCH_HUMAN_SEROLOGY_ERROR';

export const FETCH_VACCINE_PROTECTION_VALUES_REQUEST = 'FETCH_VACCINE_PROTECTION_VALUES_REQUEST';
export const FETCH_VACCINE_PROTECTION_VALUES_SUCCESS = 'FETCH_VACCINE_PROTECTION_VALUES_SUCCESS';
export const FETCH_VACCINE_PROTECTION_VALUES_ERROR = 'FETCH_VACCINE_PROTECTION_VALUES_ERROR';

export const FETCH_VACCINE_DEFAULT_SELECTIONS_REQUEST = 'FETCH_VACCINE_DEFAULT_SELECTIONS_REQUEST';
export const FETCH_VACCINE_DEFAULT_SELECTIONS_SUCCESS = 'FETCH_VACCINE_DEFAULT_SELECTIONS_SUCCESS';
export const FETCH_VACCINE_DEFAULT_SELECTIONS_ERROR = 'FETCH_VACCINE_DEFAULT_SELECTIONS_ERROR';

export const RESET_VACCINES_DATA = 'RESET_VACCINES_DATA';

export const FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_REQUEST = 'FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_REQUEST';
export const FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_SUCCESS = 'FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_SUCCESS';
export const FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_ERROR = 'FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_ERROR';

export const FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_REQUEST = 'FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_REQUEST';
export const FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_SUCCESS = 'FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_SUCCESS';
export const FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_ERROR = 'FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_ERROR';

export const RESET_VACCINES_FREQUENCIES = 'RESET_VACCINES_FREQUENCIES';
export const RESET_VACCINES_PREDICTIONS = 'RESET_VACCINES_PREDICTIONS';

export const TOGGLE_SELECTED_VACCINE_CLADES = 'TOGGLE_SELECTED_VACCINE_CLADES';
export const REVERT_SELECTED_VACCINE_CLADES = 'REVERT_SELECTED_VACCINE_CLADES';

export const REMOVE_SELECTED_VACCINE_FERRET_REF_STRAIN = 'REMOVE_SELECTED_VACCINE_FERRET_REF_STRAIN';

// export const FETCH_COLOR_BY_RULE_STRAINS_REQUEST= 'FETCH_COLOR_BY_RULE_STRAINS_REQUEST';
// export const FETCH_COLOR_BY_RULE_STRAINS_SUCCESS = 'FETCH_COLOR_BY_RULE_STRAINS_SUCCESS';
// export const FETCH_COLOR_BY_RULE_STRAINS_ERROR = 'FETCH_COLOR_BY_RULE_STRAINS_ERROR';

export const RESET_HUMAN_SEROLOGY_DATA = 'RESET_HUMAN_SEROLOGY_DATA';
export const RESET_MODEL_DATA = 'RESET_MODEL_DATA';
export const RESET_MODEL_TYPES = 'RESET_MODEL_TYPES';

export const SET_HIDDEN_MENU = 'SET_HIDDEN_MENU';
export const SET_HIDDEN_MENU_MOBILE = 'SET_HIDDEN_MENU_MOBILE';

export const SET_ERROR_WEBSITE = 'SET_ERROR_WEBSITE';
export const SESSION_TIMEOUT = 'SESSION_TIMEOUT';
export const SET_SESSION_TIMEOUT = 'SET_SESSION_TIMEOUT';

export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';
export const REQUEST_TIMEOUT = 'REQUEST_TIMEOUT';
export const NETWORK_ERROR = 'NETWORK_ERROR';
export const SET_AUTOCOMPLETE_OPTIONS = 'SET_AUTOCOMPLETE_OPTIONS';
// export const SET_GEO_LOADING = 'SET_GEO_LOADING';
