import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';

const ChunkedLegendElement = ({ color }) => {
    const classes = useStyles();

    // const fontSize = exportMode ? '10px' : colorBy === 'loc' ? '14px' : '12px';

    console.log(color);
    return (
        <div className={classes.legendElement} id={color.key} key={color.key}>
            <div className={classes.legendCircleWrapperExport}>
                <div className={classes.legendCircleExport} style={{ backgroundColor: color.color }} />
            </div>
            <div className={classes.legendTextExport}>
                {color.label}
            </div>
        </div>
    );
};

ChunkedLegendElement.propTypes = {
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    exportMode: PropTypes.bool.isRequired,
    colorBy: PropTypes.string.isRequired,
};

export default ChunkedLegendElement;
