import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { postAxios } from '../../../../functions/axiosRequests';
import { actionsStyles } from '../../Styles/actionsStyles';
import config from '../../../../config/envConfig';
import MutClassDialog from '../Dialogs/MutClassDialog';
import { fetchMutationClassesArray } from '../../../../redux/actions/settingsActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const Edit = (props) => {
    const [openDialog, setOpenDialog] = useState(false);
    const { mutClass, setInfo, setInfoDialog, setAction, fetchMutationClassesArray, mutationGenes } = props;
    const classes = actionsStyles();

    const handleClick = async () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    const updateMutClass = async (body) => {
        const keys = Object.keys(body.positions);
        let result = {};

        keys.forEach(key => {
            body.positions[key].forEach(range => {
                for (let i = range.min; i <= range.max; i++) {
                    result[i] = true;
                }
            });
            body.positions[key] = result;
            result = {};
        });
        try {
            const url = `${config.serverLink}/api/admin/updateMutationClass`;
            await postAxios(url, { mutClass: { ...body } });
            setInfo(`Mutation class ${body.name} was updated sucessfully`);
        }
        catch (error) {
            console.error(error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred updating mutation class ${body.name}.: ${message}`);
        }
        finally {
            setAction('Update mutation class');
            handleCloseDialog();
            setInfoDialog(true);
            fetchMutationClassesArray();
        }
    };

    return (
        <>
            <IconButton onClick={handleClick} className={classes.button}>
                <EditIcon />
            </IconButton>
            {openDialog &&
                <MutClassDialog mutationGenes={mutationGenes} openDialog={openDialog} handleCloseDialog={handleCloseDialog} updateMutClass={updateMutClass} mutClass={mutClass} isAdd={false} />
            }
        </>
    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchMutationClassesArray
        },
        dispatch,
    );

export default connect(undefined, mapDispatchToProps)(Edit);
