import appConfig from '../../../../config/appConfig';

const filterParameters = (parameters, typesArray) => {
    const parametersArr = Object.entries(parameters);
    const filteredParameters = parametersArr.filter(function ([key]) {
        return typesArray.includes(key);
    });
    return Object.fromEntries(filteredParameters);
};

const getColorByOptionsArray = (object) => {
    if (!object) return;

    const array = [];
    for (const key of Object.keys(object)) {
        if (object[key])
            array.push(key);
    }
    return array;
};

const getRegionsArray = (regions) => {
    if (!regions) return;

    const array = ['ALL'];
    for (const key of Object.keys(regions)) {
        array.push(key);
    }
    return array;
};

const getScaleTypeOptionsArray = (scaleTypes) => {
    if (!scaleTypes) return;

    const array = [];
    for (const type of scaleTypes) {
        array.push(type.key);
    }
    return array;
};

const getIdsArray = (arrayInit) => {
    if (!arrayInit) return;

    const array = [];
    for (const type of arrayInit) {
        array.push(type.id);
    }
    return array;
};

const getMutGeneOptions = (genotypeFilterList) => {
    if (!genotypeFilterList) return;

    const array = [];
    for (const gene of genotypeFilterList) {
        array.push(gene.val);
    }
    return array;
};

const setValidModelId = (filteredParameters, modelTypes) => {
    return filteredParameters.modelType === 'Antigenic' ?
        filteredParameters.antigenicModelId : modelTypes[0];
};

const getModelIdItems = (modelId, modelType) => {
    if (modelType !== 'Antigenic') return {};

    const [
        strainPropagation,
        refStrainPropagation,
        collaboratingCenter,
        assay,
    ] = modelId.split('-');

    return {
        strainPropagation,
        refStrainPropagation,
        collaboratingCenter,
        assay
    };
};

const getModelIdItemsOptions = (modelType) => {
    if (modelType !== 'Antigenic') return {};
    const strainPropagation = getIdsArray(appConfig.strainPropagations);
    const refStrainPropagation = getIdsArray(appConfig.refStrainPropagations);
    const collaboratingCenter = getIdsArray(appConfig.collaboratingCenters);
    const assay = getIdsArray(appConfig.assays);

    return {
        strainPropagation,
        refStrainPropagation,
        collaboratingCenter,
        assay,
    };
};

const getModelId = (modelItems) => {
    console.log(modelItems);
    return `${modelItems.strainPropagation}-${modelItems.refStrainPropagation}-${modelItems.collaboratingCenter}-${modelItems.assay}`;
};

export {
    filterParameters,
    getColorByOptionsArray,
    getRegionsArray,
    getScaleTypeOptionsArray,
    getIdsArray,
    getMutGeneOptions,
    setValidModelId,
    getModelIdItems,
    getModelIdItemsOptions,
    getModelId,
    //submit
};
