export const LAYOUT = {
    TREE: {
        label: 'Tree', value: 'tree',
    },
    FAN: {
        label: 'Fan', value: 'fan',
    },
}

export const PATHOGEN_TYPES = {
    INFLUENZA: { value: 'INFLUENZA', label: 'Influenza', subTypes: true },
    SARS_COV_2: { value: 'SARS_COV_2', label: 'SARS-CoV-2' },
    OTHER: { value: 'OTHER', label: 'Other', subTypes: true }
}
