import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TablePagination, IconButton, InputAdornment, TableSortLabel
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import AddUser from './Actions/AddUser';
import { styles, StyledTableCell } from './styles';
import DeleteUser from './Actions/DeleteUser';
import EditUser from './Actions/EditUser';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import { HideIcon } from '../../Alerts/styles';
import Info from '../Info';
import config from '../../../config/envConfig';
import { fetchAxios } from '../../../functions/axiosRequests';
import AuthorizeUsers from './Actions/AuthorizeUsers';
import { getBiggerForSortTable } from '../../../functions/functions';

function createData(email, username, role, password, lineages, defaultLineage) {
    return { email, username, role, password, lineages, defaultLineage };
}

const orderInitState = {
    email: 'none',
    username: 'none',
    role: 'none',
};

const TableUsers = () => {
    const classes = styles();
    const [tableData, setTableData] = useState([]);
    const [displayedTableData, setDisplayedTableData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loaded, setLoaded] = useState(false);
    const [searched, setSearched] = useState('');
    const [infoDialog, setInfoDialog] = useState(false);
    const [info, setInfo] = useState('');
    const [action, setAction] = useState('');
    const [unauthorizedUsers, setUnauthorizedUsers] = useState([]);
    const [orderBy, setOrderBy] = useState({ ...orderInitState, email: 'asc' });

    useEffect(() => {
        if (loaded) return;

        const url = `${config.serverLink}/api/admin/getAllUsersObjects`;
        fetchAxios(url)
            .then(response => {
                const { users } = response.data;
                const authorized = users.filter(user => !user.unauthorized);
                const unauthorized = users.filter(user => user.unauthorized);
                const rows = authorized.map((user) => {
                    return createData(user.email, user.username, user.role, user.password, user.lineages, user.defaultLineage);
                });
                setTableData(rows);
                const sorted = sortData([...rows], 'email', 'asc');
                setDisplayedTableData(sorted);
                setUnauthorizedUsers(unauthorized);
            });
        setLoaded(true);
    }, [loaded]);

    const sortData = (data, type, order) => {
        if (order === 'none')
            return [...tableData];

        const asc = order === 'asc';
        return data.sort((a, b) => getBiggerForSortTable(a, b, type, asc));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const requestSearch = (e) => {
        const search = e.target.value;
        setSearched(search);
        const filteredRows = tableData.filter((row) => {
            return row.email.toLowerCase().includes(search.toLowerCase()) ||
                row.username.toLowerCase().includes(search.toLowerCase());
        });
        setDisplayedTableData(filteredRows);
        setPage(0);
    };

    const cancelSearch = () => {
        setSearched('');
        setDisplayedTableData(tableData);
    };

    const handleCloseInfo = () => {
        setInfoDialog(false);
    };

    const handleSort = (type) => {
        let val = '';

        if (orderBy[type] === 'none')
            val = 'asc';
        else if (orderBy[type] === 'asc')
            val = 'desc';
        else
            val = 'asc';

        const newOrderBy = {
            ...orderInitState,
            [type]: val
        };

        setOrderBy(newOrderBy);
        const sorted = sortData([...displayedTableData], type, val);
        setDisplayedTableData(sorted);
    };

    return (
        <Paper sx={{ marginRight: '15px', marginLeft: '15px' }}>
            <Grid container >
                <Grid size={8} >
                    <StyledTextField
                        id="search"
                        label="Search"
                        className={classes.search}
                        value={searched}
                        onChange={(searchVal) => requestSearch(searchVal)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton onClick={cancelSearch}>
                                        <HideIcon />
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </Grid>
                <Grid size={4} >
                    <AuthorizeUsers unauthorizedUsers={unauthorizedUsers} setLoaded={setLoaded} setInfoDialog={setInfoDialog} setInfo={setInfo} setAction={setAction} />
                </Grid>
            </Grid>


            <TableContainer >
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                <TableSortLabel
                                    active={orderBy.email !== 'none'}
                                    direction={orderBy.email !== 'none' ? orderBy.email : 'asc'}
                                    onClick={() => handleSort('email')}
                                >
                                    Email
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <TableSortLabel
                                    active={orderBy.username !== 'none'}
                                    direction={orderBy.username !== 'none' ? orderBy.username : 'asc'}
                                    onClick={() => handleSort('username')}
                                >
                                    Username
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <TableSortLabel
                                    active={orderBy.role !== 'none'}
                                    direction={orderBy.role !== 'none' ? orderBy.role : 'asc'}
                                    onClick={() => handleSort('role')}
                                >
                                    Role
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="center">Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (rowsPerPage > 0
                                ? displayedTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : displayedTableData
                            ).map((row) => (
                                <TableRow
                                    key={row.email}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.email}
                                    </TableCell>
                                    <TableCell align="left">{row.username}</TableCell>
                                    <TableCell align="left">{row.role}</TableCell>
                                    <TableCell align="center">
                                        <DeleteUser email={row.email} setLoaded={setLoaded}
                                            setInfoDialog={setInfoDialog} setInfo={setInfo} setAction={setAction} />
                                        <EditUser user={row} setLoaded={setLoaded}
                                            setInfoDialog={setInfoDialog} setInfo={setInfo} setAction={setAction} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        <TableRow>
                            <td>
                                <AddUser setLoaded={setLoaded} setInfoDialog={setInfoDialog} setInfo={setInfo} setAction={setAction} />
                            </td>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={displayedTableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Info openDialog={infoDialog} handleCloseDialog={handleCloseInfo} info={info} action={action} />
        </Paper>
    );
};


export default TableUsers;
