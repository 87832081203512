class Defaults {
    constructor() {
        this.strainTreePermissionsLabels = {
            colorBySelector: 'Color by selector',
            modelSelector: 'Model selector',
            antigenicColorByOptionsSelector: 'Antigenic color by options selectors',
            branchNodesSelector: 'Branch nodes selector',
            vpMethodSelector: 'Vp method selector',
            layoutSelector: 'Layout selector',
            treeScaleTypeSelector: 'Tree scale types selectors',
            predictionBaselineSelector: 'Prediction baseline picker',
            submissionDateSelector: 'Submission date picker',
            strainCutoffDateSelector: 'Strain cutoff date picker',
            showLeafNumberSelector: 'Displayed Sequences input',
            displayOrderSelector: 'Display order selector',
            nodeVisibilitySelector: 'Node visibility options',
            strainsSubsetSelector: 'Subset strains selector',
            strainsHighlightSelector: 'Highlight strains selector',
            searchStrainSelector: 'Search strain selector',
            cladeTypeSelector: 'Clade type selector',
            cladeBarTypeSelector: 'Clade bar type selector',
            colorScaleSelector: 'Color scale selector'
        };

        this.strainTreePermissions = {
            colorBySelector: true,
            modelSelector: true,
            antigenicColorByOptionsSelector: true,
            branchNodesSelector: true,
            vpMethodSelector: true,
            layoutSelector: true,
            treeScaleTypeSelector: true,
            predictionBaselineSelector: true,
            strainCutoffDateSelector: true,
            showLeafNumberSelector: true,
            displayOrderSelector: true,
            nodeVisibilitySelector: true,
            strainsSubsetSelector: true,
            strainsHighlightSelector: true,
            searchStrainSelector: true,
            cladeTypeSelector: true,
            cladeBarTypeSelector: true,
            submissionDateSelector: true,
            colorScaleSelector: true
        };

        this.frequenciesPermissionsLabels = {
            frequenciesCategorySelector: 'Frequencies category celector',
            layoutSelector: 'Layout selector',
            modelSelector: 'Model selector',
            strainsSubsetSelector: 'Subset strains selector',
            plotTypeSelector: 'Plot selector',
            trackingDatesSelector: 'Tracking dates pickers',
            predictionBaselineSelector: 'Prediction baseline picker',
            binMethodSelector: 'Bin method selector',
            sigmaAgSelector: 'SigmaAg selector',
            tauSelector: 'Tau selector',
            displayErrorBarsSelector: 'Display error bars selector',
            displayGreyZoneSelector: 'Display grey zone selector',
            generateReportButton: 'Generate report button',
            cladeTypeSelector: 'Clade type selector',
            multiplicityMethodSelector: 'Multiplicity method selector'
        };

        this.frequenciesPermissions = {
            frequenciesCategorySelector: true,
            layoutSelector: true,
            modelSelector: true,
            strainsSubsetSelector: true,
            plotTypeSelector: true,
            trackingDatesSelector: true,
            predictionBaselineSelector: true,
            binMethodSelector: true,
            sigmaAgSelector: true,
            tauSelector: true,
            displayErrorBarsSelector: true,
            displayGreyZoneSelector: true,
            generateReportButton: true,
            cladeTypeSelector: true,
            multiplicityMethodSelector: true
        };

        this.antigenicPermissionsLabels = {
            dataTypeSelector: 'Data type selector',
            modelSelector: 'Model selectors',
            titerTypeSelector: 'Titer type selector',
        };

        this.antigenicPermissions = {
            dataTypeSelector: true,
            modelSelector: true,
            titerTypeSelector: true,
        };

        this.fitnessPermissionsLabels = {
            modelSelector: 'Model selectors',
        };

        this.fitnessPermissions = {
            modelSelector: true,
        };

        this.mapPermissionsLabels = {
            colorBySelector: 'Color by selector',
            antigenicColorByOptionsSelector: 'Antigenic color by options selectors',
            geoFreqCategorySeclector: 'Frequencies category selector',
            predictionBaselineSeclector: 'Prediction baseline picker',
        };

        this.mapPermissions = {
            colorBySelector: true,
            antigenicColorByOptionsSelector: true,
            geoFreqCategorySeclector: true,
            predictionBaselineSeclector: true,
        };
        this.vaccinesPermissions = {
        };
        this.tabsLabels = {
            strainTree: 'Tree',
            frequencies: 'Frequencies',
            antigenic: 'Antigenic',
            fitness: 'Fitness',
            vaccines: 'Vaccines',
            map: 'Map',
        };

    }
}

const defaults = new Defaults();
export default defaults;
