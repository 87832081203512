import React from 'react';
import { TableRow } from '@mui/material';
import { connect } from 'react-redux';
import AddNewMutationClass from '../../../../pages/SettingsPanel/MutationClasses/Actions/Add';

const AddMutationClassAction = (props) => {
    const { setInfo, setInfoDialog, setAction, lineages, lineageMutationGenes } = props;

    return (
        <TableRow>
            <td>
                <AddNewMutationClass
                    mutationGenes={lineageMutationGenes}
                    lineages={lineages}
                    setInfo={setInfo}
                    setInfoDialog={setInfoDialog}
                    setAction={setAction}
                />
            </td>
        </TableRow>
    );
};

const mapStateToProps = (state) => {
    const { data } = state.settings.lineageMutationGenes;
    const { lineages } = state.lineages;

    return {
        lineages,
        lineageMutationGenes: data,
    };
};

export default connect(mapStateToProps)(AddMutationClassAction);
