import React, { useState, useEffect, createRef } from 'react';
import { select } from 'd3-selection';
import { symbol, symbolTriangle } from 'd3-shape';
import { scaleLinear } from 'd3-scale';
import { Typography } from '@mui/material';
import { useStyles, continousScaleStyles } from './styles';
import { setMeasureDomain } from '../../../redux/actions/parametersActions';
import { setAlertStatus } from '../../../redux/actions/alertActions';
import { bindActionCreators } from 'redux';
import { getNodeWidth } from '../../../functions/cssHelpers';
import { daysToDate, emptyObject, getMeasureScaleParamName } from '../../../functions/functions';
import { getMeasure, getColorByMeasure } from '../../../redux/selectors/metadataSelector';
import { getCurrentColorScalesForMeasures } from '../../../redux/selectors/parametersSelector';
import { getMetadataMeasuresWithScales } from '../../../redux/selectors/rangeDataSelector';
import { connect } from 'react-redux';
import { numFormat } from '../../../functions/formats';



const InputMaxMin = ({ elemKey, scaleId, value, className, prcWidth, onBlur, onChange, onKeyPress, isTimeScale }) => {
    const inputId = elemKey && scaleId ? `${elemKey}_${scaleId}` : undefined;
    const tdClass = `${(elemKey && scaleId) || 'internal'}Td`;
    const typographyClass = `${(elemKey && scaleId) || 'internal'}Typography`;
    const classes = useStyles();

    return (
        <td key={elemKey} className={classes[tdClass]} style={{ width: `${prcWidth}%` }}>
            <Typography onBlur={onBlur} className={classes[typographyClass]} style={{ width: `100%` }}>
                {scaleId ?
                    (isTimeScale ?
                        <div style={{ width: `100%` }} className={classes[className]}> {value} </div>
                        :
                        <input
                            id={inputId}
                            style={{ width: `100%` }}
                            className={classes[className]}
                            onChange={onChange}
                            onKeyDown={onKeyPress}
                            value={value}
                            type='text'
                        />

                    )
                    : <>{value}</>
                }
            </Typography>
        </td>);
};

const ContinousScale = ({ domain, scaleId, isTimeScale, value, setMeasureDomain,
    renderLegend, paramForMeasure, ticks, modelType, colorScale, lineage, discrete,
    min, max, precision, measureName, colorScaleRange, title, inContainer, }) => {

    const [minString, setMinString] = useState('');
    const [maxString, setMaxString] = useState('');
    const [isMinSmallerThanMax, setIsMinSmallerThanMax] = useState(true);
    const [loadedBySubmit, setLoadedBySubmit] = useState(false);
    const [rangeVals, setRangeVals] = useState([]);
    const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
    const _ticks = ticks || 3;
    // const rangeVals = [];
    const _element = createRef();
    const classes = continousScaleStyles();
    let prcWidth;

    const formatLabel = (value, precision) => {
        if (isTimeScale)
            return daysToDate(value).toLocaleDateString();
        return numFormat(value, precision);
    };

    useEffect(() => {
        const maxStringProps = formatLabel(max, precision);
        const minStringProps = formatLabel(min, precision);

        const _rangeVals = [];
        if (!emptyObject(min) && !emptyObject(max)) {
            const diff = (max - min) / (_ticks - 1);
            for (let i = 1; i < _ticks - 1; i++)
                _rangeVals.push(min + i * diff);
        }

        setRangeVals(_rangeVals);
        prcWidth = 100 / (rangeVals.length + 2);

        if (max && !minString || (maxString != minStringProps && maxString)) {
            setMaxString(maxStringProps);
            setMinString(minStringProps);
        }

        prepareLegend();
        showPositionOnLegend();
    }, []);

    useEffect(() => {
        const maxStringProps = formatLabel(max, precision);
        const minStringProps = formatLabel(min, precision);

        if (max && !minString || (maxString != minStringProps && maxString)) {
            setMaxString(maxStringProps);
            setMinString(minStringProps);
        } else {
            setMaxString(maxStringProps);
            setMinString(minStringProps);
        }

        prepareLegend();
        showPositionOnLegend();

    }, [discrete, colorScale, domain, lineage, measureName]);

    useEffect(() => {
        if (emptyObject(min) || emptyObject(max)) return;
        
        const _rangeVals = [];
        const diff = (max - min) / (_ticks - 1);
        for (let i = 1; i < _ticks - 1; i++)
            _rangeVals.push(min + i * diff);

        setRangeVals(_rangeVals);
        prcWidth = 100 / (_rangeVals.length + 2);
    }, [min, max]);

    useEffect(() => {
        const maxStringCondition = maxString.slice(-1) !== ',' && maxString.slice(-1) !== '.' && isMinSmallerThanMax === true;
        const maxStringNew = maxStringCondition ? formatLabel(max, precision) : maxString;
        const minStringNew = formatLabel(min, precision);

        if (maxString != maxStringNew && maxString && !loadedBySubmit) {
            setMaxString(maxStringNew);
            setMinString(minStringNew);
        }

        setGradientColors();
        showPositionOnLegend();
    });

    const colorError = (inputIds) => {
        inputIds.forEach(id => {
            const input = document.querySelector(id);
            input.classList.add('red');
        });
    };

    const hideHighlight = (inputIds) => {
        inputIds.forEach(id => {
            const input = document.querySelector(id);
            input.classList.remove('red');
        });
    };

    const setGradientColors = () => {
        if (!renderLegend)
            return;

        const gradient = select(`#legend-gradient_${scaleId}`);
        gradient.selectAll('stop').remove();

        for (let i = 0; i < colorScaleRange.length; i++) {
            gradient
                .append('svg:stop')
                .attr('offset', i / (colorScaleRange.length - 1))
                .attr('stop-color', colorScaleRange[i]);
        }
    };

    const showPositionOnLegend = () => {
        if (min === undefined || max === undefined) return;

        const mountNode = _element.current;
        const positionScale = scaleLinear()
            .domain([min, max])
            .range([0, mountNode ? getNodeWidth(mountNode) : 0])
            .clamp(true);
        const render = !emptyObject(value) && renderLegend;
        const position = () => (render ? positionScale(value) : 0);
        const opacity = () => (render ? '1' : '0');
        select(`#legend-pointer_${scaleId}`)
            .attr('opacity', opacity())
            .attr('transform', () => `translate( ${position()}, ${-8}) rotate(-180)`);
    };

    const prepareLegend = () => {
        const svg = select(`#legend_svg_${scaleId}`);
        const defs = svg.selectAll('defs').data([scaleId]);
        const pointer = svg.selectAll('path').data([scaleId]);
        const rect = svg.selectAll('rect').data([scaleId]);

        defs.enter().append('svg:defs')
            .append('svg:linearGradient')
            .attr('id', `legend-gradient_${scaleId}`)
            .attr('x1', '0%')
            .attr('y1', '0%')
            .attr('x2', '100%')
            .attr('y2', '0%')
            .attr('spreadMethod', 'pad');
        setGradientColors(measureName);

        rect.enter().append('svg:rect')
            .attr('id', `color_legend_${scaleId}`)
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', '100%')
            .attr('height', '100%')
            .style('fill', `url(#legend-gradient_${scaleId}`);

        pointer.enter().append('path')
            .attr('id', `legend-pointer_${scaleId}`)
            .attr('fill', '#B4B4B4')
            .attr('opacity', emptyObject(value) ? '0' : '1')
            .attr(
                'd',
                symbol()
                    .size([40])
                    .type(symbolTriangle)
            )
            .attr('transform', () => `translate( ${0}, ${-8}) rotate(-180)`);
    };

    const checkInput = (inputString) => {
        if (inputString === '-')
            return inputString;

        if (inputString[0] === '0' && inputString[1] && inputString[1] !== ',' && inputString[1] != '.') {
            return inputString.substring(1);
        }
        return inputString;
    };

    const checkIfFloat = (inputString, type) => {
        if (inputString === '-') {
            if (type === 'min')
                setMinString(inputString);
            if (type === 'max')
                setMaxString(inputString);

            return false;
        }

        if (!floatRegex.test(inputString) && inputString != 0)
            return false;

        return true;
    };

    const setInputValues = (input, type) => {
        if (input.string === '') {
            if (type === 'min') setMinString('');
            if (type === 'max') setMaxString('');
            return;
        }

        if (!checkIfFloat(input.string, type)) return;

        if (input.string.slice(-1) === ',' || input.string.slice(-1) === '.') {
            input.rounded = input.string.replace(',', '.');
            input.floated = parseFloat(input.rounded);
        } else {
            input.floated = parseFloat(input.string.replace(',', '.'));
            input.rounded = numFormat(input.floated, precision);
        }

        if (type === 'max') {
            if (input.floated < min) {
                setAlertStatus({ status: true, model: modelType, id: `${scaleId}ColorLegendRange` });
                colorError([`#max_${scaleId}`, `#min_${scaleId}`]);

                setMaxString(input.rounded);
                setIsMinSmallerThanMax(false);
                return;
            }
        } else {
            if (input.floated > max) {
                setAlertStatus({ status: true, model: modelType, id: `${scaleId}ColorLegendRange` });
                colorError([`#max_${scaleId}`, `#min_${scaleId}`]);

                setMinString(input.rounded);
                setIsMinSmallerThanMax(false);

                return;
            }
        }

        return input;
    };

    const handleChangeMin = (e) => {
        let input = {
            string: checkInput(e.target.value),
            rounded: '',
            floated: 0
        };

        if (input.string === '') {
            setMinString('');
            setAlertStatus({ status: true, model: modelType, id: `${scaleId}ColorLegendEmpty` });
            colorError([`#max_${scaleId}`, `#min_${scaleId}`]);
            return;
        } else {
            setAlertStatus({ status: false, model: modelType, id: `${scaleId}ColorLegendEmpty` });
            hideHighlight([`#max_${scaleId}`, `#min_${scaleId}`]);
        }

        input = setInputValues(input, 'min');
        if (!input) return;

        setMinString(input.floated === 0 ? input.string : input.rounded);
        setIsMinSmallerThanMax(true);
        setAlertStatus({ status: false, model: modelType, id: `${scaleId}ColorLegendRange` });

        hideHighlight([`#max_${scaleId}`, `#min_${scaleId}`]);
    };

    const handleChangeMax = (e) => {
        let input = {
            string: checkInput(e.target.value),
            rounded: '',
            floated: 0
        };
        input = setInputValues(input, 'max');
        if (!input) return;

        setMaxString(input.floated === 0 ? input.string : input.rounded);
        setIsMinSmallerThanMax(true);
        setAlertStatus({ status: false, model: modelType, id: `${scaleId}ColorLegendRange` });

        hideHighlight([`#max_${scaleId}`, `#min_${scaleId}`]);
    };

    const handleKeyPress = event => {
        const max = parseFloat(maxString.replace(',', '.'));
        const min = parseFloat(minString.replace(',', '.'));

        setLoadedBySubmit(true);

        if (event.key === 'Enter' && isMinSmallerThanMax) {
            setMeasureDomain({ measureName, min, max, paramForMeasure });
        }
    };

    const handleFocusOut = () => {
        const max = parseFloat(maxString.replace(',', '.'));
        const min = parseFloat(minString.replace(',', '.'));

        setLoadedBySubmit(true);

        if (isMinSmallerThanMax)
            setMeasureDomain({ measureName, min, max, paramForMeasure });
    };


    return (
        <div className={`svg-bg ${(inContainer) ? classes.containedRoot : classes.root}`} style={{ display: (renderLegend ? 'block' : 'none') }}>
            <div>{title && <p>{title}</p>}</div>
            <svg id={`legend_svg_${scaleId}`} ref={_element} className={classes.legend} />
            {renderLegend && (
                <div id="legend_values">
                    <table className={classes.valuesTable}>
                        <tbody>
                            <tr>
                                <InputMaxMin
                                    key={`${scaleId}_min`}
                                    elemKey='min'
                                    scaleId={scaleId}
                                    prcWidth={prcWidth}
                                    value={minString}
                                    className={'minInput'}
                                    onBlur={handleFocusOut}
                                    onChange={handleChangeMin}
                                    onKeyDown={handleKeyPress}
                                    isTimeScale={isTimeScale}
                                />

                                {rangeVals.map((v, i) =>
                                    <InputMaxMin
                                        key={`${scaleId}_${i}`}
                                        elemKey={`aRangeVal_${scaleId}_${i}`}
                                        prcWidth={prcWidth}
                                        value={formatLabel(v, precision)}
                                        isTimeScale={isTimeScale}
                                    />
                                )}
                                <InputMaxMin
                                    key={`${scaleId}_max`}
                                    elemKey='max'
                                    scaleId={scaleId}
                                    prcWidth={prcWidth}
                                    value={maxString}
                                    className={'maxInput'}
                                    onBlur={handleFocusOut}
                                    onChange={handleChangeMax}
                                    onKeyDown={handleKeyPress}
                                    isTimeScale={isTimeScale}
                                />
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );

};

const mapStateToProps = (state, ownProps) => {
    const { lineage, colorBy } = state.parameters;
    const { valueGetter, scaleId } = ownProps;
    const measureName = ownProps.measureName || colorBy;
    const measure = getMeasure(state, measureName) || getColorByMeasure(state);

    const paramForMeasure = getMeasureScaleParamName(measure);
    const modelType = scaleId !== 'strainTree' ? 'antigenic' : 'strainTree';
    const value = valueGetter ? valueGetter(state) : null;
    const isValueString = typeof value === 'string' || value instanceof String;
    const measuresScales = getCurrentColorScalesForMeasures(state);
    const colorScale = measuresScales[measureName];

    const measuresWithScales = getMetadataMeasuresWithScales(state);
    const domain = measuresWithScales[measureName]?.scale.domain;
    const colorScaleRange = measuresWithScales[measureName]?.scale.range;
    const discrete = measuresWithScales[measureName]?.scale.discrete;
    const renderLegend = colorScaleRange !== undefined;
    const min = domain && domain.length && domain[0];
    const max = domain && domain.length && domain[domain.length - 1];

    return {
        min,
        max,
        colorScaleRange,
        renderLegend,
        paramForMeasure,
        modelType,
        value: isValueString ? 0 : value,
        colorScale,
        lineage,
        discrete,
        domain,
        scaleId,
        isTimeScale: measure && measure.time,
    };
};


const mapDispatchToProps = dispatch => bindActionCreators({
    setMeasureDomain,
    setAlertStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContinousScale);
