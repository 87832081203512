// External imports
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Internal imports
import { setParameters } from '../../../redux/actions/parametersActions';
import SelectInput from '../../Common/SelectInput';

const coloringOptions = [
    {
        key: 'count-key',
        value: 'count',
        label: 'Sequences'
    },
    {
        key: 'multiplicity-key',
        value: 'multiplicity',
        label: 'Cases'
    }
];

const GeoFreqCategorySelector = (props) => {
    const { geoFreqCategory, setParameters } = props;

    const handleChange = async value => {
        setParameters({ geoFreqCategory: value });
    };

    return (
        <SelectInput
            label="Frequencies category"
            id="geoFreqCategory"
            value={geoFreqCategory}
            onChange={handleChange}
            options={coloringOptions}
        />
    );
};

GeoFreqCategorySelector.propTypes = {
    geoFreqCategory: PropTypes.string.isRequired,
    setParameters: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    geoFreqCategory: state.parameters.geoFreqCategory
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GeoFreqCategorySelector);
