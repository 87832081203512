import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ScalesLegend from './ScalesLegend';
import { getNodeDataById } from '../../../redux/selectors/nodeDataSelector';
import { getSelectedMeasureTreeLegend } from '../../../redux/selectors/rangeDataSelector';
 
const TreeLegend =  (props) => {
        
    const { colorBy, searchStrainMode, visibleBins } = props;

    return (
        <ScalesLegend
            measureName={colorBy}
            valueGetter={(state) => getNodeDataById(state)[colorBy]}
            ticks={3}
            inContainer
            precision={3}
            colorLegendVisible={true} 
            visibleBins={visibleBins}
            mouseEventsEnabled={!searchStrainMode}
            type='strainTree'
        />
    );
};


const mapStateToProps = (state) => {
    const { colorBy, searchStrainMode } = state.parameters;
    const visibleBins = getSelectedMeasureTreeLegend(state);// selectedMeasure.scale?.range.data; //visibleRange;
    
    return {
        colorBy,
        searchStrainMode,
        visibleBins
    };
};


export default connect(mapStateToProps)(TreeLegend);

TreeLegend.propTypes = {
    colorBy: PropTypes.string.isRequired,
    searchStrainMode: PropTypes.bool,
    visibleBins: PropTypes.object,
};
