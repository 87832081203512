// External imports
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';

// Redux imports
import { setParameters } from '../../../redux/actions/parametersActions';
import { setHiddenMenu } from '../../../redux/actions/uiActions';
import { getIsMobile } from '../../../redux/selectors/statusSelector';
// import { getSelectedMeasureTreeLegend } from '../../../redux/selectors/rangeDataSelector';

// Component imports
import ModelSelector from '../../ModelSelector/ModelSelector';
import ColorBySelector from '../../OptionsSelector/ColorBySelector';
import BranchColorBySelector from '../../OptionsSelector/BranchColorBySelector';
import TreeScaleTypeSelector from '../../OptionsSelector/TreeScaleTypeSelector';
import NodeVisibilitySelector from '../../OptionsSelector/NodeVisibilitySelector';
import StrainsSubsetSelector from '../../OptionsSelector/StrainsSubsetSelector';
import StrainsHighlightSelector from '../../OptionsSelector/StrainsHighlightSelector';
import VpMethodSelector from '../../OptionsSelector/VpMethodSelector';
import ShowLeafNumberSelector from '../../OptionsSelector/ShowLeafNumberSelector';
import DisplayOrderSelector from '../../OptionsSelector/DisplayOrderSelector';
import AdditionalColorBySelector from '../../OptionsSelector/AdditionalColorBySelector';
import PredictionBaselinePicker from '../../DatePickers/PredictionBaselinePicker';
import StrainCutoffDatePicker from '../../DatePickers/StrainCutoffDatePicker';
import ColorScaleSelector from '../../OptionsSelector/ColorScaleSelector/ColorScaleSelector';
import Alerts from '../../Alerts/Alerts';
import TreeStrainSearch from '../TreeStrainSearch';
import CladeBarTypeSelector from '../../OptionsSelector/CladeBarTypeSelector';
import SubmissionDatePicker from '../../DatePickers/SubmissionDatePicker';
import LayoutSelector from '../../OptionsSelector/LayoutSelector';
import BuildSelector from '../../OptionsSelector/LineageSelector/BuildSelector';
import TreeLegend from '../../ColorLegend/Legends/TreeLegend';

// Icons and styles
import HamburgerIcon from '../../SvgIcons/HamburgerIcon';
import { HideIcon } from '../../Alerts/styles';
import { styles } from './styles';

// Utils
import { emptyObject } from '../../../functions/functions';

// Selectors that should be shown during intro
const introSelectors = {
    colorBySelector: true,
    modelSelector: true,
    antigenicColorByOptionsSelector: true,
    layoutSelector: true,
    treeScaleTypeSelector: true,
    strainCutoffDateSelector: true,
    nodeVisibilitySelector: true,
    searchStrainSelector: true
};

const useStyles = makeStyles(styles);

/**
 * TreeGraphOptions component - Controls and options for the phylogenetic tree visualization
 */
const TreeGraphOptions = props => {
    const { 
        colorBy, 
        nodeId, 
        permissions, 
        showAlert, 
        hiddenMenu, 
        isMobile, 
        showCladeBar,
        intro, 
        setHiddenMenu, 
        modelParam 
    } = props;

    const classes = useStyles();

    // Determine if a selector should be shown based on permissions and intro state
    const showSelector = (selectorName) => permissions[selectorName] || (intro && introSelectors[selectorName]);

    // Handle menu visibility toggle
    const handleHideClick = () => {
        setHiddenMenu({ hiddenMenu: !hiddenMenu });
    };

    return (
        <>
            {!hiddenMenu ?
                <div
                    style={nodeId ? { padding: 0, margin: 0 } : {}}
                    className={showAlert ?
                        isMobile ?
                            classes.rootWithAlertsMobile
                            : classes.rootWithAlerts
                        : isMobile ?
                            classes.rootWithoutAlertsMobile
                            : classes.rootWithoutAlerts
                    }>
                    <div style={{ width: '100%' }}>
                        {emptyObject(nodeId) && (
                            <form autoComplete="off" style={{ paddingBottom: '55px' }}>
                                {!isMobile && !nodeId &&
                                    <div className={classes.buttonWrapper}>
                                        <IconButton className={classes.hideButton} onClick={handleHideClick}>
                                            <HideIcon />
                                        </IconButton>
                                    </div>
                                }
                                <Alerts module="strainTree" />
                                <BuildSelector />
                                {showSelector('colorBySelector') && <ColorBySelector intro={intro} />}
                                {showSelector('modelSelector') && modelParam && 
                                    <ModelSelector 
                                        modelContext="strainTree" 
                                        fixedModelType={colorBy === 'antigenic' ? 'Antigenic' : undefined} 
                                    />
                                }
                                {showSelector('antigenicColorByOptionsSelector') && <AdditionalColorBySelector />}
                                {showSelector('cladeBarTypeSelector') && showCladeBar && <CladeBarTypeSelector />}
                                {showSelector('colorScaleSelector') && <ColorScaleSelector />}
                                <TreeLegend/>
                                {showSelector('branchNodesSelector') && <BranchColorBySelector />}
                                {showSelector('vpMethodSelector') && <VpMethodSelector />}
                                {showSelector('layoutSelector') && <LayoutSelector/>}
                                {showSelector('treeScaleTypeSelector') && <TreeScaleTypeSelector intro={intro} />}
                                {showSelector('predictionBaselineSelector') && 
                                    <PredictionBaselinePicker type={'predictionBaseline'} />
                                }
                                
                                <Grid container columnSpacing={2}>
                                    {showSelector('strainCutoffDateSelector') && 
                                        <Grid size={showSelector('submissionDateSelector') ? 6 : 12}>
                                            <StrainCutoffDatePicker />
                                        </Grid>
                                    }
                                    {showSelector('submissionDateSelector') && 
                                        <Grid size={6}>
                                            <SubmissionDatePicker view='tree'/>
                                        </Grid>
                                    }
                                </Grid>

                                <Grid container columnSpacing={2}>
                                    {showSelector('showLeafNumberSelector') && 
                                        <Grid size={6}>
                                            <ShowLeafNumberSelector />
                                        </Grid>
                                    }
                                    {showSelector('displayOrderSelector') && 
                                        <Grid size={intro ? 12 : 6}>
                                            <DisplayOrderSelector />
                                        </Grid>
                                    }
                                </Grid>

                                {showSelector('nodeVisibilitySelector') && <NodeVisibilitySelector intro={intro} />}
                                {showSelector('strainsSubsetSelector') && <StrainsSubsetSelector context="strainTree" />}
                                {showSelector('strainsHighlightSelector') && <StrainsHighlightSelector />}
                                {showSelector('searchStrainSelector') && 
                                    <TreeStrainSearch />
                                }
                            </form>
                        )}
                    </div>
                </div>
                :
                <IconButton className={classes.expandButton} onClick={handleHideClick}>
                    <HamburgerIcon />
                </IconButton>
            }
        </>
    );
};

TreeGraphOptions.propTypes = {
    colorBy: PropTypes.string,
    modelParam: PropTypes.bool,
    nodeId: PropTypes.number,
    permissions: PropTypes.object.isRequired,
    showAlert: PropTypes.bool.isRequired,
    hiddenMenu: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    showCladeBar: PropTypes.bool,
    intro: PropTypes.bool,
    setParameters: PropTypes.func.isRequired,
    setHiddenMenu: PropTypes.func.isRequired,
};

/**
 * Maps Redux state to component props
 */
const mapStateToProps = (state) => {
    const { metadata, parameters, nodeData, user, alert, ui } = state;
    const isMobile = getIsMobile();
    
    return {
        colorBy: parameters.colorBy,
        showCladeBar: parameters.showCladeBar,
        modelParam: metadata.measures[parameters.colorBy]?.model || false,
        nodeId: nodeData.onlyHighlight ? undefined : nodeData.nodeId,
        permissions: user.permissions.strainTreePermissions,
        showAlert: alert.strainTree.show,
        hiddenMenu: isMobile ? ui.hiddenMenuMobile : ui.hiddenMenu,
        isMobile,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setParameters,
        setHiddenMenu
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(TreeGraphOptions);
