import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    root: {
        //padding: '10px 15px',
        width:'100%',
        paddingTop: '10px'
    },
    legendElement: {
        display: 'flex',
        alignItems: 'flex-start',
        cursor: 'pointer',
    },
    
    legendCircleWrapper: {
        height: '26px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    legendCircleWrapperExport: {
        height: '18px',
        display: 'flex',
        alignItems: 'center',
    },
    legendText: {
        color: 'black',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        lineHeight: '26px',
    },
    legendTextExport: {
        color: 'black',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        lineHeight: '18px',
        fontSize: '10px',
    },
    legendCircle: {
        borderRadius: '50%',
        marginRight: 9,
        flexShrink: 0,
    },
    legendCircleExport: {
        borderRadius: '50%',
        marginRight: 4,
        flexShrink: 0,
    },
    maxInput: {
        textAlign: 'right',
        border: 'none',
        fontFamily: 'Inter',
        fontSize: 12,
        '&:focus': {
            'outline': 'none'
        },
    },

    minInput: {
        border: 'none',
        fontSize: 12,
        fontFamily: 'Inter',
        '&:focus': {
            'outline': 'none'
        }
    },
    minTd: {},
    maxTd: {},
    internalTd: {
        textAlign: 'center'
    },
    internalTypography: {
        fontSize: '12px',
        width: `100%`
    
    },
    minTypography: {
        fontSize: '12px',
        width: `100%`
    },
    maxTypography: {
        fontSize: '12px',
        textAlign: 'right'
    },
});


export const continousScaleStyles = makeStyles({
    root: {
        //padding: '10px 15px',
        width:'100%',
        paddingTop: '10px'
    },
    containedRoot: {
        padding: '12px 0px',
        width: '100%'
    },
    legend: {
        height: 30,
        width: '100%',
        overflow: 'visible'
    },
    valuesTable: {
        width: '100%',
        borderSpacing: '0px'
    },
});
