import React from 'react';
import { TableRow } from '@mui/material';
import { connect } from 'react-redux';
import AddSubset from '../../../../pages/SettingsPanel/Subsets/Actions/Add';

const AddSubsetAction = (props) => {
    const { setInfo, setInfoDialog, setAction, lineages } = props;

    return (
        <TableRow>
            <td>
                <AddSubset
                    lineages={lineages}
                    setInfo={setInfo}
                    setInfoDialog={setInfoDialog}
                    setAction={setAction}
                />
            </td>

        </TableRow>
    );
};

const mapStateToProps = (state) => {
    const { lineages } = state.lineages;

    return {
        lineages
    };
};

export default connect(mapStateToProps)(AddSubsetAction);
