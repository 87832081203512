import { produce } from 'immer';
// Action Types
import {
   
    SET_ERROR_WEBSITE,
    FETCH_ANTIGENIC_MODEL_ERROR,
    SET_SESSION_TIMEOUT,
    FETCH_ANTIGENIC_RAW_MODEL_ERROR,
    FETCH_ANTIGENIC_OBSERVED_DATA_ERROR,
    FETCH_CLADES_ERROR,
    FETCH_FREQUENCIES_ERROR,
    FETCH_GEO_DATA_ERROR,
    FETCH_LINEAGES_ERROR,
    FETCH_LINEAGE_ERROR,
    FETCH_MODELS_ERROR,
    FETCH_MODEL_TYPES_ERROR,
    FETCH_MODEL_DATA_ERROR,
    FETCH_MUTATION_CLASSES_ERROR,
    FETCH_GENOTYPE_DATA_ERROR,
    FETCH_MUT_POS_DICT_ERROR,
    FETCH_MUTATION_GROUP_ERROR,
    FETCH_PREDICTIONS_ERROR,
    INIT_STRAIN_TREE_ERROR,
    SET_LINEAGE_SETTINGS_ERROR,
    FETCH_MEASURE_SCALES_DOMAINS_ERROR,
    FETCH_RECALCULATED_TREE_ERROR,
    FETCH_SUBSET_TREE_ERROR,
    FETCH_TREE_FREQS_ERROR,
    FETCH_CUSTOM_TREE_ATTRS_ERROR,
    FETCH_TCELL_ANTIGENICITY_OPTIONS_ERROR,
    FETCH_VP_METHODS_ERROR,
    FETCH_VP_VALUES_ERROR,
    FETCH_TCELL_ANTIGENICITY_SCORES_ERROR,
    FETCH_SELECTED_STRAIN_ERROR,
    FETCH_ANTIGENIC_REFERENCE_STRAIN_ERROR,
    FETCH_VACCINE_CANDIDATES_ERROR,
    FETCH_STRAINS_LIST_ERROR,
    FETCH_NODE_ERROR,
    ZOOM_TREE_ERROR,
    SET_STRAINS_TREE_DIMENSIONS,
    SET_HIDDEN_MENU,
    SET_HIDDEN_MENU_MOBILE,
} from '../actions/actionTypes';


let uiInitialState = {};


export const setUiInitialState = (state) => {
    uiInitialState = {
        ...state,
    };
};

export const uiReducer = (state = uiInitialState, action) => 
    produce(state, (draft) => {
        switch (action.type) {
            case SET_SESSION_TIMEOUT:
            case ZOOM_TREE_ERROR:
            case FETCH_NODE_ERROR:
            case FETCH_STRAINS_LIST_ERROR:
            case FETCH_VACCINE_CANDIDATES_ERROR:
            case FETCH_ANTIGENIC_REFERENCE_STRAIN_ERROR:
            case FETCH_SELECTED_STRAIN_ERROR:
            case FETCH_TCELL_ANTIGENICITY_SCORES_ERROR:
            case FETCH_VP_VALUES_ERROR:
            case FETCH_VP_METHODS_ERROR:
            case FETCH_TCELL_ANTIGENICITY_OPTIONS_ERROR:
            case FETCH_CUSTOM_TREE_ATTRS_ERROR:
            case FETCH_TREE_FREQS_ERROR:
            case FETCH_SUBSET_TREE_ERROR:
            case FETCH_RECALCULATED_TREE_ERROR:
            case FETCH_MEASURE_SCALES_DOMAINS_ERROR:
            case SET_LINEAGE_SETTINGS_ERROR:
            case INIT_STRAIN_TREE_ERROR:
            case FETCH_PREDICTIONS_ERROR:
            case FETCH_MUTATION_GROUP_ERROR:
            case FETCH_MUT_POS_DICT_ERROR:
            case FETCH_GENOTYPE_DATA_ERROR:
            case FETCH_MUTATION_CLASSES_ERROR:
            case FETCH_MODEL_DATA_ERROR:
            case FETCH_MODELS_ERROR:
            case FETCH_MODEL_TYPES_ERROR:
            case FETCH_LINEAGE_ERROR:
            case FETCH_LINEAGES_ERROR:
            case FETCH_GEO_DATA_ERROR:
            case FETCH_FREQUENCIES_ERROR:
            case FETCH_CLADES_ERROR:
            case FETCH_ANTIGENIC_OBSERVED_DATA_ERROR:
            case FETCH_ANTIGENIC_RAW_MODEL_ERROR:
            case FETCH_ANTIGENIC_MODEL_ERROR: {
                const { errorWebsiteText, errorWebsiteType } = action.payload;
                draft.errorWebsite = true;
                draft.errorWebsiteText = errorWebsiteText;
                draft.errorWebsiteType = errorWebsiteType;
                break;
            }
            case SET_ERROR_WEBSITE: {
                const { errorWebsite } = action.payload;
                draft.errorWebsite = errorWebsite;
                break;
            }
            case SET_STRAINS_TREE_DIMENSIONS: {
                const { strainTreeWidth, strainTreeHeight } = action.payload;
                draft.strainTreeWidth = strainTreeWidth;
                draft.strainTreeHeight = strainTreeHeight;
                break;
            }
            case SET_HIDDEN_MENU: {
                const { hiddenMenu } = action.payload;
                draft.hiddenMenu = hiddenMenu;
                break;
            }
            case SET_HIDDEN_MENU_MOBILE: {
                const { hiddenMenuMobile } = action.payload;
                draft.hiddenMenuMobile = hiddenMenuMobile;
                break;
            }
            default:
                break;
        }
    });

