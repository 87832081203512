import { scaleLinear, scaleThreshold, scaleTime, scaleLog } from 'd3-scale';
import { timeMonths, timeMonth } from 'd3-time';
import { uniqBy, uniq } from 'lodash';
import {
    dateToDays,
    daysToDate,
    monthFirstDate,
    monthLastDate,
    yearFirstDate,
    yearLastDate,
    emptyObject,
} from './functions';
import { LAYOUT } from '../config/dictionaries';

const scales = {};
const epsilon = 0.01;
const yMargin = 10;
export const xMargin = 20;

const getExactDate = (time) => (time instanceof Date ? time : daysToDate(time));

// const getStartDate = (minTime, alignment) => {
//     if (alignment === 'N') return getExactDate(minTime);
//     if (alignment === 'M') return monthFirstDate(minTime);
//     if (alignment === 'Q') return quarterFirstDate(minTime);
//     if (alignment === 'Y') return yearFirstDate(minTime);
//     return daysToDate(minTime);
// };

// const getEndDate = (maxTime, alignment) => {
//     if (alignment === 'N') return getExactDate(maxTime);
//     if (alignment === 'M') return monthLastDate(maxTime);
//     if (alignment === 'Q') return quarterLastDate(maxTime);
//     if (alignment === 'Y') return yearFirstDate(maxTime);
//     //  const timeDiff = maxTime - minTime;
//     //  const epsilon = Math.min(Math.round(timeDiff * 0.02), 15);

//     return daysToDate(maxTime); //monthLastDate(maxTime); // quarterLastDate(maxTime);
// };

export const getTimeTicks = (startDate, endDate, w, steps, displayedStrainNames) => {
    let months = new Set();
    let monthsTxt = new Set();
    const monthStep = 20;
    const yearStep = 40;
    // const steps = [1, 2, 3, 4, 6, 12, 24, 36];
    const allSteps = steps;

    const stepsSpace =
        steps.reduce((_steps, s) => {
            if (s < 12) _steps[s] = monthStep;
            else _steps[s] = yearStep /* steps[s].bigTick*/;
            return _steps;
        }, {});
    let s = 0;

    let ticksSet = false;
    let ticksTxtSet = false;
    let correctedWidth = w;
    while (s < allSteps.length && (!ticksSet || !ticksTxtSet)) {
        const sDate = timeMonth.offset(startDate, -allSteps[s]);
        const lDate = timeMonth.offset(endDate, allSteps[s]);

        //console.log(s, sDate.toLocaleDateString(), lDate.toLocaleDateString())
        const tMonths = timeMonths(yearFirstDate(dateToDays(startDate)), lDate, allSteps[s]);

        let avgTicksWidth = 0;
        //console.log(`BBB ${s}. ${allSteps[s]}, ticksSet = ${ticksSet}, tick = ${steps[allSteps[s]].tick}`);

        const bigTick = allSteps[s] <= 12 ? 1 : (allSteps[s] / 12);
        const tick = allSteps[s] <= 12;

        ticksSet = ticksSet || !tick;
        if (tick && !ticksSet) {
            months = new Set(tMonths.filter(d => ((d === endDate || d <= lDate) && d >= sDate)));
            // console.log('[getTimeTicks]', s, allSteps[s], months, tMonths);
            let x = w;


            if (displayedStrainNames) {
                // Find the min date.
                const minTime = dateToDays([...months].reduce((min, current) => (current < min ? current : min)));
                // Find the max date.
                const maxTime = dateToDays([...months].reduce((max, current) => (current > max ? current : max)));
                const arr = displayedStrainNames.map(d => ({ ...d, x: Math.min(w, (w - d.l) * (maxTime - minTime) / (d.time - minTime)), w }));
                const labelElem = arr.reduce((min, current) => (current.x < min.x) ? current : min);
                x = labelElem.x;
                // console.log(labelElem);
                // console.log('[getTimeTicks]', s, allSteps[s], `[${minTime}, ${maxTime}] ${w} => ${x}`);
            }

            avgTicksWidth = (months.size > 0) ? x / months.size : 0;
            if (avgTicksWidth >= stepsSpace[allSteps[s]]) {
                ticksSet = true;
                correctedWidth = x;
            }
        }

        if (!ticksTxtSet || !ticksSet) {
            monthsTxt = new Set([//sDate,
                ...tMonths
                    .filter(d => (d <= endDate && d >= startDate))
            ]);

            const avgWidth = ((monthsTxt.size > 0) ? w / months.size : 0) * bigTick; // (allSteps[s] > 12 ? steps[allSteps[s]].bigTick : 1);
            if (avgWidth >= stepsSpace[allSteps[s]]) ticksTxtSet = true;

        }
        s += 1;
    }

    const txtMonths = [...monthsTxt].map(d => d.getTime()).reduce((acc, time) => { acc[time] = true; return acc; }, {});
    const tickMonths = [...months].map((d, i, arr) => ({ d, time: d.getTime(), tick: true, txt: i < arr.length - 1 ? txtMonths[d.getTime()] || false : false }));
    const ticks = uniqBy([...tickMonths], ({ time }) => time).sort((a, b) => a - b);
    const res = ticks.reduce((acc, t) => { acc[t.time] = t; return acc; }, {});

    // console.log(res, allSteps[s - 1], correctedWidth)
    return { ticks: res, step: allSteps[s - 1], width: correctedWidth };
};



const getTimeScaleDomain = (startDate, endDate) => {
    const months = uniqBy([startDate, ...timeMonths(startDate, endDate, 1), endDate], (d) => d.getTime()).sort(
        (a, b) => a - b,
    );
    return months;
};

const getTimeScaleRange = (months, width) => {
    //console.log('[getTimeScaleRange]: width', width )
    const startDate = months[0];
    const endDate = months[months.length - 1];
    const oneDay = 1000 * 60 * 60 * 24;
    const dates1 = months.map((d, i) => ({
        d,
        dMonthDays: Math.round(((months[i + 1] || endDate) - d) / oneDay),
        monthDays: Math.round((monthLastDate(dateToDays(d)) - monthFirstDate(dateToDays(d))) / oneDay) + 1,
        yearStart: new Date(Math.max(yearFirstDate(dateToDays(d)), startDate)),
        yearEnd: new Date(Math.min(yearLastDate(dateToDays(d)), endDate)),
    }));
    //console.log(months.map(d => d.toLocaleDateString()));
    //console.log(`startDate = ${startDate}, endDate = ${endDate}`);
    const dates2 = dates1.map((d) => ({
        ...d,
        monthsInYear: timeMonths(d.yearStart, d.yearEnd, 1).length,
        monthDaysFraction: d.dMonthDays / d.monthDays,
    }));

    const monthsFraction = dates2.reduce((monthsFractions, d) => d.monthDaysFraction + monthsFractions, 0);
    const monthWidth = (width) / monthsFraction;
    const xScaleRange = [0];
    dates2.forEach((d) => {
        const mWidth = d.monthDaysFraction * monthWidth;
        const lastVal = xScaleRange[xScaleRange.length - 1];
        xScaleRange.push(lastVal + mWidth);
    });
    return xScaleRange;
};

const initTimeScale = (minTime, maxTime, width, timeTicks, timeStep) => {
    // console.log(`[initTimeScale] minTime = ${minTime}, maxTime = ${maxTime}, width = ${width}` )
    const startDate = getExactDate(minTime);
    const endDate = getExactDate(maxTime);
    const scaleDomain = getTimeScaleDomain(startDate, endDate); // = uniqBy([startDate, ...timeMonths(startDate, endDate, 1), endDate], d => d.getTime()).sort((a, b) => a - b);
    const scaleRange = getTimeScaleRange(scaleDomain, width);

    //const _scaleRange = margins ? scaleRange.map((v) => v + 10) : scaleRange;
    const scale = scaleTime().domain(scaleDomain).range(scaleRange);
    scale.time = true;
    scale.timeTicks = timeTicks;
    scale.timeStep = timeStep;
    //console.log('timeScale', Object.values(scale), scale.timeTicks, timeTicks);
    return scale;
};

export const layoutWidth = (layout, width, height) => layout === LAYOUT.TREE.value ? width : (Math.min(width / 2, height) - xMargin);

// const initTimeScaleRadial = (minTime, maxTime, width) => {
//     const startDate = getExactDate(minTime);
//     const endDate = getExactDate(maxTime);
//     const scaleDomain = getTimeScaleDomain(startDate, endDate); // = uniqBy([startDate, ...timeMonths(startDate, endDate, 1), endDate], d => d.getTime()).sort((a, b) => a - b);
//     const scaleRange = getTimeScaleRange(scaleDomain, width);
//     // console.log(`[initTimeScaleRadial] [${scaleRange.at(0)}, ${scaleRange.at(-1)}], xMargin = ${xMargin}, width = ${width}, height = ${height}`)
//     return scaleTime().domain(scaleDomain).range(scaleRange);
// };

const initLinearScale = (min, max, width, margin) => {
    //console.log(`[initLinearScale] width = ${width}, min = ${min}, max = ${max}`);
    const xMargin = margin || 0;
    const xScaleRange = [xMargin, width - xMargin];
    const scale = scaleLinear().domain([min, max]).range(xScaleRange).nice(0);
    return scale;
};

const getOrderScaleRange = (cladeBarData, minRange, maxRange, nodeRadius = 4, space = 2) => {
    //console.log('[getOrderScaleRange]', cladeBarData)
    const marginCount = cladeBarData.length - 1;
    const { minOrder, maxOrder, domainLength } = cladeBarData.reduce((acc, elem) => {
        acc.domainLength + (elem.endOrder - elem.startOrder);
        acc.minOrder = Math.min(acc.minOrder || elem.startOrder, elem.startOrder);
        acc.maxOrder = Math.max(acc.maxOrder || elem.endOrder, elem.endOrder);
        return acc;
    }
    , { minOrder: null, maxOrder: null, domainLegth: 0 });


    const domainSpan = domainLength || maxOrder - minOrder;
    let remainingHeight = (maxRange - minRange) - cladeBarData.reduce((acc, c) => acc + (c.minHeight || 0), 0) - space * marginCount; //marginCount * minSectionHeight;

    // const additionalLeafSpace = (domainLength === 0) ? remainingHeight / cladeBarData.length : 0;
    //console.log('[getOrderScaleRange]: remainingHeight = ',remainingHeight)
    while (remainingHeight < 0) {
        //_minSectionHeight = _minSectionHeight - 1;
        for (const i in cladeBarData) {
            cladeBarData[i].minHeight -= 1;
        }
        remainingHeight = (maxRange - minRange) - cladeBarData.reduce((acc, c) => acc + (c.minHeight || 0), 0) - space * marginCount; //marginCount * _minSectionHeight;
    }
    // console.log(`[getOrderScaleRange]:
    //  minRange = ${minRange},
    //  maxRange = ${maxRange},
    //  height = ${(maxRange - minRange)}
    //  domainSpan = ${domainSpan} => ${cladeBarData.map((elem) => (elem.endOrder - elem.startOrder))}
    //  remainingHeigth = ${remainingHeight},
    //  marginCount = ${marginCount},
    //  minOrder = ${minOrder},
    //  maxOrder = ${maxOrder}`);
    //if (domainLength === 0) return cladeBarData.map(({startOrder}) => startOrder);
    const tmpScale = scaleLinear().domain([minOrder, domainSpan + minOrder]).range([0, remainingHeight]);
    //console.log('[getOrderScaleRange]: domain = ',tmpScale.domain(), 'range = ', tmpScale.range());

    let actVal = 0;
    const res = cladeBarData.reduce((acc, elem, i) => {
        const tmpStartOrder = elem.startOrder - i;
        const tmpEndOrder = elem.endOrder - i;

        if (elem.startOrder === elem.endOrder) { //place the label at the middle
            actVal += (elem.minHeight || 0) / 2 + (i && space);
            acc.push(tmpScale(tmpStartOrder) + /*_minSectionHeight * i*/ actVal + minRange);

            actVal += (elem.minHeight || 0) / 2; // + additionalLeafSpace;
        }
        else {
            actVal += (i && space);
            acc.push(tmpScale(tmpStartOrder) + /*_minSectionHeight * i*/ actVal + nodeRadius + minRange);
            actVal += (elem.minHeight || 0);
            acc.push(tmpScale(tmpEndOrder) + /*_minSectionHeight * i*/ actVal - nodeRadius + minRange);
        }
        return acc;
    }, []);

    //stretch the scale
    const rescale = scaleLinear().domain([res[0], res[res.length - 1]]).range([minRange + nodeRadius, maxRange - nodeRadius]);
    const res2 = res.map(r => rescale(r));
    return res2;

};


const getOrderScaleDomainAndRange = (minOrder, maxOrder, height, cladeBarData) => {
    if (cladeBarData && cladeBarData.length === 1) {
        cladeBarData[0].startOrder = cladeBarData[0].startOrder - epsilon;
        cladeBarData[0].endOrder = cladeBarData[0].endOrder + epsilon;
    }
    const domainArr = (cladeBarData || [{ startOrder: minOrder, endOrder: maxOrder }]).sort((a, b) => a.startOrder - b.startOrder);

    const scaleDomain = uniq((domainArr).reduce((acc, elem) => {
        acc.push(elem.startOrder);
        acc.push(elem.endOrder);
        return acc;
    }, [])).sort((a, b) => a - b);
    const scaleRange = getOrderScaleRange(domainArr, yMargin, height - yMargin);
    //console.log('[getOrderScaleDomainAndRange]: domainArr:', domainArr, 'domain:',scaleDomain, 'range: ',scaleRange)
    return { scaleDomain, scaleRange };
};


const initIntervalOrderScale = (minOrder, maxOrder, height, cladeBarData) => {
    // console.log('[initIntervalOrderScale]')
    const { scaleDomain, scaleRange } = getOrderScaleDomainAndRange(minOrder, maxOrder, height, cladeBarData);

    const orderScale = scaleLinear().domain(scaleDomain).range(scaleRange);
    return orderScale;
};

const initYScale = (minOrder, maxOrder, height) => {
    const orderScaleRange = [yMargin, height - yMargin];
    return scaleLinear().domain([minOrder, maxOrder]).range(orderScaleRange);
};


const degreesToRadians = (degrees) => degrees * (Math.PI / 180);

const initYScaleRadial = (minOrder, maxOrder) => {
    const radVal90 = degreesToRadians(90);
    const yScaleRange = [-radVal90, radVal90];
    return scaleLinear().domain([minOrder, maxOrder]).range(yScaleRange);
};

const initYFrequencyScaleLinear = (maxVal, height, padding) =>
    scaleLinear()
        .domain([0, maxVal])
        .range([height + padding.top, padding.top])
        .clamp(true);

const initYFrequencyScaleLog = (maxVal, height, padding, minLogSpace) =>  scaleLog()
    .domain([minLogSpace, maxVal])
    .range([height + padding.top, padding.top])
    .clamp(true);

const initYMultipicityScale = (maxMultiplicity, height) =>
    scaleLinear().domain([0, maxMultiplicity]).range([height, 0]).clamp(true);

const initYSequencesScale = (maxVal, height, paddingTop) =>
    scaleLinear()
        .domain([0, maxVal])
        .range([height + (paddingTop || 0), paddingTop || 0])
        .clamp(true);

const getDomain = (colorScaleRange, scaleName, min, max) => {
    if (!colorScaleRange) return [];
    if (min === max) {
        min -= 0.01;
        max += 0.01;
    }
    const rangesNumber = colorScaleRange.length;
    const diff = (max - min) / (rangesNumber - 1);
    const domain = [];
    for (let i = 0; i < rangesNumber; i += 1) domain.push(min + i * diff);
    return domain;
};

const initColorScale = (scaleName, minVal, maxVal, measure) => {
    // console.log('[initColorScale]', scaleName, 'range = ',measure.scale.range);
    const colorScale = measure.discrete
        ? (v) => measure.scale.range.colorGetter ? measure.scale.range.colorGetter(v) : measure.scale.range.data[v]?.color //discrete measure, discrete scale
        : ((measure.scale.discrete)
            ? scaleThreshold()                  // continous measure, threshold scale
                .domain(measure.scale.domain)
                .range(measure.scale.range)
            : scaleLinear()                     //continous measure, continous scale
                .domain(getDomain(measure.scale.range, scaleName, minVal, maxVal))
                .range(measure.scale.range)
                .clamp(true)
        );


    // if (!colorScaleRange.discrete && scaleName === 'antigenic') {
    //     console.log('[initColorScale]', scaleName, colorScale.domain(), colorScaleRange.scale.domain);
    //     for (let v = -1; v <= 11; v++)
    //         console.log(v, colorScale(v));
    // }
    //console.log('[initColorSscale]', scaleName, colorScaleRange.discrete);
    return colorScale;
};

const initValueScale = (scaleName, minVal, maxVal, measure) => {
    //console.log('[initValueScale]', scaleName, measure);

    const valueScale = measure.discrete
        ? (v) => v //discrete measure, discrete scale
        : (measure.scale.discrete && measure.scale.bins
            ? scaleThreshold()                  // continous measure, threshold scale
                .domain(measure.scale.domain)
                .range(measure.scale.bins.map(({ bin }) => bin))
            : v => v
        );


    // if (!colorScaleRange.discrete && scaleName === 'antigenic') {
    //     console.log('[initColorScale]', scaleName, colorScale.domain(), colorScaleRange.scale.domain);
    //     for (let v = -1; v <= 11; v++)
    //         console.log(v, colorScale(v));
    // }
    //console.log('[initColorSscale]', scaleName, colorScaleRange.discrete);
    return valueScale;
};

const initFreqScale = () => scaleLinear().domain([0, 1]).range([1.5, 9]);

export const initColorScales = (measures, ranges) => {
    //console.log('initColorScales');
    Object.keys(measures).forEach((colorBy) => {
        const measure = measures[colorBy];

        //if (colorBy === 'cladeSalpha2')
        // console.log('[initColorScales]', colorBy, measure, measure && measure.scale && measure.scale.range && (measure.discrete || (ranges.min[colorBy] !== undefined && ranges.max[colorBy] !== undefined)))
        if (measure && measure.scale && measure.scale.range &&
            (measure.discrete || (ranges.min[colorBy] !== undefined && ranges.max[colorBy] !== undefined))
        ) {
            scales[`${colorBy}ColorScale`] = initColorScale(
                colorBy,
                ranges.min[colorBy],
                ranges.max[colorBy],
                measure,
            );
            scales[`${colorBy}ValueScale`] = initValueScale(
                colorBy,
                ranges.min[colorBy],
                ranges.max[colorBy],
                measure,
            );
        } else delete scales[`${colorBy}ColorScale`];
    });

    if (scales.antigenicColorScale) scales.antigenicColorScale.missingValueColor = '#FFFFFF';
    if (scales.alphaYColorScale) scales.alphaYColorScale.missingValueColor = '#FFFFFF';
    if (scales.rhoRColorScale) scales.rhoRColorScale.missingValueColor = '#FFFFFF';

    //console.log('[initColorScales]', scales)
    //return res;
};

const getCorrectedMinMax = (minV, maxV) => (minV === maxV)
    ? [minV - epsilon, maxV + epsilon]
    : [minV, maxV + Math.min(maxV * 0.1, 5)];

const initYScales = (props, height, nodeRadius) => {
    // console.log('[initYScales]')
    const { measures, ranges, cladeBarData } = props;

    Object.keys(measures)
        .filter((m) => measures[m].yScale)
        .forEach((m) => {
            // if (m === 'AADivergence') console.log('[initYScales]', m, measures[m])
            // if (!scales[`y${m}Scale`] || m === 'order') {
            const yScaleRange = measures[m];
            // console.log(`init y${m}Scale, ranges.min = `, ranges.min[m], ranges.max[m]);
            if (yScaleRange && ranges.min[m] !== undefined && ranges.max[m] !== undefined) {
                const [yMin, yMax] = getCorrectedMinMax(ranges.min[m], ranges.max[m]);
                scales[`y${m}Scale`] = (m === 'order')
                    ? initIntervalOrderScale(yMin, yMax, height, cladeBarData, nodeRadius)
                    : initYScale(yMin, yMax, height);

                scales[`y${m}RadialScale`] = initYScaleRadial(yMin, yMax);
            }
        });
};

const initXScales = (props, width, cladeBarWidth, height, timeProps) => {
    const { measures, ranges } = props;
    const { minTime, maxTime, correctedWidth, minTimeRadial, maxTimeRadial, timeTicks, timeTicksRadial, timeStep, timeStepRadial } = timeProps;
    const fanWidth = layoutWidth(LAYOUT.FAN.value, width, height);
    const treeWidth = width - cladeBarWidth;
    Object.keys(measures)
        .filter((m) => measures[m].xScale)
        .forEach((m) => {
            //if (!scales[`x${m}Scale`]) {
            const xScaleRange = measures[m];
            // console.log(`init x${m}Scale, ranges.min = `,ranges.min[m], ranges.max[m], xScaleRange);
            if (xScaleRange && ranges.min[m] !== undefined && ranges.max[m] !== undefined) {
                const { time } = xScaleRange;

                const [xMin, xMax] = time ? [minTime, maxTime] : getCorrectedMinMax(ranges.min[m], ranges.max[m]);
                scales[`x${m}Scale`] = time 
                    ? initTimeScale(xMin, xMax, correctedWidth, timeTicks, timeStep) 
                    : initLinearScale(xMin, xMax, treeWidth, xMargin);

                const [xMinR, xMaxR] = time ? [minTimeRadial, maxTimeRadial] : getCorrectedMinMax(ranges.min[m], ranges.max[m]);
                scales[`x${m}RadialScale`] = time 
                    ? initTimeScale(xMinR, xMaxR, fanWidth, timeTicksRadial, timeStepRadial) 
                    : initLinearScale(xMinR, xMaxR, fanWidth, xMargin);
            }
            //${m}Scale`].range().at(-1)}`);
            //} else {
            //   scales[`x${m}Scale`].range([xMargin, width - xMargin]);
            // scales[`x${m}RadialScale`].range([xMargin, _width - xMargin ]);
            // }

            //console.log(`INIT x${m}Scale`);
        });
};

const resetScales = () => {
    //console.log('RESET SCALES');
    Object.keys(scales).forEach((key) => {
        delete scales[key];
    });
};

const getStartEndDates = (minTime, maxTime, initWidth, displayedStrainNames) => {

    const yearMonths = [...Array(30).keys()].map(i => (i + 1) * 12);
    const steps = [1, 2, 3, 6, ...yearMonths];

    const startDate = getExactDate(minTime);
    const endDate = getExactDate(maxTime);

    const { ticks, step, width } = getTimeTicks(startDate, endDate, initWidth, steps, displayedStrainNames);

    const tickStartDate = ticks ? new Date(Math.min(...Object.values(ticks).map(({ d }) => d))) : null;
    const tickEndDate = ticks ? new Date(Math.max(...Object.values(ticks).map(({ d }) => d))) : null;
    // console.log(`[getStartEndDates] step = ${step},
    //  tickStartDate = ${tickStartDate.toLocaleDateString()} / ${startDate.toLocaleDateString()},
    //  tickEndDate = ${tickEndDate.toLocaleDateString()} / ${endDate.toLocaleDateString()}`);
    const corrStartTime = dateToDays(tickStartDate);
    const corrEndTime = dateToDays(tickEndDate);
    //console.log(`[getStartEndDates] ${minTime}, ${maxTime}, ${width} => ${corrStartTime}, ${corrEndTime}`);
    //console.log('[getStartEndTimes] ticks = ',ticks);
    return [corrStartTime, corrEndTime, ticks, step, width];
};


const initTreeScales = (props, width, cladeBarWidth, height, nodeRadius) => {
    const { showCladeBar, ranges, cladeBarData, measures, displayedStrainNames } = props;
    // console.log('[initTreeScales]')
    const _width = layoutWidth(LAYOUT.FAN.value, width, height);

    const _cladeBarData = showCladeBar ? cladeBarData : null;

    scales.freqScale = initFreqScale();

    if (props.measures) {
        const [minTime, maxTime, timeTicks, timeStep, correctedWidth] = getStartEndDates(ranges.min.time, ranges.max.time, width - cladeBarWidth, displayedStrainNames);
        const [minTimeRadial, maxTimeRadial, timeTicksRadial, timeStepRadial] = getStartEndDates(ranges.min.time, ranges.max.time, _width);

        const timeProps = { minTime, maxTime, timeTicks, timeStep, correctedWidth, minTimeRadial, maxTimeRadial, timeTicksRadial, timeStepRadial };

        initXScales({ measures, ranges, displayedStrainNames }, width, cladeBarWidth, height, timeProps);
        initYScales({ measures, ranges, cladeBarData: _cladeBarData }, height, nodeRadius);
        return correctedWidth;
    }
    return 0;
};


const initFrequencyChartScales = (width, height, startTime, endTime, maxMultiplicity, padding, multiexport, logSpace, minLogSpace) => {
    const yFEpsilon = multiexport ? 0 : height < 150 ? 0.2 : 0.05;
    const maxFreq = 1 + yFEpsilon;
    const yMEpsilon = maxMultiplicity / 100;

    scales.xFrequencyScale = initTimeScale(startTime, endTime, width);
    scales.yFrequencyScale = logSpace ? initYFrequencyScaleLog(maxFreq, height, padding, minLogSpace) : initYFrequencyScaleLinear(maxFreq, height, padding);
    scales.yMultiplicityScale = initYMultipicityScale(maxMultiplicity + yMEpsilon, height);
    // console.log(`[initYFrequencyScaleLinear]: width = ${width}`, [scales.xFrequencyScale.range()[0], scales.xFrequencyScale.range()[scales.xFrequencyScale.range().length-1]]);
    // console.log(`xFrequencyScale => ${scales.xFrequencyScale.range()}`);
    // console.log(`yFrequencyScale => ${scales.yFrequencyScale.range()}`);

    return scales;
};

const reinitFrequencyChartScales = (width, height, padding) => {
    if (scales.xFrequencyScale)
        setScaleRange('xFrequencyScale', getTimeScaleRange(scales.xFrequencyScale.domain(), width));
    setScaleRange('yFrequencyScale', [height + padding.top, padding.top]);
    setScaleRange('yMultiplicityScale', [height + padding.top, padding.top]);
};

const initStackedFrequencyChartScales = (width, height, startTime, endTime, maxStackedMultiplicity, padding) => {
    const yFEpsilon = height < 150 ? 0.2 : 0.05;
    const maxFreq = 1 + yFEpsilon;
    const yMEpsilon = maxStackedMultiplicity / 100;
    scales.xStackedFrequencyScale = initTimeScale(startTime, endTime, width);
    scales.yStackedFrequencyScale = initYFrequencyScaleLinear(maxFreq, height, padding);
    scales.yStackedMultiplicityScale = initYMultipicityScale(maxStackedMultiplicity + yMEpsilon, height);
    return scales;
};

const reinitStackedFrequencyChartScales = (width, height, padding) => {
    if (scales.xStackedFrequencyScale)
        setScaleRange('xStackedFrequencyScale', getTimeScaleRange(scales.xStackedFrequencyScale.domain(), width)); //[0, width]);
    setScaleRange('yStackedFrequencyScale', [height + padding.top, padding.top]);
    setScaleRange('yStackedMultiplicityScale', [height + padding.top, padding.top]);
};

const initSequenceChartScales = (width, height, startTime, endTime, maxVal, type, subsetId, padding) => {
    const yEpsilon = Math.round((20 * maxVal) / 100);
    const name = type === 'seq' ? 'Sequences' : 'Cases';
    scales[`x${name}Scale`] = initTimeScale(startTime, endTime, width);

    // console.log(`[initSequenceChartScales]: x${name}Scale,  d: [${scales[`x${name}Scale`].domain().map(d => d.toLocaleString().split(',')[0]).join(' - ')}], r: [${scales[`x${name}Scale`].range()}] `);
    scales[`y${name}${subsetId}Scale`] = initYSequencesScale(
        maxVal + yEpsilon,
        height,
        padding.top + (padding.title || 0),
    );
    // const sr = scales[`x${name}Scale`].range();
    // console.log(`x${name}Scale => ${scales[`x${name}Scale`].range()}`);

    // console.log(`[initSequenceChartScales]: width = ${width}, ${subsetId}`, [sr[0], sr[sr.length-1]]);
    return scales;
};

const reinitSequenceChartScales = (width, height, type, subsetId, padding) => {
    const name = type === 'seq' ? 'Sequences' : 'Cases';
    const paddingTop = padding ? padding.top + (padding.title || 0) : 0;
    const timeScale = scales[`x${name}Scale`];
    if (timeScale) setScaleRange(`x${name}Scale`, getTimeScaleRange(timeScale.domain(), width)); //[0, width]);
    setScaleRange(`y${name}${subsetId}Scale`, [height + paddingTop, paddingTop]);
};

const initVaccinesChartScales = (width, height, sectionHeight, cladesCnt, refStrainsCnt) => {
    scales.xVaccinesScale = initLinearScale(0, 1, width, xMargin);
    scales.yVaccinesScale = initLinearScale(0, cladesCnt, sectionHeight, sectionHeight/4);
    scales.yAllVaccinesScale = initLinearScale(-0.5, refStrainsCnt+1, height);
    return scales;
};


const setScaleRange = (name, scaleRange) => {
    if (scales && scales[name]) scales[name].range(scaleRange);
};

const getScale = (name) => scales[name];

const getScaledValue = (name, value) => {
    const _value = scales[name]?.time ? daysToDate(value) : value;
    if (!scales[name]) return null;
    if (scales[name].missingValueColor && emptyObject(_value)) {
        return scales[name].missingValueColor;
    }
    return scales[name](_value);
};


const getInvertedValue = (name, value) => scales[name].invert(value);

// const getZoomAreaFromScales = ({ time, minOrder, maxOrder }, layout) => ({
//     minTime: layout === LAYOUT.TREE.value ? dateToDays(yearFirstDate(time)) : time,
//     minOrder,
//     maxOrder,
// });

// const initAntigenicScales = (measures, ranges) => {
//     if (measures) {
//         //initColorScales(measures, ranges);

//         if (scales.antigenicColorScale) scales.antigenicColorScale.missingValueColor = '#FFFFFF';
//         // console.log(`[initAntigenicScales]: scales.antigenicColorScale.missingValueColor = ${scales.antigenicColorScale.missingValueColor}`);

//         // scales.alphaYColorScale = initColorScale('alphaY', minAlphaY, maxAlphaY);
//         if (scales.alphaYColorScale) scales.alphaYColorScale.missingValueColor = '#FFFFFF';

//         // scales.rhoRColorScale = initColorScale('rhoR', minRhoR, maxRhoR);
//         if (scales.rhoRColorScale) scales.rhoRColorScale.missingValueColor = '#FFFFFF';
//     }
// };

const getAntigenicScales = () => ({
    antigenicColorScale: scales.antigenicColorScale,
    alphaYColorScale: scales.alphaYColorScale,
    rhoRColorScale: scales.rhoRColorScale,
});

export {
    initTreeScales,
    initFrequencyChartScales,
    initSequenceChartScales,
    initStackedFrequencyChartScales,
    //initAntigenicScales,
    // reinitXYTreeScales,
    reinitFrequencyChartScales,
    reinitStackedFrequencyChartScales,
    reinitSequenceChartScales,
    getScale,
    getScaledValue,
    setScaleRange,
    // getZoomAreaFromScales,
    getInvertedValue,
    monthFirstDate,
    getAntigenicScales,
    resetScales,
    initVaccinesChartScales
};
