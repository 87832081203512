// External imports
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid2';
import PropTypes from 'prop-types';

// Internal imports - Components
import SelectInput from '../Common/SelectInput';
import TreeAntigenicStrainSearch from '../Tree/TreeAntigenicStrainSearch';
import GenotypeSelector from './GenotypeSelector';
import HumanSerologySelector from './HumanSerologySelector';
import CladeTypeSelector from './CladeTypeSelector';

// Internal imports - Actions
import { setParameters } from '../../redux/actions/parametersActions';
import AntigenicDataTypeSelector from './AntigenicDataTypeSelector';
import AntigenicTiterTypeSelector from './AntigenicTiterTypeSelector';



const AdditionalColorBySelector = (props) => {
    const { 
        // Parameters
        gene, hla, colorBy, colorByVar,
        // Others
        geneOptions, hlaOptions, permissions,
        // Actions
        setParameters,
    } = props;
    const _gene = gene || '';
    const _hla = hla || '';

    const handleChange = name => value => {;
        setParameters({ [name]: value });
    };

    return (
        <>
            {(colorBy === 'clade' && permissions.cladeTypeSelector) &&
                <CladeTypeSelector />
            }
            {(colorBy === 'antigenic') && (
                <>
                    <AntigenicDataTypeSelector />
                    <AntigenicTiterTypeSelector />
                    <TreeAntigenicStrainSearch searchId="antigenic" />
                </>
            )}
            {(colorBy === 'tcellAntigenicity') && (
                <Grid container spacing={2}>
                    <Grid size={6}>
                        <SelectInput
                            id="gene"
                            label="Gene"
                            value={_gene}
                            onChange={handleChange('gene')}
                            options={geneOptions}
                            getOptionValue={option => option}
                            getOptionLabel={option => option}
                        />
                    </Grid>
                    <Grid size={6}>
                        <SelectInput
                            id="hla"
                            label="HLA"
                            value={_hla}
                            onChange={handleChange('hla')}
                            options={hlaOptions}
                            getOptionValue={option => option}
                            getOptionLabel={option => option}
                        />
                    </Grid>
                </Grid>
            )}

            {colorBy === 'genotype' && (
                <GenotypeSelector colorByVar={colorByVar} />
            )}
            {colorBy === 'humanSerology' && (
                <HumanSerologySelector />
            )}
        </>
    );
};

AdditionalColorBySelector.propTypes = {
    // Required props
    measures: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    permissions: PropTypes.object.isRequired,
    setMeasureScale: PropTypes.func.isRequired,
    setParameters: PropTypes.func.isRequired,
    setReferenceStrain: PropTypes.func.isRequired,

    // Optional props
    antigenicTiterType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    colorBy: PropTypes.string,
    colorByVar: PropTypes.string,
    gene: PropTypes.string,
    geneOptions: PropTypes.arrayOf(PropTypes.string),
    hla: PropTypes.string,
    hlaOptions: PropTypes.arrayOf(PropTypes.string),
    refStrain: PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
};

const mapStateToProps = (state, ownProps) => ({
  
    // Color related props
    colorBy: ownProps.mapSelector ? state.parameters.geoMapColorBy : state.parameters.colorBy,
    colorByVar: ownProps.mapSelector ? 'geoMapColorBy' : 'colorBy',

    // T-cell antigenicity props
    gene: state.parameters.gene,
    geneOptions: state.metadata.tcellAntigenicityOptions.geneOptions,
    hla: state.parameters.hla,
    hlaOptions: state.metadata.tcellAntigenicityOptions.hlaOptions,

    // Other props
    permissions: state.user.permissions.strainTreePermissions
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalColorBySelector);
