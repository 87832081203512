import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';

// MUI Components
import { 
    Dialog, 
    DialogTitle, 
    DialogActions,
    DialogContent,
    Button,
    Autocomplete
} from '@mui/material';

// Custom Components
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import SelectInput from '../../../Common/SelectInput';

// Styles
import { styles } from '../styles';

// Config & Actions
import appConfig from '../../../../config/appConfig';
import { fetchLineages } from '../../../../redux/actions/lineagesActions';

const errorState = {
    email: {
        status: false,
        message: ''
    },
    username: {
        status: false,
        message: ''
    },
    password: {
        status: false,
        message: ''
    },
    role: {
        status: false,
        message: ''
    }
};

const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EditDialog = (props) => {
    const { handleCloseDialog, openDialog, user, lineages, fetchLineages, updateUser, roles } = props;
    const [updatedUser, setUpdatedUser] = useState(user);
    const [error, setError] = useState(errorState);
    const classes = styles();
    const defaultLin = `Aplication default (${appConfig.default.lineage})`;

    useEffect(() => {
        if (lineages.length === 0)
            fetchLineages();
    }, []);

    const handleChange = (type) => (value) => {
        // Update user state
        setUpdatedUser(prev => ({ ...prev, [type]: value }));

        // Clear error if value exists and there was an error
        if (error[type]?.status && value) {
            setError(prev => ({
                ...prev,
                [type]: { status: false, message: '' }
            }));
        }
    };

    const validate = () => {
        const matchEmail = updatedUser.email.match(emailRegex);
        setError({
            username: !updatedUser.username ? { status: true, message: 'Username cannot be empty' } : errorState.username,
            email: !updatedUser.email ? { status: true, message: 'Email cannot be empty' } :
                !matchEmail ? { status: true, message: 'Email is not valid' } : errorState.email,
            password: !updatedUser.password ? { status: true, message: 'Password cannot be empty' } : errorState.password,
            role: !updatedUser.role ? { status: true, message: 'Role cannot be empty' } : errorState.role
        });
        const state = !updatedUser.username || !updatedUser.email || !updatedUser.password || !updatedUser.role || !matchEmail;
        return state;
    };

    const handleSubmit = () => {
        if (!validate()) {
            if (!lineages.includes(updatedUser.defaultLineage)) {
                const updateLinUser = { ...updatedUser, defaultLineage: '' };
                updateUser(updateLinUser);
                return;
            }
            updateUser(updatedUser);
        }
    };

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={{
            '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                    borderRadius: '12px'
                },
            }
        }}>
            <DialogTitle id="confirm-dialog-title">
                Edit user
            </DialogTitle>

            <DialogContent>
                <StyledTextField
                    id="username"
                    type="text"
                    label="Username"
                    required
                    autoComplete='off'
                    className={classes.textField}
                    value={updatedUser.username}
                    onChange={handleChange('username')}
                    error={error.username.status}
                    helperText={error.username.message}
                />
                <StyledTextField
                    id="email"
                    type="email"
                    label="Email"
                    disabled
                    required
                    className={classes.textField}
                    value={updatedUser.email || ''}
                    onChange={handleChange('email')}
                    error={error.email.status}
                    helperText={error.email.message}
                />
                <StyledTextField
                    id="password"
                    type="password"
                    label="Password"
                    autoComplete="new-password"
                    required
                    className={classes.textField}
                    value={updatedUser.password}
                    onChange={handleChange('password')}
                    error={error.password.status}
                    helperText={error.password.message}
                />

                <SelectInput
                    id='role'
                    label='Permissions'
                    value={updatedUser.role}
                    required={true}
                    showRequired={true}
                    onChange={handleChange('role')}
                    options={roles}
                    getOptionValue={(option) => option.name}
                    getOptionLabel={(option) => capitalize(option.name)}
                    error={error.role.status}
                    errorText={error.role.message}
                    className={classes.textField}
                />
         
                <Autocomplete
                    multiple
                    id="lineages"
                    className={classes.autoComplete}
                    options={lineages}
                    getOptionLabel={(option) => option}
                    value={updatedUser.lineages}
                    onChange={(e, v) => setUpdatedUser({ ...updatedUser, lineages: v })}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <StyledTextField
                            {...params}
                            label="Available lineages"
                            placeholder={updatedUser.lineages.length > 0 ? '' : 'All lineages'}
                        />
                    )}
                />
                <SelectInput
                    id='defaultLineage'
                    label='Default lineage'
                    value={updatedUser.defaultLineage || defaultLin}
                    required={true}
                    showRequired={true}
                    onChange={handleChange('defaultLineage')}
                    options={user.lineages.length > 0 ? user.lineages : lineages}
                    getOptionValue={(option) => option}
                    getOptionLabel={(option) => option}
                    className={classes.textField}
                />
            </DialogContent>

            <DialogActions>
                <Button className={classes.cancel}
                    onClick={handleCloseDialog}>Cancel
                </Button>
                <Button className={classes.confirm} onClick={handleSubmit}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>);
};

EditDialog.propTypes = {
    handleCloseDialog: PropTypes.func.isRequired,
    openDialog: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    lineages: PropTypes.array.isRequired,
    fetchLineages: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
    lineages: state.lineages.lineages
});

const mapDispatchToProps = {
    fetchLineages
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDialog);
