import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import { StyledTextField } from '../../assets/GlobalStyles/TextField';
import { setBranchNodes, setParameters } from '../../redux/actions/parametersActions';
import { fetchBranchNodes } from '../../redux/actions/treeDataActions';
import { getCustomTreeBranchOptions, branchNodesSelector } from '../../redux/selectors/metadataSelector';
import { bindActionCreators } from 'redux';

const styles = () => ({
    // root: {
    //     display: 'flex',
    //     flexWrap: 'wrap',
    //     textAlign: 'left',
    //     padding: '22px 12px 12px 12px'
    // },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
        margin: '8px 0px'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    // selectEmpty: {
    //     marginTop: theme.spacing(2)
    // },
});

const BranchColorBySelector = props => {
    const { lineage, branchColorOptions, branchNodes, classes, setBranchNodes } = props;

    const handleSelectedOptions = (event, _branchNodes) => {
        const branchNodes = _branchNodes.map(({key}) => key);
        setBranchNodes({lineage, branchNodes });
    };


    return (
        <>
            {branchColorOptions.length > 0 && (
                <div className={classes.formControl}>
                    <Autocomplete
                        multiple
                        id="branchNodes"
                        size="small"
                        options={branchColorOptions}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.key === value.key}
                        value={branchNodes}
                        onChange={handleSelectedOptions}
                        renderInput={(params) => (
                            <StyledTextField
                                {...params}
                                label="Mark branches with"
                                placeholder={''}
                            />
                        )}
                    />
                </div>
            )}
        </>
    );

};

BranchColorBySelector.propTypes = {
    lineage: PropTypes.string,
    branchColorOptions: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, label: PropTypes.string })),
    branchNodes: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, label: PropTypes.string })),
    customTreeAttrs: PropTypes.shape({}),
    classes: PropTypes.shape({ formControl: PropTypes.string }),
    fetchCustomTreeAttrs: PropTypes.func,
    setParameters: PropTypes.func
};


const mapStateToProps = (state) => {
    const branchColorOptions = getCustomTreeBranchOptions(state);
    const branchNodes = branchNodesSelector(state);

    return ({
        lineage: state.parameters.lineage,
        strainSubset: state.parameters.strainSubset,
        branchColorOptions,
        branchNodes,
        customTreeAttrs: state.customTreeData.customTreeAttrs,
        status: state.customTreeData.status
    });
};



const mapDispatchToProps = dispatch => bindActionCreators({
    fetchBranchNodes,
    setParameters,
    setBranchNodes
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BranchColorBySelector));
