import {
    SET_STRAINS_TREE_DIMENSIONS,
    SET_HIDDEN_MENU,
    SET_HIDDEN_MENU_MOBILE,
    SET_ERROR_WEBSITE,
} from './actionTypes';

// Base Actions
import { received } from '../baseActions';


export const setStrainTreeDimensions = (payload) => received(SET_STRAINS_TREE_DIMENSIONS, payload);

export const setHiddenMenu = (payload) => received(SET_HIDDEN_MENU, payload);

export const setHiddenMenuMobile = (payload) => received(SET_HIDDEN_MENU_MOBILE, payload);

export const setErrorWebsite = (payload) => received(SET_ERROR_WEBSITE, payload);

