// External imports
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Box, Button, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

// Internal imports - Components
import { HideIcon } from '../Alerts/styles';
import ModelSelector from '../ModelSelector/ModelSelector';
import ColorScaleSelector from '../OptionsSelector/ColorScaleSelector/ColorScaleSelector';
import ScalesLegend from '../ColorLegend/Legends/ScalesLegend';
import FitnessInfo from './FitnessInfo';
import BuildSelector from '../OptionsSelector/LineageSelector/BuildSelector';
import CheckboxInput from '../Common/CheckboxInput';

// Internal imports - Actions & Styles
import { setMeasureScale, setParameters } from '../../redux/actions/parametersActions';
import { resetAntigenicHiddenClades } from '../../redux/actions/antigenicActions';
import { styles } from './styles/antigenicFitnessOptionsStyles';


/**
 * FitnessOptions component displays controls for configuring fitness visualization
 */
const FitnessOptions = ({
    hiddenAlphaCladesCnt,
    hiddenRhoCladesCnt,
    handleHideClick,
    permissions,
    showAntigenicTableValues,
    wrapAntigenicTableHeaders,
    resetAntigenicHiddenClades,
    setParameters,
    undoDeleting,
    cellInfo
}) => {
    const classes = styles();

    const handleReset = () => {
        resetAntigenicHiddenClades();
    };

    const handleUndo = () => {
        undoDeleting();
    };

    const handleChange = (id) => (value) => {
        setParameters({ [id]: value });
    };

    return (
        <form className={classes.root} autoComplete="off">
            <div className={classes.buttonWrapper}>
                <IconButton className={classes.hideButton} onClick={handleHideClick}>
                    <HideIcon />
                </IconButton>
            </div>

            <BuildSelector />
            
            {permissions.modelSelector && (
                <ModelSelector 
                    fixedModelType="Antigenic" 
                    modelContext="antigenicFitness" 
                />
            )}

            <CheckboxInput 
                id="showAntigenicTableValues"
                label="Show values" 
                value={showAntigenicTableValues} 
                onChange={handleChange('showAntigenicTableValues')}
                className={classes.formControl}
            />  

            <CheckboxInput 
                id="wrapAntigenicTableHeaders"
                label="Wrap labels" 
                value={wrapAntigenicTableHeaders} 
                onChange={handleChange('wrapAntigenicTableHeaders')}
                className={classes.formControl}
            />  
                
            <Typography style={{ fontSize: '15px'}}>
                DELETED: {hiddenAlphaCladesCnt} Rows / {hiddenRhoCladesCnt} Columns
            </Typography>

            <Box sx={{ marginTop: '5px' }}>
                <Grid container spacing={1}>
                    <Grid >
                        <Button className={classes.button} onClick={handleReset}>
                            Reset
                        </Button>
                    </Grid>
                    <Grid >
                        <Button className={classes.button} onClick={handleUndo}>
                            Undo
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <ColorScaleSelector colorBy='antigenicFitness' />
            <ScalesLegend
                scaleId="antigenicFitness"
                measureName="antigenicFitness"
                valueGetter={() => cellInfo?.antigenicFitness}
                ticks={3}
            />
            <FitnessInfo cellInfo={cellInfo}/>
        </form>
    );
};

FitnessOptions.propTypes = {
    hiddenAlphaCladesCnt: PropTypes.number,
    hiddenRhoCladesCnt: PropTypes.number,
    handleHideClick: PropTypes.func.isRequired,
    permissions: PropTypes.object.isRequired,
    showAntigenicTableValues: PropTypes.bool,
    wrapAntigenicTableHeaders: PropTypes.bool,
    resetAntigenicHiddenClades: PropTypes.func.isRequired,
    undoDeleting: PropTypes.func.isRequired,
    cellInfo: PropTypes.object,
};

const mapStateToProps = (state) => ({
    hiddenAlphaCladesCnt: Object.keys(state.parameters.hiddenAlphaClades).length,
    hiddenRhoCladesCnt: Object.keys(state.parameters.hiddenRhoClades).length,
    showAntigenicTableValues: state.parameters.showAntigenicTableValues,
    permissions: state.user.permissions.fitnessPermissions,
    measures: state.metadata.measures,
    wrapAntigenicTableHeaders: state.parameters.wrapAntigenicTableHeaders
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setParameters,
    setMeasureScale,
    resetAntigenicHiddenClades,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FitnessOptions);
