import { ofType } from 'redux-observable';
import { tap, ignoreElements } from 'rxjs/operators';
import {
    SIGNOUT_REQUEST,
    GET_SIGNED_USER_ERROR,
    SIGNIN_SUCCESS,
    SESSION_TIMEOUT,
} from '../actions/actionTypes';
import auth from '../../functions/auth-helper';

export const signOutTriggerEpic = (action$) => action$.pipe(
    ofType(SIGNOUT_REQUEST, GET_SIGNED_USER_ERROR, SESSION_TIMEOUT),
    tap(() => {
        auth.signout();
    }),
    ignoreElements()
);

export const signInTriggerEpic = (action$) => action$.pipe(
    ofType(SIGNIN_SUCCESS),
    tap((action) => {
        const { token } = action.payload;
        auth.authenticate(token);
    }),
    ignoreElements()
);
  
