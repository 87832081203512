import React, { useState } from 'react';
import { Button, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styles } from '../styles';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import { connect } from 'react-redux';

const Menu = ({
    width,
    setSchemaWidth,
    height,
    setSchemaHeightMultiplier
}) => {
    const classes = styles();
    const [widthLocal, setWidthLocal] = useState(width);
    const [heightLocal, setHeightLocal] = useState(height);

    const handleChangeWidth = e => {
        setWidthLocal(e.target.value);
    };

    const handleChangeHeight = e => {
        setHeightLocal(e.target.value);
    };

    const handleKeyDown = e => {
        if (e.key === 'Enter'){
            setSchemaWidth(widthLocal);
            setSchemaHeightMultiplier(heightLocal);
        }
    };

    const download = async () => {
        const selector = 'schema_div';
        const svg = document.getElementById(selector);
        const serializer = new XMLSerializer();
        let source = serializer.serializeToString(svg);

        if (!source.match(/^<svg[^>]*?\sxmlns=(['"`])https?\:\/\/www\.w3\.org\/2000\/svg\1/)) {
            source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if (!source.match(/^<svg[^>]*?\sxmlns:xlink=(['"`])http\:\/\/www\.w3\.org\/1999\/xlink\1/)) {
            source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }

        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
        const url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);
        const element = document.createElement('a');

        element.href = url;
        element.download = `schema.svg`;
        document.body.appendChild(element);
        element.click();
    };

    return (
        <Paper variant="outlined" square className={`${classes.menu} ${classes.cladeMenu}`} >
            <Grid container>
                <Grid size={6} className={classes.item}>
                    <StyledTextField
                        id="width"
                        type="number"
                        label="Width"
                        className={classes.textField}
                        value={widthLocal}
                        onChange={handleChangeWidth}
                        onKeyDown={handleKeyDown}
                    />
                </Grid>
                <Grid size={6} className={classes.item}>
                    <StyledTextField
                        id="height"
                        type="number"
                        label="Space between branches"
                        className={classes.textField}
                        value={heightLocal}
                        onChange={handleChangeHeight}
                        onKeyDown={handleKeyDown}
                    />
                </Grid>
            </Grid>
            <Button className={classes.export} onClick={download}>
                Export
            </Button>
        </Paper>
    );
};

const mapStateToProps = () => {

    return {

    };
};

export default connect(mapStateToProps, null)(Menu);
