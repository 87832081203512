import { produce } from 'immer';
import { emptyObject } from '../../functions/functions';
import {
    FETCH_CUSTOM_TREE_ATTRS_REQUEST,
    FETCH_CUSTOM_TREE_ATTRS_SUCCESS,
    FETCH_CUSTOM_TREE_ATTRS_ERROR,
    FETCH_TCELL_ANTIGENICITY_SCORES_REQUEST,
    FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS,
    FETCH_TCELL_ANTIGENICITY_SCORES_ERROR,
    FETCH_FREQUENCIES_SUCCESS,
    SIGNOUT_REQUEST,
    RESET_SESSION,
    INIT_STRAIN_TREE_REQUEST,
    FETCH_RECALCULATED_TREE_REQUEST,
    FETCH_SUBSET_TREE_REQUEST,
    FETCH_VP_VALUES_REQUEST,
    ZOOM_TREE_REQUEST,
    FETCH_SELECTED_STRAIN_REQUEST,
    SET_COLOR_BY,
    SET_BRANCH_NODES,
    FETCH_BRANCH_NODES_REQUEST,
    FETCH_BRANCH_NODES_SUCCESS,
    FETCH_BRANCH_NODES_ERROR
} from '../actions/actionTypes';

// Initial State
let customTreeDataInitialState = {};
export const setCustomTreeDataInitialState = (state) => {
    customTreeDataInitialState = state;
};

export const customTreeDataReducer = (state = customTreeDataInitialState, action) => 
    produce(state, (draft) => {
        switch (action.type) {
            case RESET_SESSION:
            case SIGNOUT_REQUEST: {
                return customTreeDataInitialState;
            }

            case FETCH_CUSTOM_TREE_ATTRS_REQUEST: {
                const { colorBy, branchNodes } = action.payload;
                if (colorBy) draft.status[colorBy] = 'loading';
                if (branchNodes) branchNodes.forEach(k => { 
                    draft.status[k] = 'loading'; 
                });
                break;
            }

            case FETCH_CUSTOM_TREE_ATTRS_SUCCESS: {
                const { customTreeAttrData, colorBy, branchNodes } = action.payload;

                if (!draft.customTreeAttrs) draft.customTreeAttrs = {};

                // Merge customTreeAttrData into draft.customTreeAttrs
                Object.assign(draft.customTreeAttrs, customTreeAttrData);
            
                // Handle status
                if (!draft.status) draft.status = {};
            
                if (colorBy) draft.status[colorBy] = 'loaded';
                if (branchNodes) branchNodes.forEach((k) => { draft.status[k] = 'loaded'; });
                
                break;
            }


            case FETCH_BRANCH_NODES_REQUEST: {
                const { branchNodes } = action.payload;
                if (branchNodes) branchNodes.forEach(k => { draft.status[k] = 'loading'; });
                break;
            }

            case FETCH_BRANCH_NODES_SUCCESS: {
                const { branchNodesData, branchNodes } = action.payload;

                if (!draft.customTreeAttrs) draft.customTreeAttrs = {};
                // Merge customTreeAttrData into draft.customTreeAttrs
                Object.assign(draft.customTreeAttrs, branchNodesData);
                if (branchNodes)
                    branchNodes.forEach(k => { draft.status[k] = 'loaded'; });
                break;
            }


            case FETCH_CUSTOM_TREE_ATTRS_ERROR: {
                const { colorBy } = action.payload;
                if (colorBy) draft.status[colorBy] = 'error';
                break;
            }

            case FETCH_BRANCH_NODES_ERROR: {
                const { branchNodes } = action.payload;
                if (branchNodes) branchNodes.forEach(k => { draft.status[k] = 'error'; });
                break;
            }

            case INIT_STRAIN_TREE_REQUEST:
            case FETCH_SELECTED_STRAIN_REQUEST:
            case ZOOM_TREE_REQUEST:
            case FETCH_VP_VALUES_REQUEST:
            case FETCH_SUBSET_TREE_REQUEST:
            case FETCH_RECALCULATED_TREE_REQUEST: {
                Object.keys(draft.status).forEach((attr) => {
                    draft.status[attr] = 'refetchNeeded';
                });
                break;
            }

            case FETCH_TCELL_ANTIGENICITY_SCORES_REQUEST: {
                const { gene, hla } = action.payload;
                if (gene && hla) draft.tcellStatus[`${gene}_${hla}`] = 'loading';
                break;
            }

            case FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS: {
                if (emptyObject(action.payload)) break;
                
                const { gene, hla, antigenicityScores, antigenicityScoresBins } = action.payload;

                // Update tcellStatus
                if (gene && hla) {
                    if (!draft.tcellStatus) draft.tcellStatus = {};
                    draft.tcellStatus[`${gene}_${hla}`] = 'loaded';
                }

                // Update tcellAntigenicityScores
                if (!draft.tcellAntigenicityScores) draft.tcellAntigenicityScores = {};
                draft.tcellAntigenicityScores[`${gene}_${hla}`] = antigenicityScores;
     

                // Update tcellAntigenicityScoresBins if available
                if (antigenicityScoresBins) {
                    if (!draft.tcellAntigenicityScoresBins) draft.tcellAntigenicityScoresBins = {};
                    draft.tcellAntigenicityScoresBins[`${gene}_${hla}`] = antigenicityScoresBins;
                }
                break;
            }
            // case INIT_STRAIN_TREE_SUCCESS:
            case FETCH_FREQUENCIES_SUCCESS: {
       
                const { customTreeAttrData, gene, hla, antigenicityScores, antigenicityScoresBins } = action.payload;

                if (!draft.customTreeAttrs) draft.customTreeAttrs = {};

                // Merge customTreeAttrData into draft.customTreeAttrs
                Object.assign(draft.customTreeAttrs, customTreeAttrData);
    
                if (customTreeAttrData) Object.keys(customTreeAttrData).forEach(k => { draft.status[k] = 'loaded'; });

               
                if (gene && hla) draft.tcellStatus[`${gene}_${hla}`] = 'loaded';

                if (!draft.tcellAntigenicityScores) draft.tcellAntigenicityScores = {};
                Object.assign(draft.tcellAntigenicityScores, antigenicityScores);

                if (!draft.tcellAntigenicityScoresBins) draft.tcellAntigenicityScoresBins = {};
                Object.assign(draft.tcellAntigenicityScoresBins, antigenicityScoresBins);
                break;
            }

            case FETCH_TCELL_ANTIGENICITY_SCORES_ERROR: {
                draft.tcellAntigenicityScoresStatus = 'error';
                break;
            }

            case SET_COLOR_BY: {
                const { colorBy } = action.payload;

                if (draft.customTreeAttrs) {
                    delete draft.customTreeAttrs[colorBy];
                }
                if (draft.status) {
                    delete draft.status[colorBy];
                }
                break;
            }


            case SET_BRANCH_NODES: {
                const { branchNodes } = action.payload;
            
                if (branchNodes) {
                    branchNodes.forEach((customTreeAttrId) => {
                        if (draft.customTreeAttrs) {
                            delete draft.customTreeAttrs[customTreeAttrId];
                        }
                        if (draft.status) {
                            delete draft.status[customTreeAttrId];
                        }
                    });
                }
                break;
            }

            default:
                break;
        }
    });

