export const UploadIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        style={{ paddingRight: "5px" }}
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 11.2499C9.14918 11.2499 9.29226 11.1906 9.39775 11.0852C9.50324 10.9797 9.5625 10.8366 9.5625 10.6874V4.17028L11.9767 6.58566C12.029 6.63796 12.0911 6.67944 12.1595 6.70775C12.2278 6.73605 12.301 6.75062 12.375 6.75062C12.449 6.75062 12.5222 6.73605 12.5905 6.70775C12.6589 6.67944 12.721 6.63796 12.7733 6.58566C12.8255 6.53336 12.867 6.47127 12.8953 6.40294C12.9236 6.33461 12.9382 6.26137 12.9382 6.18741C12.9382 6.11345 12.9236 6.04021 12.8953 5.97188C12.867 5.90354 12.8255 5.84146 12.7733 5.78916L9.39825 2.41416C9.346 2.36177 9.28393 2.32021 9.21559 2.29186C9.14725 2.2635 9.07399 2.2489 9 2.2489C8.92601 2.2489 8.85275 2.2635 8.78441 2.29186C8.71607 2.32021 8.654 2.36177 8.60175 2.41416L5.22675 5.78916C5.12113 5.89478 5.06179 6.03803 5.06179 6.18741C5.06179 6.33678 5.12113 6.48003 5.22675 6.58566C5.33237 6.69128 5.47563 6.75062 5.625 6.75062C5.77437 6.75062 5.91763 6.69128 6.02325 6.58566L8.4375 4.17028V10.6874C8.4375 10.8366 8.49676 10.9797 8.60225 11.0852C8.70774 11.1906 8.85082 11.2499 9 11.2499ZM1.125 14.0624C1.125 13.9132 1.18426 13.7701 1.28975 13.6647C1.39524 13.5592 1.53832 13.4999 1.6875 13.4999H16.3125C16.4617 13.4999 16.6048 13.5592 16.7102 13.6647C16.8157 13.7701 16.875 13.9132 16.875 14.0624C16.875 14.2116 16.8157 14.3547 16.7102 14.4602C16.6048 14.5656 16.4617 14.6249 16.3125 14.6249H1.6875C1.53832 14.6249 1.39524 14.5656 1.28975 14.4602C1.18426 14.3547 1.125 14.2116 1.125 14.0624Z"
            fill="black"
        />
    </svg>
)