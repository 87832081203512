// React and Redux
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Material UI
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';

// Components
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import ExportableComponent from '../Export/ExportableComponent';
import VaccinesLegend from '../../components/ColorLegend/Legends/VaccinesLegend';
import PointInfo from '../../components/Charts/pointInfo/PointInfo';
import VaccinesChart from '../../components/Vaccines/VaccinesChart';
import VaccinesOptions from '../../components/Vaccines/options/VaccinesOptions';

// Actions
import { fetchClades } from '../../redux/actions/cladeActions';
import { fetchVaccineAntigenicCladeFrequencies, fetchVaccineAntigenicCladePredictions, fetchVaccineDefaultSelections, fetchVaccineProtectionValues } from '../../redux/actions/vaccinesActions';

// Constants and Styles
import { RENDER_STATUS } from '../../config/consts';
import { styles } from './styles';
import { dynamicStyles, useWindowSize } from '../../assets/GlobalStyles/dynamicStyles';
import { getIsMobile } from '../../redux/selectors/statusSelector';
import { shouldFetch } from '../../functions/functions';

const useStyles = makeStyles(styles);

const VaccinesSidebar = ({className, isMobile=false, intro=false}) => (
    <Grid size={isMobile ? 12 : undefined} className={className}>
        <PointInfo />
        <VaccinesOptions intro={intro} />
    </Grid>
);

const VaccinesSection = ({ className }) => {

    return (
        <Grid flex={1} className={className}>
            <ExportableComponent filename="vaccines">
                <VaccinesChart />
            </ExportableComponent>
        </Grid>
    );
};

const Vaccines = ({
    intro = false,
    lineage,
    strainSubset, vaccinesTrackedProtectionDate, vaccinesPredictedProtectionDate,
    vaccinesModel,
    lineageStatus,
    renderStatus,
    cladesStatus,
    vaccinesFrequenciesStatus,
    vaccinesPredictionsStatus,
    exportMode,
    menuRight = true,
    hiddenMenu,
    hiddenMenuMobile,
    isMobile,
    fetchVaccineProtectionValues,
    fetchVaccineDefaultSelections,
    fetchClades,
    fetchVaccineAntigenicCladeFrequencies,
    fetchVaccineAntigenicCladePredictions,
    vaccinesDataStatus,
    vaccinesDefaultsStatus
}) => {
    const classes = useStyles();
    const { width } = useWindowSize();

    const exportDone = exportMode === true && 
        renderStatus === RENDER_STATUS.DONE &&
        vaccinesDataStatus === 'loaded' && 
        vaccinesDefaultsStatus === 'loaded' &&
        vaccinesFrequenciesStatus === 'loaded' &&
        vaccinesPredictionsStatus === 'loaded';

    // console.log('[Vaccines] exportMode', { exportMode, renderStatus, vaccinesDataStatus, vaccinesDefaultsStatus, vaccinesFrequenciesStatus, vaccinesPredictionsStatus, exportDone });

    useEffect(() => {
        if (lineageStatus !== 'loaded') return;
        if (shouldFetch(vaccinesDataStatus)) {
            fetchVaccineProtectionValues(lineage);
        }
        if (shouldFetch(vaccinesDefaultsStatus)) {
            fetchVaccineDefaultSelections(lineage);
        }
    }, [lineageStatus, lineage, vaccinesDataStatus, vaccinesDefaultsStatus]);

    useEffect(() => {
        console.log('[Vaccines] lineageStatus', lineageStatus, 'cladesStatus', cladesStatus);
        if (lineageStatus === 'loaded' && shouldFetch(cladesStatus)) {
            fetchClades({lineage});
        }
        if (lineageStatus === 'loaded' && cladesStatus === 'loaded') {
            console.log('[Vaccines] fetchVaccineAntigenicCladeFrequencies', {lineage, strainSubset, vaccinesTrackedProtectionDate});
            if (shouldFetch(vaccinesFrequenciesStatus)) {
                fetchVaccineAntigenicCladeFrequencies({lineage, strainSubset, vaccinesTrackedProtectionDate });
            }
            if (shouldFetch(vaccinesPredictionsStatus) && !vaccinesModel.invalid) {
                console.log('[Vaccines] fetchVaccineAntigenicCladePredictions', {lineage, strainSubset, vaccinesPredictedProtectionDate, vaccinesModel});
                fetchVaccineAntigenicCladePredictions({lineage, strainSubset, vaccinesTrackedProtectionDate, vaccinesPredictedProtectionDate, vaccinesModel});
            }
        }
    }, [cladesStatus, lineageStatus, lineage, fetchClades]);


    
    return (
        <>
            {exportMode ? (
                <div className={classes.rootExport}>
                    <div className={classes.containerExport}>
                        <div className={classes.itemExport}>
                            <VaccinesSection />
                        </div>
                        <div className={classes.legendExport}>
                            <VaccinesLegend />
                        </div>
                    </div>
                </div>
            ) : (
                <div style={dynamicStyles(isMobile).root}>
                    <ErrorAlert />
                    <Grid container className={classes.container}>
                        {isMobile ? (
                            hiddenMenuMobile ? (
                                <VaccinesSidebar isMobile={true} className={classes.cladeSidebar} intro={intro}/>
                            ) : (
                                <VaccinesSection isMobile={true} className={classes.item}/>
                            )
                        ) : (
                            <>
                                {!menuRight && (
                                    <VaccinesSidebar isMobile={false} className={classes.cladeSidebarLeft} intro={intro}/>
                                )}
                                <VaccinesSection isMobile={false} className={classes.item}/>
                                {menuRight && (
                                    <VaccinesSidebar isMobile={false} className={classes.cladeSidebarRight} intro={intro}/>
                                )}
                            </>
                        )}
                    </Grid>
                </div>
            )}
            {exportDone && <div id="exportDone" />}
        </>
    );
};

const mapStateToProps = (state) => ({
    vaccinesDataStatus: state.vaccines.vaccinesDataStatus,
    vaccinesDefaultsStatus: state.vaccines.vaccinesDefaultsStatus,
    exportMode: state.parameters.exportMode,
    lineage: state.parameters.lineage,
    strainSubset: state.parameters.strainSubset,
    vaccinesModel: state.parameters.vaccinesModel,
    vaccinesTrackedProtectionDate: state.parameters.vaccinesTrackedProtectionDate,
    vaccinesPredictedProtectionDate: state.parameters.vaccinesPredictedProtectionDate,
    lineageStatus: state.lineages.lineageStatus,
    cladesStatus: state.cladeData.cladesStatus,
    vaccinesFrequenciesStatus: state.vaccines.vaccinesFrequenciesStatus,
    vaccinesPredictionsStatus: state.vaccines.vaccinesPredictionsStatus,
    menuRight: state.user.menuRight,
    hiddenMenu: state.ui.hiddenMenu,
    hiddenMenuMobile: state.ui.hiddenMenuMobile,
    renderStatus: state.render.renderStatus,
    isMobile: getIsMobile(),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchVaccineProtectionValues,
    fetchVaccineDefaultSelections,
    fetchClades,
    fetchVaccineAntigenicCladeFrequencies,
    fetchVaccineAntigenicCladePredictions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Vaccines);
