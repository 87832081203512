import React from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Divider, Button, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { HideIcon } from '../../../Alerts/styles';
import { styles, dialog } from '../styles';
import DoneIcon from '@mui/icons-material/Done';

const AuthorizeDialog = ({ handleCloseDialog, openDialog, unauthorizedUsers, handleSubmit, deleteUser }) => {
    const classes = styles();

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={dialog}>
            <DialogTitle id="confirm-dialog-title">
                Unauthorized users
            </DialogTitle>

            <DialogContent>
                {
                    unauthorizedUsers && unauthorizedUsers.length > 0 &&
                    <>
                        {unauthorizedUsers.map(user => {
                            return (
                                <div key={user.email}>
                                    <Divider />
                                    <Grid container style={{ margin: '6px 0' }}>
                                        <Grid size={10}>
                                            <span>
                                                {user.email}
                                            </span>
                                        </Grid>
                                        <Grid size={2} >
                                            <IconButton onClick={(e) => handleSubmit(e, user.email)} className={classes.buttonIcon}>
                                                <DoneIcon />
                                            </IconButton>
                                            <IconButton onClick={(e) => deleteUser(e, user.email)} className={classes.buttonIcon}>
                                                <HideIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            );
                        })}
                        <Divider />
                    </>
                }
            </DialogContent>

            <DialogActions>
                <Button style={{ marginRight: '15px' }} className={classes.cancel} onClick={handleCloseDialog}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>);
};

export default AuthorizeDialog;
