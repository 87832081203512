// External imports
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Autocomplete, FormControl } from '@mui/material';

// Internal imports - Components
import { StyledTextField } from '../../assets/GlobalStyles/TextField';
import CustomLabel from '../../assets/GlobalStyles/CustomLabel';

// Internal imports - Redux
import { setParameters } from '../../redux/actions/parametersActions';
import { resetGenotypeStatus, fetchMutationsPositionsDictionary } from '../../redux/actions/genotypeActions';

// Internal imports - Utils
import { emptyObject } from '../../functions/functions';

const styles = () => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'left',
        width: '100%'
    },
    // formControl: {
    //     minWidth: 120,
    //     margin: '0px 0px',
    // },
    formControl: {
        minWidth: 120,
        margin: '8px 0px',
    },
    field: {
        width: '100%',
        margin: '16px 0px 0px 0px',
    },
    autocompleteInput: {
        margin: '0px',
        '&>div': {
            height: '29px'
        }
    }
});

const MutPositionSearch = (props) => {
    const { 
        mutgene, 
        mutPositions,
        mutationsPositionsDictStatus,
        setParameters, 
        resetData, 
        resetGenotypeStatus,
        classes,
        label,
        mutposition
    } = props;

    const geneMutPositions = useMemo(() => {
        const positions = mutPositions[mutgene] || [];
        return positions.length === 0
            ? positions
            : [{ label: '\u00A0', key: '' }, // Non-breaking space
                ...positions.map(key => ({ label: key, key }))];
    }, [mutPositions, mutgene]);

    const [error, setError] = useState(false);
    const [errorTxt, setErrorTxt] = useState();
    const [positionList, setPositionList] = useState(geneMutPositions);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (mutationsPositionsDictStatus !== 'loaded') return;
        preparePositionList();
    }, [mutationsPositionsDictStatus, mutgene]);

    const preparePositionList = () => {
        if (emptyObject(mutPositions)) {
            setError(true);
            setErrorTxt('No mutations for this lineage');
            setDisabled(true);
            return;
        }

        setPositionList(geneMutPositions);
        setDisabled(false);
        setError(false);
        setErrorTxt(null);
    };

    const handleMutPositionChange = async (value) => {
        setParameters({
            mutposition: value?.key && `${value.key}`.length > 0 ? value.key : null,
        });

        resetGenotypeStatus();
        if (resetData) {
            resetData();
        }
    };

    return (
        // <div className={classes.root}>
        <FormControl className={classes.formControl} fullWidth>
            <CustomLabel id={'mutposition'} label={'Position'} />
            <Autocomplete
                id='mutposition'
                className={classes.field}
                options={positionList}
                getOptionLabel={(option) => { return typeof option === 'number' ? `${option}` : `${option.label}`; }}
                isOptionEqualToValue={(option, value) => { return option.key == value; }}
                disabled={disabled}
                value={mutposition}
                onChange={(e, value) => handleMutPositionChange(value)}
                disableClearable
                renderInput={(params) => (
                    <StyledTextField {...params}
                        id='mutposition-input'
                        name='mutposition'
                        className={classes.autocompleteInput}
                        label={label}
                        fullWidth
                        InputProps={{
                            id: 'mutposition',
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
            {error && (<div style={{ color: 'red' }}>{errorTxt}</div>)}
        </FormControl>
        // </div>
    );
};

MutPositionSearch.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string,
        formControl: PropTypes.string,
        field: PropTypes.string,
        autocompleteInput: PropTypes.string
    }),
    label: PropTypes.string,
    mutgene: PropTypes.string,
    mutposition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mutPositions: PropTypes.object,
    mutationsPositionsDictStatus: PropTypes.string,
    setParameters: PropTypes.func,
    resetData: PropTypes.func,
    resetGenotypeStatus: PropTypes.func
};

const mapStateToProps = ({ parameters, genotype }) => ({
    mutposition: parameters.mutposition,
    mutgene: parameters.mutgene,
    mutPositions: genotype.mutPositions,
    mutationsPositionsDictStatus: genotype.mutationsPositionsDictStatus
});

const mapDispatchToProps = {
    setParameters,
    resetGenotypeStatus,
    fetchMutationsPositionsDictionary
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MutPositionSearch));
