import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, FormControl, CircularProgress, Switch, MenuItem, FormControlLabel, FormGroup } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DialogSX, styles } from '../../../../pages/SettingsPanel/Styles/dialogStyles';
import CustomLabel from '../../../../assets/GlobalStyles/CustomLabel';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import CustomSelect from '../../../../assets/GlobalStyles/CustomSelect';
import JSZip from 'jszip';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import CheckboxInput from '../../../../components/Common/CheckboxInput';

const dialogInitState = {
    key: '',
    label: '',
    lineage: '',
    rule: '',
    highlightAll: false,
    showAll: false,
};

const SubsetDialog = ({ 
    openDialog, 
    handleCloseDialog, 
    lineages, 
    addNewSubsetFile, 
    addNewSubsetRule,
    oldSubset,
    isEdit = false,
    submit
}) => {
    const [subset, setSubset] = useState(dialogInitState);
    const [isRule, setIsRule] = useState(true);
    const [file, setFile] = useState([]);
    const [dataFileUploaded, setDataFileUploaded] = useState('none');
    const classes = styles();

    useEffect(() => {
        if (!isEdit)
            return;

        if (oldSubset.rule === 'from file')
            setIsRule(false);

        const newState = {
            key: oldSubset.key,
            label: oldSubset.label,
            highlightAll: oldSubset.highlightAll === 'true' ? true : false,
            showAll: oldSubset.showAll === 'true' ? true : false
        };
        setSubset(newState);
    }, [oldSubset]);

    const handleChangeSwitch = () => setIsRule(!isRule);
    const handleChange = (name) => (value) => setSubset({ ...subset, [name]: value });
    const handleCheckboxChange = type => (checked) => setSubset({ ...subset, [type]: checked });
   
    const handleDataFileChange = async (e) => {
        setDataFileUploaded('loading');
        const zip = new JSZip();
        const selectedFile = e.target.files[0];

        const data = await selectedFile.arrayBuffer();
        zip.file(selectedFile.name, data, { binary: true });

        const zippedFile = await zip.generateAsync({ type: 'blob' });
        setDataFileUploaded('done');
        setFile(zippedFile);
    };

    const handleSubmit = async () => {
        if (isEdit) {
            await submit(subset);
        } else {
            if (isRule) {
                await addNewSubsetRule(subset);
            } else {
                await addNewSubsetFile(subset, file);
                setFile([]);
                setDataFileUploaded('none');
                setIsRule(true);
            }
        }
        setSubset(dialogInitState);
        handleCloseDialog();
    };

    const xs = 12;
    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={DialogSX}>
            <DialogTitle id="confirm-dialog-title">
                {isEdit ? 'Edit subset:' : 'Add subset:'}
            </DialogTitle>

            <DialogContent style={{ height: '450px', width: 400, overflow: 'overlay' }}>
                {!isEdit && (
                    <FormGroup className={classes.switch}>
                        <FormControlLabel
                            control={
                                <Switch color="secondary"
                                    classes={{
                                        track: classes.switch_track,
                                        switchBase: classes.switch_base,
                                    }}
                                    onChange={handleChangeSwitch}
                                    checked={isRule}
                                />}
                            label={isRule ? 'Based on rule' : 'Based on file'}
                        />
                    </FormGroup>
                )}
                {!isRule &&
                    <div style={{ marginTop: '5px', marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                        <label htmlFor="dataFileInput" className={classes.dataButton}>
                            Upload data file
                        </label>
                        <input
                            id="dataFileInput"
                            type="file"
                            accept=".json"
                            onChange={handleDataFileChange}
                            style={{ display: 'none' }}
                        />
                        {dataFileUploaded === 'loading' &&
                            <div className={classes.icon}>
                                <CircularProgress size={18} disableShrink />
                            </div>
                        }
                        {dataFileUploaded === 'none' &&
                            <div className={classes.icon}>
                                <CloseIcon style={{ color: 'grey' }} />
                            </div>
                        }
                        {dataFileUploaded === 'done' &&
                            <div className={classes.icon}>
                                <DoneIcon style={{ color: 'green' }} />
                            </div>
                        }
                        {dataFileUploaded !== 'loading' && dataFileUploaded !== 'none' && dataFileUploaded !== 'done' &&
                            <div className={classes.icon}>
                                <CloseIcon style={{ color: 'red' }} />
                            </div>
                        }
                    </div>
                }

                {!isEdit && <FormControl fullWidth className={classes.formControlLineage}>
                    <CustomLabel id={'lineage'} label={'Lineage'} />
                    <CustomSelect
                        value={subset.lineage}
                        onChange={handleChange('lineage')}
                        inputProps={{
                            name: 'lineage',
                            id: 'lineage',
                        }}
                    >
                        <MenuItem key={'empty'} value={'none'} >{'(all)'}</MenuItem>
                        {lineages.length > 0 && lineages.map(option =>
                            (<MenuItem key={option} value={option} >{option}</MenuItem>)
                        )}

                    </CustomSelect>
                </FormControl>}
                <StyledTextField
                    id="key"
                    type="text"
                    label="Key"
                    autoComplete='off'
                    value={subset.key}
                    onChange={handleChange('key')}
                />
                <StyledTextField
                    id="label"
                    type="text"
                    label="Label"
                    autoComplete='off'
                    value={subset.label}
                    onChange={handleChange('label')}
                />
                {isRule &&
                    <StyledTextField
                        id="label"
                        type="text"
                        label="Name regex"
                        autoComplete='off'
                        value={subset.rule}
                        onChange={handleChange('rule')}
                    />
                }
                <Grid container >
                    <Grid size={xs} >
                        <CheckboxInput
                            id="showAll"
                            label="Show all"
                            value={subset.showAll}
                            onChange={handleCheckboxChange('showAll')}
                        />
                    </Grid>
                    <Grid size={xs} >
                        <CheckboxInput 
                            id="highlightAll"
                            label="Highlight all"
                            value={subset.highlightAll}
                            onChange={handleCheckboxChange('highlightAll')}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button className={classes.cancel} onClick={handleCloseDialog}>
                    Cancel
                </Button>
                <Button 
                    className={classes.confirm} 
                    onClick={handleSubmit} 
                    disabled={!isRule && dataFileUploaded !== 'done' && !isEdit}
                >
                    {isEdit ? 'Update' : 'Save'}
                </Button>
            </DialogActions>

        </Dialog>
    );
};

export default SubsetDialog;
