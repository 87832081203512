import React from 'react';

const ArrowVerticalIcon = () => (
    <svg
        style={{ padding: '6px 3px 4px 6px', overflow: 'visible' }}
        width="8"
        height="11"
        viewBox="0 0 8 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.35355 0.646446C4.15829 0.451184 3.84171 0.451184 3.64645 0.646446L0.464466 3.82843C0.269204 4.02369 0.269204 4.34027 0.464466 4.53553C0.659728 4.7308 0.976311 4.7308 1.17157 4.53553L4 1.70711L6.82843 4.53553C7.02369 4.7308 7.34027 4.7308 7.53553 4.53553C7.7308 4.34027 7.7308 4.02369 7.53553 3.82843L4.35355 0.646446ZM4.5 11L4.5 1L3.5 1L3.5 11L4.5 11Z"
            fill="#888888"
        />
    </svg>
);

const BracketIcon = (props) => (
    <svg
        style={{ position: 'absolute', marginLeft: '67px', marginTop: '-16px' }}
        onClick={props.switchBracket}
        width="6"
        height="26"
        viewBox="0 0 6 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1 1H4.05634C4.60862 1 5.05634 1.44772 5.05634 2V24C5.05634 24.5523 4.60862 25 4.05634 25H1"
            stroke={props.isBraceOn === true ? 'black' : '#888888'}
            strokeLinecap="round"
        />
    </svg>
);


const ArrowHorizontalIcon = () => (
    <svg
        style={{ padding: '0px 3px 2px 6px', overflow: 'visible' }}
        width="8"
        height="11"
        viewBox="0 0 8 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.35355 4.35355C8.54882 4.15829 8.54882 3.84171 8.35355 3.64645L5.17157 0.464466C4.97631 0.269204 4.65973 0.269204 4.46447 0.464466C4.2692 0.659728 4.2692 0.976311 4.46447 1.17157L7.29289 4L4.46447 6.82843C4.2692 7.02369 4.2692 7.34027 4.46447 7.53553C4.65973 7.7308 4.97631 7.7308 5.17157 7.53553L8.35355 4.35355ZM0 4.5L8 4.5V3.5L0 3.5L0 4.5Z"
            fill="#888888"
        />
    </svg>
);

const MinusIcon = (props) => (
    <svg
        style={{ padding: props.styleM, overflow: 'visible' }}
        viewBox="0 0 13 13"
        width="13"
        height="13"
        fill="none"
        onClick={() => props.updateAxes(props.type, 'minus', props.isBraceOn)}
    >
        <circle cx="6.5" cy="6.5" r="6" stroke="#888888" />
        <line x1="3.5" y1="6.5" x2="9.5" y2="6.5" stroke="#888888" strokeLinecap="round" />
    </svg>
);

const MinusIconMobile = () => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="14.5" stroke="#888888"/>
        <line x1="7.77148" y1="15.6852" x2="22.29" y2="15.6852" stroke="#888888" strokeLinecap="round"/>
    </svg>
);

const PlusIcon = (props) => (
    <svg
        style={{ padding: props.styleP, overflow: 'visible' }}
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => props.updateAxes(props.type, 'plus', props.isBraceOn)}
    >
        <circle cx="6.5" cy="6.5" r="6" stroke="#888888" />
        <line x1="3.5" y1="6.5" x2="9.5" y2="6.5" stroke="#888888" strokeLinecap="round" />
        <line x1="6.5" y1="3.5" x2="6.5" y2="9.5" stroke="#888888" strokeLinecap="round" />
    </svg>
);

const PlusIconMobile = () => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="14.5" stroke="#888888"/>
        <path d="M15 7.5L15 22.0186" stroke="#888888" strokeLinecap="round"/>
        <path d="M22.5195 15L8.00097 15" stroke="#888888" strokeLinecap="round"/>
    </svg>
);

export {
    ArrowVerticalIcon,
    BracketIcon,
    ArrowHorizontalIcon,
    MinusIcon,
    MinusIconMobile,
    PlusIcon,
    PlusIconMobile
};
