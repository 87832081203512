import { makeStyles } from '@mui/styles';

export const styles = makeStyles(() =>({
    colorButton: {
        marginRight: '20px',
        width: '30px',
        height: '30px',
        borderRadius: '5px',
        boxShadow: '1px 1px #e6e6e6'
    },
    colorButtonMutations: {
        marginRight: '20px',
        width: '20px',
        height: '20px',
        borderRadius: '5px',
        boxShadow: '1px 1px #e6e6e6'
    },
    draggingListItem: {
        background: 'rgb(235,235,235)',
        paddingLeft: '0'
    },
    listItems: {
        paddingLeft: '0'
    },
    colorsGridLinear: {
        padding: '5px'
    },
    colorsGrid: {
        marginLeft: '5px',
        paddingTop: '25px'
    },
    domain: {
        overflowY: 'hidden',
        overflowX: 'hidden'
    },
    formControlAdd: {
        marginRight: 0,
        verticalAlign: 'top'
    },
    newRoleGrid: {
        paddingLeft: '5px',
        marginTop: '8px',
        marginBottom: '5px',
    },
    removeLinear: {
        marginLeft: '3px',
        marginTop: '0px'
    },
    removeDiscrete: {
        marginLeft: '8px',
        marginTop: '0px'
    },
    empty: {
        marginLeft: '15px'
    },
    addMargin: {
        marginLeft: '15px'
    },
    measurePicker: {
        marginTop: '16px',
        width: 'calc(100% - 20px)',
        padding: '16px 10px, 0px, 10px'
    },
}));
