import { select, selectAll } from 'd3-selection';

export const handleMouseEnter = (_id, chartLayout) => {
    const id = `${_id}`.replace(/\./g, '\\.').replace(/\*/g, '\\*');

   
    if (chartLayout === 'stacked') {
        select(`#freqStacked_${id}`).style('opacity', 0.8);
        selectAll(`[id^='predStacked_${id}']`).style('opacity', 0.6);
    } else {
        select(`#freq_${id}`).style('stroke-width', 6);
        selectAll(`[id^='pred_${id}_']`).style('stroke-width', 6);
    }
};

export const handleMouseLeave = (_id, chartLayout) => {
    // console.log('[handleMouseLeave]', selectAll('.freqLayers'));
    const id = `${_id}`.replace(/\./g, '\\.').replace(/\*/g, '\\*');
    if (chartLayout === 'stacked') {
        select(`#freqStacked_${id}`).style('opacity', 0.9);
        selectAll(`#predStacked_${id}`).style('opacity', 0.7);
    } else {
  
        select(`#freq_${id}`).style('stroke-width', 3);
        selectAll(`[id^='pred_${id}_']`).style('stroke-width', 3);
    }
};

export const handleMouseLeaveAll = (chartLayout) => {
    if (chartLayout === 'stacked') {
        selectAll(`.freqStackedLayers`).style('opacity', 0.9);
        selectAll(`.predStackedLayers`).style('opacity', 0.7);
    } else {
        selectAll('.freqLayers').style('stroke-width', 3);
        selectAll('.predLayers').style('stroke-width', 3);
    }
};

export const splitDataToChunks = (data, collSize) => {
    Object.defineProperty(Array.prototype, 'chunk', {
        writable: false,
        configurable: true,
        value: function (chunkSize) {
            const R = [];
            for (let i = 0; i < this.length; i += chunkSize)
                R.push(this.slice(i, i + chunkSize));
            return R;
        },
    });

    const chunkSize = Math.ceil(data.length / collSize);
    return data.chunk(chunkSize);
};
