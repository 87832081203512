
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setMutationClasses } from '../../redux/actions/treeDataActions';
import { toggleMutationClassLabelVisibility } from '../../redux/actions/parametersActions';

const MutClassIcon = (props) => {
    const { mutclass, visibleMutationClasses, setMutationClasses,
        toggleMutationClassLabelVisibility, mutationsGroup, mutationClasses,
        visibleMutationClassesLabels, intro } = props;

    useEffect(() => {
        const classIndex = mutationClasses.findIndex((element) => element.key === mutclass);
        const color = mutationClasses[classIndex].color;
        const star = document.querySelector(`#${mutclass}_symbol > path`);

        if (!visibleMutationClasses[mutationsGroup][mutclass]) {
            if (visibleMutationClassesLabels[mutclass])
                toggleMutationClassLabelVisibility(mutclass);
            star.setAttribute('fill', 'white');
            star.setAttribute('stroke-width', '2px');
        }
        else {
            star.setAttribute('fill', color);
            star.setAttribute('stroke-width', '1px');
        }
    });

    const handleClick = () => {
        if (intro)
            return;

        setMutationClasses({ mutType: mutationsGroup, mutClass: mutclass });
    };

    return (
        <svg onClick={handleClick} id={`${mutclass}_symbol`} height='26px' width='26px' />
    );
};

const mapStateToProps = ({ parameters }) => (
    {
        visibleMutationClasses: parameters.visibleMutationClasses,
        visibleMutationClassesLabels: parameters.visibleMutationClassesLabels,
        mutationsGroup: parameters.mutationsGroup,
        intro: parameters.intro
    }
);

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setMutationClasses,
            toggleMutationClassLabelVisibility
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(MutClassIcon);
