import { ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';

import {
    SIGNIN_REQUEST,
    SIGNOUT_REQUEST,
    GET_SIGNED_USER_REQUEST,
} from '../actions/actionTypes';
import {
    signInError,
    signInSuccess,
    signOutError,
    signOutSuccess,
    getSignedUserSuccess,
    getSignedUserError
} from '../actions/userActions';
import { prepareUrl } from '../../functions/functions';
import config from '../../config/envConfig';
import { fetchAxios, postAxios } from '../../functions/axiosRequests';

import { from } from 'rxjs';
import { handleErrors } from '../operators/error';

export const signinEpic = (action$) => action$.pipe(
    ofType(SIGNIN_REQUEST),
    mergeMap(action => {
        const url = `${config.serverLink}/api/auth/signin/`;
        const { email, password } = action.payload;
        const body = {
            email,
            password
        };
        return from(postAxios(url, body))
            .pipe(
                map(response => {
                    const { user, token } = response.data;
                    // auth.authenticate(token, () => dep.history.push(`${config.frontendPrefix}/`));
                 
                    return signInSuccess({ user, token });
                }),
                handleErrors(signInError, 'An error has occurred during signin')
            );
    })
);

export const signOutEpic = (action$) => action$.pipe(
    ofType(SIGNOUT_REQUEST),
    mergeMap(() => {
        const url = `${config.serverLink}/api/auth/signout`;
        
        return from(fetchAxios(url))
            .pipe(
                map(() => signOutSuccess({ email: null })),
                handleErrors(signOutError, 'An error has occurred during signout')
            );
    })
);

export const getSignedUserEpic = (action$) => action$.pipe(
    ofType(GET_SIGNED_USER_REQUEST),
    mergeMap(() => {
        const url = prepareUrl(`${config.serverLink}/api/auth/signedUser`);
        return from(fetchAxios(url))
            .pipe(
                map(response => {
                    const { user } = response.data;
                    if (response.status === 299 || user === '' || !user) {
                        return signOutSuccess({ email: null });
                    }
                    else return getSignedUserSuccess({ user });
                }),
    
                handleErrors(getSignedUserError, 'An error has occurred during getting user data')
            );
    })
);


