export const prepareGroupingExpressions = (uniqueGroups, paramId) => {
    const groupsExps = uniqueGroups.reduce((acc, groupdId) => {
        acc[groupdId] = (['==', ['get', paramId], groupdId]);
        return acc;
    }, {});

    const expressions = groupsExps ? Object.entries(groupsExps) : [];
    return expressions.reduce((acc, [id, exp], index) => {
        
        acc.groupsCounts[id] = (['+', ['case', exp, ['get', 'count'], 0]]);
        acc.groupsMultiplicity[id]= (['+', ['case', exp, ['get', 'multiplicity'] , 0]]);

        if (index !== expressions.length) {
            acc.groupsColors.push(exp);
        };
        acc.groupsColors.push(['get', 'color']);

        if (index === expressions.length - 1)
            acc.groupsColors.push(['get', 'color']);

        return acc;
    }, { groupsCounts: {}, groupsColors: [], groupsMultiplicity: {} });

};

export const prepareColorGroupingFromGenotype = (genotypeColors) => {
    const colorVal = Object.values(genotypeColors);
    const colorKey = Object.keys(genotypeColors);
    const colorsGroupsGenotype = [];

    for (let index = 0; index < colorVal.length; index++){
        const rowOne = ['==', ['get','groupId'], colorKey[index]];
        const rowTwo = colorVal[index];

        colorsGroupsGenotype.push(rowOne);
        colorsGroupsGenotype.push(rowTwo);
    }
    colorsGroupsGenotype.push('#000');

    return colorsGroupsGenotype;
};
