import { makeStyles } from '@mui/styles';

export const actionsStyles = makeStyles(() =>({
    button: {
        padding: '0px'
    },
    add: {
        marginLeft: '10px'
    },
}));
