import { ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';
import { fetchAxios } from '../../functions/axiosRequests';
import {
    FETCH_LINEAGES_REQUEST,
    FETCH_LINEAGE_REQUEST,
} from '../actions/actionTypes';
import {
    fetchLineagesSuccess,
    fetchLineagesError,
    fetchLineageSuccess,
    fetchLineageError,
} from '../actions/lineagesActions';
import config from '../../config/envConfig';
import { prepareUrl } from '../../functions/functions';
import { handleErrors } from '../operators/error';
import { from } from 'rxjs';

export const fetchLineages = (action$) => action$.pipe(
    ofType(FETCH_LINEAGES_REQUEST),
    mergeMap(() => {
        const url = prepareUrl(`${config.serverLink}/api/lineages`);
        // console.log(`fetchLineages => url = ${url}`);

        return from(fetchAxios(url))
            .pipe(
                map(response => fetchLineagesSuccess(response.data)),
                handleErrors(fetchLineagesError, 'An error has occurred during downloading lineages')
            );
    })
);

export const fetchLineage = (action$) =>
    action$.pipe(
        ofType(FETCH_LINEAGE_REQUEST),
        mergeMap((action) => {
            const { settings, exportMode, ...otherParams } = action.payload;
            const url = prepareUrl(`${config.serverLink}/api/lineage`, otherParams, exportMode);

            return from(fetchAxios(url))
                .pipe(
                    map((response) => {
                        const { parameters } = response.data;
                        const _parameters = { ...parameters, ...otherParams };
                        return fetchLineageSuccess({ ...response.data, settings, parameters: _parameters });
                    })
                    ,
                    handleErrors(fetchLineageError, 'An error has occurred during downloading lineage data')
                );
        })
    );
