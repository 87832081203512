import { produce } from 'immer';
import {
    FETCH_ANTIGENIC_MODEL_REQUEST,
    FETCH_ANTIGENIC_MODEL_SUCCESS,
    FETCH_ANTIGENIC_MODEL_ERROR,
    FETCH_ANTIGENIC_RAW_MODEL_REQUEST,
    FETCH_ANTIGENIC_RAW_MODEL_SUCCESS,
    FETCH_ANTIGENIC_RAW_MODEL_ERROR,
    FETCH_ANTIGENIC_OBSERVED_DATA_REQUEST,
    FETCH_ANTIGENIC_OBSERVED_DATA_SUCCESS,
    FETCH_ANTIGENIC_OBSERVED_DATA_ERROR,
    SELECT_ANTIGENIC_VALUES,
    RESET_ANTIGENIC_MODEL,
    RESET_ANTIGENIC_CLADES,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    FETCH_CLADES_SUCCESS,
} from '../actions/actionTypes';

let antigenicInitalState = { };
export const setAntigenicInitalState = (state) => {
    antigenicInitalState = state;
};

export const antigenicReducer = (state = antigenicInitalState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case RESET_SESSION:
            case SIGNOUT_REQUEST: {
                return antigenicInitalState;
            }

            case FETCH_CLADES_SUCCESS: {
                const { antigenicClades } = action.payload;
                draft.antigenicClades = antigenicClades;
                draft.antigenicCladesStatus = 'loaded';
                break;
            }

            case FETCH_ANTIGENIC_MODEL_REQUEST: {
                draft.antigenicModelStatus = 'loading';
                break;
            }
            
            case FETCH_ANTIGENIC_MODEL_SUCCESS: {
                const { antigenicModel } = action.payload;
                draft.antigenicModel = antigenicModel;
                draft.antigenicModelStatus = antigenicModel === null ? 'nodata' : 'loaded';
                break;
            }

            case FETCH_ANTIGENIC_MODEL_ERROR: {
                draft.antigenicModelStatus = 'error';
                break;
            }

            case FETCH_ANTIGENIC_OBSERVED_DATA_REQUEST: {
                draft.antigenicObservedDataStatus = 'loading';
                break;
            }
            case FETCH_ANTIGENIC_OBSERVED_DATA_SUCCESS: {
                const { antigenicObservedData, antigenicObservedRefStrains } = action.payload;
                draft.antigenicObservedData = antigenicObservedData;
                draft.antigenicObservedDataStatus = antigenicObservedData === null ? 'nodata' : 'loaded';
                draft.antigenicObservedRefStrains = antigenicObservedRefStrains;
                break;
            }
            case FETCH_ANTIGENIC_OBSERVED_DATA_ERROR: {
                draft.antigenicObservedDataStatus = 'error';
                break;
            }

            case FETCH_ANTIGENIC_RAW_MODEL_REQUEST: {
                draft.rawAntigenicCladeStrains = {};
                draft.rawStrains = {};
                draft.rawAntigenicModel = {};
                draft.antigenicRawModelStatus = 'loading';
                break;
            }
            case FETCH_ANTIGENIC_RAW_MODEL_SUCCESS: {
                const { rawAntigenicCladeStrains, rawAntigenicModel, rawStrains } = action.payload;
                draft.rawAntigenicModel = rawAntigenicModel;
                draft.rawStrains = rawStrains;
                draft.rawAntigenicCladeStrains = rawAntigenicCladeStrains;
                draft.antigenicRawModelStatus = rawAntigenicModel === null ? 'nodata' : 'loaded';
                break;
            }
            case FETCH_ANTIGENIC_RAW_MODEL_ERROR: {
                draft.antigenicRawModelStatus = 'error';
                break;
            }

            case RESET_ANTIGENIC_MODEL: {
                const nodata = action.payload?.nodata;
                draft.antigenicModel = {};
                draft.rawAntigenicCladeStrains = {};
                draft.rawAntigenicModel = {};
                draft.rawStrains = {};
                draft.antigenicModelStatus = nodata ? 'nodata' : 'none';
                draft.antigenicRawModelStatus = nodata ? 'nodata' : 'none';
                draft.antigenicObservedDataStatus = nodata ? 'nodata' : 'none';
                break;
            }
            case RESET_ANTIGENIC_CLADES: {
                draft.antigenicClades = {};
                draft.antigenicCladesStatus = 'none';
                break;
            }

            case SELECT_ANTIGENIC_VALUES: {
                const { alpha, rho } = action.payload;
                draft.alpha = alpha;
                draft.rho = rho;
                break;
            }
            default:
                break;
        }
    });
;
