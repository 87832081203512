import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogActions, Autocomplete, DialogContent, FormControl, MenuItem, Button, FormHelperText } from '@mui/material';
import { styles } from '../styles';
import CustomSelect from '../../../../assets/GlobalStyles/CustomSelect';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import { fetchLineages } from '../../../../redux/actions/lineagesActions';
import appConfig from '../../../../config/appConfig';
import CustomLabel from '../../../../assets/GlobalStyles/CustomLabel';
import SelectInput from '../../../Common/SelectInput';
import { capitalize } from 'lodash';

const errorState = {
    email: {
        status: false,
        message: ''
    },
    username: {
        status: false,
        message: ''
    },
    password: {
        status: false,
        message: ''
    },
    role: {
        status: false,
        message: ''
    }
};
const initialStateUser = {
    email: '',
    username: '',
    password: '',
    role: 'user',
    lineages: [],
    defaultLineage: ''
};

const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const AddUserDialog = (props) => {
    const { handleCloseDialog, openDialog, lineages, fetchLineages, addUser, roles } = props;
    const [user, setUser] = useState(initialStateUser);
    const [error, setError] = useState(errorState);
    const classes = styles();
    const defaultLin = `Application default (${appConfig.default.lineage})`;

    useEffect(() => {
        if (lineages.length === 0)
            fetchLineages();
    }, []);

    const handleChange = (type) => (value) => {
    
        // Update user state
        setUser(prev => ({ ...prev, [type]: value }));

        // Clear error if value exists and there was an error
        if (error[type]?.status && value) {
            setError(prev => ({
                ...prev,
                [type]: { status: false, message: '' }
            }));
        }
    };

    const validate = () => {
        const matchEmail = user.email.match(emailRegex);
        setError({
            username: !user.username ? { status: true, message: 'Username cannot be empty' } : errorState.username,
            email: !user.email ? { status: true, message: 'Email cannot be empty' } :
                !matchEmail ? { status: true, message: 'Email is not valid' } : errorState.email,
            password: !user.password ? { status: true, message: 'Password cannot be empty' } : errorState.password,
            role: !user.role ? { status: true, message: 'Role cannot be empty' } : errorState.role
        });
        const state = !user.username || !user.email || !user.password || !user.role || !matchEmail;
        return state;
    };

    const handleSubmit = () => {
        if (!validate()) {
            if (!lineages.includes(user.defaultLineage)) {
                const newUser = { ...user, defaultLineage: appConfig.default.lineage };
                addUser(newUser);
                setUser(initialStateUser);
                return;
            }
            addUser(user);
            setUser(initialStateUser);
        }
    };

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={{
            '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                    borderRadius: '12px'
                },
            }
        }}>
            <DialogTitle id="confirm-dialog-title">
                Add user
            </DialogTitle>

            <DialogContent>
                <StyledTextField
                    id="username"
                    type="text"
                    label="Username"
                    required
                    autoComplete='off'
                    className={classes.textField}
                    value={user.username}
                    onChange={handleChange('username')}
                    error={error.username.status}
                    helperText={error.username.message}
                />
                <StyledTextField
                    id="email"
                    type="email"
                    label="Email"
                    required
                    className={classes.textField}
                    value={user.email || ''}
                    onChange={handleChange('email')}
                    error={error.email.status}
                    helperText={error.email.message}
                />
                <StyledTextField
                    id="password"
                    type="password"
                    label="Password"
                    autoComplete="new-password"
                    required
                    className={classes.textField}
                    value={user.password}
                    onChange={handleChange('password')}
                    error={error.password.status}
                    helperText={error.password.message}
                />
            
                <SelectInput
                    id='role'
                    label='Permissions'
                    value={user.role}
                    required={true}
                    showRequired={true}
                    onChange={handleChange('role')}
                    options={roles}
                    getOptionValue={(option) => option.name}
                    getOptionLabel={(option) => capitalize(option.name)}
                    error={error.role.status}
                    errorText={error.role.message}
                    className={classes.textField}
                />
            
                <Autocomplete
                    multiple
                    id="lineages"
                    options={lineages}
                    getOptionLabel={(option) => option}
                    value={user.lineages}
                    onChange={(e, v) => setUser({ ...user, lineages: v })}
                    className={classes.autoComplete}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <StyledTextField
                            {...params}
                            label="Available lineages"
                            placeholder={user.lineages.length > 0 ? '' : 'All lineages'}
                        />
                    )}
                />

                <SelectInput
                    id='defaultLineage'
                    label='Default lineage'
                    value={user.defaultLineage || defaultLin}
                    required={true}
                    showRequired={true}
                    onChange={handleChange('defaultLineage')}
                    options={user.lineages.length > 0 ? user.lineages : lineages}
                    getOptionValue={(option) => option}
                    getOptionLabel={(option) => option}
                    className={classes.textField}
                />
            </DialogContent>

            <DialogActions>
                <Button className={classes.cancel}
                    onClick={handleCloseDialog}>Cancel</Button>
                <Button className={classes.confirm} onClick={handleSubmit}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>);
};

const mapStateToProps = state => {
    const lineages = state.lineages.lineages;

    return {
        lineages
    };
};

const mapDispatchToProps = {
    fetchLineages
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserDialog);
