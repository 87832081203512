import React from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const ErrorPage = ({ text, retryInit }) => (
    <div className="error-page">
        <div className="error-page-text">
            <div className="error-page-warning">
                <WarningAmberIcon style={{ paddingRight: '5px', fontSize: 30 }} />
                Something went wrong
            </div>
            <div className="error-page-small-text">
                Reloading Previr app may fix this.
            </div>
            <button onClick={retryInit} className="error-page-button">
                Retry
            </button>
            <div className="error-page-small-text">
                For help visit{' '}
                <a href="https://previr.com/center" className="error-page-link">
                    Previr center
                </a>{' '}
                <br />
                or contact support@hectorlabs.io.
            </div>
            <div className="error-page-small-text">Reason: {text}</div>
        </div>
    </div>
);

export default ErrorPage;
