import React from 'react';
import EditMeasure from '../../../../pages/SettingsPanel/Measures/Actions/EditMeasure';
import DeleteMeasure from '../../../../pages/SettingsPanel/Measures/Actions/DeleteMeasure';
import { TableCell } from '@mui/material';

const MeasuresCells = (props) => {
    const { row, setInfo, setInfoDialog, setAction } = props;

    return (
        <>
            <TableCell component="th" scope="row">
                {row.name}
            </TableCell>
            <TableCell align="left">
                {row.lineage}
            </TableCell>
            <TableCell align="left">
                {row.label}
            </TableCell>
            <TableCell align="left">
                {row.element}
            </TableCell>
            <TableCell align="left">
                {(typeof row.scale === 'string') ? row.scale : '(parameterized)'}
            </TableCell>
            <TableCell align="left">
                <EditMeasure
                    measure={row.measure}
                    setInfo={setInfo}
                    setInfoDialog={setInfoDialog}
                    setAction={setAction}
                />
                {row.custom && (
                    <DeleteMeasure
                        measure={row.measure}
                        setInfoDialog={setInfoDialog}
                        setInfo={setInfo}
                        setAction={setAction}
                    />)}
            </TableCell>
        </>

    );
};

export default MeasuresCells;
