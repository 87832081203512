import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
;
import { numFormat } from '../../functions/formats';

const styles = () => ({
    root: {
        backgroundColor: '#fff',
        paddingTop: '15px',
        paddingBottom: '70px'
    },
    table: {
        borderCollapse: 'collapse',
        wordBreak: 'break-all',
        '& tbody': {
            display: 'table',
        }
    },
    row: {
        fontSize: 15,
        // height: 'auto',
        height: 19,
    },
    cellTitle: {
        wordBreak: 'break-all',
        width: 110,
        maxWidth: 110,
        minWidth: 110,
        border: 'none',
        whiteSpace: 'nowrap',
        verticalAlign: 'top',
        fontFamily: 'Inter Bold',
        lineHeight: '19px',
        padding: 0
    },
    cell: {
        width: '100%',
        maxWidth: '100%',
        fontWeight: 'normal',
        border: 'none',
        lineHeight: '19px',
        verticalAlign: 'top',
        textAlign: 'left',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        padding: 0
    },
});

let rowId = 0;

const createData = (rowName, value) => {
    rowId += 1;
    return { rowId: `${rowId}_${rowName}`, rowName, value };
};

// const addSpaces = (val) => (!emptyObject(val) ? val.replace(/,/g, ', ') : '');


function AntigenicInfo(props) {
    const { classes, clade, refClade, titer } = props;

    rowId = 0;
    const rows = [
        createData('Clade ', clade),
        createData('Reference clade', refClade),
        createData('Titer', numFormat(titer)),
    ];

    return (
        <div className={classes.root}>
            {titer !== null && (
                <Table className={classes.table}>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow className={classes.row} key={row.rowId}>
                                <TableCell className={classes.cell} align="right">
                                    <span className={classes.cellTitle}>
                                        {row.rowName}:&nbsp;
                                    </span>
                                    <span >
                                        {row.value}
                                    </span>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </div>
    );
}

AntigenicInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    antigenicData: PropTypes.shape({
        titer: PropTypes.number,
        clade: PropTypes.string,
        refClade: PropTypes.string,
    }),
};

const mapStateToProps = ({ cladeData }, ownProps) => {
    const { rho, alpha, titer } = ownProps.cellInfo || {};
    return {
        titer,
        clade: cladeData.cladesStatus === 'loaded' && alpha ? cladeData.clades[alpha].label : null,
        refClade: cladeData.cladesStatus === 'loaded' && rho ? cladeData.clades[rho].label : null,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(AntigenicInfo));
