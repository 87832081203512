import { axisBottom, axisLeft } from 'd3-axis';
import { getScale } from '../../../functions/scales';

const foldTicks = [
    {domainStart: 0, domainEnd: 0.33, label: '> 16 fold', color: '#B22222'},
    {domainStart: 0.33, domainEnd: 0.5, label: '< 16 fold', color: '#FF7F50'},
    {domainStart: 0.5, domainEnd: 0.67, label: '< 8 fold', color: '#F0E68C'},
    {domainStart: 0.67, domainEnd: 1, label: '< 4 fold', color: '#FFFACD'},
];

const drawXAxis = (svg, chartName, xScaleName, height, prefix = '') => {
    const xScale = getScale(xScaleName);
    if (!xScale || !height) return;

    // Select or create the axis group
    const xAxisSvg = 
        svg.select(`#${chartName}${prefix}XAxis`)
            .attr('transform', `translate(0,${height})`);

    const xAxis = axisBottom(xScale)
        .tickSize(6)
        .tickValues([0, 0.2, 0.4, 0.6, 0.8, 1]);

    // Apply the axis
    xAxisSvg.call(xAxis);
    
    // // Style the tick lines
    xAxisSvg.selectAll('.tick line')
        // .attr('stroke', '#808080')
        .attr('stroke-width', 0.5);

    // xAxisSvg.selectAll('.domain')    
    //     .attr('stroke', 'red')
    //     .attr('stroke-width', 1);

    // // Style the tick text
    // xAxisSvg.selectAll('.tick text')
    //     .attr('fill', '#000000')
    //     .attr('dy', '1em');
};


const drawXThresholds = (outerSVG, chartName, xScaleName, height) => {


    const xScale = getScale(xScaleName);
    if (!xScale) return;

    const thresholdNode = outerSVG.select(`#${chartName}ThresholdRange`)
        // .attr('transform', `translate(${paddingLeft},0)`)
        .selectAll('g.thresholdRange')
        .data(foldTicks);
    
    const thresholdNodeEnter = thresholdNode.enter().append('g').attr('class', 'thresholdRange');
    
    thresholdNodeEnter.append('rect')
        .attr('class', 'thresholdRange')
        .attr('height', 20)
        .attr('fill', d => d.color);
    
    thresholdNodeEnter.append('text')
        .attr('text-anchor', 'middle')
        .attr('fill', '#000')
        .style('font-size', '10px')
        .style('font-weight', 'bold')
        .text(d => d.label);

    const thresholdNodeUpdate = thresholdNode.merge(thresholdNodeEnter);

    thresholdNodeUpdate.selectAll('rect')
        .attr('x', d => xScale(d.domainStart))
        .attr('y', height-20)
        .attr('width', d => xScale(d.domainEnd) - xScale(d.domainStart));

    thresholdNodeUpdate.selectAll('text')
        .attr('x', d => (xScale(d.domainEnd)+xScale(d.domainStart))/2)
        .attr('y', height-6);
         
    thresholdNodeEnter
        .filter((_d,i,nodes) => i < nodes.length - 1)
        .append('path')
        .attr('stroke-width', 2)
        .attr('stroke-dasharray', '12,12');   
    // Position and size based on the domain


    thresholdNodeUpdate.selectAll('path')
        .attr('d', d =>`M${xScale(d.domainEnd)-1},${0}L${xScale(d.domainEnd)-1},${height-20}`)
        .attr('stroke', d => d.color);

    thresholdNode.exit().remove();
};

const drawYAxis = (svg, chartName, yScaleName, width, height,refStrainsCnt, prefix = '') => {

    const yScale = getScale(yScaleName);

    if (!yScale) return;

    const yAxisGroup = svg.select(`#${chartName}${prefix}YAxis`);
    const yAxis = axisLeft(yScale)
        .tickSize(-width)
        .tickValues(Array.from({ length: refStrainsCnt }, (_, i) => i+0.5))
        .tickFormat(() => '');

    yAxisGroup.call(yAxis);
        
    // Style the grid lines
    yAxisGroup.selectAll('.tick line')
        .attr('stroke', '#808080')
        .attr('stroke-width', 0.5);
        
    // Style the axis line (left border)
    yAxisGroup.selectAll('.domain')
        .attr('d', `M0,0V${yScale.range()[0]}L0,${height}L${width},${height}L${width},0L0,0`)
        .attr('stroke', '#000000')
        .attr('stroke-width', 1);

//     // Add right border
//     yAxisGroup.selectAll('.right-border')
//         .data([null])
//         .join('path')
//         .attr('class', 'right-border')
//         .attr('d', `M${width},0V${yScale.range()[0]}`)
//         .attr('stroke', '#000000')
//         .attr('stroke-width', 1);
};


const drawChartAxes = (svg, chartName, xScaleName, yScaleName, width, height, refStrainsCnt) => {
    drawXAxis(svg, chartName, xScaleName, height);
    drawYAxis(svg, chartName, yScaleName, width, height, refStrainsCnt);
    drawXAxis(svg, chartName, xScaleName, height, 'Diff');
    drawYAxis(svg, chartName, yScaleName, width, height, refStrainsCnt, 'Diff');
    drawXThresholds(svg, chartName, xScaleName, height);
};


export { drawChartAxes };
