// Action Types
import {
    FETCH_FREQUENCIES_REQUEST,
    FETCH_FREQUENCIES_SUCCESS,
    FETCH_FREQUENCIES_ERROR,
    TOGGLE_FREQUENCIES_OPTION,
    RESET_FREQUENCIES,
    RESET_VISIBLE_BINS,
    SELECT_POINT,
    REVERT_SELECTED_FREQUENCIES_BINS,
} from './actionTypes';

import { request, received } from '../baseActions';


export const fetchFrequencies = (payload) => received(FETCH_FREQUENCIES_REQUEST, payload);
export const fetchFrequenciesSuccess = (payload) => received(FETCH_FREQUENCIES_SUCCESS, payload);
export const fetchFrequenciesError = (error) => received(FETCH_FREQUENCIES_ERROR, error);

export const fetchFrequenciesForAllRegions = (payload) => received(FETCH_FREQUENCIES_REQUEST, {...payload, allRegions: true});

export const resetFrequencies = () => request(RESET_FREQUENCIES);

// const resetVisibleBins = (strainSubset, selectedBins) => received(RESET_VISIBLE_BINS, { strainSubset, selectedBins });

export const toggleFrequenciesOption = (optionId) => received(TOGGLE_FREQUENCIES_OPTION, { optionId });
export const revertSelectedFrequenciesBins = () => received(REVERT_SELECTED_FREQUENCIES_BINS);
export const selectPoint = (payload) => received(SELECT_POINT, {...payload});

//export { fetchFrequencies, fetchFrequenciesSuccess, fetchFrequenciesError, fetchFrequenciesForAllRegions, resetFrequencies, toggleFrequenciesOption, selectPoint };
