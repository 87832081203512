import React from 'react';
import Delete from '../../../../pages/SettingsPanel/Regions/Actions/Delete';
import { TableCell } from '@mui/material';
import Edit from '../../../../pages/SettingsPanel/Regions/Actions/Edit';
import { SymbolComponent } from '../../../../pages/SettingsPanel/MutationClasses/Elements/Symbol';

const RegionsCells = (props) => {
    const { row, setInfo, setInfoDialog, setAction, currentLineage } = props;

    return (
        <>
            <TableCell component="th" scope="row">
                {row.key}
            </TableCell>
            <TableCell align="left">
                {row.label}
            </TableCell>
            <TableCell align="left">
                <SymbolComponent color={row.color} shape={'square'} />
            </TableCell>
            <TableCell align="left">
                <Delete
                    lineageRegion={row}
                    currentLineage={currentLineage}
                    setInfo={setInfo}
                    setInfoDialog={setInfoDialog}
                    setAction={setAction}
                />
                <Edit
                    lineageRegion={row}
                    currentLineage={currentLineage}
                    setInfo={setInfo}
                    setInfoDialog={setInfoDialog}
                    setAction={setAction}
                />
            </TableCell>
        </>
    );
};

export default RegionsCells;
