import { produce } from 'immer';
import {
    FETCH_GEO_DATA_REQUEST,
    FETCH_GEO_DATA_SUCCESS,
    FETCH_GEO_DATA_ERROR,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    SET_COLORING,
    SET_PARAMETERS,
    SET_LAYOUT_PARAMETERS,
} from '../actions/actionTypes';


let geomapInitialState = { };
export const setGeomapInitialState = (state) => {
    geomapInitialState = state;
};

export const geoMapReducer = (state = geomapInitialState, action) =>  
    produce(state, (draft) => {
        switch (action.type) {
            case RESET_SESSION:
            case SIGNOUT_REQUEST: {
                return geomapInitialState;
            }

            case FETCH_GEO_DATA_REQUEST: {
                draft.geoMapStatus = 'loading';
                break;
            }

            case FETCH_GEO_DATA_SUCCESS: { 
                const { geojson } = action.payload;
                draft.geoMapStatus = 'loaded';
                draft.geojson = geojson;
                break; 
            }

            case FETCH_GEO_DATA_ERROR: {
                draft.geoMapStatus = 'error';
                break;
            }

            case SET_COLORING: {
                const { coloring } = action.payload;
                draft.coloring = coloring;
                break;
            }
            
            case SET_PARAMETERS: 
            case SET_LAYOUT_PARAMETERS: {
                const { mapParams, mutgene, mutpostition, geoFreqCategory } = action.payload?.parameters || {};
                draft.geoMapStatus = mapParams || mutgene || mutpostition || geoFreqCategory ? draft.geoMapStatus : 'none';
                break;
            }
        
            default:
                break;
        };
    });
