import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, FormControl, Button } from '@mui/material';
import { prepareUrl, pick } from '../../functions/functions';
import config from '../../config/envConfig';

const PDFDialog = (props) => {
    const { pdfLoading } = props;
    return (<Dialog open={pdfLoading}><DialogTitle id="pdf-dialog-title">PDF loading...</DialogTitle></Dialog>);
};

const getFileNameWithParams = (props) => {
    const { parameters } = props;
    const params = pick('lineage', 'modelId', 'regionId')(parameters);
    const paramsString = Object.values(params).join('_');
    return `regionalFrequenciesReport_${paramsString}.pdf`;
};

export const RegionalFreqButton = (props) => {
    const [pdfLoading, setPdfLoading] = useState(false);
    const { parameters, classes } = props;

    const getPDF = async () => {

        // console.log(`[getPDF], ${config.serverLink}`);
        const url = prepareUrl(`${config.serverLink}/api/export/regionalFrequenciesReport`, parameters || {});

        return fetch(url, { headers: { 'Secret': config.REACT_APP_FRONTEND_SECRET_HEADER } }).then(function (response) {
            return response.arrayBuffer();
        }).then(function (buffer) {
            const blob = new Blob([buffer], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            const downloadName = getFileNameWithParams(props);
            link.download = downloadName;
            link.click();
            setPdfLoading(false);
        });
    };

    const savePDF = () => {
        setPdfLoading(true);
        return getPDF();
    };

    return (
        <>
            <PDFDialog pdfLoading={pdfLoading} />
            <FormControl className={classes.formControl}>
                <Button className={classes.button} onClick={() => savePDF()}>
                    Generate report
                </Button>
            </FormControl>
        </>
    );

};

RegionalFreqButton.propTypes = {
    setParameters: PropTypes.func,
    classes: PropTypes.shape({ formControl: PropTypes.string })
};



