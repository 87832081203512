import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AutocompleteInput from '../../Common/AutocompleteInput';
// import config from '../../../config/envConfig';
import { vaccinesRefStrainsOptionsSelector, getAllRefStrainsWithLabAndClade, selectedFerretStrainsSelector } from '../../../redux/selectors/vaccinesSelector';
import { setParameters } from '../../../redux/actions/parametersActions';

const defaultSearchId = 'ferret_refstrains';

const ReferenceStrainsSelector = ({
    label = 'Ferret reference strains',
    searchId = defaultSearchId,
    // lineage,
    vaccinesRefStrains,
    refStrainsNames,
    // treeAttrs,
    initStrainsList,
    setParameters,
    loaded
}) => {
    const handleSelectedStrain = (selectedStrains) => {
        // console.log('[handleSelectedStrain]', {selectedStrains});
        setParameters({vaccinesFerretRefStrains: selectedStrains});
    };

    const initStrainsListWithLab = useMemo(() => {
        const list = (initStrainsList||[]).map(strain => ({
            id: `${strain.refid}_${strain.lab}`,
            name: `${strain.name} (${strain.lab})`,
        })).sort((a, b) => a.name.localeCompare(b.name));
        // console.log('[initStrainsListWithLab]', list);
        return list;
    }, [initStrainsList]);

    // console.log('[ReferenceStrainsSelector] initStrainsListWithLab', initStrainsListWithLab);

    const fetchOptionLabel = useCallback(async (strainLabId) => {
        const [strainId, lab] = strainLabId.split('_');
        const name = refStrainsNames?.[strainId]?.name;
        return `${name} (${lab})`;
    }, [refStrainsNames]);
    
    const getOptionLabelMemo = useCallback((option) => option?.name ?? '', []);
    
    const isOptionEqualToValueMemo = useCallback((option, value) => {
        if (!option || !value) return false;
        //TO DO - check if value is an array in any case
        if (Array.isArray(value)) {
            return value.some(v => option.id === v.id);
        }
        return option.id === value.id;
    }, []);

    if (!loaded) return null;

    return (
        <AutocompleteInput
            id={searchId}
            label={label}
            initialOptions={initStrainsListWithLab}
            getOptionLabel={getOptionLabelMemo}
            isOptionEqualToValue={isOptionEqualToValueMemo}
            valueIds={vaccinesRefStrains}
            onSelect={handleSelectedStrain}
            fetchOptionLabel={fetchOptionLabel}
            shouldFetchOptions={false}
            multiple={true}
            showMinTextLength={false}
        />
    );
};

ReferenceStrainsSelector.propTypes = {
    searchId: PropTypes.string.isRequired,
    lineage: PropTypes.string,
    vaccinesRefStrains: PropTypes.array,
    treeAttrs: PropTypes.object,
    initStrainsList: PropTypes.array,
    setParameters: PropTypes.func.isRequired,
    label: PropTypes.string
};

const mapStateToProps = (state) => {
    // const { lineage } = state.parameters;
    // const searchId = ownProps.searchId || defaultSearchId;
    return {
        // lineage,
        vaccinesRefStrains: selectedFerretStrainsSelector(state), // state.parameters.vaccinesFerretRefStrains,
        refStrainsNames: state.vaccines.vaccinesData.refStrains,
        initStrainsList: getAllRefStrainsWithLabAndClade(state),//vaccinesRefStrainsOptionsSelector(state),
        loaded: state.vaccines.vaccinesDataStatus === 'loaded' && state.vaccines.vaccinesDefaultsStatus === 'loaded',
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setParameters
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceStrainsSelector);
