import React from 'react';
import { Typography, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styles } from './styles';
import CheckboxInput from '../../../../../components/Common/CheckboxInput';


const Checkboxes = ({ measure, handleCheckboxChange }) => {
    const classes = styles();
    const xs = 12;
    
    return (
        <Grid container>
            <Typography className={classes.title}>General</Typography>
            <Divider className={classes.divider} />
            <Grid size={xs}>
                <CheckboxInput
                    id="discrete"
                    label="Discrete"
                    value={measure.discrete}
                    onChange={handleCheckboxChange('discrete')}
                />
            </Grid>
            <Grid size={xs}>
                <CheckboxInput
                    id="numeric"
                    label="Numeric"
                    value={measure.numeric}
                    onChange={handleCheckboxChange('numeric')}
                />
            </Grid>
            {measure.element === 'node' && (
                <>
                    <Typography className={classes.title}>Strain tree</Typography>
                    <Divider className={classes.divider} />
                    <Grid size={xs}>
                        <CheckboxInput
                            id="colorBy"
                            label="Color by"
                            value={measure.colorBy}
                            onChange={handleCheckboxChange('colorBy')}
                        />
                    </Grid>
                    <Grid size={xs}>
                        <CheckboxInput   
                            id="xScale"
                            label="X scale"
                            value={measure.xScale}
                            onChange={handleCheckboxChange('xScale')}
                        />
                    </Grid>
                    <Grid size={xs}>
                        <CheckboxInput
                            id="yScale"
                            label="Y scale"
                            value={measure.yScale}
                            onChange={handleCheckboxChange('yScale')}
                        />
                    </Grid>
                    <Grid size={xs}>
                        <CheckboxInput      
                            id="ignoreStrainCutOffDate"
                            label="Ignore strainCutOffDate"
                            value={measure.ignoreStrainCutOffDate}
                            onChange={handleCheckboxChange('ignoreStrainCutOffDate')}
                        />
                    </Grid>
                    <Typography className={classes.title}>Frequencies</Typography>
                    <Divider className={classes.divider} />
                    <Grid size={xs}>
                        <CheckboxInput
                            id="frequenciesChart"
                            label="Frequencies chart"
                            value={measure.frequenciesChart}
                            onChange={handleCheckboxChange('frequenciesChart')}
                        />
                    </Grid>
                    <Typography className={classes.title}>Geo map</Typography>
                    <Divider className={classes.divider} />
                    <Grid size={xs}>
                        <CheckboxInput   
                            id="geoColorBy"
                            label="Geo map color by"
                            value={measure.geoColorBy}
                            onChange={handleCheckboxChange('geoColorBy')}
                        />
                    </Grid>
                </> 
            )}
        </Grid>
    );
};

export default Checkboxes;  
