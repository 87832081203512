
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';

import { RENDER_STATUS } from '../../../config/consts';
import { setComponentStatus } from '../../../redux/actions/renderActions';
import { getStrainTreeStatus } from '../../../redux/selectors/statusSelector';
import { treeD3 } from '../d3/TreeD3';
import { getBranchNodes } from '../../../redux/selectors/metadataSelector';

const viewToRender = 'strainTree';
const componentId = 'branchNodes';

const BranchNodesLayer = (props) => {
    const _element = useRef();

    // const prevProps = {
    //     loading: usePrevious(props.loading),
    //     layout: usePrevious(props.layout),
    //     branchNodes: usePrevious(props.branchNodes),
    // };

    const { loading, branchNodes, submissionDate, renderStatus, setComponentStatus } = props;



    const startRender = async () => {
        if (renderStatus !== RENDER_STATUS.START) setComponentStatus(viewToRender, componentId, RENDER_STATUS.START);
    };

    const stopRender = () => {
        //if (renderStatus !== RENDER_STATUS.DONE)
        setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE);
    };


    // useEffect(() => {
    //     if (!loading) return;
    //     console.log('useEffect', {renderStatus, loading})
    //     startRender()
    //         .then(() => treeD3.translateBranchNodes())
    //         .then(() => stopRender());
    // }, [loading])

    useEffect(() => {
        if (renderStatus !== RENDER_STATUS.NONE /*|| loading*/) return;
        // console.log('useEffect', {renderStatus, loading})
        startRender()
            .then(() => treeD3.drawBranchNodesLayer(_element.current)) //treeD3.translateBranchNodes())
            .then(() => stopRender());
    }, [renderStatus]);



    useEffect(() => {
        if (loading) return;
        startRender()
            .then(() => treeD3.drawBranchNodesLayer(_element.current))
            .then(() => stopRender());
    }, [loading, branchNodes, submissionDate]);

    return <g id="branchNodes" transform={treeD3.translate(false)} ref={_element} />;
};

BranchNodesLayer.propTypes = {
    layout: PropTypes.string,
    loading: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
    const loading = getStrainTreeStatus(state) || !ownProps.initialized;

    return {
        loading,
        submissionDate: state.parameters.submissionDate,
        renderStatus: state.render.viewToRender ? state.render.viewToRender.components[componentId] : null,
        branchNodes: getBranchNodes(state),
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setComponentStatus,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(BranchNodesLayer);
