// React and Redux
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import DatePickerInput from '../Common/DatePickerInput';

// Redux
import { fetchTreeFreqs, resetTree } from '../../redux/actions/treeDataActions';
import { resetActiveClades } from '../../redux/actions/cladeActions';
import { setParameters } from '../../redux/actions/parametersActions';
import { submissionDateSelector } from '../../redux/selectors/parametersSelector';
import { resetFrequencies } from '../../redux/actions/frequenciesActions';
import { resetPredictions } from '../../redux/actions/predictionsActions';

// Styles
import { styles } from '../Tree/styles';

const SubmissionDatePicker = (props) => {
    const { submissionDate, setParameters, fetchTreeFreqs, resetActiveClades, lineage, predictionBaseline, strainSubset, view, resetFrequencies, resetPredictions, resetTree, subsetBySubmission } = props;
    
    const handleAccept = (date) => {
        setParameters({ 
            submissionDate: date,
            ...(date === null && { subsetBySubmission: false })
        });

        if ( view === 'tree') {
            fetchTreeFreqs({ submissionDate: date, lineage, predictionBaseline, strainSubset, subsetBySubmission: date === null ? false : subsetBySubmission });
            resetActiveClades();
            if (subsetBySubmission) {
                resetTree();
            }
        } else if ( view === 'frequencies') {
            resetFrequencies();
            resetPredictions();
        }
    };

    return (
        <DatePickerInput
            id='submissionDate'
            value={submissionDate}
            label='Submitted before'
            onAccept={handleAccept}
            clearable
        />
    );
};

SubmissionDatePicker.propTypes = {
    submissionDate: PropTypes.instanceOf(Date),
    setParameters: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    submissionDate: submissionDateSelector(state),
    lineage: state.parameters.lineage,
    predictionBaseline: state.parameters.predictionBaseline,
    strainSubset: state.parameters.strainSubset,
    subsetBySubmission: state.parameters.subsetBySubmission,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setParameters,
            fetchTreeFreqs,
            resetActiveClades,
            resetFrequencies,
            resetPredictions,
            resetTree
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionDatePicker);
