import { ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
import config from '../../config/envConfig';
import { fetchAxios } from '../../functions/axiosRequests';
import {
    FETCH_ACTIVE_CLADES_REQUEST,
    FETCH_CLADES_REQUEST,
} from '../actions/actionTypes';
import {
    fetchCladesSuccess,
    fetchCladesError,
    fetchActiveCladesSuccess,
    fetchActiveCladesError,
} from '../actions/cladeActions';
import { prepareUrl } from '../../functions/functions';

import { handleErrors } from '../operators/error';

export const fetchCladesEpic = (action$) => action$.pipe(
    ofType(FETCH_CLADES_REQUEST),
    mergeMap(action => {
        const { lineage } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/clades`, { lineage });
        //console.log(`fetchClades: ${url}`);

        return from(fetchAxios(url))
            .pipe(
                map((response) => fetchCladesSuccess(response.data)),
                handleErrors(fetchCladesError, 'An error has occurred during downloading clades')
            );
    })
);


export const fetchActiveCladesEpic = (action$) => action$.pipe(
    ofType(FETCH_ACTIVE_CLADES_REQUEST),
    mergeMap(action => {
        const { lineage, cladeType, predictionBaseline, cladeActiveDays, submissionDate, strainSubset } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/clades/active`, { lineage, cladeType, predictionBaseline, cladeActiveDays, submissionDate, strainSubset });
        //console.log(`fetchActiveClades: ${url}`);

        return from(fetchAxios(url))
            .pipe(
                map((response) => fetchActiveCladesSuccess(response.data)),
                handleErrors(fetchActiveCladesError, 'An error has occurred during fetching active clades')
            );
    })
);
