import * as React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DraggableListItem from './DraggableListItem';
import { styles } from './styles';

const DraggableList = React.memo(
    ({
        domain,
        newScale,
        handleValueChange,
        removeElement,
        handleColorChange,
        handleQuantileChange,
        addNewElement,
        onEndDrag,
        colorBy
    }) => {
        const classes = styles();
        return (
            <DragDropContext onDragEnd={onEndDrag}>
                <Droppable droppableId="droppable-list">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {domain.map((element, index) => {
                                const last = index === domain.length - 1;
                                const middleLinear =
                                    !newScale.discrete && index !== 0 && !last;

                                return (
                                    <DraggableListItem
                                        className={classes.listItems}
                                        discrete={newScale.discrete}
                                        prevValue={
                                            index === 0
                                                ? 0
                                                : domain[
                                                    !newScale.discrete
                                                        ? 0
                                                        : index - 1
                                                ].value
                                        }
                                        element={element}
                                        index={index}
                                        keyId={`element-${index}`}
                                        key={`el-${index}`}
                                        middleLinear={middleLinear}
                                        itemsLength={domain.length}
                                        newScale={newScale}
                                        handleValueChange={handleValueChange}
                                        handleColorChange={handleColorChange}
                                        handleQuantileChange={
                                            handleQuantileChange
                                        }
                                        addNewElement={addNewElement}
                                        removeElement={removeElement}
                                        lastDiscrete={last && newScale.discrete}
                                        colorBy={colorBy}
                                    />
                                );
                            })}

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
);

DraggableList.displayName = 'DraggableList';
export default DraggableList;
