import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';

// Custom components
import SelectInput from '../Common/SelectInput';

// Redux actions
import { setParameters } from '../../redux/actions/parametersActions';
import { resetFrequencies } from '../../redux/actions/frequenciesActions';

const multiplicityMethodOptions = [ 'regions', /*'nodes',*/ 'none']; // nodes commented out because it's not used

const MultiplicityMethodSelector = (props) => {
    const { multiplicityMethod, setParameters, resetFrequencies } = props;

    const handleChange = value => {
        setParameters({ multiplicityMethod: value });
        resetFrequencies();
    };

    return (
        <>
            {multiplicityMethodOptions.length > 0 &&
                <SelectInput
                    id="multiplicityMethod"
                    label="Multiplicity method"
                    value={multiplicityMethod}
                    onChange={handleChange}
                    options={multiplicityMethodOptions}
                    getOptionValue={el => el}
                    getOptionLabel={el => capitalize(el)}
                />
            }
        </>
    );
};

MultiplicityMethodSelector.propTypes = {
    multiplicityMethod: PropTypes.string.isRequired,
    multiplicityMethodOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    setParameters: PropTypes.func.isRequired,
    resetFrequencies: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    multiplicityMethod: state.parameters.multiplicityMethod,
});

const mapDispatchToProps = dispatch => ({
    setParameters: payload => dispatch(setParameters(payload)),
    resetFrequencies: payload => dispatch(resetFrequencies(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiplicityMethodSelector);
