/* eslint-disable */
import { ofType } from "redux-observable";
import { mergeMap, debounceTime, map } from "rxjs/operators";
import { prepareUrl } from "../../functions/functions";
import config from "../../config/envConfig";
import {
    FETCH_MUTATION_CLASSES_REQUEST,
    FETCH_GENOTYPE_DATA_REQUEST,
    FETCH_MUT_POS_DICT_REQUEST,
    FETCH_MUTATION_GROUP_REQUEST,
} from "../actions/actionTypes";
import {
    fetchMutationClassesSuccess,
    fetchMutationClassesError,
} from "../actions/treeDataActions";
import { fetchAxios } from "../../functions/axiosRequests";
import {
    fetchGenotypeDataSuccess,
    fetchGenotypeDataError,
    fetchMutationsPositionsDictionarySuccess,
    fetchMutationsPositionsDictionaryError,
    fetchMutationGroupValuesSuccess,
    fetchMutationGroupValuesError,
} from "../actions/genotypeActions";

import { from } from "rxjs";
import { handleErrors } from '../operators/error';

export const fetchMutationClassesEpic = (action$) =>
    action$.pipe(
        ofType(FETCH_MUTATION_CLASSES_REQUEST),
        mergeMap((action) => {
            const { settings, ...params } = action.payload;
            const url = prepareUrl( `${config.serverLink}/api/lineage/mutationClasses/`, params);
            // console.log(`fetchMutationClasses => url = ${url}`);
            return from(fetchAxios(url)).pipe(
                map((response) =>  fetchMutationClassesSuccess({...response.data, settings})),
                handleErrors(fetchMutationClassesError, 'An error has occurred during downloading mutation classes')
            );
        })
    );

export const fetchGenotypeDataEpic = (action$) =>
    action$.pipe(
        ofType(FETCH_GENOTYPE_DATA_REQUEST),
        debounceTime(250),
        mergeMap((action) => {
            const { lineage, mutgene, mutposition, zoomNodeId } =
                action.payload;
            const url = prepareUrl(`${config.serverLink}/api/tree/genotype/`, { lineage, mutgene, mutposition, zoomNodeId });
            //console.log(`getGenotypeData => url = ${url}, mutgene: ${mutgene}`);

            return from(fetchAxios(url)).pipe(
                map((response) => fetchGenotypeDataSuccess(response.data)),
                handleErrors(fetchGenotypeDataError, 'An error has occurred during downloading genotype data')
            );
        })
    );

export const fetchMutationsPositionsDictionaryEpic = (action$) =>
    action$.pipe(
        ofType(FETCH_MUT_POS_DICT_REQUEST),
        mergeMap((action) => {
            const { lineage, settings } = action.payload;
            const url = prepareUrl(`${config.serverLink}/api/mutations/positionsDictionary/`, { lineage });
            //console.log(`getMutationsPositionsDictionary => url = ${url}`);

            return from(fetchAxios(url)).pipe(
                map((response) => fetchMutationsPositionsDictionarySuccess({...response.data, settings})),
                handleErrors(fetchMutationsPositionsDictionaryError, 'An error has occurred during downloading mutation positions')
            );
        })
    );

export const fetchMutationsPositionsGroupsValuesEpic = (action$) =>
    action$.pipe(
        ofType(FETCH_MUTATION_GROUP_REQUEST),
        mergeMap((action) => {
            const url = prepareUrl(
                `${config.serverLink}/api/mutations/groups/`,
                action.payload
            );

    return from(fetchAxios(url))
            .pipe(
                map(response => fetchMutationGroupValuesSuccess(response.data)),
                handleErrors(fetchMutationGroupValuesError, 'An error has occurred during downloading mutation positions')
            );
    })
);
