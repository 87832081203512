import React from 'react';
import { Autocomplete, InputAdornment, IconButton } from '@mui/material';
import { HideIcon } from '../../Alerts/styles';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import { styles } from '../styles';

const TableSearchElement = (props) => {
    const { options, name, value, requestSearch, label, clearField } = props;
    const classes = styles();
    
    return (
        <Autocomplete
            freeSolo
            id={name}
            options={options}
            getOptionLabel={(option) => option ? option : ''}
            value={value}
            className={classes.searchAuto}
            onChange={(event, newValue) => requestSearch(newValue, name)}
            inputValue={value}
            onInputChange={(event, newInputValue) => requestSearch(newInputValue, name)}
            renderInput={(params) => (
                <StyledTextField
                    {...params}
                    className={classes.search}
                    label={label}
                    InputProps={{
                        style: { paddingRight: 0 },
                        ...params.InputProps,
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton onClick={() => clearField(name)}>
                                    <HideIcon />
                                </IconButton>
                            </InputAdornment>
                    }}
                />
            )}
        />
    );
};

export default TableSearchElement;
