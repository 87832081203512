// External imports
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

// Internal imports - Components
import SelectInput from '../Common/SelectInput';

// Internal imports - Redux Actions
import { setStrainSearchStatus } from '../../redux/actions/treeDataActions';
import { setColorBy, setParameters, setReferenceStrain } from '../../redux/actions/parametersActions';
import { fetchMeasureScalesDomains } from '../../redux/actions/settingsActions';
import { resetModelData, resetModelTypes } from '../../redux/actions/modelActions';
import { resetHumanSerologyData } from '../../redux/actions/humanSerologyActions';

// Internal imports - Redux Selectors
import { getCustomMeasures, getColorOptions, getGeoMapColorsOptions } from '../../redux/selectors/metadataSelector';

// Internal imports - Config
import appConfig from '../../config/appConfig';

const ColorBySelector = (props) => {
    const {
        // Parameters
        antigenicModelId, antigenicModelStatus, lineage, strainSubset, colorBy,
        gene, geneOptions, mutgene, mutposition, genotypeFilterGenesList,
        hla, hlaOptions, mapSelector,
        // Data
        colorOptions, customMeasures,
        // Actions
        setParameters, setColorBy, setReferenceStrain, fetchMeasureScalesDomains,
        resetHumanSerologyData, resetModelData, resetModelTypes
    } = props;

    const handleColorByChange = colorBy => {
        if (mapSelector){
            setParameters({ geoMapColorBy: colorBy });
            return;
        }
      
        setColorBy({ colorBy });
        if (colorBy !== 'antigenic') setReferenceStrain(null, 'antigenic');
        const mutRegExp = /^(.*)Mutations$/;
        const params = { lineage };

        switch (colorBy) {
            case 'fitness':
            case 'advance':
            case 'flux': {
                resetModelTypes();
                resetModelData();
                break;
            }
            case 'antigenic': {
                params.modelType = 'Antigenic';
                params.modelId = antigenicModelId;
                break;
            }
            case 'ALLMutations':
            case 'NSMutations':
            case 'SMutations': {
                console.log(`[handleColorByChange] mutRegExp`, colorBy);
                const mutationsType = mutRegExp.exec(colorBy)[1];
                params.mutationsType = mutationsType;
                break;
            }
            case 'tcellAntigenicity': {
                params.gene = gene || geneOptions[0];
                params.hla = hla || hlaOptions[0];
                params.colorBy = colorBy;
                break;
            }
            case 'genotype': {
                if (genotypeFilterGenesList.length)
                    params.mutgene = mutgene || genotypeFilterGenesList[0].val;
                break;
            }
            case 'humanSerology': {
                resetHumanSerologyData();
                break;
            }
            default: {
                if (customMeasures[colorBy]) {
                    params.strainSubset = strainSubset;
                }
            }
        }

        setParameters(params);

        if (colorBy !== 'genotype' || (mutgene && mutposition)) {
            fetchMeasureScalesDomains({  
                lineage, 
                colorBy,
                mutgene: (colorBy === 'genotype') ? mutgene : undefined,
                mutposition: (colorBy === 'genotype') ? mutposition : undefined
            });
        };
    };

    const colorByError = (antigenicModelStatus === 'nodata' && colorBy === 'antigenic');

    return (
        <>
            <SelectInput
                id='colorBy'
                label='Color by'
                value={colorBy}
                onChange={handleColorByChange}
                options={colorOptions}
                getOptionValue={option => option.key}
                getOptionLabel={option => option.label}
                error={colorByError}
                errorText={colorByError ? 'No antigenic summary data for selected model' : null}
            />
        </>
    );
};

ColorBySelector.propTypes = {
    antigenicModelId: PropTypes.string,
    antigenicModelStatus: PropTypes.string,
    colorBy: PropTypes.string,
    colorOptions: PropTypes.arrayOf(PropTypes.shape({ 
        key: PropTypes.string, 
        label: PropTypes.string 
    })),
    customMeasures: PropTypes.objectOf(PropTypes.shape({
        key: PropTypes.string,
    })),
    fetchMeasureScalesDomains: PropTypes.func,
    gene: PropTypes.string,
    geneOptions: PropTypes.arrayOf(PropTypes.string),
    genotypeFilterGenesList: PropTypes.array,
    hla: PropTypes.string,
    hlaOptions: PropTypes.arrayOf(PropTypes.string),
    lineage: PropTypes.string,
    mapSelector: PropTypes.bool,
    mutgene: PropTypes.string,
    mutposition: PropTypes.number,
    resetHumanSerologyData: PropTypes.func,
    resetModelData: PropTypes.func,
    resetModelTypes: PropTypes.func,
    setColorBy: PropTypes.func,
    setParameters: PropTypes.func,
    setReferenceStrain: PropTypes.func,
    strainSubset: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
    const { colorOptions } = ownProps.mapSelector ? 
        getGeoMapColorsOptions(state) : 
        getColorOptions(state);
    
    const colorBy = ownProps.mapSelector ? 
        state.parameters.geoMapColorBy : 
        state.parameters.colorBy;
    
    const colorOptionsItems = ownProps.intro ?
        colorOptions.filter(el => appConfig.introColorByOptions.includes(el.key)) : 
        colorOptions;

    return {
        antigenicModelId: state.parameters.antigenicModelId,
        antigenicModelStatus: state.antigenic.antigenicModelStatus,
        colorBy,
        colorOptions: colorOptionsItems,
        customMeasures: getCustomMeasures(state),
        gene: state.parameters.gene,
        geneOptions: state.metadata.tcellAntigenicityOptions.geneOptions,
        genotypeFilterGenesList: state.genotype.genotypeFilterGenesList,
        hla: state.parameters.hla,
        hlaOptions: state.metadata.tcellAntigenicityOptions.hlaOptions,
        lineage: state.parameters.lineage,
        mutgene: state.parameters.mutgene,
        mutposition: +state.parameters.mutposition,
        strainSubset: state.parameters.strainSubset
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchMeasureScalesDomains,
    resetHumanSerologyData,
    resetModelData,
    resetModelTypes,
    setColorBy,
    setParameters,
    setReferenceStrain,
    setStrainSearchStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ColorBySelector);
