import React from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)({
    marginBottom: '15px',
    '& .MuiInputBase-root': {
        fontSize: '14px'
    }
});

const NameLabel = ({ measure, onChange, className }) => {
    return (
        <>
            <StyledTextField
                id='name'
                type="text"
                label="Measure name"
                autoComplete="off"
                className={className}
                value={measure.measure}
                onChange={onChange('name')}
            />

            <StyledTextField
                id="label" 
                type="text"
                label="Label"
                autoComplete="off"
                className={className}
                value={measure.label}
                onChange={onChange('label')}
            />
        </>
    );
};

export default NameLabel;
