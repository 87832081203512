// External imports
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

// Internal imports
import { setParameters } from '../../redux/actions/parametersActions';
import NumberInput from '../Common/NumberInput';

const GeoFreqSpanSelector = ({ caseSpan, setParameters }) => {
    const setCaseSpan = (value) => {
        setParameters({ caseSpan: value });
    };

    return (
        <NumberInput
            id="case-span"
            label="Time window"
            value={caseSpan}
            onAccept={setCaseSpan}
            min={0}
        />
    );
};

GeoFreqSpanSelector.propTypes = {
    caseSpan: PropTypes.number.isRequired,
    setParameters: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    caseSpan: state.parameters.caseSpan
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GeoFreqSpanSelector);
