// Start of Selection
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';

import { resetPredictions } from '../../redux/actions/predictionsActions';
import { setAlertStatus } from '../../redux/actions/alertActions';
import { setParameters } from '../../redux/actions/parametersActions';
import { trackingFromSelector, trackingToSelector } from '../../redux/selectors/parametersSelector';

import DatePickerInput from '../Common/DatePickerInput';
import { styles } from '../Tree/styles';

const TrackingToPicker = ({
    trackingTo,
    trackingFrom,
    intro,
    resetPredictions,
    setParameters,
    setAlertStatus,
}) => {
    const maxTrackingToDate = useMemo(() => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 5);
        return date;
    }, []);
    const now = new Date();

    useEffect(() => {
        const errorStatus = trackingTo > maxTrackingToDate;
        setAlertStatus({
            status: errorStatus,
            model: 'frequencies',
            id: 'trackingToError',
            messageVar: maxTrackingToDate,
        });
    }, [trackingTo, maxTrackingToDate, setAlertStatus]);

    useEffect(() => {
        const errorStatus = trackingTo < trackingFrom;
        setAlertStatus({
            status: errorStatus,
            model: 'frequencies',
            id: 'trackingToTrackingFromError',
            messageVar: trackingFrom,
        });
    }, [trackingTo, trackingFrom, setAlertStatus]);

    const handleAccept = (value) => {
        setParameters({
            trackingTo: value,
            ...(intro && { predictionBaseline: value }),
        });
        resetPredictions();
    };

    return (
        <DatePickerInput
            id='trackingTo'
            value={trackingTo || now}
            label="Tracking to"
            onAccept={handleAccept}
        />
    );
};

TrackingToPicker.propTypes = {
    trackingTo: PropTypes.instanceOf(Date),
    trackingFrom: PropTypes.instanceOf(Date),
    intro: PropTypes.bool,
    setParameters: PropTypes.func.isRequired,
    resetPredictions: PropTypes.func.isRequired,
    setAlertStatus: PropTypes.func.isRequired,
    alertStatus: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const freqAlertsError = state.alert.frequencies.errors;
    const alertStatus = freqAlertsError['trackingToError'];

    return {
        alertStatus,
        trackingTo: trackingToSelector(state),
        trackingFrom: trackingFromSelector(state),
        intro: state.parameters.intro,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setParameters,
            resetPredictions,
            setAlertStatus,
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(TrackingToPicker));
