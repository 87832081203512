import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2';

import { shouldFetch } from '../../functions/functions';
import { RENDER_STATUS, VIEWS_NAMES } from '../../config/consts';
import { RegionalFrequenciesResportType } from './type';

import FrequenciesChart from '../../components/Charts/FrequenciesChart';
import SequencesChart from '../../components/Charts/SequencesChart';


/* test url
http://localhost:3000/export/regionalFrequenciesReport?antigenicDataType=inferred&antigenicModelId=CELL-CELL-ALL-ALL&antigenicTiterType=absolute&chartLayout=nonstacked&cladeFrequencyThreshold=0.05&colorBy=loc&date=44033&displayErrorBars=false&displayGreyZone=true&exportMode=false&freqCategory=predfeinedClade&freqSpan=135&freqsFrom=43677&freqsTo=43921&hiddenAlphaClades=&hiddenRhoClades=&layout=tree&lineage=H1N1p_1yrp&branchNodes=&modelId=CGR&modelType=SEQ&mutationsType=NS&plotType=frequencies&predictionBaseline=43921&regionId=ALL&showColoredNodesOnly=true&showInternalNodes=false&showLeafNumber=5000&showLeaves=true&showCladeLabels=true&showMutations=false&sigmaAg=1&stdDays=45&strainSubset=ALL&trackingFrom=43677&trackingTo=44408&treeScaleTypeX=time&treeScaleTypeY=order&zoomNodeId=48794
*/

export const RegionalFrequenciesReport = props => {
    const { lineageStatus, cladesStatus,
        lineage,
        showPrediction,
        freqCategory,
        trackingFrom,
        trackingTo,
        predictionBaseline,
        fetchFrequenciesForAllRegions,
        renderStatus,
        selectedModels,
        regions, classes, exportMode, frequenciesStatus, 
        cladeType, scaleName,
        fetchClades } = props;


    useEffect(() => {
        if (lineageStatus !== 'loaded') return;
        if (shouldFetch(cladesStatus)) fetchClades({ lineage });
        // initComponentData();
    });

    useEffect(() => {
        if (cladesStatus !== 'loaded') return;
        if (shouldFetch(frequenciesStatus))
            fetchFrequenciesForAllRegions({ lineage, freqCategory, trackingFrom, trackingTo, predictionBaseline,/* gene, hla, binMethod, binCnt,*/ regions, selectedModels, cladeType, scaleName, showPrediction});
    }, [cladesStatus]);


    // const initComponentData = async () => {

        

    //     // console.log(`[initComponentData]: treeDataStatus = ${treeDataStatus}, fetchNeeded: ${shouldFetch(treeDataStatus) || shouldFetch(cladesStatus)}`)
    //     // await Promise.all([
    //     //     (shouldFetch(treeDataStatus) ? initStrainTree({ lineage, modelId, colorBy, freqCategory, zoomNodeId, strainSubset, binMethod, binCnt, vpMethod }) : null),
    //     //     (shouldFetch(cladesStatus) && treeDataStatus === 'loaded'? fetchClades({ lineage, modelId, colorBy, freqCategory, zoomNodeId, strainSubset, binMethod, binCnt, vpMethod }) : null)
    //     // ]);
    //     // // console.log(`[initComponentData]: treeInitialized`)

    //     await initFrequenciesData();
    // }


    // const initFrequenciesData = async () => {

    //     // console.log(`[initFrequenciesData]: treeDataStatus = ${treeDataStatus}, renderStatus = ${renderStatus} => ${treeDataStatus !== 'loaded' || renderStatus === RENDER_STATUS.DONE}`);
    //     if (cladesStatus !== 'loaded' || renderStatus === RENDER_STATUS.DONE) return;
    //     // console.log(`[initFrequenciesData]: fetchingFrequencies`);
    //     fetchFrequenciesForAllRegions({ lineage, freqCategory, trackingFrom, trackingTo, predictionBaseline,/* gene, hla, binMethod, binCnt,*/ regions, selectedModels });
    //     // console.log(`[initFrequenciesData]: frequenciesStatus = ${frequenciesStatus}, modelId = ${modelId}, strainSubset = ${strainSubset}, regionId = ${regionId}`);
    // }


    return (
        <>
            {exportMode && regions && (
                <div className={classes.rootExport}>
                    {
                        Object.keys(regions).map((reg, index) => (
                            <div key={reg}>
                                {index % 4 === 0 && (
                                    <Grid container className={classes.containerTitles}>
                                        <Grid size={2} />
                                        <Grid container size={10}>
                                            <Grid size={4} className={classes.headerTitle}>
                                                Inclusive Clade<br />Frequency&nbsp;Trajectories
                                            </Grid>
                                            <Grid size={4} className={classes.headerTitle}>
                                                Smoothed<br />Sequence&nbsp;Counts
                                            </Grid>
                                            <Grid size={4} className={classes.headerTitle}>
                                                Smoothed<br />Case&nbsp;Counts
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid container style={{ pageBreakAfter: (index + 1) % 4 === 0 ? 'left' : 'avoid' }} className={`no-break ${classes.containerExport}`} key={regions[reg].label}>
                                    <Grid className={classes.regionLabel} size={2}>{regions[reg].label.toLowerCase()}</Grid>
                                    <Grid container size={10}>
                                        <Grid size={4} className={classes.itemExport}>
                                            <FrequenciesChart
                                                showPrediction={showPrediction}
                                                multiexport
                                                regionId={reg}
                                                viewName={VIEWS_NAMES.REGIONAL_FREQ_REPORT}
                                                size="small" />
                                        </Grid>
                                        <Grid size={4} className={classes.itemExport}>
                                            <SequencesChart multiexport regionId={reg} type="seq" viewName={VIEWS_NAMES.REGIONAL_FREQ_REPORT} size="small" />
                                        </Grid>
                                        <Grid size={4} className={classes.itemExport}>
                                            <SequencesChart multiexport regionId={reg} type="case" viewName={VIEWS_NAMES.REGIONAL_FREQ_REPORT} size="small" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        )
                        )}
                </div>
            )
            }
            {
                (exportMode === true && renderStatus === RENDER_STATUS.DONE) && (
                    <div id="exportDone" />
                )
            }
        </>
    );
};


RegionalFrequenciesReport.propTypes = RegionalFrequenciesResportType;


