import React from 'react';


const PlusIcon = () => (
    <svg
        style={{ overflow: 'visible', position: 'relative' }}
        viewBox="0 0 16 16"
        width="16"
        height="16"
        fill="none"
    >
        <circle cx="8" cy="8" r="8" stroke="#888888" />
        <line x1="5" y1="8" x2="11" y2="8" stroke="#888888" strokeLinecap="round" />
        <line x1="8" y1="5" x2="8" y2="11" stroke="#888888" strokeLinecap="round" />
    </svg>
);

const MinusIcon = () => (
    <svg
        style={{ overflow: 'visible', position: 'relative' }}
        viewBox="0 0 16 16"
        width="16"
        height="16"
        fill="none"
    >
        <circle cx="8" cy="8" r="8" stroke="#888888" />
        <line x1="5" y1="8" x2="11" y2="8" stroke="#888888" strokeLinecap="round" />
    </svg>
);

const ValidateIcon = (props) => (
    <svg
        style={{ overflow: 'visible', position: 'relative' }}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
        <circle cx="8" cy="8" r="8" stroke={props.color}/>
        <path d="M3.69238 7.3846L7.38469 11.6923L11.6924 4.92307" stroke={props.color} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export {
    PlusIcon,
    MinusIcon,
    ValidateIcon
};
