import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogActions, DialogContent,
    Button, Tabs, Tab, Box, Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styles, NewRoleDialog } from '../../Users/styles';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import defaults from '../defaults';
import CheckboxInput from '../../../Common/CheckboxInput';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};

const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
}); 

const PermissionsPanel = ({ tabKey, permissions, labels, onChange, disabled }) => {
    const classes = styles();
    return (
        <Grid container>
            {Object.keys(permissions).map(permission => (
                <Grid size={4} key={permission}>
                    <CheckboxInput
                        id={permission}
                        label={labels[permission]}
                        value={permissions[permission]}
                        onChange={onChange(permission, tabKey)}
                        // className={classes.formControlAdd}
                        // labelClassName={classes.formControlLabel}
                        disabled={disabled}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

const tabsData = [
    { key: 'strainTree', label: 'Strain Tree' },
    { key: 'frequencies', label: 'Frequencies' },
    { key: 'antigenic', label: 'Antigenic' },
    { key: 'fitness', label: 'Fitness' },
    { key: 'map', label: 'Map' },
    { key: 'vaccines', label: 'Vaccines' },
];

const getInitState = () => ({
    name: '',
    adminRights: false,
    dataAdminRights: false,
    tabs: {
        strainTree: true,
        frequencies: true,
        antigenic: true,
        fitness: true,
        map: true,
        vaccines: true,
    },
    strainTreePermissions: { ...defaults.strainTreePermissions },
    frequenciesPermissions: { ...defaults.frequenciesPermissions },
    antigenicPermissions: { ...defaults.antigenicPermissions },
    fitnessPermissions: { ...defaults.fitnessPermissions },
    mapPermissions: { ...defaults.mapPermissions },
    vaccinesPermissions: { ...defaults.vaccinesPermissions },
});
  

const mergePermissions = (role) => {
    const mergeDefaults = (key) => ({ ...defaults[key], ...role[key] });
    return {
        ...role,
        antigenicPermissions: mergeDefaults('antigenicPermissions'),
        frequenciesPermissions: mergeDefaults('frequenciesPermissions'),
        mapPermissions: mergeDefaults('mapPermissions'),
        strainTreePermissions: mergeDefaults('strainTreePermissions'),
        fitnessPermissions: mergeDefaults('fitnessPermissions'),
        vaccinesPermissions: mergeDefaults('vaccinesPermissions'),
    };
};

const RoleDialog = ({ handleCloseDialog, openDialog, onSubmit, mode = 'add', role }) => {
    const [permissions, setPermissions] = useState(getInitState());
    const [value, setValue] = useState(0);
    const classes = styles();

    useEffect(() => {
        if (openDialog) {
            if (mode === 'edit' && role) {
                setPermissions(mergePermissions(role));
            } else if (mode === 'add') {
                setPermissions(getInitState());
                setValue(0); // Reset the tab index
            }
        }
    }, [openDialog, mode, role]);
      
      
    const handleTabsChangeFactory = (tabKey) => (checked) => {
        setPermissions(prevState => {
            const updatedPermissions = { ...prevState };
            const permissionsKey = `${tabKey}Permissions`;

            if (!checked) {
                // Set all permissions to false when the tab is deselected
                const permissionKeys = Object.keys(defaults[permissionsKey]);
                const falsePermissions = {};
                permissionKeys.forEach(key => {
                    falsePermissions[key] = false;
                });
                updatedPermissions[permissionsKey] = falsePermissions;
            } else if (!updatedPermissions[permissionsKey]) {
                // Initialize permissions if they don't exist
                updatedPermissions[permissionsKey] = { ...defaults[permissionsKey] };
            }
            return {
                ...updatedPermissions,
                tabs: {
                    ...prevState.tabs,
                    [tabKey]: checked,
                },
            };
        });

        if (!checked && ['antigenic', 'map', 'fitness', 'vaccines'].includes(tabKey)) {
            setValue(0);
        }
    };

    const handleAdminRightsChange = (checked) => {
        setPermissions(prevState => ({
            ...prevState,
            adminRights: checked,
        }));
    };

    const handleDataAdminRightsChange = (checked) => {
        setPermissions(prevState => ({
            ...prevState,
            dataAdminRights: checked,
        }));
    };

    const handlePermissionChangeFactory = (permission, tabKey) => (checked) => {
        const permissionsKey = `${tabKey}Permissions`;
        setPermissions(prevState => ({
            ...prevState,
            [permissionsKey]: {
                ...prevState[permissionsKey],
                [permission]: checked,
            },
        }));
    };

    const handleSubmit = () => {
        onSubmit(permissions);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const dialogTitle = mode === 'edit' ? 'Edit Role' : 'Add New Role';

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={NewRoleDialog}>
            <DialogTitle id="confirm-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent style={{ height: '450px' }}>
                <Grid container >
                    <Grid size={12}>
                        <StyledTextField
                            id="name"
                            type="text"
                            label="Role name"
                            required
                            autoComplete="off"
                            className={classes.textField}
                            value={permissions.name}
                            onChange={(e) => setPermissions({ ...permissions, name: e.target.value })}
                            disabled={mode === 'edit'} // Disable in edit mode if needed
                        />
                    </Grid>
                    <Grid size={12}>
                        <CheckboxInput
                            id="adminRights"
                            label="Admin rights"
                            value={permissions.adminRights}
                            onChange={handleAdminRightsChange}
                            //className={`${classes.newRoleGrid} ${classes.formControlAdd}`}
                            // labelClassName={classes.formControlLabel}
                        />
                    </Grid>
                    <Grid size={12}>
                        <CheckboxInput
                            id="dataAdminRights"
                            label="Data admin rights"
                            value={permissions.dataAdminRights}
                            onChange={handleDataAdminRightsChange}
                            // className={`${classes.newRoleGrid} ${classes.formControlAdd}`}
                            // labelClassName={classes.formControlLabel}
                        />
                    </Grid>
                </Grid>
                <Typography pt={2}>Available tabs:</Typography>
                <Grid container>
                    {tabsData.map(tab => (
                        <Grid size={2} key={tab.key}>
                            <CheckboxInput
                                id={tab.key}
                                label={defaults.tabsLabels[tab.key]}
                                value={permissions.tabs[tab.key]}
                                onChange={handleTabsChangeFactory(tab.key)}
                                // className={classes.formControlAdd}
                                // labelClassName={classes.formControlLabel}
                                disabled={tab.key === 'strainTree' || tab.key === 'frequencies'}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Box sx={{ width: '100%' }} className={classes.root}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange}>
                            {tabsData.map((tab, index) => (
                                <Tab label={tab.label} {...a11yProps(index)} key={tab.key} />
                            ))}
                        </Tabs>
                    </Box>

                    {tabsData.map((tab, index) => (
                        <TabPanel value={value} index={index} key={tab.key}>
                            {permissions[`${tab.key}Permissions`] && (
                                <PermissionsPanel
                                    tabKey={tab.key}
                                    permissions={permissions[`${tab.key}Permissions`]}
                                    labels={defaults[`${tab.key}PermissionsLabels`]}
                                    onChange={handlePermissionChangeFactory}
                                    disabled={!permissions.tabs[tab.key]}
                                />
                            )}
                        </TabPanel>
                    ))}
                </Box>
            </DialogContent>

            <DialogActions>
                <Button className={classes.cancel} onClick={handleCloseDialog}>
                    Cancel
                </Button>
                <Button className={classes.confirm} onClick={handleSubmit}>
                    {mode === 'edit' ? 'Save' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RoleDialog;
