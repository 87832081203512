import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    formControl: {
        minWidth: 120,
        margin: '0px 0px 0px 0px'
    },
    icon: {
        padding: 0,
        '&:hover': {
            color: '#000000',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            color: '#000000',
        },
    },
    confirm: {
        backgroundColor: '#6F6CFF',
        color: 'white',
        marginBottom: '16px',
        textTransform: 'none',
        marginRight: '16px',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            color: 'white',
        },
    },
    cancel: {
        marginRight: '6px',
        marginBottom: '16px',
        backgroundColor: '#e0e0e0',
        color: '#000000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    textField: {
        minWidth: 120,
        backgroundColor: 'white !important',
        '& > div': {
            width: '100%',
        },
        '& input:-internal-autofill-selected': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            // fontFamily: 'Inter, Verdana',
            fontSize: '16px'
        }
    },
});

export const dialog = {
    '& .MuiDialog-container': {
        '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '300px',
            borderRadius: '12px'
        },
    },
};
