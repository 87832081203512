// External imports
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

// Internal imports
import appConfig from '../../config/appConfig';
import { setParameters } from '../../redux/actions/parametersActions';
import SelectInput from '../Common/SelectInput';

const chartLayoutOptions = [
    { key: 'nonstacked', label: 'Non-stacked' },
    { key: 'stacked', label: 'Stacked' }
];

const ChartLayoutSelector = (props) => {
    const { chartLayout } = props;

    const handleChartLayoutChange = value => {
        const { setParameters } = props;
        setParameters({ chartLayout: value, plotType: 'frequencies' });
    };

    return (
        <SelectInput
            id="chartLayout"
            label="Layout"
            value={chartLayout}
            onChange={handleChartLayoutChange}
            options={chartLayoutOptions}
            getOptionValue={option => option.key}
            getOptionLabel={option => option.label}
        />
    );
};

ChartLayoutSelector.propTypes = {
    // Required props
    setParameters: PropTypes.func.isRequired,
    // Optional props
    chartLayout: PropTypes.string
};

const mapStateToProps = state => ({
    chartLayout: state.parameters.chartLayout || appConfig.default.chartLayout
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChartLayoutSelector);
