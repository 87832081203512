import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mui/material';
import { DialogSX, styles } from '../../../../pages/SettingsPanel/Styles/dialogStyles';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import ColorPickerComponent from '../../Scales/Elements/ColorPicker';

const dialogInitState = {
    key: '',
    color: '#ffffff',
    label: ''
};

const LineageRegionDialog = ({ openDialog, handleCloseDialog, oldLineageRegion, updateLineageRegion, addNewLineageRegion }) => {
    const [lineageRegion, setLineageRegion] = useState(dialogInitState);
    const classes = styles();
    const isEdit = Boolean(oldLineageRegion);

    useEffect(() => {
        if (isEdit) {
            setLineageRegion({ ...oldLineageRegion });
        } else {
            setLineageRegion({ ...dialogInitState });
        }
    }, [oldLineageRegion]);

    const handleChangeKey = (e) => setLineageRegion({ ...lineageRegion, key: e.target.value });
    const handleLabelChange = (e) => setLineageRegion({ ...lineageRegion, label: e.target.value });
    const handleColorChange = (color) => {
        const hexColor = color.hex;
        setLineageRegion({ ...lineageRegion, color: hexColor });
    };

    const handleSubmit = async () => {
        if (isEdit) {
            await updateLineageRegion(oldLineageRegion.key, lineageRegion);
        } else {
            await addNewLineageRegion(lineageRegion);
        }
        setLineageRegion({ ...dialogInitState });
        handleCloseDialog();
    };

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={DialogSX}>
            <DialogTitle id="confirm-dialog-title">
                {isEdit ? 'Update' : 'Add'} lineage region:
            </DialogTitle>

            <DialogContent style={{ height: '450px', width: 400, overflow: 'overlay' }}>
                <StyledTextField
                    id="key"
                    type="text"
                    label="Key"
                    autoComplete='off'
                    value={lineageRegion.key}
                    onChange={handleChangeKey}
                />
                <StyledTextField
                    id="label"
                    type="text"
                    label="Label"
                    autoComplete='off'
                    value={lineageRegion.label}
                    onChange={handleLabelChange}
                />
                <div>
                    Color:
                    <ColorPickerComponent handleColorChange={handleColorChange} color={lineageRegion.color} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button className={classes.cancel} onClick={handleCloseDialog}>
                    Cancel
                </Button>
                <Button className={classes.confirm} onClick={handleSubmit}>
                    {isEdit ? 'Save' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LineageRegionDialog;