import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    formControl: {
        minWidth: 120,
        margin: '8px 0px'
    },
    selectControl: {
        width: 350,
        margin: '15px 0px 8px 0px'
    },
    icon: {
        padding: 0,
        '&:hover': {
            color: '#000000',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            color: '#000000',
        },
    },
    switch_base: {
        color: '#6F6CFF',
        '&:hover': {
            color: '#6F6CFF',
        },
        '&.Mui-disabled': {
            color: '#6F6CFF',
            backgroundColor: '#6F6CFF',
        },
        '&.Mui-checked': {
            color: '#6F6CFF'
        },
        '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#6F6CFF',
        }
    },
    switch_track: {
        backgroundColor: '#6F6CFF',
    },
    save: {
        backgroundColor: '#6F6CFF',
        color: 'white',
        padding: '5px 12px',
        marginBottom: '16px',
        textTransform: 'none',
        marginRight: '16px',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            color: 'white',
        },
    },
    error: {
        fontSize: '15px',
        marginBottom: '5px',
        display: 'flex',
        alignItems: 'center'
    },
    logo: {
        width: '400px',
    },
    validationText: {
        fontSize: '13px',
        marginLeft: '5px'
    },
    cancel: {
        marginRight: '6px',
        marginBottom: '16px',
        padding: '5px 12px',
        backgroundColor: '#e0e0e0',
        color: '#000000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    textField: {
        width: 350,
        display: 'block',
        backgroundColor: 'white !important',
        '& > div': {
            width: '100%',
        },
        '& input:-internal-autofill-selected': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            // fontFamily: 'Inter, Verdana',
            fontSize: '15px'
        }
    },
    switch: {
        marginTop: '10px',
        marginBottom: '25px'
    },
    errorTxt: {
        fontSize: '10pt',
        color: 'red'
    },
});
