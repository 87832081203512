export default {
    root: {
        boxShadow: 'none',
        zIndex: 10,
        position: 'relative',
        overflowY: 'none',
    },
    logoButton: {
        marginLeft: -12,
        marginRight: 10,
        display: 'flex',
        color: '#EEEEEE',
        '&:hover': {
            color: '#FFFFFF',
            boxShadow: 'none',
            borderColor: 'white',
        },
    },
    toolbar: { 
        backgroundColor: '#5D5A5A',  
        paddingRight: '21px', 
        paddingLeft: '24px' 
    },
    grow: {
        flexGrow: 1,
    },
    mobileMenu: {
        backgroundColor: '#323232',
        padding: '0px 5px 0px 0px',
        fontSize: 10,
        height: '54px',
    },
    mobileMenuItems: {
        paddingTop: '10px',
        display: 'flex',
    },
    desktopLinkMenu: {
        backgroundColor: '#323232',
        fontSize: '12px',
        height: '60px',
        paddingLeft: '2%',
    },
    tabletLinkMenu: {
        backgroundColor: '#323232',
        fontSize: '12px',
        height: '145px',
    },
    leftButtons: {
        width: '100%',
        display: 'flex',
        marginTop: '4px'
    },

    linksDesktop: {
        marginTop: '15px',
        display: 'flex',
        marginBottom: '10px'
    },
    line: {
        backgroundColor: 'white',
        marginTop: '5px',
        marginRight: '10px',
    },
    lineMobile: {
        backgroundColor: 'white',
        marginTop: '1px',
        marginRight: '8px',
    },
    lineButtons: {
        marginTop: '-5px',
        marginBottom: '5px',
        backgroundColor: '#B5B2B2',
        marginLeft: '20px',
        marginRight: '10px',
        height: '82px'
    },
    mobileLinks: {
        margin: '10px 0px 0px 3px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    smallTextDesktop: {
        marginTop: '15px',
        paddingTop: '5px',
        height: '40px',
        fontSize: '17px',
        lineHeight: '25px'
    },
    smallTextMobile: {
        marginTop: '13px',
        height: '24px',
        fontSize: '12px',
        lineHeight: '13px'
    },
    buttonContainer: {
        position: 'relative',
        marginRight: '10px',
    },
    buttonContainerMobile: {
        position: 'relative',
        marginRight: '5px',
        marginTop: '10px'
    },
};