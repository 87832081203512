// React and Redux
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Redux Actions
import { fetchTreeFreqs } from '../../redux/actions/treeDataActions';
import { setParameters } from '../../redux/actions/parametersActions';
import { resetFrequencies } from '../../redux/actions/frequenciesActions';
import { resetPredictions } from '../../redux/actions/predictionsActions';
import { resetActiveClades } from '../../redux/actions/cladeActions';

// Redux Selectors
import { predictionBaselineSelector, submissionDateSelector } from '../../redux/selectors/parametersSelector';

// Components
import DatePickerInput from '../Common/DatePickerInput';

const PredictionBaselinePicker = (props) => {
    const {
        predictionBaseline, submissionDate, lineage, type, caseSpan,
        setParameters, fetchTreeFreqs, resetActiveClades, resetFrequencies, resetPredictions, strainSubset
    } = props;

    const handleAccept = date => {
        setParameters({ predictionBaseline: date });

        switch (type) {
            case 'predictionBaseline': {
                fetchTreeFreqs({ lineage, predictionBaseline: date, submissionDate, strainSubset });
                resetActiveClades();
                break;
            }
            case 'frequencies': {
                resetFrequencies();
                resetPredictions();
                break;
            }
            default:
                return;
        }
    };

    return <>
        <DatePickerInput
            id='predictionBaseline'
            value={predictionBaseline}
            label={type === 'geoMap' ? `Date +/- ${caseSpan} days` : 'Prediction baseline'}
            onAccept={handleAccept}
            allowSameDateSelection
            disableToolbar
        />
    </>;
};

PredictionBaselinePicker.propTypes = {
    lineage: PropTypes.string,
    type: PropTypes.string,
    caseSpan: PropTypes.number,
    predictionBaseline: PropTypes.instanceOf(Date),
    setParameters: PropTypes.func.isRequired,
    fetchTreeFreqs: PropTypes.func.isRequired,
    resetFrequencies: PropTypes.func.isRequired,
    resetPredictions: PropTypes.func.isRequired,
    resetActiveClades: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    predictionBaseline: predictionBaselineSelector(state),
    submissionDate: submissionDateSelector(state),
    lineage: state.parameters.lineage,
    caseSpan: state.parameters.caseSpan,
    strainSubset: state.parameters.strainSubset
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setParameters,
        fetchTreeFreqs,
        resetFrequencies,
        resetPredictions,
        resetActiveClades
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PredictionBaselinePicker);
