import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Grid from '@mui/material/Grid2';
import { ListItem, IconButton } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { ColorButton } from 'mui-color';
import ColorPickerComponent from './ColorPicker';
import { styles } from './styles';
import CheckboxInput from '../../../../components/Common/CheckboxInput';
import NumberInput from '../../../../components/Common/NumberInput';

const DraggableListItem = ({ discrete, element, prevValue, index, keyId, middleLinear, newScale, handleValueChange, handleColorChange, handleQuantileChange, addNewElement, removeElement, itemsLength, lastDiscrete, colorBy }) => {
    const classes = styles();

    return (
        <Draggable draggableId={keyId} index={index} >
            {(provided, snapshot) => (
                <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={snapshot.isDragging ? classes.draggingListItem : classes.listItems}
                >

                    <Grid container className={classes.domain}>
                        {element.color && colorBy && (
                            <Grid size={middleLinear ? 6 : 1.5} className={middleLinear ? classes.colorsGridLinear : classes.colorsGrid}>
                                {newScale.palette === 'custom' ? (
                                    <ColorPickerComponent
                                        color={element.color}
                                        handleColorChange={handleColorChange}
                                        index={index}
                                    />
                                ) : (
                                    <ColorButton disabled color={element.color} />
                                )}
                            </Grid>
                        )}
                        {lastDiscrete &&
                            <>
                                <Grid size={2} style={{ marginRight: '15px', marginTop: '10px' }} >
                                    <NumberInput
                                        id="range"
                                        showButtons={false}
                                        label="value min"
                                        autoComplete='off'
                                        value={prevValue}
                                        disabled
                                    />
                                </Grid>
                                <Grid size={2} style={{ marginTop: '10px' }} >
                                    <NumberInput
                                        id="range"
                                        showButtons={false}
                                        label="value min"
                                        autoComplete='off'
                                        value={Infinity}
                                        disabled
                                    />
                                </Grid>
                            </>
                        }
                        {!middleLinear && !lastDiscrete &&
                            <>
                                {discrete &&
                                    <Grid size={2} style={{ marginRight: '15px', marginTop: '10px' }} >
                                        <NumberInput
                                            id="range"
                                            showButtons={false}
                                            label="value min"
                                            autoComplete='off'
                                            allowFloat
                                            value={index === 0 ? -Infinity : prevValue}
                                            disabled
                                        />
                                    </Grid>
                                }
                                <Grid size={2} style={{ marginRight: '15px', marginTop: '10px' }} >
                                    <NumberInput
                                        id="range"
                                        showButtons={false}
                                        label={discrete ? 'value max' : 'value'}
                                        autoComplete='off'
                                        value={element.value}
                                        onChange={handleValueChange(index)}
                                    />
                                </Grid>
                            </>
                        }

                        {!middleLinear && !lastDiscrete &&
                            <Grid size={2} className={classes.colorsGrid}>
                                <CheckboxInput
                                    id="quantile"
                                    label="Quantile"
                                    value={element.quantile}
                                    onChange={handleQuantileChange(index)}
                                    className={`${classes.newRoleGrid} ${classes.formControlAdd}`}
                                />
                            </Grid>
                        }
                        {
                            itemsLength > 2 && index !== 0 && itemsLength - 1 !== index && newScale.palette === 'custom' &&
                            <Grid size={1} className={middleLinear ? classes.empty : classes.colorsGrid}>
                                <IconButton onClick={() => removeElement(index)} className={middleLinear ? classes.removeLinear : classes.removeDiscrete}>
                                    <RemoveCircleOutlineOutlinedIcon />
                                </IconButton>
                            </Grid>
                        }

                        {index === 0 && newScale.palette === 'custom' && (!element.color && discrete) &&
                            <Grid size={1} className={`${classes.colorsGrid} ${classes.addMargin}`} >
                                <IconButton onClick={addNewElement}>
                                    <AddCircleOutlineOutlinedIcon />
                                </IconButton>
                            </Grid>
                        }

                    </Grid>
                </ListItem>
            )}
        </Draggable>
    );
};

export default DraggableListItem;
