import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';


import { getIsMobile } from '../../redux/selectors/statusSelector';
import VaccinesChartD3 from './helpers/VaccinesChartD3';
import { getNodeWidth } from '../../functions/cssHelpers';

import { setComponentStatus } from '../../redux/actions/renderActions';

import { useEventListener } from 'usehooks-ts';
import { useDebouncedCallback } from '../../functions/customHooks';
import { getAllRefStrainsWithLabAndClade, selectedFerretStrainsSelector, vaccinesAntigenicCladeFrequenciesSelector, vaccinesDataSelector } from '../../redux/selectors/vaccinesSelector';
import { getTextMetrics } from '../../functions/functions';
// import { fetchRegionMultiplicities } from '../../actions/predictionsActions';

const styles = () => ({
    // root: {
    //     width: '100%',
    //     height: '100%',
       
    // },
    graph: {
        padding: '0px 20px 20px 20px',
        // height: 'fit-content',
        // width: 'fit-content',
        height: 'calc(100% - 20px)',
        // height: '100%',
        // width: '100%',
        // minHeight: '100%',
        // maxHeight: '100%',
        // overflow: 'overlay',

    },
    border: {
        border: 'solid 1px #c1c1c1'
    },
    graphMobile: {
        padding: '2px 0px 20px 0px',
    },
    graphExport: {
        padding: 0,
        boxSizing: 'border-box',
        height: '100%',
        width: '100%'
    },
});

const useStyles = makeStyles(styles);

const textFont = `10px 'Inter'`;
const VaccinesChart = props => {


    const { exportMode, exportWidth, exportHeight, loading, hiddenMenu, isMobile,
        clades, vaccinesData, refStrainsCnt, cladesCnt, frequencies
    } = props;

    // console.log('[VaccinesChart] frequencies', frequencies);
    const classes = useStyles();
    const _element = useRef();
    const parentRef = useRef();
    const componentId = 'VACCINES_CHART';
    const chartD3 = useRef(new VaccinesChartD3(componentId));

    const referenceStrains = useMemo(() => vaccinesData?.reduce((acc, elem) => {
        if (!acc[elem.strainIndex]) acc[elem.strainIndex] = { 
            refid: elem.refid, 
            name: elem.name, 
            lab: elem.lab, 
            textLength: getTextMetrics(elem.name, textFont).width,
            cladeTextLength: getTextMetrics(`${elem.antigenicCladeLabel} (${elem.lab})`, textFont).width,
            antigenicCladeLabel: elem.antigenicCladeLabel,
            strainIndex: elem.strainIndex
        };
        return acc;
    }, {}), [vaccinesData]);

    // console.log('[VaccinesChart] referenceStrains', referenceStrains);

    const maxTextLength = useMemo(() => 
        Math.max(...Object.values(referenceStrains||{})
            .map(strain => Math.max(strain.textLength, strain.cladeTextLength))), 
    [referenceStrains]);

    const updateDimensions = () => {
        if (_element.current) {
            const mountNode = _element.current;
            const { parentNode } = mountNode;
            const _width = getNodeWidth(parentNode);
            const height = chartD3.current.getCalculatedHeight();
            const width = chartD3.current.getCalculatedWidth(_width);
            // console.log('updateDimensions', {width, height});
            chartD3.current.resizeComponent(width, height);
        }
    };

    const debouncedResize = useDebouncedCallback(() => {
        // console.log('debouncedResize', componentId);
        updateDimensions();
    }, 200);


    if (!exportMode) {
        useEventListener('resize', debouncedResize);
    }

    const d3Props = useMemo(() => ({
        ...props,
        // setComponentStatus,
        // vaccinesData,
        // clades,
        // refStrainsCnt,
        // cladesCnt,
        referenceStrains,
        maxTextLength: maxTextLength < 0 ? 0 : maxTextLength
    }), [props, maxTextLength]);
  
    console.log('[VaccinesChart] loading', loading);
    const init = () => {
        // console.log('[VaccinesChart] INIT');
        const mountNode = _element.current;
        // Only initialize if we have valid dimensions
        const { parentNode } = mountNode;
        const widthChart = (exportMode) ? exportWidth - 50 : getNodeWidth(parentNode);
        const heightChart = 
        chartD3.current.sectionHeight * refStrainsCnt;
       
        // chartD3.current.setProps(d3Props);
        chartD3.current.setMountNode(mountNode);
        chartD3.current.setHeight(heightChart);
        chartD3.current.setWidth(widthChart);
        chartD3.current.prepareGraphArea();
        // }
    };

    // Modify the initial useEffect to run when width/height are available
    useEffect(() => {
        // if (exportMode) return;
        init();
    }, []);


    useEffect(() => {
        chartD3.current.setProps(d3Props);
    }, [d3Props]);


    const isFirstRender = useRef(true);

    useEffect(() => {   
        if (loading) return;
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        // console.log('[VaccinesChart] useEffect', {hiddenMenu, refStrainsCnt, maxTextLength});
        updateDimensions();
    }, [hiddenMenu, refStrainsCnt, maxTextLength]);


    useEffect(() => {
        if (loading || !vaccinesData) {
            return;
        }
        chartD3.current.renderD3Component('vaccines', componentId);

    }, [loading, vaccinesData]);


    // useEffect(() => {
    //     handleMouseLeaveAll(chartLayout);
    //     const value = activeLegendOption?.value;
    //     if (!isNil(value)) {
    //         handleMouseEnter(value, chartLayout);
    //     }
    // }, [activeLegendOption]);

    const style = exportMode
        ? classes.graphExport
        : isMobile
            ? classes.graphMobile
            : classes.graph;


    return (
        <div className={style} ref={parentRef}>
            {/* {title && <div className={classes.title}>{title}</div>} */}
            <svg className={`${classes.border} svg-bg`} id={componentId} ref={_element} />
        </div>
    );
};

// Mapping our Redux State to Props
VaccinesChart.propTypes = {
    loading: PropTypes.bool,
    lineage: PropTypes.string,
};



const mapStateToProps = (state) => {
    const { clades, cladesStatus } = state.cladeData;
    const { vaccineProtectionValues, 
        vaccineProtectionValuesStatus, vaccineDefaultSelectionsStatus } = state.vaccines;
    const { exportMode } = state.parameters;

    const loading = cladesStatus === 'loaded' && vaccineProtectionValuesStatus === 'loaded' && vaccineDefaultSelectionsStatus === 'loaded';

    const frequencies = vaccinesAntigenicCladeFrequenciesSelector(state);
    return ({
        clades,
        vaccineProtectionValues,
        cladesStatus,
        loading,
        exportMode,
        hiddenMenu: state.ui.hiddenMenu,
        isMobile: getIsMobile(),
        vaccinesData: vaccinesDataSelector(state),
        refStrainsCnt: selectedFerretStrainsSelector(state)?.length || 0,
        cladesCnt: state.parameters.vaccinesRhos?.length || 0,
        frequencies,
    });
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setComponentStatus,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VaccinesChart);
