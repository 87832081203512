import React, { useState } from 'react';
import { Button, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styles } from '../styles';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import { elementToSVG, inlineResources } from 'dom-to-svg';

const ColorLegendMenu = ({
    collSize,
    setCollSize
}) => {
    const classes = styles();
    const [collSizeHolder, setCollSizeHolder] = useState(collSize);

    const handleChangeCollSize = e => {
        setCollSizeHolder(e.target.value);
    };

    const handleKeyPress = e => {
        if (e.key === 'Enter'){
            setCollSize(collSizeHolder);
        }
    };

    const download = async () => {
        const selector = 'legendWrapper';
        const svgDocument = elementToSVG(document.getElementById(selector));
        await inlineResources(svgDocument.documentElement);
        const svgString = new XMLSerializer().serializeToString(svgDocument);
        const element = document.createElement('a');
        const file = new Blob([svgString], {type: 'text/html'});

        element.href = URL.createObjectURL(file);
        element.download = `legend.svg`;
        document.body.appendChild(element);
        element.click();
    };

    return (
        <Paper variant="outlined" square className={`${classes.menu} ${classes.cladeMenu}`} >
            <Grid container>
                <Grid size={6} className={classes.item}>
                    <StyledTextField
                        id="collSize"
                        type="number"
                        label="Columns"
                        className={classes.textField}
                        value={collSizeHolder}
                        onChange={handleChangeCollSize}
                        onKeyDown={handleKeyPress}
                    />
                </Grid>
            </Grid>
            <Button className={classes.export} onClick={download}>
                Export
            </Button>
        </Paper>
    );
};

export default ColorLegendMenu;
