import React from 'react';
import { TableRow } from '@mui/material';
import AddMeasure from '../../../../pages/SettingsPanel/Measures/Actions/AddMeasure';

const AddMeasureAction = () => {
    return (
        <TableRow>
            <td>
                <AddMeasure />
            </td>
        </TableRow>
    );
};

export default AddMeasureAction;
