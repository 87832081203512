// External imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, MenuItem, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Internal imports
import CustomLabel from '../../assets/GlobalStyles/CustomLabel';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import { isNil } from 'lodash';

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: 120,
        margin: '8px 0px',
    },
    tooltip: {
        // Optional: Customize Tooltip styles if needed
    },
    menuItem: {
        margin: 0,
        padding: '8px 16px', // Ensure consistent padding
        fontSize: '14px',
        display: 'flex', // Ensure children can expand
        alignItems: 'center', // Vertically center the content
    },
    optionLabel: {
        fontSize: '14px',
        color: 'inherit', // Inherit color from MenuItem
        width: '100%', // Make the label span the full width
    },
    fullRow: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        color: 'inherit', // Ensure the div inherits color
    },
}));

const SelectInput = (props) => {
    const { 
        label,
        id, 
        value,
        required = true,
        onChange,
        disabled,
        options,
        className,
        inputProps = {},
        selectProps = {},
        getOptionValue,
        getOptionLabel,
        error: propError,
        errorText: propErrorText,
        helperText: propHelperText,
        onValidate,
        onValidateChange,
        showRequired = false,
        getTooltip,
        emptyValue = '', // Prop for specifying empty value
        ...rest 
    } = props;

    const classes = useStyles();

    // State to manage open state of Select
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(propError || false);
    const [errorText, setErrorText] = useState(propErrorText);
    const [helperText, setHelperText] = useState(propHelperText);

    const setErrorState = (error, errorText, helperText) => {
        setError(error);
        setErrorText(error ? errorText : '');
        setHelperText(helperText);
        onValidateChange?.(!error);
    };

    const validate = async (val) => {
        if (onValidate) {
            const validationResult = await onValidate(val);
            const { error, errorText, helperText } = validationResult;
            setErrorState(error, errorText, helperText);
            return !error;
        }

        // Adjust required check to allow emptyValue as a valid value
        if (required && (val === emptyValue || val === null || val === undefined)) {
            setErrorState(true, `required`, '');
            return false;
        }

        const optionValues = options.map(option =>
            getOptionValue ? getOptionValue(option) : option?.id ?? option?.value ?? option
        );

        const isInvalid = 
            val !== null && 
            val !== undefined && 
            val !== emptyValue && 
            !optionValues.includes(val);

        setErrorState(isInvalid, isInvalid ? `${val} is not a valid option` : '', '');
        return !isInvalid;
    };

    const handleChange = (event) => {
        const newValue = event.target.value;
        onChange?.(newValue);
        event.preventDefault();
    };

    useEffect(() => {
        validate(value);
    }, [value, options]);

    return (
        <FormControl 
            fullWidth 
            required={showRequired ? required : false} 
            className={className || classes.formControl} 
            {...rest}
        >
            <CustomLabel id={id} label={label} />
            <CustomSelect
                id={`${id}-select`}
                value={isNil(value) ? emptyValue : value}
                onChange={handleChange}
                disabled={disabled}
                error={error}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                inputProps={{
                    id,
                    name: id,
                    ...inputProps,
                }}
                {...selectProps}
            >
                {!required && (
                    <MenuItem 
                        value={emptyValue} 
                        className={classes.menuItem}
                        tabIndex={0}
                    >
                        <span className={classes.optionLabel}>
                            {props.emptyLabel || ''}
                        </span>
                    </MenuItem>
                )}
               
                {(options||[]).map((option, index) => {
                    const optionValue = getOptionValue ? getOptionValue(option) : option?.id ?? option?.value ?? option;
                    const optionLabel = getOptionLabel ? getOptionLabel(option) : option?.label ?? option;
                    const tooltipContent = getTooltip ? getTooltip(option, index) : null;

                    return (
                        <MenuItem
                            key={`${optionValue}-select-item`}
                            value={optionValue}
                            className={classes.menuItem}
                            tabIndex={0}
                        >
                            {getTooltip && isOpen ? (
                                <Tooltip
                                    title={tooltipContent}
                                    placement="bottom-start"
                                >
                                    <div className={classes.fullRow}>
                                        {optionLabel}
                                    </div>
                                </Tooltip>
                            ) : (
                                <span className={classes.optionLabel}>
                                    {optionLabel}
                                </span>
                            )}
                        </MenuItem>
                    );
                })}
            </CustomSelect>
            {error && (
                <FormHelperText id={`${id}-error-text`} error>
                    {errorText}
                </FormHelperText>
            )}
            {helperText && (
                <FormHelperText id={`${id}-helper-text`}>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};

// Define PropTypes for better type checking
SelectInput.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    required: PropTypes.bool,
    showRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    inputProps: PropTypes.object,
    selectProps: PropTypes.object,
    getOptionValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    onValidate: PropTypes.func,
    onValidateChange: PropTypes.func,
    getTooltip: PropTypes.func,
    emptyValue: PropTypes.any, // Prop for empty value
    emptyLabel: PropTypes.string, // Optional prop for empty label
};

export default SelectInput;
