import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { actionsStyles } from '../../Styles/actionsStyles';
import { ExpandIcon } from '../../../../components/Alerts/styles';
import AddMeasureDialog from '../Dialogs/AddMeasureDialog';
import config from '../../../../config/envConfig';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAllMeasures } from '../../../../redux/actions/settingsActions';

const AddMeasure = ({ lineages, fetchAllMeasures }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = actionsStyles();

    const handleUpload = async (measure, files) => {
        try {
            const formData = new FormData();
            formData.append('file', files, 'data.zip');
            formData.append('lineage', measure.lineage);

            await fetch(`${config.serverLink}/api/customAttributes/upload`, {
                method: 'POST',
                body: formData,
            });
            fetchAllMeasures();
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    return (
        <>
            <IconButton className={classes.add} onClick={() => setOpenDialog(true)}>
                <ExpandIcon color={'#0000FF'} />
            </IconButton>
            <AddMeasureDialog 
                openDialog={openDialog}
                handleCloseDialog={() => setOpenDialog(false)}
                addNewMeasure={handleUpload}
                lineages={lineages}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    lineages: state.lineages.lineages
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchAllMeasures
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddMeasure);
