import React, { useEffect, useRef, useState, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { hierarchy } from 'd3-hierarchy';
import { select } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import d3Tip from 'd3-tip';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { emptyObject, shouldFetch, treePreOrder, getTextMetrics, getTrimmedText } from '../../functions/functions';
import { getCladeSchema, getMutationClassesForCladeSchema, getSortedVisibleClades, getVaccinesAndReassortmentsForCladeSchema } from '../../redux/selectors/treeDataSelector';
import { style } from './styles';
import { linkGenerator, closureGenerator, schemaHeightMultiplierInit, schemaHeightMultiplierWithMutations } from './helpers';
import { fetchActiveClades } from '../../redux/actions/cladeActions';
import { reassortmentIcon, vaccineIcon } from '../Tree/d3/svgshapes/icons';
import { getNodePaddingWidth, getNodeWidth } from '../../functions/cssHelpers';
import { useEventListener } from 'usehooks-ts';
import { useDebouncedCallback } from '../../functions/customHooks';
import { cloneDeep } from 'lodash';

const defaultColor = 'black';
const minFontSize = 15.5;

const stretchCladeStructure = (cladeTimes, cladeSchema, mutations, schemaWidth) => {
    // const cladeTimesDict = cladeTimes.reduce((acc, clade) => { acc[clade.id] = clade; return acc; }, {});
    //console.log('[stretchCladeStructure]', cladeTimes, mutations);
    // const { min, max } = Object.values(cladeTimes).reduce(
    //     (minMax, { startTime, endTime }) => {
    //         if (!minMax.min || startTime < minMax.min) minMax.min = startTime;
    //         if (!minMax.max || endTime > minMax.max) minMax.max = endTime;
    //         return minMax;
    //     },
    //     { min: null, max: null },
    // );

    //const maxOrder = allNodes.reduce((_maxOrder, node) => Math.max(node.data.order, _maxOrder), 0);
    // const scaleTime = scaleLinear().domain([min, max]).range([0, schemaWidth]);
    //const scaleOrder = scaleLinear().domain([1, maxOrder]).range([20, svgHeight - 10]);



    const { minTime, maxTime, cladeDict } = cladeTimes.reduce(
        (acc, c) => {
            acc.cladeDict[c.id] = c;
            acc.minTime = !acc.minTime || acc.minTime > c.startTime ? c.startTime : acc.minTime;
            acc.maxTime = !acc.maxTime || acc.maxTime < c.endTime ? c.endTime : acc.maxTime;
            return acc;
        },
        { cladeDict: {} },
    );

    const tmpScale = scaleLinear().domain([minTime, maxTime]).range([0, schemaWidth]);
    const minTimeDiff = Math.ceil(tmpScale.invert(15) - tmpScale.invert(0));


    // treePreOrder(cladeSchema, (clade) => {
    //     // const getSpace = (id, childId) => {
    //     //     //console.log('[getSpace]', clade.label, id, childId); //, cladeTimesDict[childId]);
    //     //     return tmpScale(cladeTimesDict[childId].startTime) - tmpScale(cladeTimesDict[id].startTime) - 5;
    //     // }
    //     // const childrenAbove = (clade.children || [])
    //     //     .filter((c) => c.rootOrder < clade.rootOrder);
    //     // const closestChildAbove = childrenAbove.length > 0 ? childrenAbove.reduce((prev, curr) => {
    //     //     return prev.startTime < curr.startTime ? prev : curr;
    //     // }) : null;

    //     // const space = closestChildAbove ? getSpace(clade.id, closestChildAbove.id) : null;

    // });


    let sweep = [];
    let actTime = null;

    const _cladeTimes = cloneDeep(cladeTimes);
    _cladeTimes.forEach((clade) => {
        sweep.push(clade);
        actTime = clade.startTime;
        sweep = sweep.filter((c) => c.endTime > actTime);
        const { startTime, p } = clade;
        const parentStartTime = cladeDict[p]?.startTime;

        if (parentStartTime && startTime - parentStartTime < minTimeDiff) {
            const diff = minTimeDiff - (startTime - parentStartTime);
            clade.startTime += diff;
            clade.endTime += diff;
            sweep.forEach((c) => {
                c.endTime += diff;
            });
        }
    });

    return cladeDict;
};

const CladeSchemaBase = (props) => {

    const {
        lineage,
        cladeSchema, cladeTimes, clades, classes, colorBy, editMode, cladeActiveDays, cladeType, predictionBaseline,
        mutations, vaccines, reassortments, exportMode,
        activeCladesStatus,
        fetchActiveClades, submissionDate, strainSubset,
        cladeSchemaWidth, cladeSchemaHeightMultiplier } = props;

    const ref = useRef();
    const maxWidth = useRef();

    const [ratio, setRatio] = useState();
    const [schemaWidth, setSchemaWidth] = useState(); // schemaWidthRef.current;

    const displayCladeShema = colorBy === 'clade';

    const getTextHeight = () => {
        const textNode = select('text.labels');

        if (!textNode || !textNode.node()) return;

        const bbox = textNode.node().getBBox();

        const actualHeight = bbox.height * ratio;
        return actualHeight;
    };


    const recalculateSchemaWidth = () => {
        // console.log('[TreeGraph.updateDimensions]: loading = ', props.loading);

        if (ref.current) {
            const mountNode = ref.current;
            const { parentNode } = mountNode;
            const containerNode = select('#schema_div_container').node();
            maxWidth.current = getNodeWidth(containerNode) - getNodePaddingWidth(parentNode);
            //console.log('recalculateSchemaWidth');
            //console.log(`[setMaxWidth]: schemaWidth = ${schemaWidth}, cladeSchemaWidth = ${cladeSchemaWidth}, maxWidth.current = ${maxWidth.current}`);
            if (schemaWidth > maxWidth.current) {
                //console.log('recalculateSchemaWidth.1');
                //console.log(`[setMaxWidth]: setting schemaWidth to ${maxWidth.current}`); 
                setSchemaWidth(maxWidth.current);
            } else if (schemaWidth < cladeSchemaWidth) {
                //console.log('recalculateSchemaWidth.2');
                //console.log('should set schemaWidth to', Math.min(cladeSchemaWidth, maxWidth.current))
                setSchemaWidth(Math.min(cladeSchemaWidth, maxWidth.current));
            }
            else if (getTextHeight() < minFontSize && schemaWidth < maxWidth.current) {
                const ratio = minFontSize / getTextHeight();
                const _schemaWidth = Math.min(maxWidth.current, schemaWidth * ratio);
                setSchemaWidth(_schemaWidth);
            }

            //console.log('maxSchemaWidth = ',maxWidth.current);
        }
    };

    const debouncedResize = useDebouncedCallback(() => {
        recalculateSchemaWidth();
    }, 200);


    if (!editMode) {
        useEventListener('resize', debouncedResize);
    };

    const cladesCorrected = useMemo(() => { return cladeTimes ? stretchCladeStructure(cladeTimes, cladeSchema, mutations, schemaWidth) : {}; }, [cladeTimes, cladeSchema, schemaWidth]);

    const drawLinks = (svg, allNodes, scaleTime, scaleOrder) => {
        svg.selectAll('g').remove();
        const diagonal = linkGenerator(scaleTime, scaleOrder, cladesCorrected);
        const closureDiagonal = closureGenerator(scaleTime, scaleOrder, cladesCorrected);
        const cladeLink = svg.selectAll('g').data(allNodes, (d) => d.data.id);
        const cladeLinkEnter = cladeLink.enter().append('g');

        // console.log(cladeLinkEnter, clades, allNodes)
        cladeLinkEnter
            .append('path')
            .attr('fill', 'none')
            .attr('stroke', (d) => clades[d.data.id]?.color || defaultColor)
            .attr('stroke-width', 3)
            .attr('d', diagonal);

        cladeLinkEnter
            .filter((d) => d.data.closed)
            .append('path')
            .attr('fill', 'none')
            .attr('stroke', (d) => clades[d.data.id]?.color || defaultColor)
            .attr('stroke-width', 3)
            .attr('d', closureDiagonal);
    };

    const initializeTooltip = (maxLengthInPX) => {
        const tip = d3Tip()
            .attr('class', 'd3-tip')
            .offset([10, 10])
            .html(d => {
                const text = d.replace(' ', '&nbsp;');
                return (
                    `<div class="tooltip-container" style='width: ${maxLengthInPX}px; overflow-wrap: anywhere'>
                    <span>${text}</span>
                </div>`
                );
            });
        return tip;
    };

    const getRestOfLabel = (text, maxLengthInPX) => {
        let label = text.split(',').slice(1).join(',');
        const len = getTextMetrics(label, `12px 'Inter'`).width;

        if (len > maxLengthInPX && !exportMode)
            label = getTrimmedText(label, `12px 'Inter'`, maxLengthInPX);

        return `,${label}`;
    };



    const drawLabels = (svg, allNodes, scaleTime, scaleOrder) => {
        svg.selectAll('g').remove();
        const clade = svg.selectAll('g').data(allNodes, (d) => d.data.id);
        const mut = svg.selectAll('g').data(allNodes.filter(d => (mutations?.[d.data.id] || []).length > 0));// , (d) => d.data.id); clade.filter(d => { console.log(d); return mutations[d.data.id]} );
        const cladeEnter = clade.enter().append('g');
        const maxLengthInPX = maxWidth.current * 0.3;
        const tip = initializeTooltip(maxLengthInPX);
        svg.call(tip);

        const mouseOver = (e, d) => {
            const text = d.data.label;
            const label = text.replace(' ', '&nbsp;');
            const len = getTextMetrics(label, `12px 'Inter'`).width;

            if (len > maxLengthInPX)
                tip.show(label, e.currentTarget);
        };

        const cladeLabelTextEnter = cladeEnter
            .append('text')
            .attr('id', (d) => `text_${d.data.id}`)
            .attr('text-anchor', 'right')
            .attr('alignment-baseline', 'central')
            .attr('font-size', 12)
            .attr('font-family', 'Inter')
            .attr('fill', defaultColor)
            .attr('x', (d) => scaleTime(cladesCorrected[d.data.id].endTime) + 5 || 0)
            .attr('y', (d) => scaleOrder(d.data.order))
            .attr('class', 'labels');

        cladeLabelTextEnter
            .append('tspan')
            .attr('alignment-baseline', 'central')
            .style('font-family', 'Inter Bold')
            .text((d) => (d.data.label || '').split(',')[0]);

        cladeLabelTextEnter.filter(d => (d.data.label || '').split(',').length > 1)
            .append('tspan')
            .attr('alignment-baseline', 'central')
            .text((d) => getRestOfLabel(d.data.label, maxLengthInPX))
            .on('mouseover', mouseOver)
            .on('mouseout', tip.hide);

        const mutEnter = mut.enter();
        mutEnter.append('rect')
            .attr('class', 'mutations')
            .attr('fill', '#ffffff')
            .attr('opacity', 0.5)
            .attr('stroke', '#4f4f4f')
            .attr('stroke-width', 1)
            .attr('rx', 4).attr('ry', 4)
            .attr('height', 20);

        const mutTextEnter = mutEnter
            .append('text')
            //.text() //(d) => mutations[d.data.id])
            .attr('id', (d) => `mut_text_${d.data.id}`)
            .attr('text-anchor', 'right')
            //.attr('alignment-baseline', 'central')
            .attr('font-size', 10)
            //.attr('fill', defaultColor)
            .attr('x', (d) => scaleTime(cladesCorrected[d.data.id].startTime) + 5 || 0)
            .attr('y', (d) => scaleOrder(d.data.order) - 5)
            .attr('class', 'mutations');

        ['bold', 'italic', 'normal'].forEach(style => {
            mutTextEnter.append('tspan')
                // .style("font-weight", style)
                .style('font-style', style)
                .style('font-family', style === 'bold' ? 'Inter Bold' : 'Inter')
                .text(d => {
                    // console.log(mutations[d.data.id].filter(m => m.style === style)); 
                    return mutations?.[d.data.id].filter(m => m.style === style).map(m => `${m.mutClass}:${m.mutations};`);
                });
        });

        const texts = {};
        const mutationsTexts = {};


        svg.selectAll('text.labels').each((d, i, _nodes) => {
            texts[d.data.id] = _nodes[i].getBBox().width; // get bounding box of text field and store it in texts array
        });


        const mutUpdate = mutEnter.merge(mut);

        mutUpdate.selectAll('text.mutations').each((d, i, _nodes) => {
            mutationsTexts[d.data.id] = { width: _nodes[i].getBBox().width, height: _nodes[i].getBBox().height }; // get bounding box of text field and store it in texts array
        });


        mutUpdate.selectAll('.mutations')
            .attr('width', (d, i, nodes) => mutationsTexts[d.data.id].width + (nodes[i].nodeName === 'rect' ? 10 : 0))
            .attr('height', (d, i, nodes) => mutationsTexts[d.data.id].height + (nodes[i].nodeName === 'rect' ? 4 : 0))
            .attr('x', (d, i, nodes) => scaleTime(cladesCorrected[d.data.id].startTime) + (nodes[i].nodeName === 'rect' ? 4 : 8) || 0)
            .attr('y', (d, i, nodes) => scaleOrder(d.data.order) - 12 - (nodes[i].nodeName === 'rect' ? mutationsTexts[d.data.id].height - 1 : 0));// ;mutationsTexts[d.data.id].height);

        // mutUpdate.selectAll('rect.mutations')
        //     .attr('width', (d, i, nodes)  => { console.log('rect', nodes[i].nodeName); return mutationsTexts[d.data.id].width})
        //     .attr('x', (d) => scaleTime(cladesCorrected[d.data.id].startTime) + 5 || 0)
        //     .attr('y', (d) => scaleOrder(d.data.order) - 5)

        return { texts, mutationsTexts };
    };

    const drawReassortments = (svg, allNodes, scaleTime, scaleOrder) => {
        svg.selectAll('g').remove();
        const clade = svg.selectAll('g').data(allNodes.filter(d => reassortments[d.data.id] || vaccines[d.data.id]), (d) => d.data.id);
        const cladeEnter = clade.enter().append('g');

        const reassortmentEnter = cladeEnter
            .filter(d => reassortments[d.data.id])
            .append('g')
            .attr('class', 'R')
            .attr('transform', d => `translate(${16 + scaleTime(cladesCorrected[d.data.id].startTime)}, ${scaleOrder(d.data.order) - cladeSchemaHeightMultiplier / 2})`);

        reassortmentEnter
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', 15)
            .attr('height', 15)
            .attr('transform', () => 'translate(-16, -10)rotate(45)')
            .style('fill', reassortmentIcon.path.fill)
            .style('stroke', reassortmentIcon.path.stroke)
            .style('stroke-width', reassortmentIcon.path['stroke-width']);
        reassortmentEnter
            .append('path')
            .attr('d', reassortmentIcon.symbol.d)
            .attr('transform', () => 'translate(-19, -3)')
            .style('fill', reassortmentIcon.symbol.fill); //scolor)

        // const texts = {};
        // const mutationsTexts = {};
    };

    const drawVaccines = (svg, allNodes, scaleTime, scaleOrder) => {
        svg.selectAll('g').remove();
        const clade = svg.selectAll('g').data(allNodes.filter(d => reassortments[d.data.id] || vaccines[d.data.id]), (d) => d.data.id);
        const cladeEnter = clade.enter().append('g');

        const vaccineEnter = cladeEnter
            .filter(d => vaccines[d.data.id])
            .append('g')
            .attr('class', 'vaccine')
            .attr('transform', d => `translate(${scaleTime(vaccines[d.data.id])}, ${scaleOrder(d.data.order) - 6})`);
        vaccineEnter
            .append('path')
            .attr('d', vaccineIcon.path.d)
            .attr('transform', () => 'translate(-8, 8)')
            .style('fill', vaccineIcon.path.fill) //scolor)
            .style('stroke', vaccineIcon.path.stroke)
            .style('stroke-width', vaccineIcon.path['stroke-width'])
            .style('opacity', '0.9');
        vaccineEnter
            .append('path')
            .attr('d', vaccineIcon.symbol.d)
            .attr('transform', () => 'translate(-8, 8)')
            .style('fill', vaccineIcon.symbol.fill); //scolor)
    };

    const drawCladeSchema = () => {
        if (!cladeTimes) return;
        const root = hierarchy(cladeSchema);

        const svgHeight = root.descendants().length * cladeSchemaHeightMultiplier;
        const svg = select(ref.current)
            .attr('width', schemaWidth)
            .attr('height', svgHeight)
            .attr('overflow', 'visible')
            .style('background-color', '#fff');
        svg.selectAll('g').remove();
        svg.append('g').attr('id', 'links');
        svg.append('g').attr('id', 'labels');
        svg.append('g').attr('id', 'reassortments');
        svg.append('g').attr('id', 'vaccines');

        const allNodes = root
            .descendants()
            .sort((a, b) => cladesCorrected[b.data.id].startTime - cladesCorrected[a.data.id].startTime);

        //   console.log(cladeSchema)
        const { min, max } = Object.values(cladesCorrected).reduce(
            (minMax, { startTime, endTime }) => {
                if (!minMax.min || startTime < minMax.min) minMax.min = startTime;
                if (!minMax.max || endTime > minMax.max) minMax.max = endTime;
                return minMax;
            },
            { min: null, max: null },
        );
        const maxOrder = allNodes.reduce((_maxOrder, node) => Math.max(node.data.order, _maxOrder), 0);
        const scaleTime = scaleLinear().domain([min, max]).range([0, cladeSchemaWidth]);
        const scaleOrder = scaleLinear().domain([1, maxOrder]).range([20, svgHeight - 10]);


        drawLinks(svg.select('#links'), allNodes, scaleTime, scaleOrder);
        const { texts, mutationsTexts } = drawLabels(svg.select('#labels'), allNodes, scaleTime, scaleOrder);
        drawReassortments(svg.select('#reassortments'), allNodes, scaleTime, scaleOrder);
        drawVaccines(svg.select('#vaccines'), allNodes, scaleTime, scaleOrder);

        let correctedSchemaWidth = schemaWidth;

        treePreOrder(cladeSchema, (clade) => {
            const getSpace = (id, childId) =>
                scaleTime(cladesCorrected[childId].startTime) - scaleTime(cladesCorrected[id].startTime) - 5;
            (clade.children || [])
                .filter((c) => c.rootOrder < clade.rootOrder)
                .sort((c1, c2) => c1.startTime - c2.startTime)
                .map((c, i, cArray) => {
                    const prevId = i === 0 ? clade.id : cArray[i - 1].id;
                    const fits = getSpace(prevId, c.id) > mutationsTexts[clade.id]?.width + 5;
                    return {
                        pos: scaleTime(cladesCorrected[fits ? prevId : c.id].startTime),
                        fits,
                        //space: getSpace(prevId, c.id)
                    };
                });

            const pos = (+scaleTime(cladesCorrected[clade.id].endTime) + 5) || 0;
            const mutPos = (+scaleTime(cladesCorrected[clade.id].startTime) + 5) || 0;

            // console.log('childrenAbove', clade.id, childrenAbove)
            // let childIndex = 0;
            // let labelFits;

            // do {
            //     const child = childrenAbove.length === 0 ? null : childrenAbove[childIndex];
            //     labelFits = childrenAbove.length === 0 || child.fits;
            //     mutPos = child ? child.pos + 5 : mutPos;
            //     childIndex += 1;
            // } while (!labelFits && childIndex < childrenAbove.length);
            //select(`#mut_text_${clade.id}`).attr('x', mutPos || 0);

            const cladeLabelWidth = 5 + pos + texts[clade.id];
            const mutLabelWidth = mutationsTexts[clade.id] ? (+mutPos + mutationsTexts[clade.id].width + 10) : 0;
            correctedSchemaWidth = Math.max(correctedSchemaWidth, cladeLabelWidth, mutLabelWidth);


        });

        if (correctedSchemaWidth > maxWidth.current) {
            const _schemaWidth = maxWidth.current;
            const _ratio = _schemaWidth / correctedSchemaWidth;
            const correctedSchemaHeight = svgHeight * _ratio;
            //console.log('svgHeight', svgHeight, '/', correctedSchemaHeight, 'width',correctedSchemaWidth, _schemaWidth, 'ratio',_ratio);
            //select(ref.current).attr('width', _schemaWidth);
            select(ref.current).attr('height', correctedSchemaHeight);
            select(ref.current).attr('viewBox', `0 0 ${_schemaWidth} ${svgHeight}`);
            setSchemaWidth(_schemaWidth);
            setRatio(_ratio);
        } else {
            select(ref.current).attr('viewBox', null);
            select(ref.current).attr('width', correctedSchemaWidth);
            setSchemaWidth(correctedSchemaWidth);
            setRatio(1);
        }
    };



    useEffect(() => {
        if (!displayCladeShema) return;
        if (shouldFetch(activeCladesStatus)) {
            fetchActiveClades({ lineage, predictionBaseline, cladeType, cladeActiveDays, submissionDate, strainSubset });
        }
    }, [activeCladesStatus, displayCladeShema]);


    useEffect(() => {
        if (!editMode) {
            recalculateSchemaWidth();
        }
    }, []);


    //reinit cladeSchemaWidth;
    useEffect(() => {
        // console.log('useEffect: cladeSchemaWidth', cladeSchemaWidth)
        setSchemaWidth(cladeSchemaWidth);
    }, [cladeSchemaWidth]);

    // data loaded
    useEffect(() => {
        if (!cladeSchema || cladeSchema.length === 0 || activeCladesStatus !== 'loaded' || !displayCladeShema) return;
        //console.log('useEffect: data', schemaWidth)
        recalculateSchemaWidth();
        if (schemaWidth !== cladeSchemaWidth) setSchemaWidth(cladeSchemaWidth);
        else if (schemaWidth) drawCladeSchema();
        //console.log('change 1, setting width to', cladeSchemaWidth);
    }, [cladeSchema, mutations, cladeSchemaHeightMultiplier, vaccines, reassortments]);


    // redraw cladeSchema after width change
    useEffect(() => {
        // console.log('schemaWidth = ', schemaWidth, maxWidth.current)
        if (cladeSchema && cladeSchema.length === 0 && schemaWidth) return;
        //console.log('useEffect: schemaWidth', schemaWidth)
        if (schemaWidth && displayCladeShema) drawCladeSchema();
        // console.log('change 3, schemaWidth = ', schemaWidth);
    }, [schemaWidth]);

    return (
        <div id='schema_div_container' className={classes.schemaWrapperContainer}>
            {!emptyObject(cladeSchema) &&
                colorBy === 'clade' && (
                <div id='schema_div' className={editMode ? classes.schemaWrapperEdit : classes.schemaWrapper}>
                    <div className={classes.schemaTitle}>Clade structure</div>
                    <svg className="svg-bg" ref={ref} preserveAspectRatio="xMinYMin meet">
                        {editMode && <defs>
                            <style>
                                    @import url(&apos;https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400&display=swap&apos;);
                            </style>
                        </defs>}
                    </svg>
                </div>

            )}
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const { vaccines, reassortments } = getVaccinesAndReassortmentsForCladeSchema(state);
    const mutations = getMutationClassesForCladeSchema(state);
    const showMutations = !emptyObject(mutations);
    const cladeSchemaHeightMultiplier = ownProps.cladeSchemaHeightMultiplier || (showMutations ? schemaHeightMultiplierWithMutations : schemaHeightMultiplierInit);

    return {
        //cladeSchema: state.cladeData.cladeSchema,
        lineage: state.parameters.lineage,
        cladeSchema: getCladeSchema(state),
        cladeTimes: getSortedVisibleClades(state),
        colorBy: state.parameters.colorBy,
        editMode: state.parameters.editMode,
        predictionBaseline: state.parameters.predictionBaseline, //predictionBaselineSelector(state),
        cladeType: state.parameters.cladeType,
        cladeActiveDays: state.parameters.cladeActiveDays,
        clades: state.cladeData.clades,
        submissionDate: state.parameters.submissionDate,
        activeCladesStatus: state.cladeData.activeCladesStatus,
        strainSubset: state.parameters.strainSubset,
        mutations,
        vaccines,
        reassortments,
        cladeSchemaHeightMultiplier,
        exportMode: state.parameters.exportMode
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchActiveClades
}, dispatch);
const CladeSchemaWithStyles = withStyles(style)(CladeSchemaBase);
export default connect(mapStateToProps, mapDispatchToProps)(CladeSchemaWithStyles);
