import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    rootFrequenciesLegendFull: {
        maxHeight: "100%",
        maxWidth: "100%",
        width: "100%",
        display: 'flex'
    },
    rootFrequenciesLegend: {
        padding: "9px 0 8px",
    },
    legendFull: {
        fontSize: "13px",
        width: "100%",
        flexGrow: 1,
        overflow: "visible",
        
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "block",
        },
        "&::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px #cccccc",
        },
        "&::-webkit-scrollbar-thumb": {
            display: "block",
        },
    },
    legend: {
        maxHeight: "234px",
    },
    rootHeight: {
        maxHeight: "300px",
        padding: "9px 0 8px",
    },
    rootHeightNodeInfo: {
        maxHeight: "100%",
        "&::-webkit-scrollbar": {
            display: "block",
        },
        "&::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px #cccccc",
        },
        "&::-webkit-scrollbar-thumb": {
            display: "block",
        },
    },
    legendText: {
        color: "black",
        overflowWrap: "break-word",
        wordBreak: "break-word",
        lineHeight: "24px",
        fontSize: "12px",
    },
    legendElement: {
        display: "flex",
        alignItems: "flex-start",
        cursor: "pointer",
    },
    legendCircleWrapper: {
        height: "24px",
        display: "flex",
        alignItems: "center",
    },
    legendCircleWrapperExport: {
        height: "18px",
        display: "flex",
        alignItems: "center",
    },
    legendCircleFreqs: {
        borderRadius: "50%",
        width: 9,
        height: 9,
        marginRight: 9,
        flexShrink: 0,
    },
    rootExport: {
        background: "white",
        width: "190px",
        padding: 0,
        paddingBottom: "24px",
    },
    legendExport: {
        padding: "0",
        height: "100%",
        width: "100%",
        overflow: "visible",
    },
    legendTextExport: {
        color: "black",
        overflowWrap: "break-word",
        wordBreak: "break-word",
        lineHeight: "18px",
        fontSize: "10px",
    },
    legendElementExport: {
        display: "flex",
        alignItems: "flex-start",
        cursor: "pointer",
    },

    // legendCircleExport: {
    //     borderRadius: '50%',
    //     width: 4,
    //     height: 4,
    //     marginRight: 4,
    //     flexShrink: 0,
    // },
    rootTreeLegend: {
        background: "white",
        minWidth: "180px",
        bottom: 130,
        zIndex: "2",
        overflowY: "scroll",
        width: "100%",
    },

    legendWrapperNodeInfo: {
        maxHeight: "100%",
        overflowY: "none",
    },
    title: {
        paddingBottom: "33px",
        marginLeft: "-17px",
    },
    titleExport: {
        paddingBottom: "6px",
        fontSize: "12px",
        color: "rgba(0, 0, 0, 0.54)",
        borderBottom: "1px solid #9f9f9f",
    },
    legendWrapper: {
        paddingTop: 4,
        // paddingBottom: 4,
        maxWidth: "80%",
        "&::-webkit-scrollbar": {
            display: "block",
        },
        "&::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px #cccccc",
        },
        "&::-webkit-scrollbar-thumb": {
            display: "block",
        },
    },
    legendWrapperHeight: {
        height: "auto",
        maxHeight: "234px",
        overflowY: "scroll",
    },
    legendWrapperExport: {
        height: "auto",
        overflow: "visible",
        maxWidth: "100%",
    },
    legendTextTree: {
        color: "black",
        overflowWrap: "break-word",
        wordBreak: "break-word",
        lineHeight: "26px",
    },
    legendEdit: {
        background: "white",
        padding: "10px",
    },
    legendEditWrapper: {
        marginTop: "15px",
    },
    legendCircle: {
        borderRadius: "50%",
        width: 15,
        height: 15,
        marginRight: 9,
        flexShrink: 0,
    },
    legendCircleExport: {
        borderRadius: "50%",
        width: 12,
        height: 12,
        marginRight: 4,
        flexShrink: 0,
    },
});
