import { makeStyles } from '@mui/styles';

export const styles = makeStyles(() => ({
    confirm: {
        backgroundColor: '#6F6CFF',
        color: 'white',
        marginBottom: '16px',
        textTransform: 'none',
        marginRight: '16px',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            color: 'white',
        },
    },
}));

export const dialog = {
    '& .MuiDialog-container': {
        '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '300px',
            borderRadius: '12px'
        },
    },
};
