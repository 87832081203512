import {
    SET_LINEAGE_SETTINGS_REQUEST,
    SET_LINEAGE_SETTINGS_SUCCESS,
    SET_LINEAGE_SETTINGS_ERROR,
    FETCH_ALL_SCALES_REQUEST,
    FETCH_ALL_SCALES_SUCCESS,
    FETCH_ALL_SCALES_ERROR,
    FETCH_CUSTOM_TREE_SUBSETS_REQUEST,
    FETCH_CUSTOM_TREE_SUBSETS_SUCCESS,
    FETCH_CUSTOM_TREE_SUBSETS_ERROR,
    FETCH_LINEAGE_REGIONS_REQUEST,
    FETCH_LINEAGE_REGIONS_SUCCESS,
    FETCH_LINEAGE_REGIONS_ERROR,
    FETCH_MEASURE_SCALES_DOMAINS_REQUEST,
    FETCH_MEASURE_SCALES_DOMAINS_SUCCESS,
    FETCH_MEASURE_SCALES_DOMAINS_ERROR,
    SET_SETTINGS_PARAMETERS,
    SET_SETTINGS_METADATA,
    FETCH_LINEAGE_MUTATION_GENES_REQUEST,
    FETCH_LINEAGE_MUTATION_GENES_SUCCESS,
    FETCH_LINEAGE_MUTATION_GENES_ERROR,
    FETCH_MUTATION_CLASSES_ARRAY_REQUEST,
    FETCH_MUTATION_CLASSES_ARRAY_SUCCESS,
    FETCH_MUTATION_CLASSES_ARRAY_ERROR,
    FETCH_ALL_MEASURES_REQUEST,
    FETCH_ALL_MEASURES_SUCCESS,
    FETCH_ALL_MEASURES_ERROR,
} from './actionTypes';

import { received } from '../baseActions';



export const setSettings = (payload) => received(SET_LINEAGE_SETTINGS_REQUEST, payload);
export const setSettingsSuccess = (payload) => received(SET_LINEAGE_SETTINGS_SUCCESS, payload);
export const setSettingsError = (payload) => received(SET_LINEAGE_SETTINGS_ERROR, payload);

export const fetchAllScales = (payload) => received(FETCH_ALL_SCALES_REQUEST, payload);
export const fetchAllScalesSuccess = payload => received(FETCH_ALL_SCALES_SUCCESS, payload);
export const fetchAllScalesError = payload => received(FETCH_ALL_SCALES_ERROR, payload);

export const fetchCustomTreeSubsets = (payload) => received(FETCH_CUSTOM_TREE_SUBSETS_REQUEST, payload);
export const fetchCustomTreeSubsetsSuccess = res => received(FETCH_CUSTOM_TREE_SUBSETS_SUCCESS, res);
export const fetchCustomTreeSubsetsError = err => received(FETCH_CUSTOM_TREE_SUBSETS_ERROR, err);

export const fetchLineageRegions = (payload) => received(FETCH_LINEAGE_REGIONS_REQUEST, payload);
export const fetchLineageRegionsSuccess = res => received(FETCH_LINEAGE_REGIONS_SUCCESS, res);
export const fetchLineageRegionsError = err => received(FETCH_LINEAGE_REGIONS_ERROR, err);

// export const fetchScaleDomain = (payload) => received(FETCH_SCALE_DOMAIN_REQUEST, payload);
// export const fetchScaleDomainSuccess = res => received(FETCH_SCALE_DOMAIN_SUCCESS, res);
// export const fetchScaleDomainError = err => received(FETCH_SCALE_DOMAIN_ERROR, err);

export const fetchMeasureScalesDomains = payload => received(FETCH_MEASURE_SCALES_DOMAINS_REQUEST, payload);
export const fetchMeasureScalesDomainsSuccess = res => received(FETCH_MEASURE_SCALES_DOMAINS_SUCCESS, res);
export const fetchMeasureScalesDomainsError = err => received(FETCH_MEASURE_SCALES_DOMAINS_ERROR, err);

export const setSettingsParameters = payload => received(SET_SETTINGS_PARAMETERS, payload);
export const setSettingsMetadata = payload => received(SET_SETTINGS_METADATA, payload);

export const fetchLineageMutationGenes = (payload) => received(FETCH_LINEAGE_MUTATION_GENES_REQUEST, payload);
export const fetchLineageMutationGenesSuccess = payload => received(FETCH_LINEAGE_MUTATION_GENES_SUCCESS, payload);
export const fetchLineageMutationGenesError = payload => received(FETCH_LINEAGE_MUTATION_GENES_ERROR, payload);

export const fetchMutationClassesArray = (payload) => received(FETCH_MUTATION_CLASSES_ARRAY_REQUEST, payload);
export const fetchMutationClassesArraySuccess = payload => received(FETCH_MUTATION_CLASSES_ARRAY_SUCCESS, payload);
export const fetchMutationClassesArrayError = payload => received(FETCH_MUTATION_CLASSES_ARRAY_ERROR, payload);

export const fetchAllMeasures = (payload) => received(FETCH_ALL_MEASURES_REQUEST, payload);
export const fetchAllMeasuresSuccess = payload => received(FETCH_ALL_MEASURES_SUCCESS, payload);
export const fetchAllMeasuresError = payload => received(FETCH_ALL_MEASURES_ERROR, payload);