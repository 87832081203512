import { get, set } from 'lodash';
import { produce } from 'immer';

import {
    FETCH_PREDICTIONS_REQUEST,
    FETCH_PREDICTIONS_SUCCESS,
    FETCH_PREDICTIONS_ERROR,
    FETCH_FREQUENCIES_REQUEST,
    FETCH_FREQUENCIES_SUCCESS,
    FETCH_FREQUENCIES_ERROR,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    RESET_PREDICTIONS,
    SET_MODEL_TYPE,
    SET_MODEL_REGION_ID,
    SET_SELECTED_MODELS,
    // SET_SELECTED_MODELS,
} from '../actions/actionTypes';

let predictionsInitialState = {};
export const setPredictionsInitialState = (state) => {
    predictionsInitialState = state;
};


export const predictionsDataReducer = (state = predictionsInitialState, action) => 
    produce(state, draft => {
        switch (action.type) {
            case RESET_SESSION:
            case SIGNOUT_REQUEST: {
                return predictionsInitialState;
            }
            case SET_MODEL_REGION_ID:
            case SET_MODEL_TYPE:
            case RESET_PREDICTIONS: {
                draft.predictionsStatus = {};
                break;
            }
            case SET_SELECTED_MODELS: {
                const { selectedModels, strainSubset } = action.payload;
               
                draft.predictionsStatus = {}; 
                draft.predictions = {};
                for (const index in selectedModels) {
                    const { modelRegionId, modelType, modelId, invalid, idIncomplete } = selectedModels[index];
                    const _invalid = (invalid || false);
                    if (idIncomplete ) {
                        continue;
                    }
                    const modelPath = [ strainSubset, modelRegionId, modelType, modelId];

                    const modelStatus =  _invalid ? 'nodata' : (get(state.predictionsStatus, modelPath) || 'none');
                    set(draft.predictionsStatus, modelPath, modelStatus);
                    const modelPredictions = _invalid ? undefined : get(state.predictions, modelPath);
                    set(draft.predictions, modelPath, modelPredictions);
                }
                break;
            }
            case FETCH_PREDICTIONS_REQUEST:
            case FETCH_FREQUENCIES_REQUEST: {
                const { strainSubset, selectedModels, allRegions, regions } = action.payload;
                
                const _regions = allRegions ? Object.keys(regions) : [strainSubset];

                if (selectedModels) {
                    _regions.forEach(subsetId => {
                        selectedModels.forEach(model => {
                            const { modelRegionId, modelType, modelId } = model;
                            // Set status to 'loading'
                            set(draft.predictionsStatus, [subsetId, modelRegionId, modelType, modelId], 'loading');
                        });
                    });
                }
                break;
            }

            case FETCH_PREDICTIONS_SUCCESS:
            case FETCH_FREQUENCIES_SUCCESS: {
                const { predictions } = action.payload;
              
                Object.keys(predictions).forEach(region => {
                    predictions[region].forEach(m => {
                        const { modelRegionId, modelType, modelId, predictionsData } = m;   
                        set(draft.predictions, [region, modelRegionId, modelType, modelId], predictionsData);
                        set(draft.predictionsStatus, [region, modelRegionId, modelType, modelId], 'loaded');
                    });
                });
                break;
            }
            case FETCH_PREDICTIONS_ERROR:
            case FETCH_FREQUENCIES_ERROR: {
                const { allRegions, strainSubset, selectedModels } = action.payload;
                const regionIds = allRegions ? Object.keys(draft.predictionsStatus) : [strainSubset];
                regionIds.forEach(regionId => {
                    selectedModels.forEach(model => {
                        const { modelRegionId, modelType, modelId } = model;
                        set(draft.predictionsStatus, [regionId, modelRegionId, modelType, modelId], 'error');
                        set(draft.predictions, [regionId, modelRegionId, modelType, modelId], undefined);
                    });
                });
               
                break;
            }

            default:
                break;
        }
    });

