import { createSelector, createSelectorCreator, lruMemoize } from 'reselect';
// import { quantile } from 'd3-array';
// import appConfig from '../../config/appConfig';
import {
    emptyObject,
    getMeasureScaleParamName,
} from '../../functions/functions';

import {
    treeOrderDictSelector,
    getTreeNodeAttrs,
    getVisibleTreeClades,
} from './treeDataSelector';
//import { getContinousMeasures, getMeasure, getMeasures } from './metadataSelector';
import { initColorScales } from '../../functions/scales';
import { isEqual } from 'lodash';
import { measuresSelector } from './metadataSelector';

const treeDataStatusSelector = (state) => state.treeData.treeDataStatus;
const zoomNodeIdSelector = (state) => state.parameters.zoomNodeId;
const getMeasureScalesDomains = ({ metadata }) => metadata.measureScalesDomains;
const getScales = ({ metadata }) => metadata.scales;

const getCalculatedDomain = ({ parameters }) => parameters.calculatedDomain;
const getClades = ({ cladeData }) => cladeData.clades;
const getRegions = ({ parameters }) => parameters.regions;
const getColorScalesParams = ({ parameters }) => parameters.colorScales;

const getParameters = ({ parameters }) => parameters;

const createDeepEqualSelector = createSelectorCreator(lruMemoize, isEqual);
// const rootCladeColor = '#a8a8a8';

const getComplexDomainParameterNames = createSelector(
    measuresSelector,
    (measures) =>
        Object.values(measures).reduce((acc, measure) => {
            const paramName = getMeasureScaleParamName(measure);
            if (measure.scale && paramName) acc[paramName] = true;
            return acc;
        }, {})
);

const getDomainParameterValues = createSelector(
    [getComplexDomainParameterNames, getParameters],
    (paramNames, parameters) => {
        const paramArr = Object.keys(paramNames);
        const res = paramArr.reduce((acc, pName) => {
            acc[pName] = parameters[pName];
            return acc;
        }, {});
        return res;
    }
);

const getComplexDomainParameterValues = createDeepEqualSelector(
    getDomainParameterValues,
    (parameters) => ({ ...parameters })
);

const getCladesDomain = createSelector(
    [getClades, getVisibleTreeClades],
    (clades, visibleClades) => {
        const domain = {};
        // console.log(Object.keys(clades).length, 'visibleClades = ',visibleClades);
        domain['clade.default'] = { data: clades || {} };
        domain['clade.default'].data = clades;
        domain['clade.default'].colorGetter = (key) =>
            domain['clade.default'].data[key]?.color;
        domain['clade.default'].labelGetter = (key) =>
            domain['clade.default'].data[key]?.label;
        domain['clade.default'].isVisible = (key) =>
            visibleClades && visibleClades[key];
        return domain;
    }
);

// const getCladesDomain = createSelector([getClades, getCladesOfType], (clades, visibleClades) => {
//     const domain = {};
//     console.log(Object.keys(clades).length, 'visibleClades = ',visibleClades);
//     domain['clade.default'] = { data: clades || {} };
//     domain['clade.default'].data = clades;
//     domain['clade.default'].colorGetter = (key) => domain['clade.default'].data[key]?.color;
//     domain['clade.default'].labelGetter = (key) => domain['clade.default'].data[key]?.label;
//     domain['clade.default'].isVisible = (key) => visibleClades && visibleClades[key];
//     return domain;
// })

const getRegionsDomain = createSelector(getRegions, (regions) => {
    const domain = {};
    domain['loc.default'] = { data: regions || {} };
    domain['loc.default'].colorGetter = (key) =>
        domain['loc.default'].data[key]?.color;
    domain['loc.default'].labelGetter = (key) =>
        domain['loc.default'].data[key]?.label;
    return domain;
});

const getPredefinedDiscreteColorBins = createSelector(
    [getCladesDomain, getRegionsDomain],
    (cladesDomain, regionsDomain) => {
        const domain = { ...cladesDomain, ...regionsDomain };
        // console.log('[getPredefinedDiscreteColorBins]', domain);
        return domain;
    }
);

const getMeasureBins = ({ metadata }) => metadata.measureBins;

const getMetadataMeasuresWithScales = createSelector(
    [
        measuresSelector,
        getMeasureScalesDomains,
        getScales,
        getComplexDomainParameterValues,
        getCalculatedDomain,
        getColorScalesParams,
        getMeasureBins,
        getPredefinedDiscreteColorBins,
    ],
    (
        measures,
        measureScalesDomains,
        scales,
        parameters,
        calculatedDomain,
        colorScalesParams,
        measureBins,
        predefinedDiscreteScales
    ) => {
        try {
            // console.log('1. [getMetadataMeasuresWithScales] --------');
            const getScaleName = (m) => {
                if (colorScalesParams[m]) return colorScalesParams[m];
                const scale = measures[m].scale;
                const paramName = getMeasureScaleParamName(measures[m]);

                // const paramName = scale?.paramName;
                const scaleName = paramName
                    ? scale?.[paramName]?.[parameters[paramName]]
                    : scale;

                // if (paramName)
                //     console.log(`[getScaleName]
                //     measure = ${m},
                //     paramName = ${paramName},
                //     parameters[${paramName}] = ${parameters[paramName]},
                //     scaleName = ${scaleName}
                //     scale =`, scale,
                //     'colorScalesParams =', colorScalesParams)
                return scaleName;
            };

            const getMeasureDomain = (m, scaleName) => {
                const paramName = getMeasureScaleParamName(measures[m]);
                const calcDomain =
                    measures[m] &&
                    (paramName && calculatedDomain && calculatedDomain[m]
                        ? getComplexDomain(
                            calculatedDomain[m],
                            parameters,
                            paramName
                        )
                        : calculatedDomain
                            ? calculatedDomain[m]
                            : undefined);
                const domain = calcDomain
                    ? [calcDomain.min, calcDomain.max]
                    : measureScalesDomains?.[m]?.[scaleName];
                return domain;
            };

            const res = Object.keys(measures).reduce((_measures, m) => {
                _measures[m] = { ...measures[m] };

                const scaleName = getScaleName(m);
                const domain = getMeasureDomain(m, scaleName);

                const discrete =
                    scales[scaleName]?.discrete === false ? false : true;
                const colorBins = measureBins?.[m]?.[scaleName];
                const discreteRange =
                    measures[m].discrete &&
                    (predefinedDiscreteScales[scaleName] ||
                        (colorBins && { data: colorBins }));

                // if (m === 'clade')
                //     console.log(m, 'discreteRange = ', discreteRange) ;//, measures[m].discrete, colorBins);

                // if (discreteRange && discreteRange.data && predefinedDiscreteScales[scaleName])
                //     discreteRange.isVisible = (key) => discreteRange.data[key];

                const range = discreteRange || scales[scaleName]?.range;

               
                _measures[m].scale = {
                    domain,
                    paramName: getMeasureScaleParamName(m), //measures[m].scale?.paramName,
                    range,
                    visibleRange: range?.data,
                    discrete,
                    numeric: measures[m].numeric,
                    scaleName,
                    bins: measureBins?.[m]?.[scaleName],
                };
                // if (measureBins?.[m]?.[scaleName]) 
                //     console.log({m, scaleName, range, bins: measureBins?.[m]?.[scaleName]});
                return _measures;
            }, {});
            // console.log(res);
            return res;
        } catch (e) {
            console.error(e);
            return null;
        }
    }
);

const getXYScaleMeasures = createSelector(measuresSelector, (measures) =>
    Object.keys(measures).filter(
        (m) => measures[m].xScale || measures[m].yScale
    )
);

const treeBranchMinMaxSelector = createSelector(
    [
        zoomNodeIdSelector,
        treeOrderDictSelector,
        getTreeNodeAttrs,
        treeDataStatusSelector,
        getXYScaleMeasures,
    ],
    (zoomNodeId, treeOrderDict, treeAttrs, treeDataStatus, xyMeasures) => {
        //console.log('[treeBranchMinMaxSelector]');
        if (treeDataStatus !== 'loaded' || emptyObject(treeAttrs)) return {};

        const xyScaleMeasures = Object.keys(treeOrderDict).reduce(
            (acc, id) => {
                const measuresV = xyMeasures.reduce((acc, m) => {
                    acc[m] = treeAttrs[id][m];
                    return acc;
                }, {});

                Object.keys(measuresV).forEach((m) => {
                    acc.min[m] = Math.min(
                        measuresV[m],
                        acc.min[m] || measuresV[m]
                    );
                    acc.max[m] = Math.max(
                        measuresV[m],
                        acc.max[m] || measuresV[m]
                    );
                });

                // if (!emptyObject(AADivergence)) {
                //     acc.minAADivergence = Math.min(AADivergence, acc.minAADivergence || AADivergence);
                //     acc.maxAADivergence = Math.max(AADivergence, acc.maxAADivergence || AADivergence);
                // }
                // if (!emptyObject(NLDivergence)) {
                //     acc.minNLDivergence = Math.min(NLDivergence, acc.minNLDivergence || NLDivergence);
                //     acc.maxNLDivergence = Math.max(NLDivergence, acc.maxNLDivergence || NLDivergence);
                // }
                return acc;
            },
            { min: {}, max: {} }
        );

        const { time } = treeAttrs[zoomNodeId];
        // console.log('zoomNodeId: time = ',time, daysToDate(time).toLocaleDateString())
        const maxTime =
            treeAttrs[zoomNodeId].maxTime || treeAttrs[zoomNodeId].time;

        const treeMinMax = {
            min: {
                time,
                ...xyScaleMeasures.min,
                //order: minOrder || 1,
            },
            max: {
                time: maxTime,
                ...xyScaleMeasures.max,
                //..order: maxOrder,
            },
        };

        //console.log('minMax',treeMinMax)
        return treeMinMax;
    }
);

const getComplexDomain = (domain, parameters, paramName) => {
    let minMax = domain;

    if (paramName) minMax = minMax[paramName][parameters[paramName]];

    //console.log('[getComplexDomain]', paramName, minMax)
    return minMax;
};

const domainMinMaxSelector = createSelector(
    getMetadataMeasuresWithScales,
    /*continousMeasures,*/(measures) /*, calculatedDomain, parameters*/ => {
        //console.log('[domainMinMaxSelector]');// continousMeasures = ', continousMeasures)
        const getMinMaxValues = (name) => {
            const domain =
                measures[name] &&
                measures[name].scale &&
                measures[name].scale.domain;

            if (!domain) return { min: {}, max: {} };
            const minMaxDomain = {
                min: domain[0],
                max: domain[domain.length - 1],
            };
            const res = {
                min: {
                    [name]: minMaxDomain.min,
                },
                max: {
                    [name]: minMaxDomain.max,
                },
            };

            return res;
        };
        //console.log('continousMeasures', continousMeasures)

        const minMax = //continousMeasures.reduce(
            Object.keys(measures)
                .filter(
                    (m) =>
                        measures[m] &&
                        !measures[m].discrete &&
                        measures[m].scale &&
                        measures[m].scale.domain
                )
                .reduce(
                    (_minMax, key) => {
                        // const measure = measures[key];
                        //console.log('measure = ', key)
                        //if (!measure) return _minMax;
                        // const attrName = measure.value || key;
                        const minMaxVal = getMinMaxValues(key);
                        // if (key === 'antigenic') {
                        //     console.log(`${key}, ${attrName}`);
                        //     console.log(minMaxVal);
                        // }
                        return {
                            min: { ..._minMax.min, ...minMaxVal.min },
                            max: { ..._minMax.max, ...minMaxVal.max },
                        };
                    },
                    { min: {}, max: {} }
                );
        return minMax;
    }
);

const getRanges = createSelector(
    [
        treeBranchMinMaxSelector,
        domainMinMaxSelector,
        getMetadataMeasuresWithScales,
    ],
    (treeMinMax, domainMinMax, measures) => {
        //console.log('1. [getRanges]');

        const mergeDomains = (m1, m2, callback) =>
            Object.keys(m2 || {}).reduce((acc, m) => {
                acc[m] = m2[m];
                if (m1[m]) acc[m] = callback(m1[m], m2[m]);
                return acc;
            }, {});

        const corrMin = mergeDomains(
            domainMinMax.min,
            treeMinMax.min,
            Math.max
        );
        const corrMax = mergeDomains(
            domainMinMax.max,
            treeMinMax.max,
            Math.min
        );
        //console.log(treeMinMax);
        const ranges = {
            min: {
                ...domainMinMax.min,
                ...corrMin,
            },
            max: {
                ...domainMinMax.max,
                ...corrMax,
            },
        };

        // console.log('[getRanges] measures', measures);
        // console.log('[getRanges] ranges', ranges)

        initColorScales(measures, ranges);
        //console.log('[getRanges]', measures, 'ranges = ',ranges)
        return ranges;
    }
);

const getColorBy = ({ parameters }) => {
    //console.log('[getColorBy] parameters', parameters);
    return parameters.colorBy;
};

const getGeoMapColorBy = ({ parameters }) => parameters.geoMapColorBy;

export const getSelectedMeasureTreeLegend = createSelector(
    [getMetadataMeasuresWithScales, getColorBy, getVisibleTreeClades],
    (measures, colorBy, visibleClades) => {
        const selectedMeasure = measures[colorBy] || {};
        //console.log('[getSelectedMeasureTreeLegend] selectedMeasure', {selectedMeasure, colorBy});
        const range = selectedMeasure.scale.range;
        let legendData = range?.data;
        if (colorBy === 'clade') {
            legendData = Object.keys(legendData).reduce((acc, key) => {
                if (visibleClades && visibleClades[key])
                    acc[key] = legendData[key];
                return acc;
            }, {});
        }
        return legendData;
    }
);

const getGeojsonFeatures = ({ geomap }) => geomap.geojson?.features;

const getFilteredGeoMapCategories = createSelector(
    getGeojsonFeatures,
    (features) => {
        if (!features) return {};
        // console.log(features);
        const res = (features || []).reduce((acc, feature) => {
            acc[feature.properties.groupId] = true;
            return acc;
        }, {});
        return res;
    }
);

export const getSelectedMeasureGeoMapLegend = createSelector(
    [
        getMetadataMeasuresWithScales,
        getGeoMapColorBy,
        getFilteredGeoMapCategories,
    ],
    (measures, colorBy, visibleGeoCats) => {
        const selectedMeasure = measures[colorBy] || {};
        //console.log(selectedMeasure.scale?.range?.data);
        const range = selectedMeasure.scale.range;
        let legendData = range?.data;

        if (visibleGeoCats) {
            legendData = Object.keys(legendData || {}).reduce((acc, key) => {
                if (visibleGeoCats[key]) acc[key] = legendData[key];
                return acc;
            }, {});
        }
        // console.log('[getSelectedMeasureGeoMapLegend]', colorBy, legendData, visibleGeoCats);
        return legendData;
    }
);

export {
    getRanges,
    getMetadataMeasuresWithScales,
    getComplexDomainParameterValues,
};
