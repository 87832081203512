import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, IconButton, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Decimal from 'decimal.js';
import { MinusIcon, PlusIcon, ValidateIcon } from '../SvgIcons/SequencesInputIcons';
import { StyledTextField } from '../../assets/GlobalStyles/TextField';

const useStyles = makeStyles(() => ({
    textField: {
        margin: '8px 0px',
    },
    icon: {
        padding: 0,
        marginRight: '3px',
        '&:hover': {
            color: '#000000',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            color: '#000000',
        },
    },
}));

const emptyValues = [null, undefined, ''];
const incompleteValues = ['-', '-.', '.'];

const NumberInput = ({
    id,
    label,
    value,
    onAccept,
    fullWidth = true,
    increment = 1,
    min,
    max,
    showButtons = true,
    allowFloat = false,
    onValidate = null,
    onValidateChange = null,
    required = false,
    error: propError,
    errorText: propErrorText,
    helperText: propHelperText,
    ...rest
}) => {
    const [numberValue, setNumberValue] = useState(value != null ? String(value) : '');
    const [error, setError] = useState(propError || false);
    const [errorText, setErrorText] = useState(propErrorText || '');
    const [helperText, setHelperText] = useState(propHelperText || '');
    const classes = useStyles();

    useEffect(() => {
        setNumberValue(value !== null && value !== undefined ? String(value) : '');
    }, [value]);

    const setErrorState = (hasError, errText = '', hlpText = '') => {
        setError(hasError);
        setErrorText(hasError ? errText : '');
        setHelperText(hlpText || '');
        onValidateChange?.(!hasError);
    };

    const validateNumber = async (val) => {
        // This function returns an object: { isValid: boolean, parsedValue: number|null }
        // Also updates error states internally.
 
        // Empty or undefined values
        if (emptyValues.includes(val)) {
            if (required) {
                setErrorState(true, 'Required field', '');
                return { isValid: false, parsedValue: null };
            }
            // Optional empty
            if (onValidate) {
                const { error, errorText, helperText } = await onValidate(val);
                setErrorState(error, errorText || 'Invalid value', helperText);
                return { isValid: !error, parsedValue: null };
            }
            // Valid empty if not required and no custom validation
            setErrorState(false, '', '');
            return { isValid: true, parsedValue: null };
        }

        // Incomplete values
        if (incompleteValues.includes(val)) {
            setErrorState(false, '', '');
            return { isValid: true, parsedValue: null };
        }

        // Built-in validation
        let numValue;
        try {
            numValue = new Decimal(val);
        } catch {
            setErrorState(true, 'Invalid number', '');
            return { isValid: false, parsedValue: null };
        }

        // Check min/max
        if (max !== undefined && numValue.greaterThan(max)) {
            setErrorState(true, `Value cannot be greater than ${max}`, '');
            return { isValid: false, parsedValue: numValue.toNumber() };
        }
        if (min !== undefined && numValue.lessThan(min)) {
            setErrorState(true, `Value cannot be less than ${min}`, '');
            return { isValid: false, parsedValue: numValue.toNumber() };
        }

        // Passed built-in checks, run custom validation if any
        if (onValidate) {
            const { error, errorText, helperText } = await onValidate(numValue.toNumber());
            setErrorState(error, errorText || 'Invalid value', helperText);
            return { isValid: !error, parsedValue: error ? null : numValue.toNumber() };
        }

        // All good
        setErrorState(false, '', '');
        return { isValid: true, parsedValue: numValue.toNumber() };
    };

    const handleAccept = async (value = numberValue) => {
        const { isValid, parsedValue } = await validateNumber(value);
        if (isValid && onAccept) {
            onAccept(parsedValue);
        }
    };

    const adjustValue = async (delta) => {
        try {
            const current = numberValue !== '' ? new Decimal(numberValue) : new Decimal(0);
            const newValue = current.plus(new Decimal(delta)).toString();
            setNumberValue(newValue);
            await handleAccept(newValue);
        } catch {
            setErrorState(true, 'Invalid number', '');
        }
    };

    const handleIncrement = () => adjustValue(increment);
    const handleDecrement = () => adjustValue(-increment);

    const handleKeyDown = async (event) => {
        const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Home', 'End', '-'];
        if (allowFloat) allowedKeys.push('.');

        if ((event.ctrlKey || event.metaKey) && ['a', 'c', 'v', 'x'].includes(event.key.toLowerCase())) {
            return; // Allow copy/paste
        }

        // Restrict invalid chars
        if (!allowedKeys.includes(event.key) && !/^[0-9]$/.test(event.key)) {
            event.preventDefault();
        }

        // Avoid multiple decimals
        if (event.key === '.' && numberValue.includes('.')) {
            event.preventDefault();
        }

        // Enter triggers accept
        if (event.key === 'Enter') {
            event.preventDefault();
            await handleAccept();
        }
    };

    const handleChange = (event) => {
        const newValue = event.target.value;
        const regex = allowFloat ? /^-?\d*\.?\d*$/ : /^-?\d*$/;

        if (regex.test(newValue)) {
            const integerPart = newValue.replace('-', '').split('.')[0];
            if (!(integerPart.length > 1 && integerPart.startsWith('0'))) {
                setNumberValue(newValue);
            }
        }
    };

    // Initial validation on mount (and/or whenever value changes)
    useEffect(() => {
        (async () => {
            await validateNumber(numberValue);
        })();
        // We depend only on numberValue to re-validate if value changes
         
    }, [numberValue]);

    return (
        <FormControl fullWidth={fullWidth}>
            <StyledTextField
                id={id}
                label={label}
                type="text"
                inputMode="decimal"
                pattern={allowFloat ? '[0-9]*.?[0-9]*' : '[0-9]*'}
                className={classes.textField}
                value={numberValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={(e) => handleAccept(e.target.value)}
                min={min}
                error={error}
                helperText={error ? errorText : helperText}
                {...rest}
                InputProps={{
                    endAdornment: (showButtons || onValidate) && (
                        <InputAdornment position="end">
                            {showButtons && (
                                <>
                                    <IconButton className={classes.icon} onClick={handleDecrement}>
                                        <MinusIcon />
                                    </IconButton>
                                    <IconButton className={classes.icon} onClick={handleIncrement}>
                                        <PlusIcon />
                                    </IconButton>
                                </>
                            )}
                            {onValidate && (
                                <IconButton className={classes.icon} onClick={handleAccept}>
                                    <ValidateIcon color="#0000FF" />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
        </FormControl>
    );
};

NumberInput.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onAccept: PropTypes.func.isRequired,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    increment: PropTypes.number,
    showButtons: PropTypes.bool,
    allowFloat: PropTypes.bool,
    onValidate: PropTypes.func,
    onValidateChange: PropTypes.func,
    helperText: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    errorText: PropTypes.string,
};

export default NumberInput;
