// External imports
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { capitalize, lowerCase } from 'lodash';

// Internal imports
import { setLayoutParameters } from '../../redux/actions/parametersActions';
import appConfig from '../../config/appConfig';
import SelectInput from '../Common/SelectInput';

const CladeBarTypeSelector = props => {
    const { cladeBarType, cladeTypes, setLayoutParameters } = props;

    const handleValueChange = value => {
        setLayoutParameters({ cladeBarType: value });
    };

    return <SelectInput
        id='cladeBarType'
        label='Clade bar type'
        value={cladeBarType}
        onChange={handleValueChange}
        options={cladeTypes}
        getOptionValue={option => option.key}
        getOptionLabel={option => capitalize(lowerCase(option.label))} 
    />;
};

CladeBarTypeSelector.propTypes = {
    cladeBarType: PropTypes.string,
    cladeTypes: PropTypes.arrayOf(PropTypes.shape({ 
        key: PropTypes.string, 
        label: PropTypes.string 
    })),
    setLayoutParameters: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    cladeBarType: state.parameters.cladeBarType,
    cladeTypes: state.metadata.cladeTypes || appConfig.cladeTypes
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setLayoutParameters
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CladeBarTypeSelector);
