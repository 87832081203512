// External imports
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid2';

// Internal imports - Components
import NumberInput from '../Common/NumberInput';

// Internal imports - Actions
import { resetPredictions } from '../../redux/actions/predictionsActions';


const SigmaAgAndTauSelector = ({ sigmaAg, tau, updateSelectedModelsNumParams, index, resetPredictions}) => {
   
    const updateParameter = (type) => (value) => {
        updateSelectedModelsNumParams(index, value, type);
        resetPredictions();
    };

    const updateSigmaAg = updateParameter('sigmaAg');
    const updateTau = updateParameter('tau');
 
    return (
        <Grid container columnSpacing={2}>
            <Grid size={6}>
                <NumberInput
                    id="sigmaAg"
                    label="SigmaAg"
                    value={sigmaAg}
                    onAccept={updateSigmaAg}
                    allowFloat={true}
                />
            </Grid>

            <Grid size={6} >
                <NumberInput
                    id="tau"
                    label="Tau"
                    value={tau}
                    onAccept={updateTau}
                    allowFloat={true}
                />
            </Grid>
        </Grid>
    );
};

SigmaAgAndTauSelector.propTypes = {
    // Required props
    index: PropTypes.number.isRequired,
    sigmaAg: PropTypes.number.isRequired,
    tau: PropTypes.number.isRequired,
    resetPredictions: PropTypes.func.isRequired,
    updateSelectedModelsNumParams: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    resetPredictions: payload => dispatch(resetPredictions(payload))
});

export default connect(null, mapDispatchToProps)(SigmaAgAndTauSelector);
