import { produce } from 'immer';
import {
    SELECT_NODE_DATA,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    SET_ACTIVE_LEGEND_OPTION,
    FETCH_NODE_REQUEST,
    FETCH_NODE_SUCCESS,
    FETCH_NODE_ERROR,
    SET_PARAMETERS
} from '../actions/actionTypes';

let nodeDataInitialState = {};
export const setNodeDataInitialState = (state) => {
    nodeDataInitialState = state;
};

export const nodeDataReducer = (state = nodeDataInitialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case RESET_SESSION:
            case SIGNOUT_REQUEST: {
                return nodeDataInitialState; // Reset to initial state
            }
            case SET_PARAMETERS: {
                const { strainId, searchStrainMode } = action.payload.parameters;
                draft.nodeId = searchStrainMode && strainId ? +strainId : null;
                break;
            }
            case SELECT_NODE_DATA: {
                draft.nodeId = action.payload?.nodeId;
                draft.nodeType = action.payload?.nodeType;
                draft.onlyHighlight = action.payload?.onlyHighlight || false;
                break;
            }
            case SET_ACTIVE_LEGEND_OPTION: {
                const { type, value, option } = action.payload;
                if (type === 'strainTree') {
                    draft.activeLegendOption = { value, option };
                } else {
                    draft.activeLegendOption = undefined;
                }
                break;
            }
            case FETCH_NODE_REQUEST: {
                draft.nodeData = [];
                draft.loadingNode = true;
                break;
            }
            case FETCH_NODE_SUCCESS: {
                draft.nodeData = action.payload.data || {};
                draft.loadingNode = false;
                break;
            }
            case FETCH_NODE_ERROR: {
                draft.error = action.payload.error?.message;
                draft.loadingNode = false;
                break;
            }
            default:
                break;
        }
    });
};
