import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ScalesLegend from './ScalesLegend';
import { getNodeDataById } from '../../../redux/selectors/nodeDataSelector';
import { getSelectedMeasureGeoMapLegend } from '../../../redux/selectors/rangeDataSelector';
 
const GeoMapLegend =  (props) => {
        
    const { geoMapColorBy, visibleBins } = props;

    return (
        <ScalesLegend
            measureName={geoMapColorBy}
            valueGetter={(state) => getNodeDataById(state)[geoMapColorBy]}
            ticks={3}
            inContainer
            precision={3}
            colorLegendVisible={true} 
            visibleBins={visibleBins}
            mouseEventsEnabled={true}
        />
    );
};


const mapStateToProps = (state) => {
    const { geoMapColorBy } = state.parameters;
    const visibleBins = getSelectedMeasureGeoMapLegend(state);
    
    return {
        geoMapColorBy,
        visibleBins
    };
};


export default connect(mapStateToProps)(GeoMapLegend);

GeoMapLegend.propTypes = {
    geoMapColorBy: PropTypes.string.isRequired,
    visibleBins: PropTypes.object,
};
