import React from 'react';
import { FormControl, TextField, Input } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomLabel from '../../../assets/GlobalStyles/CustomLabel';
import VaccinesLegend from '../../ColorLegend/Legends/VaccinesLegend';

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: 120,
        margin: '8px 0px',
    },
}));

const CladesSelector = () => {
    const classes = useStyles();

    return (
        <FormControl fullWidth className={classes.formControl}>
            <CustomLabel id="clades" label="Clades" />
            <TextField
                variant="standard"
                fullWidth
                /* 
         * Important: keep MUI’s Input for "standard" variant.
         * This ensures the underline gets rendered.
         */
                slots={{
                    input: Input, 
                }}
                /*
         * Then override only the underlying <input> element 
         * with your VaccinesLegend, preserving the underline.
         */
                slotProps={{
                    input: {
                        inputComponent: VaccinesLegend
                    },
                }}
            />
        </FormControl>
    );
};

export default CladesSelector;
