import React from 'react';
import MenuButton from '../MenuButton';


const HeaderOutsideLinks = (props) => {
    const { headerLinks } = props;
    return <>
        {headerLinks.map(link => (
            <MenuButton key={`hl-mb-${link.value}`} label={link.label} variant="text"
                {...(link.internal ? { internalLink: link.value } : { externalLink: link.value })}
            />
        ))}
    </>;
};

export default HeaderOutsideLinks;
