import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../Common/SelectInput';
import Grid from '@mui/material/Grid2';

export const AntigenicModelInput = ({ label, value, name, options, changeHandler, error, errorTxt, disabled }) => (
    <Grid size={6}>
        <SelectInput
            id={name}
            label={label}
            value={value}
            onChange={changeHandler}
            options={options}
            disabled={disabled}
            error={error}
            errorText={errorTxt}
            getOptionValue={option => option.id}
            getOptionLabel ={option => option.id === 'ALL' ? 'All' : option.id}
        />
    </Grid>
);

AntigenicModelInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    errorTxt: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
    classes: PropTypes.shape({ formControl: PropTypes.string, label: PropTypes.string }),
    changeHandler: PropTypes.func,
};
