import { cleanEnv, str, bool} from 'envalid';

function validateEnv() {
    // eslint-disable-next-line no-undef
    return cleanEnv(process.env, {
        NODE_ENV: str({ choices: ['development', 'test', 'production'], default: 'development' }),
        REACT_APP_AUTH_SERVER_LINK: str({ default: '' }),
        REACT_APP_FRONTEND_PREFIX: str({ default: '' }),
        REACT_APP_SHOW_LOGS: bool({ default: false }),
        REACT_APP_SHOW_REDUX: bool({ default: false }),
        REACT_APP_FRONTEND_SECRET_HEADER:str({default: ''}),
    });
}
const _config = validateEnv();

const config = {
    ..._config,
    serverLink: _config.REACT_APP_AUTH_SERVER_LINK,
    frontendPrefix: _config.REACT_APP_FRONTEND_PREFIX,
}

export default config;


