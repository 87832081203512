import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { CardActions, Button, Typography } from '@mui/material';
import { signInStyles } from './styles/signInStyles';
import { StyledTextField } from '../../assets/GlobalStyles/TextField';

const LoginFormBase = (props) => {
    const { email, password, err, handleChange, clickSubmit, changeView, classes } = props;
    const [error, setError] = useState('');
    const [errorText, setErrorText] = useState('');
    const [errorLink, setErrorLink] = useState('');

    useEffect(() => {
        if (!err)
            return;

        const colonIndex = err.indexOf(':');
        if (colonIndex !== -1) {
            const text = err.substring(0, colonIndex).trim();
            const link = err.substring(colonIndex + 1).trim();
            setErrorText(text);
            setErrorLink(link);
        }
    }, [err]);

    const validateAndSend = () => {
        if (email && password) {
            setError('');
            clickSubmit();
        } else {
            setError('Email and password are required');
        }
    };

    return <>
        <StyledTextField
            id="email"
            type="email"
            label="Email / username"
            className={classes.textField}
            value={email}
            onChange={(e) => handleChange(e, 'email')}
        />
        <StyledTextField
            id="password"
            type="password"
            label="Password"
            className={classes.textField}
            value={password}
            onChange={(e) => handleChange(e, 'password')}
        />
        {(err || error) && (
            <Typography component="p" color="error" className={classes.error}>
                {
                    !errorLink ?
                        (error || err || JSON.stringify(err))
                        :
                        (<>
                            {errorText}{' '}
                            <span target='_blank' className={classes.link} onClick={() => window.open(errorLink, '_blank')}>
                                here
                            </span>
                        </>)
                }
            </Typography>
        )}
        <CardActions className={classes.cardActions}>
            <Button
                id='login'
                onClick={validateAndSend}
                className={classes.submit}
            >
                Sign in
            </Button>
            <div className={classes.link} onClick={() => changeView('reset')}>Forgot your password?</div>
        </CardActions>
        {/* <span>Don't have account yet? &nbsp;<span className={classes.link} onClick={() => changeView('register')}>Click here</span></span> */}

    </>;
};

export const LoginForm = withStyles(signInStyles)(LoginFormBase);
