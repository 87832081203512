import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import Alerts from '../Alerts/Alerts';
import { setParameters } from '../../redux/actions/parametersActions';
import AdditionalColorBySelector from '../OptionsSelector/AdditionalColorBySelector';
import ColorBySelector from '../OptionsSelector/ColorBySelector';
import GeoFreqCategorySelector from './helpers/GeoFreqCategorySelector';
import PredictionBaselinePicker from '../DatePickers/PredictionBaselinePicker';
import GeoFreqSpanSelector from '../OptionsSelector/GeoFreqSpanSelector';
import HamburgerIcon from '../SvgIcons/HamburgerIcon';
import { setHiddenMenu } from '../../redux/actions/uiActions';
import { HideIcon } from '../Alerts/styles';
import { getIsMobile } from '../../redux/selectors/statusSelector';
import BuildSelector from '../OptionsSelector/LineageSelector/BuildSelector';
import GeoMapLegend from '../ColorLegend/Legends/GeoMapLegend';
import SubmissionDatePicker from '../DatePickers/SubmissionDatePicker';

const styles = (theme) => ({
    root: {
        height: '100%',
        flexWrap: 'wrap',
        textAlign: 'left',
        padding: '10px 15px',
        backgroundColor: '#fff',
    },

    formControl: {
        minWidth: 120,
        margin: '8px 0px',
    },
    colFormControl: {
        width: '49%',
        margin: '8px 4px 8px 0px',
    },
    lastColFormControl: {
        minWidth: 120,
        width: '50%',
        margin: '8px 0px 8px 0px',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    button: {
        width: '100%',
    },
    deletednfo: {
        margin: '24px 0 8px',
    },
    buttonWrapper: {
        width: '100%',
        height: '30px',
    },
    hideButton: {
        padding: '0px',
        float: 'right',
    },
    expandButton: {
        marginTop: '7px',
    },
    mapBarMobile: {
        backgroundColor: 'white',
        position: 'absolute',
    },
});

const GeoMapOptions = (props) => {
    const {
        hiddenMenu,
        setHiddenMenu,
        classes,
        permissions,
        isMobile,
        intro,
    } = props;

    const handleHideClick = () => {
        setHiddenMenu({ hiddenMenu: !hiddenMenu });
    };

    return (
        <div className={isMobile ? classes.mapBarMobile : {}}>
            {!hiddenMenu ? (
                <form className={classes.root} autoComplete="off">
                    {!isMobile && (
                        <div className={classes.buttonWrapper}>
                            <IconButton className={classes.hideButton} onClick={handleHideClick}>
                                <HideIcon />
                            </IconButton>
                        </div>
                    )}
                    <Alerts module="geomap" />
                    <BuildSelector />
                    {!intro && <ColorBySelector mapSelector />}
                    {<AdditionalColorBySelector mapSelector />}
                    {!intro && <GeoFreqCategorySelector mapSelector />}
                    <GeoFreqSpanSelector />
                    {!intro && <SubmissionDatePicker view='geomap'/>}
                    <PredictionBaselinePicker type={'geoMap'} />
                    <GeoMapLegend />
                </form>
            ) : (
                <IconButton className={classes.expandButton} onClick={handleHideClick}>
                    <HamburgerIcon />
                </IconButton>
            )}
        </div>
    );
};

GeoMapOptions.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string,
        button: PropTypes.string,
        formControl: PropTypes.string,
    }),
    setParameters: PropTypes.func,
};

const mapStateToProps = (state) => {
    const { parameters, user, ui } = state;
    return ({
        permissions: user.permissions.mapPermissions,
        hiddenMenu: ui.hiddenMenu,
        hiddenMenuMobile: ui.hiddenMenuMobile,
        intro: parameters.intro,
        isMobile: getIsMobile()
    });
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        setParameters,
        setHiddenMenu,
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GeoMapOptions));
