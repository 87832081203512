import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EditDialog from '../Dialogs/EditDialog';
import config from '../../../../config/envConfig';
import { postAxios, fetchAxios } from '../../../../functions/axiosRequests';

const EditUser = ({ user, setLoaded, setInfoDialog, setInfo, setAction }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [roles, setRoles] = useState([]);

    const handleClick = async () => {
        await fetchRoles();
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const fetchRoles = async () => {
        try {
            const url = `${config.serverLink}/api/admin/getAllRoles`;
            const response = await fetchAxios(url);
            const { roles } = response.data;
            setRoles(roles);
        }
        catch (error) {
            console.error(error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during fetching roles: ${message}`);
            handleCloseDialog();
            setInfoDialog(true);
        }
    };

    const updateUser = async (updatedUser) => {
        try {
            const url = `${config.serverLink}/api/admin/editUser`;
            const body = { user: updatedUser };
            await postAxios(url, body);
            setInfo(`User ${user.email} updated successfully.`);
        } catch (error) {
            console.error('Error updating user:', error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during updating "${user.email}": ${message}`);
        } finally {
            setAction('Update user');
            setLoaded(false);
            handleCloseDialog();
            setInfoDialog(true);
        }
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <EditIcon />
            </IconButton>
            {roles.length > 0 && (
                <EditDialog
                    user={user}
                    handleCloseDialog={handleCloseDialog}
                    openDialog={openDialog}
                    updateUser={updateUser}
                    roles={roles}
                />
            )}
        </>
    );
};

EditUser.propTypes = {
    user: PropTypes.shape({
        email: PropTypes.string.isRequired
    }).isRequired,
    setLoaded: PropTypes.func.isRequired,
    setInfoDialog: PropTypes.func.isRequired,
    setInfo: PropTypes.func.isRequired,
    setAction: PropTypes.func.isRequired
};

export default EditUser;
