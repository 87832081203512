import { makeStyles } from '@mui/styles';

export const styles = makeStyles(() => ({
    divider: {
        margin: '4px 0px 10px 0px',
        backgroundColor: 'gray',
        height: '0.5px',
        width: '100%',
    },
    title: {
        marginTop: '10px',
        paddingLeft: '0px',
        fontSize: '10px',
    }
}));


