// Action Types
import { received /*, request*/ } from '../baseActions';
import {
    // SHOW_ALERT,
    // CLOSE_ALERT,
    SET_AUTOCOMPLETE_OPTIONS,
} from './actionTypes';

// export const showAlert = (payload) => received(SHOW_ALERT, payload);
// export const showInfo = (payload) => received(SHOW_ALERT, { message: payload, type: 'info' });
// export const showWarning = (payload) => received(SHOW_ALERT, { message: payload, type: 'warning' });
// export const showError = (payload) => received(SHOW_ALERT, { message: payload, type: 'error' });
export const setAutompleteOptions = (payload) => received(SET_AUTOCOMPLETE_OPTIONS, payload);

// export const closeAlert = () => request(CLOSE_ALERT);

// export {
//     showAlert,
//     showInfo,
//     showWarning,
//     showError,
//     closeAlert,
//     setAlertStatus
// };
