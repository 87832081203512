import {
    FETCH_LINEAGES_REQUEST,
    FETCH_LINEAGES_SUCCESS,
    FETCH_LINEAGES_ERROR,
    FETCH_LINEAGE_REQUEST,
    FETCH_LINEAGE_SUCCESS,
    FETCH_LINEAGE_ERROR,
} from './actionTypes';

import { received } from '../baseActions';

const fetchLineages = (payload) => received(FETCH_LINEAGES_REQUEST, payload);
const fetchLineagesSuccess = (payload) => received(FETCH_LINEAGES_SUCCESS, payload);
const fetchLineagesError = (payload) => received(FETCH_LINEAGES_ERROR, payload);

const fetchLineage = (payload) => received(FETCH_LINEAGE_REQUEST, payload);
const fetchLineageSuccess = (payload) => received(FETCH_LINEAGE_SUCCESS, payload);
const fetchLineageError = (payload) => received(FETCH_LINEAGE_ERROR, payload);


export {
    fetchLineages,
    fetchLineagesSuccess,
    fetchLineagesError,
    fetchLineage,
    fetchLineageSuccess,
    fetchLineageError
};
