import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ParametersPanel from './Parameters/ParametersPanel';
import { styles } from './styles';
import { TabPanel, a11yProps } from '../../assets/GlobalStyles/TabElements';
import ScalesPanel from './Scales/ScalesPanel';
import MeasuresPanel from './Measures/MeasuresPanel';
import MutationClassesPanel from './MutationClasses/MutationClassesPanel';
import SubsetsPanel from './Subsets/SubsetsPanel';
import RegionsPanel from './Regions/RegionsPanel';
import { useSelector } from 'react-redux';
import ErrorAlert from '../ErrorAlert/ErrorAlert';

export const SettingsPanel = () => {
    const [value, setValue] = React.useState(0);
    const classes = styles();
    const dataAdminRights = useSelector((state) => state.user.permissions.dataAdminRights);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabs = [
        { label: 'Parameters', component: ParametersPanel },
        { label: 'Measures', component: MeasuresPanel },
        { label: 'Scales', component: ScalesPanel },
        { label: 'Mutation classes', component: MutationClassesPanel },
        { label: 'Subsets', component: SubsetsPanel },
        { label: 'Regions', component: RegionsPanel }
    ];

    const tabPanels = [
        { component: ParametersPanel },
        { component: MeasuresPanel },
        { component: ScalesPanel }, 
        { component: MutationClassesPanel },
        { component: SubsetsPanel },
        { component: RegionsPanel }
    ];

    return (
        dataAdminRights && <Box className={classes.root}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} >
                    {tabs.map((tab, index) => (
                        <Tab key={index} label={tab.label} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </Box>

            {tabPanels.map((panel, index) => (
                <TabPanel key={index} value={value} index={index}>
                    <Grid container className={classes.container}>
                        <Grid size={12} className={classes.item}>
                            <panel.component />
                        </Grid>
                    </Grid>
                </TabPanel>
            ))}
            <ErrorAlert />
        </Box>
    );
};
