import { select, pointer } from 'd3-selection';
import { area, line, curveMonotoneX, curveStep } from 'd3-shape';
import D3Component from '../../D3Component/D3Component';
import {
    getScaledValue,
    initVaccinesChartScales,
} from '../../../functions/scales';
import { getTextMetrics } from '../../../functions/functions';
import { numFormat } from '../../../functions/formats';
import { drawChartAxes } from './chartAxes';
import { RENDER_STATUS } from '../../../config/consts';
import removeCursor from '../../../assets/images/cursorRemove.svg';

const missingDataColor = '#dee0e2';

class VaccinesChartD3 extends D3Component {
    constructor(componentId) {
        super();
        this.componentId = componentId;
        this.exportWidthLabelsPadding = 200;
        this.componentName = 'vaccinesChart';
        this.padding = { top: 20, bottom: 50, left: 0, right: 20, middle:40, title: 30 };
        this.paddingExport = { top: 0, bottom: 0, left: 0, right: 0, title: 0 };
    
        this.grad = null;
    
        this.blocked = false;
    
        this.sectionHeight = 50;
        this.strainTextOffset = 5;
    }

    additionalPropsActions = (props) => {
        this.padding.left = (props.maxTextLength || 0) + this.strainTextOffset*2;
        // console.log('additionalPropsActions', {maxTextLength: props.maxTextLength, paddingLeft: this.padding.left});
    }
   

    // getWidth = () =>  this.width;

    xScale = () => 'xVaccinesScale';

    yScale = () => 'yVaccinesScale';

    yAllScale = () => 'yAllVaccinesScale';
   

    // curve = () => ((this.props && this.props.plotType === 'frequencies') ? curveMonotoneX : curveLinear);

    yVal = (d) => d;

    x = (valAttr) => d => getScaledValue(this.xScale(), d[valAttr]);
    
    y = d => this.sectionHeight * (d.strainIndex-1) + getScaledValue(this.yScale(), d.cladeIndex);

    yStrain = d => getScaledValue(this.yAllScale(), d.strainIndex-0.5);

    getCalculatedHeight = () => this.props.refStrainsCnt 
        ? this.sectionHeight * (this.props.refStrainsCnt + 1.5) + this.getPadding().top + this.getPadding().bottom + this.getPadding().title
        : 0;
    getCalculatedWidth = (width) => this.props.refStrainsCnt 
        ? width
        : 0;

    // Graph specific elements
    prepareGraphElements = () => {

        // const {  } = this.props;
        const svg = select(this.mountNode).select('g.graph');

        const titleLayer = svg.append('g')
            .attr('id', 'title');   

        const title = 'Protection'; 
        titleLayer.append('g')
            .attr('id', `${this.componentName}_title`)
            .append('text')
            .attr('transform', `translate(0, ${this.getPadding().title})`)
            .text(title);
        const titleDiff = 'Differential protection';
        titleLayer.append('g')
            .attr('id', `${this.componentName}_titleDiff`)
            .append('text')
            .attr('transform', `translate(0, ${this.getPadding().title})`)
            .text(titleDiff);
        
        const axesSvg = svg.append('g').attr('id', 'axes');

        axesSvg.append('rect')
            .attr('id', 'vaccinesAxis_background')
            .attr('pointer-events', 'all')
            .attr('rx', 7)
            .style('fill', '#FFFFFF');
        const protAxes = axesSvg.append('g')
            .attr('id', 'protAxes')
        const diffAxes = axesSvg.append('g')
            .attr('id', 'diffAxes')

        protAxes.append('g')
            .attr('id', `${this.componentName}XAxis`);
        protAxes.append('g')
            .attr('id', `${this.componentName}ThresholdRange`)
        protAxes.append('g')
            .attr('id', `${this.componentName}YAxis`)

        diffAxes.append('g')
            .attr('id', `${this.componentName}DiffXAxis`)
        diffAxes.append('g')
            .attr('id', `${this.componentName}DiffYAxis`)
            // .attr('transform', `translate(${this.getPadding().left}, 0)`);


        const plot = svg.append('g')
            .attr('id', `${this.componentName}Plot`)
            //.attr('rx', 7)
            .style('fill', '#FFFFFF');
            // .attr('transform', `translate(${this.getPadding().left}, 0)`);

        plot.append('g')
            .attr('id', 'strainsLayer');
        plot.append('g')
            .attr('id', 'vaccinesLayer');
        
        plot.append('g')
            .attr('id', 'vaccinesDiffLayer');

    }

    translateGraphElements = () => {
        // console.log('translateGraphElements');
        const { refStrainsCnt } = this.props;
        const svg = select(this.mountNode);

        svg.select(`#${this.componentName}_title`).attr('transform', `translate(${this.padding.left}, 0)`);
        svg.select(`#${this.componentName}_titleDiff`).attr('transform', `translate(${this.padding.left+this.width/2+this.padding.middle/2}, 0)`);
        svg.select('#axes').attr('transform', `translate(${this.padding.left}, ${this.padding.top+this.padding.title})`);
        svg.select(`#diffAxes`).attr('transform', `translate(${this.width/2+this.padding.middle/2}, 0)`);
        svg.select('#strainsLayer').attr('transform', `translate(0, ${this.padding.top+this.padding.title})`);
        
        svg.select('#vaccinesLayer').attr('transform', `translate(${this.padding.left}, ${this.padding.top + this.padding.title + this.sectionHeight/2})`);
        svg.selectAll('g.vaccinesLayer').attr('transform', d => `translate(${this.x('protvalue')(d)}, ${this.y(d)})`);
        
        svg.select('#vaccinesDiffLayer').attr('transform', `translate(${this.padding.left+this.width/2+this.padding.middle/2}, ${this.padding.top + this.padding.title + this.sectionHeight/2})`);
        svg.selectAll('g.vaccinesDiffLayer').attr('transform', d => `translate(${this.x('diffprotvalue')(d)}, ${this.y(d)})`);
        drawChartAxes(svg, this.componentName,
            this.xScale(), this.yAllScale(),
            (this.width-this.padding.middle)/2, this.height,
            refStrainsCnt);
    }

    removePlots = () => {
        // const svg = select(this.mountNode).select(`#${this.componentName}Plot`);
        // svg.selectAll('path.freqLayers').remove();
        // svg.selectAll('#predLayer g').remove();
        // svg.selectAll('path.freqStackedLayers').remove();
        // svg.selectAll('path.predStackedLayers').remove();
        // svg.selectAll('path.greyZoneLayers').remove();
        // svg.selectAll('path.freqStdLayers').remove();
        // svg.selectAll('path.predStdLayers').remove();
        // svg.select('g.predictionBaseline').remove();
        // select(this.mountNode).select('#dataPointsLayer').selectAll('g.dataPoints').remove();
    }

    reinitXYScales = () => {
        // console.log('reinitXYScales', this.componentId);
        const { cladesCnt, refStrainsCnt } = this.props;
        // console.log('reinitXYScales', {
        //     width: this.width, 
        //     resultingWidth: this.getWidth(), 
        //     paddingLeft: this.padding.left,
        //     paddingRight: this.padding.right,
        //     sectionHeight: this.sectionHeight, cladesCnt, refStrainsCnt});
        if (this.width && cladesCnt && refStrainsCnt) {
            initVaccinesChartScales((this.width-this.padding.middle)/2, this.height, this.sectionHeight, cladesCnt, refStrainsCnt);
        }
    }

    setComponentState = (component, state) => {
        component.setState(state);
    }
   
    color = d => this.props.clades[d.cladeid]?.color;

    drawCladeDots = (svg, vaccinesData, layerId = 'vaccinesLayer', valAttr = 'protvalue') => {
        const vaccinesDataNode = svg.select(`#${layerId}`).selectAll('g').data(vaccinesData, d => `${d.refid}_${d.cladeIndex}`);
        // console.log('vaccinesDataNode', vaccinesDataNode);
       
        const nodeEnter = vaccinesDataNode.enter().append('g')
            .attr('id', d => `${d.refid}_${d.cladeIndex}`)
            .attr('class', 'vaccinesLayer')
            // .style('pointer-events', 'none');
        nodeEnter.on('mouseenter', (e, d) => {
            console.log('mouseenter', d.strainIndex, d.cladeIndex, d.name, d);
        });
    
        vaccinesDataNode.exit().remove();
        nodeEnter.append('circle')
            .attr('r', 8)
            .style('opacity', 0.8);


        const nodeUpdate = nodeEnter.merge(vaccinesDataNode);

        nodeUpdate
            .attr('transform', d => `translate(${this.x(valAttr)(d)}, ${this.y(d)})`)
            .style('fill', this.color);
        // const data = vaccinesData.;
    }

    drawStrains = (svg, referenceStrains) => {
        // console.log('referenceStrains', referenceStrains);
        const strainsNode = svg.select('#strainsLayer').selectAll('g').data(Object.values(referenceStrains||{}), d => d.refid);
        // console.log('vaccinesDataNode', vaccinesDataNode);
       
        const lineHeight = 16; // Distance between lines
        const verticalOffset = lineHeight / 2; // Half the line height to center

        const nodeEnter = strainsNode.enter().append('g')
            .attr('id', d => d.refid)
            .attr('class', 'strainsLayer')
            .attr('fill', '#000000')
            .style('font-size', '12px')
            .style('font-family', 'Inter')
            .style('text-anchor', 'end')
            .style('cursor', 'url(' + removeCursor + '), auto',)
            .attr('dominant-baseline', 'middle')
            .on('click', (e, d) => {
                console.log('click', d);
            });

        // Add first line of text (name)
        nodeEnter.append('text')
            .attr('class', 'strain-name')
            .text(d => d.name)
            .attr('x', this.props.maxTextLength)
            .attr('y', -verticalOffset) // Move up by half line height
            .style('font-size', '10px');

        // Add second line of text (lab)
        nodeEnter.append('text')
            .attr('class', 'strain-lab')
            .text(d => `${d.antigenicCladeLabel} (${d.lab})`)
            .attr('x', this.props.maxTextLength + this.strainTextOffset)
            .attr('y', verticalOffset) // Move down by half line height
            .style('font-size', '10px');
            
        strainsNode.exit().remove();

        const nodeUpdate = nodeEnter.merge(strainsNode);
        nodeUpdate.attr('transform', d => `translate(0, ${this.yStrain(d)})`);
    }
    renderVaccinesChart = () => {
        const { vaccinesData, refStrainsCnt, referenceStrains, maxTextLength } = this.props;

        // console.log('renderVaccinesChart', {refStrainsCnt, maxTextLength, paddingLeft: this.padding.left});

        // console.log('renderVaccinesChart', {vaccinesData});
        if (!vaccinesData) return;
        const svg = select(this.mountNode).select('g.graph');
        // svg.select('#vaccinesLayer').attr('transform', `translate(${this.padding.left}, 0)`);

        this.drawCladeDots(svg, vaccinesData, 'vaccinesLayer', 'protvalue');
        this.drawCladeDots(svg, vaccinesData, 'vaccinesDiffLayer', 'diffprotvalue');
        this.drawStrains(svg, referenceStrains);
   
        // // console.log('[renderNonStackedD3Component]', this.props.subsetId, width)
        drawChartAxes(svg, this.componentName,
            this.xScale(), this.yAllScale(),
            (this.width-this.padding.middle)/2, this.height,
            refStrainsCnt
        );

        // const areaChart = select(`#${this.componentName}`).select(`#${this.componentName}Plot`);

        // this.drawGreyZone(areaChart, seqCaseCounts, displayGreyZone, predictionBaseline);
        // this.drawPredictionBaseline(areaChart, showPrediction ? predictionBaseline : null);
        // this.drawFrequencies(areaChart, freqData, 'freq');
        // this.drawErrorBars(areaChart, freqData, displayErrorBars, 'freq');
        // // svg.select('#predLayer').selectAll('path').remove();

        // if (this.props.showPrediction) {
        //     const predDataNode = svg.select('#predLayer').selectAll('g').data(predictionsPlotData, d => `${d.modelRegionId}_${d.modelType.replace(/ /g, "")}_${d.modelId}`);
        //     predDataNode.enter().append('g').attr('id', d => `${d.modelRegionId}_${d.modelType.replace(/ /g, "")}_${d.modelId}`);//.style('pointer-events', 'none');
        //     predDataNode.exit().remove();

        //     let labelsInputData = [];
        //     for (const predictData of predictionsPlotData) {
        //         //console.log('predictData',  predictData.modelRegionId, predictData.modelId, predictData.data.plotPredictions.filter(arr => arr[0] == 20192).map(arr => arr[1][0]))
        //         const predData = (predictData.data.plotPredictions || [])
        //             .map(([key, values]) => ({ key, values }))
        //             .filter(bin => visibleBins[bin.key] && selectedBins.includes(bin.key.toString()) && plotType === 'frequencies');
        //         labelsInputData = [...labelsInputData, ...predData];
        //         this.drawFrequencies(areaChart, predData, 'pred', `${predictData.modelRegionId}_${predictData.modelType.replace(/ /g, "")}_${predictData.modelId}`);
        //     }

        //     if (exportMode) {
        //         const labeledDataNode = svg.select('#labelsLayer').selectAll('text').remove()
        //         this.drawLabels(areaChart, labelsInputData);
        //     }
        //     //this.drawErrorBars(areaChart, predData, displayErrorBars, 'pred');
        // }
    }

    renderD3Component = async (viewName, componentId) => {
        const {setComponentStatus } = this.props;
        console.log('[VaccinesChartD3] renderD3Component', {viewName, componentId});
        setComponentStatus(viewName, componentId, RENDER_STATUS.START);
        this.reinitXYScales();
        this.renderVaccinesChart();
        setComponentStatus(viewName, componentId, RENDER_STATUS.DONE);
    };
    // get renderD3Component() {
    //     return this._renderD3Component;
    // }
    // set renderD3Component(value) {
    //     this._renderD3Component = value;
    // }
}

export default VaccinesChartD3;
