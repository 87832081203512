import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ExportContextMenu from './ExportContextMenu';
import { styles } from '../../components/ParametersInfo/styles';

const initialState = {
    menuOn: false,
    menuPos: { x: null, y: null }
};

const ExportableComponent = ({children, filename, classes, compact, setMapParams}) => {
    const [state, setState] = useState(initialState);
    const [mapDimensions, setMapDimensions] = useState({});

    const handleClick = event => {
        if (filename === 'geomap'){
            const mapParams = setMapParams();
            setMapDimensions(mapParams);
        }
        
        event.preventDefault();
        const { menuOn } = state;
        setState({
            menuOn: !menuOn,
            menuPos: {
                x: event.clientX - 2,
                y: event.clientY - 4
            }
        });

    };

    const handleClose = () => {
        setState(initialState);
    };

    return (
        <div onContextMenu={handleClick} className={compact ? classes.compact : classes.exportableComponent}>
            {children}
            <ExportContextMenu handleClose={handleClose} menuOn={state.menuOn} menuPos={state.menuPos} filename={filename} mapDimensions={mapDimensions}/>
        </div>
    );
};

ExportableComponent.propTypes = {
    children: PropTypes.element,
    filename: PropTypes.string,
    compact: PropTypes.bool,
    classes: PropTypes.shape({ exportableComponent: PropTypes.string, compact: PropTypes.string })
};

export default withStyles(styles)(ExportableComponent);
