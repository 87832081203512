import React, { useState, useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, FormControl, MenuItem, Tooltip, Grid, Typography, Divider, DialogActions, Box, Button, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';
import JSZip from 'jszip';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

import { DialogSX, styles } from '../../../../pages/SettingsPanel/Styles/dialogStyles';
import CustomLabel from '../../../../assets/GlobalStyles/CustomLabel';
import CustomSelect from '../../../../assets/GlobalStyles/CustomSelect';
import config from '../../../../config/envConfig';
import { ColorScaleSelectorBase } from '../../../../components/OptionsSelector/ColorScaleSelector/ColorScaleSelectorBase';
import { colorScalesSelector } from '../../../../redux/selectors/settingsSelector';
import { UploadIcon } from './components/Icon';
import ShapeSelect from './components/ShapeSelect';
import SizeSelect from './components/SizeSelect';
import Checkboxes from './components/Checkboxes';
import NameLabel from './components/NameLabel';

const INITIAL_DIALOG_STATE = {
    measure: '',
    lineage: '',
    scaleType: '',
    scale: '',
    size: '',
    shape: '',
    label: '',
    discrete: false,
    numeric: false,
    colorBy: false,
    frequenciesChart: false,
    geoColorBy: false,
    xScale: false,
    yScale: false,
    ignoreStrainCutOffDate: false,
    element: 'node',
};

const UPLOAD_STATUS = {
    NONE: 'none',
    LOADING: 'loading',
    DONE: 'done'
};

const ELEMENT_TYPES = ['node', 'branch'];

const AddMeasureDialog = ({
    scales,
    openDialog,
    handleCloseDialog,
    lineages,
    addNewMeasure,
}) => {
    const [newMeasure, setNewMeasure] = useState(INITIAL_DIALOG_STATE);
    const [file, setFile] = useState([]);
    const [dataFileUploaded, setDataFileUploaded] = useState(UPLOAD_STATUS.NONE);
    const [param, setParam] = useState();
    const classes = styles();

    const handleClose = () => {
        setNewMeasure(INITIAL_DIALOG_STATE);
        handleCloseDialog();
    };

    const scalesOptions = useMemo(() => {
        const { lineage, scaleType } = newMeasure;
        const filteredScales = Object.values(scales)
            .filter(scale => {
                const isValidLineage = !scale.lineage || (scale.lineage && scale.lineage === lineage);
                const matchesScaleType = scale.id.split('.')[0] === scaleType;
                return isValidLineage && matchesScaleType;
            })
            .map(scale => scale.id);
        return [...new Set(filteredScales)];
    }, [newMeasure.lineage, newMeasure.scaleType, scales]);

    const scaleTypes = useMemo(() => {
        const types = Object.values(scales).map(scale => scale.id.split('.')[0]);
        return [...new Set(types)];
    }, [scales]);

    const canUploadData = useMemo(() => {
        const { measure, scaleType, scale } = newMeasure;
        return Boolean(measure && scaleType && scale);
    }, [newMeasure]);

    const handleChange = name => event => {
        setNewMeasure(prev => ({ ...prev, [name]: event.target.value }));
    };

    const handleScaleChangeParametrized = type => event => {
        setNewMeasure(prev => ({
            ...prev,
            scale: {
                [param.paramName]: {
                    ...prev.scale[param.paramName],
                    [type]: event.target.value,
                },
            },
        }));
    };

    const handleCheckboxChange = type => checked => {
        setNewMeasure(prev => ({ ...prev, [type]: checked }));
    };

    const handleFileChange = event => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = e => {
            try {
                const jsonData = JSON.parse(e.target.result);
                const measureName = Object.keys(jsonData)[0];
                const metaData = jsonData[measureName];

                if (!metaData.scaleType && metaData.scale) {
                    metaData.scaleType = metaData.scale.split('.')[0];
                }

                setNewMeasure(prev => ({
                    ...prev,
                    measure: measureName,
                    ...metaData,
                }));
            } catch (error) {
                console.error('Error parsing JSON file:', error);
            }
        };

        reader.readAsText(file);
    };

    const checkFile = async file => {
        const zip = new JSZip();
        const data = await file.arrayBuffer();
        zip.file(file.name, data, { binary: true });
        const zippedFile = await zip.generateAsync({ type: 'blob' });

        const formData = new FormData();
        formData.append('file', zippedFile, 'data.zip');
        formData.append('lineage', newMeasure.lineage);
        formData.append('name', newMeasure.name);

        try {
            const response = await fetch(`${config.serverLink}/api/customAttributes/check`, {
                method: 'POST',
                body: formData,
            });
            const responseData = await response.json();
            
            if (!responseData.valid) {
                setDataFileUploaded(responseData.msg);
                return false;
            }
            
            setDataFileUploaded(UPLOAD_STATUS.DONE);
            return true;
        } catch (error) {
            console.error('Error:', error);
            setDataFileUploaded('An error has occurred');
            return false;
        }
    };

    const handleDataFileChange = async event => {
        setDataFileUploaded(UPLOAD_STATUS.LOADING);
        const selectedFile = event.target.files[0];
        const isValid = await checkFile(selectedFile);

        if (!isValid) return;

        const zip = new JSZip();
        const data = await selectedFile.arrayBuffer();
        zip.file(selectedFile.name, data, { binary: true });

        const dialogStateContent = JSON.stringify({
            [newMeasure.name]: newMeasure,
        });
        zip.file(`meta_${newMeasure.name}.json`, dialogStateContent);

        const zippedFile = await zip.generateAsync({ type: 'blob' });
        setFile(zippedFile);
    };

    const handleSubmit = async () => {
        await addNewMeasure(newMeasure, file);
        setParam('');
        setNewMeasure(INITIAL_DIALOG_STATE);
        handleClose();
    };

    const scale = typeof newMeasure.scale === 'string' ? newMeasure.scale : '';
    const isUploadStatusValid = [UPLOAD_STATUS.NONE, UPLOAD_STATUS.LOADING, UPLOAD_STATUS.DONE].includes(dataFileUploaded);

    return (
        <Dialog
            open={openDialog}
            onClose={handleClose}
            sx={DialogSX} 
        >
            <DialogTitle id="confirm-dialog-title">Add measure</DialogTitle>

            <DialogContent
                style={{ height: '480px', width: 400, overflow: 'overlay' }}
            >
                <FormControl fullWidth className={classes.formControlLineage}>
                    <CustomLabel id="plotType" label="Lineage" />
                    <CustomSelect
                        value={newMeasure.lineage}
                        onChange={handleChange('lineage')}
                        inputProps={{
                            name: 'lineage',
                            id: 'lineage',
                        }}
                    >
                        {lineages.map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </FormControl>

                {newMeasure.lineage && (
                    <>
                        <div>
                            <label
                                htmlFor="fileInput"
                                className={classes.metaButton}
                            >
                                <UploadIcon />
                                Upload meta file
                            </label>
                            <input
                                id="fileInput"
                                type="file"
                                accept=".json"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                        </div>

                        <NameLabel
                            measure={newMeasure}
                            onChange={handleChange}
                        />

                        {param ? (
                            <>
                                {scalesOptions.length > 0 && param.paramValues.map(elem => {
                                    const filteredOptions = scalesOptions.filter(opt => opt.includes(elem));
                                    
                                    return (
                                        <div key={elem}>
                                            <ColorScaleSelectorBase
                                                id={`${param.paramName}_scale`}
                                                label={`Scale for ${param.paramName}: ${elem}`}
                                                value={newMeasure.scale[param.paramName][elem]}
                                                onChange={handleScaleChangeParametrized(elem)}
                                                options={filteredOptions}
                                                getOptionLabel={scale => capitalize(scale.split('.').at(-1))}
                                                getOptionValue={scale => scale}
                                                scales={scales}
                                                isTimeScale={false}
                                                disabled={scalesOptions.length < 2}
                                            />
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            <>
                                <FormControl fullWidth className={classes.formControlLineage}>
                                    <CustomLabel id="scaleType" label="Scale type" />
                                    <CustomSelect
                                        value={newMeasure.scaleType}
                                        onChange={handleChange('scaleType')}
                                        inputProps={{
                                            name: 'scaleType',
                                            id: 'scaleType',
                                        }}
                                    >
                                        {scaleTypes.map(option => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </CustomSelect>
                                </FormControl>

                                <ColorScaleSelectorBase
                                    id="scale"
                                    label="Scale"
                                    value={scale}
                                    onChange={handleChange('scale')}
                                    options={scalesOptions}
                                    getOptionLabel={scale => scale}
                                    getOptionValue={scale => scale}
                                    scales={scales}
                                />
                            </>
                        )}

                        <FormControl fullWidth className={classes.formControlLineage}>
                            <CustomLabel id="element" label="Element" />
                            <CustomSelect
                                value={newMeasure.element}
                                onChange={handleChange('element')}
                                inputProps={{
                                    name: 'element',
                                    id: 'element',
                                }}
                            >
                                {ELEMENT_TYPES.map(option => (
                                    <MenuItem key={option} value={option}>
                                        {capitalize(option)}
                                    </MenuItem>
                                ))}
                            </CustomSelect>
                        </FormControl>

                        {newMeasure.element === 'branch' && (
                            <>
                                <SizeSelect
                                    value={newMeasure.size}
                                    onChange={handleChange('size')}
                                    className={classes.formControlLineage}
                                />
                                <ShapeSelect 
                                    value={newMeasure.shape}
                                    onChange={handleChange('shape')}
                                    className={classes.formControlLineage}
                                />
                            </>
                        )}

                        <Checkboxes 
                            measure={newMeasure}
                            handleCheckboxChange={handleCheckboxChange}
                        />
                    </>
                )}
            </DialogContent>

            <DialogActions>
                <Box flexGrow={1}>
                    {canUploadData && (
                        <div className={classes.uploadDataContainer}>
                            <label htmlFor="dataFileInput" className={classes.dataButton}>
                                <UploadIcon />
                                Upload data file
                            </label>
                            <input 
                                id="dataFileInput" 
                                type="file" 
                                accept=".json" 
                                onChange={handleDataFileChange} 
                                style={{ display: 'none' }} 
                            />
                            <div className={classes.icon}>
                                {dataFileUploaded === UPLOAD_STATUS.LOADING && <CircularProgress size={18} disableShrink />}
                                {dataFileUploaded === UPLOAD_STATUS.NONE && <CloseIcon style={{ color: 'grey' }} />}
                                {dataFileUploaded === UPLOAD_STATUS.DONE && <DoneIcon style={{ color: 'green' }} />}
                                {!isUploadStatusValid && (
                                    <Tooltip title={dataFileUploaded} placement="top-start" arrow>
                                        <CloseIcon style={{ color: 'red' }} />
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    )}
                </Box>
                <Button className={classes.cancel} onClick={handleClose}>
                    Cancel
                </Button>
                <Button 
                    className={classes.confirm} 
                    onClick={handleSubmit} 
                    disabled={dataFileUploaded !== UPLOAD_STATUS.DONE}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = state => ({
    scales: colorScalesSelector(state)
});

export default connect(mapStateToProps)(AddMeasureDialog);
