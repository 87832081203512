import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { RENDER_STATUS, stringOrNumberProp } from '../../../config/consts';
import { setComponentStatus } from '../../../redux/actions/renderActions';
import { getStrainTreeStatus, getAntigenicDistanceStatus } from '../../../redux/selectors/statusSelector';
import { treeD3 } from '../d3/TreeD3';
import { emptyObject } from '../../../functions/functions';

// import { printWhatChanged } from '../../../functions/utils';

const viewToRender = 'strainTree';
const componentId = 'refStrains';

const RefStrainsLayer = (props) => {
    const _element = useRef();


    const { loading, renderStatus, setComponentStatus, refStrain, humanSerologyRefStrains, treeAttrs } = props;

    const startRender = async () => {
        // console.log('[startRender] ', {renderStatus, refStrain});
        if (renderStatus !== RENDER_STATUS.START) setComponentStatus(viewToRender, componentId, RENDER_STATUS.START);
    };

    const stopRender = () => {
        //if (renderStatus !== RENDER_STATUS.DONE)
        setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE);
    };


    

    useEffect(() => {
        if (renderStatus !== RENDER_STATUS.NONE) return;
        startRender()
            .then(() => treeD3.translateRefStrains())
            .then(() => stopRender());
    }, [renderStatus]);

    useEffect(() => {
        // console.log('[RefStrainsLayer] loading, refStrain, humanSerologyRefStrains', loading, refStrain, humanSerologyRefStrains)
        if (loading) return;
        if (!loading && emptyObject(treeAttrs)){
            stopRender();
            return;
        }
        // if (!refStrain) {
        //     treeD3.drawRefStrainsLayer(_element.current)
        //     return;
        // }
        startRender()
            .then(() => treeD3.drawRefStrainsLayer(_element.current))
            .then(() => stopRender());
    }, [loading, refStrain, humanSerologyRefStrains]);

    return <g id="refStrains" transform={treeD3.translate(false)} ref={_element} />;
};

RefStrainsLayer.propTypes = {
    layout: PropTypes.string,
    loading: PropTypes.bool,
    renderStatus: PropTypes.string,
    setComponentStatus: PropTypes.func,
    refStrain: stringOrNumberProp,
    humanSerologyRefStrains: PropTypes.arrayOf(PropTypes.number),
    treeAttrs: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
    const loading = getStrainTreeStatus(state) || !ownProps.initialized || getAntigenicDistanceStatus(state);
    // console.log(loading)

    return {
        loading,
        renderStatus: state.render.viewToRender ? state.render.viewToRender.components[componentId] : null,
        refStrain: state.parameters.refStrain,
        humanSerologyRefStrains: state.parameters.colorBy === 'humanSerology' ? state.humanSerology.humanSerologyRefStrains : null,
        treeAttrs: state.treeData.treeAttrs,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setComponentStatus,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(RefStrainsLayer);
