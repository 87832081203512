import React from 'react';
import { withStyles } from '@mui/styles';
import { InputLabel } from '@mui/material';
import PropTypes from 'prop-types';

const styles = () => ({
    label: {
        transform: 'none', 
        fontSize: '12px'
    }
});

const CustomLabel = (props) => {
    const { classes, id, label, required, error } = props;
    return (
        <InputLabel error={error} required={required} className={classes.label} htmlFor={id} id={`${id}-label`}>
            {label}
        </InputLabel>
    );
};

CustomLabel.propTypes = {
    classes: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    error: PropTypes.bool,
};

export default withStyles(styles)(CustomLabel);
