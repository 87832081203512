// External imports
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { scaleThreshold } from 'd3-scale';

// Internal imports - Functions & Utils
import { numFormat } from '../../../functions/formats';
import { daysToDate } from '../../../functions/functions';

// Internal imports - Redux
import { getMetadataMeasuresWithScales } from '../../../redux/selectors/rangeDataSelector';
import { setActiveLegendOption } from '../../../redux/actions/nodeActions';

// Internal imports - Components & Styles
import LegendElement from '../Elements/LegendElement';
import { useStyles } from './styles';

const DiscreteLegend = (props) => {
    const {type, precision, isTimeScale,
        measureName, measureDiscrete, val, colorBins, visibleBins, selectedBins, exportMode, 
        domain,
        colorLegendVisible = true,
        includeEmptyBin = false,
        selectable,
        mouseEventsEnabled,
        handleClick,
        setActiveLegendOption,
        fullHeight,
        onMouseLeave,
        handleDoubleClick
    } = props;

    const [activeLegendElement, setActiveLegendElement] = useState(); 
    const classes = useStyles();
    const _element = useRef();
 
    const getSafeElementId = (measureName, value) => {
        // Replace any invalid characters with base36 encoded index
        const safeValue = String(value).replace(/[^a-zA-Z0-9]/g, char => 
            char.charCodeAt(0).toString(36)
        );
        return `elem_${measureName}_${safeValue}`;
    };

    const valueScale = (val) => measureDiscrete || !domain
        ? val
        : scaleThreshold().domain(domain).range([...Array(domain.length + 1).keys()])(val);
   
    const value = valueScale(val);

    const formatLabel = (value, precision) => {
        if (isTimeScale)
            return daysToDate(value).toLocaleDateString();
        return numFormat(value, precision);
    };

    const formattedBins = useMemo(() => {
        const _domain = (Object.values(colorBins || {}))
            .filter(el => {
                const val = isNil(el.bin) ? el.value : el.bin;
                return (includeEmptyBin || val !== -1) && (!visibleBins || visibleBins[val]);
            });
       
        const res = _domain.map((el) => {
            const { v0, v1, color  } = el;
                
            let label = el.label || el.value;
            let bin = el.value;
            let inBin = el.value == value;

            // Prepare bins
            if (!measureDiscrete) {
                bin = el.bin;
                inBin = (bin !== -1) && (value === bin);

                const fv0 = formatLabel(v0, precision); 
                const fv1 = formatLabel(v1, precision); 
                label = (bin === -1) 
                    ? 'Missing value' 
                    : fv0 && fv1 
                        ? `${fv0} < x < ${fv1}`
                        : !fv0 && fv1 
                            ? `x < ${fv1}` 
                            : `x > ${fv0}`;
            }
            const visible = !visibleBins || visibleBins[bin];
            const selected = !selectedBins || selectedBins[bin];
          
            return ({ bin, color, visible, selected, label, inBin});
        });

        return res;
    }, [colorBins, includeEmptyBin, selectedBins, visibleBins, value]);
    
    // Automatically scroll to the active legend option
    useEffect(() => {
        if (_element.current) {
            const safeElementId = getSafeElementId(measureName, value);
            const elem = `#${safeElementId}`;
            const activeElement = _element.current.querySelector(elem);
            if (activeElement) {
                const offset = activeElement.getBoundingClientRect().top - _element.current.getBoundingClientRect().top;
                _element.current.scrollTop += offset;
            }
        }
    }, [value]);

    if (!colorLegendVisible) return <></>;

    return (
        <>
            {formattedBins &&
                <div id={`discrete-legend-${measureName}`} 
                    onMouseLeave={onMouseLeave}
                    className={`${classes.rootFrequenciesLegendFull} ${!fullHeight && classes.rootFrequenciesLegend}`} >
                    <div id={`legend-wrapper-${measureName}`} ref={_element} className={`${classes.legendFull} ${!fullHeight && classes.legend}`} >
                        {formattedBins.map((bin) => {
                            const isActive = bin.inBin || bin.bin === activeLegendElement;
                            const size = exportMode ? 12 : 17;
                            const selectableSize = exportMode ? 9 : 13;
                            const borderSize = selectable ? 3 : 1; 
                            
                            const border = `${borderSize}px solid ${isActive && !selectable? '#474747' : bin.color}`;
                            const textDecoration = isActive ? 'underline' : 'none';
                           
                            const safeElementId = getSafeElementId(measureName, bin.bin);

                            return <LegendElement
                                key={safeElementId}
                                id={safeElementId}
                                color={bin.color}
                                fontSize={measureName === 'loc' ? '14px' : '12px'}
                                selected={bin.selected}
                                label={bin.label}
                                border={border}
                                size={selectable ? selectableSize : size}
                                textDecoration={textDecoration}
                                handleClick={() => {if (handleClick) return handleClick(bin.bin);}}
                                handleDoubleClick={() => {if (handleDoubleClick) return handleDoubleClick(bin.bin);}}
                                handleEnter={() => {
                                    if (isNil(value)) setActiveLegendElement(bin.bin);
                                    return mouseEventsEnabled && type && setActiveLegendOption({ type, value: bin.bin, option: measureName });
                                }}
                                handleLeave={() => {
                                    setActiveLegendElement(undefined);
                                    mouseEventsEnabled && type && setActiveLegendOption({ type, value: undefined, option: measureName });
                                }}
                            />;
                        })}
                    </div>
                </div>}
        </>
    );
};

DiscreteLegend.propTypes = {
    colorBins:PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    colorLegendVisible: PropTypes.bool,
    domain: PropTypes.array,
    exportMode: PropTypes.bool,
    fullHeight: PropTypes.bool,
    handleClick: PropTypes.func,
    includeEmptyBin: PropTypes.bool,
    isTimeScale: PropTypes.bool,
    measureDiscrete: PropTypes.bool,
    measureName: PropTypes.string.isRequired,
    mouseEventsEnabled: PropTypes.bool,
    onMouseLeave: PropTypes.func,
    precision: PropTypes.number,
    scaleId: PropTypes.string,
    selectable: PropTypes.bool,
    selectedBins: PropTypes.object,
    setActiveLegendOption: PropTypes.func,
    ticks: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.string,
    val: PropTypes.any,
    value: PropTypes.number,
    visibleBins: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
    const { exportMode } = state.parameters;
    const measureName = ownProps.measureName || state.parameters.colorBy;
    const scaleName = state.parameters.colorScales[measureName];
    
    const measuresWithScales = getMetadataMeasuresWithScales(state);
    const measure = measuresWithScales[measureName];
    const discrete = measure?.scale.discrete;
    const measureDiscrete = measure.discrete || false;
    const domain = measuresWithScales[measureName]?.scale.domain;
    const colorBins = measure.scale.bins;

    return {
        measureName,
        scaleName,
        measureDiscrete,
        discrete,
        domain,
        exportMode,
        isTimeScale: measure && measure.time,
        colorBins,
        val: ownProps.valueGetter && ownProps.valueGetter(state)
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setActiveLegendOption
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DiscreteLegend);
