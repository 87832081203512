// External imports
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

// Internal imports
import { setParameters } from '../../redux/actions/parametersActions';
import { resetActiveClades } from '../../redux/actions/cladeActions';
import appConfig from '../../config/appConfig';
import SelectInput from '../Common/SelectInput';

const CladeTypeSelector = props => {
    const {
        // Parameters
        cladeType,
        // Data
        cladeTypes,
        // Actions
        setParameters, resetData, resetActiveClades
    } = props;

    const handleValueChange = value => {
  
        setParameters({ cladeType: value });
        resetActiveClades();
        if (resetData) {
            resetData();
        }
    };

    return <SelectInput  
        id="cladeType"
        label="Clade type"
        value={cladeType}
        onChange={handleValueChange}
        options={cladeTypes}
        getOptionValue={option => option.key}
        getOptionLabel={option => option.label}
    />;
};

CladeTypeSelector.propTypes = {
    cladeType: PropTypes.string,
    cladeTypes: PropTypes.arrayOf(PropTypes.shape({ 
        key: PropTypes.string, 
        label: PropTypes.string 
    })),
    resetActiveClades: PropTypes.func.isRequired,
    resetData: PropTypes.func,
    setParameters: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    cladeType: state.parameters.cladeType,
    cladeTypes: state.metadata.cladeTypes || appConfig.cladeTypes
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
    resetActiveClades
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CladeTypeSelector);
