import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@mui/styles';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import CircleUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';


const radioButtonsStyles = makeStyles(() => ({
    checkbox: {
        padding: 0,
        margin: 0,
        verticalAlign: 'middle',
    },
    formControl: {
        margin: '4px 0px',
        padding: 0,
    },
    formControlLabel: {
        margin: 0,
        paddingLeft: '4px',
        fontSize: '12px',
        lineHeight: 1,
    },
}));

const StyledCircleUncheckedIcon = withStyles({
    root: {
        margin: 0,
        padding: 0,
    },
})(CircleUncheckedIcon);

const StyledRadioButtonCheckedIcon = withStyles({
    root: {
        margin: 0,
        padding: 0,
    },
})(RadioButtonCheckedIcon);

const CheckboxInput = ({
    id,
    label,
    value,
    disabled,
    onChange,
    className,
    labelClassName,
    controlClassName,
}) => {
 
    const classes = radioButtonsStyles();

    const handleChange = (event) => {
        const value = event.target.checked;
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <FormControlLabel
            className={className || classes.formControl}
            label={
                <Typography
                    className={labelClassName || classes.formControlLabel}
                >
                    {label}
                </Typography>
            }
            control={
                <Checkbox
                    icon={<StyledCircleUncheckedIcon />}
                    className={controlClassName || classes.checkbox}
                    checkedIcon={
                        <StyledRadioButtonCheckedIcon
                            style={{
                                color: disabled
                                    ? 'rgba(0, 0, 0, 0.6)'
                                    : '#6F6CFF',
                            }}
                        />
                    }
                    id={id}
                    inputProps={{ id, name: id}}
                    checked={value}
                    disabled={disabled}
                    onChange={handleChange}
                />
            }
            labelPlacement="end" // Ensure label is on the right
        />
    );
};

CheckboxInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    setParameters: PropTypes.func,
    setLayoutParameters: PropTypes.func,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    controlClassName: PropTypes.string,
};

const mapStateToProps = ({ parameters }, ownProps) => ({
    value: isNil(ownProps.value)
        ? parameters?.[ownProps.name]
        : ownProps.value,
});

export default connect(mapStateToProps)(CheckboxInput);
