import { combineReducers } from 'redux';
import { treeDataReducer as treeData } from './treeDataReducer';
import { customTreeDataReducer as customTreeData } from './customTreeDataReducer';
import { nodeDataReducer as nodeData } from './nodeDataReducer';
import { cladeDataReducer as cladeData } from './cladesReducer';
import { frequenciesDataReducer as frequenciesData } from './frequenciesReducer';
import { modelDataReducer as modelData, modelsReducer as models } from './modelsReducer';
import { lineagesDataReducer as lineages } from './lineagesReducer';
import { antigenicReducer as antigenic } from './antigenicReducer';
import { fitnessReducer as fitness } from './fitnessReducer';
import { alertReducer as alert } from './alertReducer';
import { renderStatusReducer as render } from './renderStatusReducer';
import { parametersReducer as parameters } from './parametersReducer';
import { metadataReducer as metadata } from './metadataReducer';
import { predictionsDataReducer as predictionsData } from './predictionsReducer';
import { userReducer as user } from './userReducer';
import { settingsReducer as settings } from './settingsReducer';
import { genotypeReducer as genotype } from './genotypeReducer';
import { geoMapReducer as geomap } from './geoMapReducer';
import { humanSerologyReducer as humanSerology } from './humanSerologyReducer';
import { uiReducer as ui } from './uiReducer';
import { autocompleteReducer as autocomplete } from './autocompleteReducer';
import { vaccinesReducer as vaccines } from './vaccinesReducer';

const rootReducer = combineReducers({
    alert,
    antigenic,
    autocomplete,
    cladeData,
    customTreeData,
    fitness,
    frequenciesData,
    genotype,
    geomap,
    humanSerology,
    lineages,
    metadata,
    modelData,
    models,
    nodeData,
    parameters,
    predictionsData,
    render,
    settings,
    treeData,
    ui,
    user,
    vaccines
});

export default rootReducer;
