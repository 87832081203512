import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { RENDER_STATUS } from '../../../config/consts';
import { setComponentStatus } from '../../../redux/actions/renderActions';
import { getStrainTreeStatus } from '../../../redux/selectors/statusSelector';
import { treeD3 } from '../d3/TreeD3';
import { getColorScaleForColorBy } from '../../../redux/selectors/parametersSelector';


const viewToRender = 'strainTree';
const componentId = 'internalNodes';

const InternalNodesLayer = (props) => {
    const _element = useRef();

    const {
        loading,
        colorBy,
        cladeType,
        colorScale,
        showInternalNodes,
        showColoredNodesOnly,
        calculatedDomain,
        renderStatus,
        setComponentStatus,
        submissionDate
    } = props;

    const startRender = async () => {
        if (renderStatus !== RENDER_STATUS.START) setComponentStatus(viewToRender, componentId, RENDER_STATUS.START);
    };

    const stopRender = () => {
        setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE);
    };

    useEffect(() => {
        if (renderStatus !== RENDER_STATUS.NONE) return;
        startRender()
            .then(() => treeD3.translateInternalNodes())
            .then(() => stopRender());
    }, [renderStatus]);

    useEffect(() => {
        if (loading) return;
        startRender()
            .then(() => treeD3.changeNodesColor(_element.current, 'internal'))
            .then(() => stopRender());
    }, [colorBy, colorScale, calculatedDomain, cladeType]);

    useEffect(() => {
        if (loading) return;
        startRender()
            .then(() => treeD3.drawInternalNodesLayer(_element.current))
            .then(() => stopRender());
    }, [loading, showInternalNodes, showColoredNodesOnly, submissionDate]);

    return <g id="internalNodes" transform={treeD3.translate(false)} ref={_element} />;
};

InternalNodesLayer.propTypes = {
    layout: PropTypes.string,
    loading: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
    const loading = getStrainTreeStatus(state) || !ownProps.initialized;

    return {
        loading,
        renderStatus: state.render.viewToRender ? state.render.viewToRender.components[componentId] : null,
       
        colorBy: state.parameters.colorBy,
        cladeType: state.parameters.cladeType,
        colorScale: getColorScaleForColorBy(state),// state.parameters.colorScale,
        showInternalNodes: state.parameters.showInternalNodes,
        showColoredNodesOnly: state.parameters.showColoredNodesOnly,
        calculatedDomain: state.parameters.calculatedDomain,
        submissionDate: state.parameters.submissionDate,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setComponentStatus,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(InternalNodesLayer);
