import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, IconButton, DialogContent, CardContent, Card, Button, FormControl, MenuItem, DialogActions } from '@mui/material';
import { DialogSX, styles } from '../../../../pages/SettingsPanel/Styles/dialogStyles';
import CustomLabel from '../../../../assets/GlobalStyles/CustomLabel';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import CustomSelect from '../../../../assets/GlobalStyles/CustomSelect';
import ColorPickerComponent from '../../Scales/Elements/ColorPicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Positions from '../Elements/Positions';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { SymbolComponent, signOptions } from '../Elements/Symbol';
import { connect } from 'react-redux';
import SelectInput from '../../../../components/Common/SelectInput';

const dialogInitState = {
    name: '',
    label: '',
    lineage: '',
    mutGroup: '',
    color: '',
    symbol_sign: '',
    cladeSchema: '',
    positions: []
};

const MutClassDialog = ({ mutClass, openDialog, handleCloseDialog, updateMutClass, mutationGenes, isAdd, lineages }) => {
    const [updatedMutClass, setUpdatedMutClass] = useState(dialogInitState);
    const [positionsState, setPositionsState] = useState({});
    const [expanded, setExpanded] = useState({});
    const classes = styles();

    useEffect(() => {
        if (!isAdd) {
            console.log('mutClass', mutationGenes);
            const statePositions = Object.keys(mutClass.positions);
            const state = {
                ...mutClass,
                positions: statePositions
            };
            const expandedObject = statePositions.reduce((obj, key) => {
                obj[key] = false;
                return obj;
            }, {});
            setExpanded(expandedObject);
            setUpdatedMutClass(state);
            setPositionsState(mutClass.positions);
        }
    }, []);

    const handleExpandClick = (key) => {
        setExpanded({ ...expanded, [key]: !expanded[key] });
    };

    const handleChange = (type) => (e) =>
        setUpdatedMutClass({ ...updatedMutClass, [type]: e.target.value });

    const handleColorChange = (color) => {
        const hexColor = color.hex;
        setUpdatedMutClass({ ...updatedMutClass, color: hexColor });
    };

    const handleEditKey = (e, index, oldKey) => {
        const newKey = e.target.value;
        const newPositions = [...updatedMutClass.positions];
        newPositions[index] = newKey;
        setUpdatedMutClass({ ...updatedMutClass, positions: newPositions });
        const newPositionsObj = { ...positionsState };
        newPositionsObj[newKey] = [...positionsState[oldKey]];
        delete newPositionsObj[oldKey];
        setPositionsState({ ...newPositionsObj });
    };

    const handleAddPositionClick = () => {
        const positions = [...updatedMutClass.positions, 'a'];
        setUpdatedMutClass({ ...updatedMutClass, positions });
        const positionsStateNew = {
            ...positionsState,
            a: [
                {
                    min: 0,
                    max: 1
                }
            ]
        };
        setPositionsState(positionsStateNew);
    };

    const handleSymbolSignChange = (e) => {
        setUpdatedMutClass({ ...updatedMutClass, symbol_sign: e.target.value });
    };

    const handleRemovePositionClick = (index, key) => {
        const positions = [...updatedMutClass.positions];
        positions.splice(index, 1);
        setUpdatedMutClass({ ...updatedMutClass, positions });
        const positionsStateNew = { ...positionsState };
        delete positionsStateNew[key];
        setPositionsState(positionsStateNew);
    };

    const handleLineageChange = lineage => {
        setUpdatedMutClass({ ...updatedMutClass, lineage });
    };

    const handleSubmit = async () => {
        const body = {
            ...updatedMutClass,
            positions: { ...positionsState }
        };

        await updateMutClass(body);
    };

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={DialogSX}>
            <DialogTitle id="edit-mut-class-dialog">
                {isAdd ? 'Add mutation class' : `Edit mutation class: ${mutClass.name}`}
            </DialogTitle>
            <DialogContent style={{ height: '450px', width: 400, overflow: 'overlay' }}>
                <StyledTextField
                    disabled={!isAdd}
                    id="name"
                    type="text"
                    label="Mutation class name"
                    autoComplete='off'
                    value={updatedMutClass.name}
                    onChange={handleChange('name')}
                />
                <StyledTextField
                    id="label"
                    type="text"
                    label="Label"
                    autoComplete='off'
                    value={updatedMutClass.label}
                    onChange={handleChange('label')}
                />
                <SelectInput
                    id='lineage'
                    label={'Lineage'}
                    value={updatedMutClass.lineage}
                    onChange={handleLineageChange}
                    options={lineages||[]}
                    getOptionValue={(option) => option}
                    getOptionLabel={(option) => option}
                />
                <StyledTextField
                    id="mutGroup"
                    disabled={!isAdd}
                    type="text"
                    label="Mutation group"
                    autoComplete='off'
                    value={updatedMutClass.mutGroup}
                    onChange={handleChange('mutGroup')}
                />
                <div>
                    Color:
                    <ColorPickerComponent handleColorChange={handleColorChange} color={updatedMutClass.color} />
                </div>
                <FormControl fullWidth className={classes.formControlLineage}>
                    <CustomLabel id={'plotType'} label={'Symbol sign'} />
                    <CustomSelect
                        value={updatedMutClass.symbol_sign}
                        onChange={handleSymbolSignChange}
                        inputProps={{
                            name: 'symbolSign',
                            id: 'symbolSign',
                        }}
                    >
                        {signOptions.map(sign => {
                            return (
                                <MenuItem key={sign} value={sign} >
                                    <SymbolComponent color={updatedMutClass.color} shape={sign} />
                                </MenuItem>
                            );
                        })}
                    </CustomSelect>
                </FormControl>

                <div className={classes.head}>
                    <span>
                        Positions:
                    </span>

                    <IconButton
                        onClick={handleAddPositionClick}
                    >
                        <AddIcon />
                    </IconButton>
                </div>

                {updatedMutClass.positions.map((key, index) => {
                    return (
                        <Card key={`${key}-el-${index}`} className={classes.cardFull}>
                            <CardContent className={classes.card}>
                                <div className={classes.line}>
                                    <FormControl fullWidth className={classes.formControlLineage}>
                                        <CustomLabel id={`${key}-head`} label={'Gene'} />
                                        <CustomSelect
                                            value={key}
                                            onChange={(e) => handleEditKey(e, index, key)}
                                            inputProps={{
                                                name: 'gene',
                                                id: 'gene',
                                            }}
                                        >
                                            {mutationGenes[updatedMutClass.lineage]?.map(gene => {
                                                return (
                                                    <MenuItem key={gene} value={gene.toLowerCase()} >
                                                        {gene}
                                                    </MenuItem>
                                                );
                                            })}
                                        </CustomSelect>
                                    </FormControl>
                                    <IconButton
                                        onClick={() => handleExpandClick(key)}
                                        aria-expanded={expanded}
                                        className={classes.expandbut}
                                        aria-label="show more"
                                    >
                                        {expanded[key] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                    <IconButton className={classes.buttonRemove} onClick={() => { handleRemovePositionClick(index, key); }}>
                                        <RemoveIcon />
                                    </IconButton>
                                </div>
                            </CardContent>
                            {expanded[key] && (
                                <Positions handleRemovePositionClick={handleRemovePositionClick} positionKey={key} positions={positionsState} setPositionsState={setPositionsState} />
                            )}
                        </Card>
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Button className={classes.cancel}
                    onClick={handleCloseDialog}>Cancel</Button>
                <Button className={classes.confirm}
                    onClick={handleSubmit}>
                    {isAdd ? 'Add' : 'Update'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = state => ({
    lineages: state.lineages.lineages
});

export default connect(mapStateToProps)(MutClassDialog);
