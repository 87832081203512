// External imports
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid2';

// Internal imports
import SelectInput from '../Common/SelectInput';
import GenotypeSelector from './GenotypeSelector';
import HumanSerologySelector from './HumanSerologySelector';
import CladeTypeSelector from './CladeTypeSelector';
import { resetFrequencies } from '../../redux/actions/frequenciesActions';
import { resetPredictions } from '../../redux/actions/predictionsActions';
import { setParameters } from '../../redux/actions/parametersActions';
import { resetGenotypeStatus } from '../../redux/actions/genotypeActions';
import { fetchMeasureScalesDomains } from '../../redux/actions/settingsActions';
import { getFrequencyCategories } from '../../redux/selectors/metadataSelector';

const styles = () => ({
    formControl: {
        minWidth: 120,
        margin: '8px 0px'
    },
});

const FrequenciesCategorySelector = props => {
    const { 
        intro, lineage, freqCategory, 
        hlaOptions, geneOptions, hla, gene, mutgene, mutposition,
        freqCategoriesOptions, setParameters, resetFrequencies,
        resetPredictions, resetFrequenciesAndPredictions,
        fetchMeasureScalesDomains, permissions
    } = props;

    const handleFreqCategoryChange = async value => {
        setParameters({ freqCategory: value });

        const _gene = gene || geneOptions[0];
        const _hla = hla || hlaOptions[0];
        const params = { lineage, gene: _gene, hla: _hla };
        
        if (freqCategory === 'tcellAntigenicity') {
            params.colorBy = freqCategory;
            setParameters(params);
        }

        if (freqCategory !== 'genotype' || (mutgene && mutposition)) {
            fetchMeasureScalesDomains({
                lineage,
                freqCategory,
                mutgene: (freqCategory === 'genotype') ? mutgene : undefined,
                mutposition: (freqCategory === 'genotype') ? mutposition : undefined,
            });
        }
        resetFrequencies();
        resetPredictions();
    };

    const handleTcellAntigenicityChange = name => async value => {
        await setParameters({ [name]: value });
        resetFrequencies();
        resetPredictions();
    };

    const introOptions = ['loc', 'clade', 'genotype'];
    const freqCategoriesOptionsItems = intro ?
        freqCategoriesOptions.filter(el => introOptions.includes(el.key)) : freqCategoriesOptions;

    return (
        <>
            {freqCategoriesOptions.length > 0 && (
                <SelectInput
                    id='freqCategory'
                    label='Frequencies category'
                    value={freqCategory}
                    onChange={handleFreqCategoryChange}
                    getOptionValue={el => el.key}
                    getOptionLabel={el => el.label}
                    options={freqCategoriesOptionsItems}
                />
            )}
            {(freqCategory === 'tcellAntigenicity') && (
                <Grid container spacing={2}>
                    <Grid size={6}>
                        <SelectInput
                            id='gene'
                            label='Gene'
                            value={gene}
                            onChange={handleTcellAntigenicityChange('gene')}
                            options={geneOptions}
                            getOptionValue={el => el}
                            getOptionLabel={el => el}
                        />
                    </Grid>
                    <Grid size={6}>
                        <SelectInput
                            id='hla'
                            label='HLA'
                            value={hla}
                            onChange={handleTcellAntigenicityChange('hla')}
                            options={hlaOptions}
                            getOptionValue={el => el}
                            getOptionLabel={el => el}
                        />
                    </Grid>
                </Grid>
            )}
            {(freqCategory === 'genotype') && (
                <GenotypeSelector resetData={resetFrequenciesAndPredictions} colorByVar='freqCategory' />
            )}
            {freqCategory === 'humanSerology' && (
                <HumanSerologySelector resetData={resetFrequenciesAndPredictions} />
            )}
            {(freqCategory === 'clade' && permissions.cladeTypeSelector) &&
                <CladeTypeSelector resetData={resetFrequenciesAndPredictions} />
            }
        </>
    );
};

FrequenciesCategorySelector.propTypes = {
    freqCategory: PropTypes.string,
    lineage: PropTypes.string,
    classes: PropTypes.shape({ 
        formControl: PropTypes.string 
    }),
    freqCategoriesOptions: PropTypes.arrayOf(
        PropTypes.shape({ 
            key: PropTypes.string, 
            label: PropTypes.string 
        })
    ),
    freqCategoriesMap: PropTypes.objectOf(
        PropTypes.shape({ 
            show: PropTypes.string, 
            custom: PropTypes.bool, 
            bins: PropTypes.shape({ 
                binMethod: PropTypes.string, 
                number: PropTypes.number 
            }) 
        })
    ),
    hla: PropTypes.string,
    gene: PropTypes.string,
    geneOptions: PropTypes.arrayOf(PropTypes.string),
    hlaOptions: PropTypes.arrayOf(PropTypes.string),
    permissions: PropTypes.object,
    setParameters: PropTypes.func,
    resetFrequencies: PropTypes.func,
    resetPredictions: PropTypes.func,
    resetFrequenciesAndPredictions: PropTypes.func,
    fetchMeasureScalesDomains: PropTypes.func
};

const mapStateToProps = (state) => {
    const { freqCategoriesOptions, freqCategoriesMap } = getFrequencyCategories(state);
    return {
        lineage: state.parameters.lineage,
        freqCategory: state.parameters.freqCategory,
        freqCategoriesOptions,
        freqCategoriesMap,
        geneOptions: state.metadata.tcellAntigenicityOptions.geneOptions,
        hlaOptions: state.metadata.tcellAntigenicityOptions.hlaOptions,
        gene: state.parameters.gene,
        hla: state.parameters.hla,
        mutgene: state.parameters.mutgene,
        mutposition: state.parameters.mutposition,
        permissions: state.user.permissions.frequenciesPermissions
    };
};

const resetFrequenciesAndPredictions = () => dispatch => {
    dispatch(resetFrequencies());
    dispatch(resetPredictions());
};

const mapDispatchToProps = dispatch => bindActionCreators({
    resetFrequencies,
    resetPredictions,
    setParameters,
    resetGenotypeStatus,
    fetchMeasureScalesDomains,
    resetFrequenciesAndPredictions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FrequenciesCategorySelector));
