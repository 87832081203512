import { FormControl, MenuItem } from "@mui/material";
import CustomLabel from "../../../../../assets/GlobalStyles/CustomLabel";
import CustomSelect from "../../../../../assets/GlobalStyles/CustomSelect";   
import { capitalize } from 'lodash';

const ShapeSelect = ({ value, onChange, className }) => {
    return (
        <FormControl fullWidth className={className}>
            <CustomLabel id={"shape"} label={"Shape"} />
            <CustomSelect
                value={value}
                onChange={onChange}
                inputProps={{
                    name: "shape",
                    id: "shape",
                }}
            >
                {["circle", "cross", "diamond", "square", "star"].map((option) => (
                    <MenuItem key={option} value={option}>
                        {capitalize(option)}
                    </MenuItem>
                ))}
            </CustomSelect>
        </FormControl>
    );
};

export default ShapeSelect;
