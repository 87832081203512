import React from 'react';
import { TableRow } from '@mui/material';
import Add from '../../../../pages/SettingsPanel/Regions/Actions/Add';

const AddNewRegionAction = (props) => {
    const { setInfo, setInfoDialog, setAction, currentLineage } = props;

    return (
        <TableRow>
            <td>
                <Add
                    currentLineage={currentLineage}
                    setInfo={setInfo}
                    setInfoDialog={setInfoDialog}
                    setAction={setAction}
                />
            </td>

        </TableRow>
    );
};

export default AddNewRegionAction;
