import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
// import { getMeasures } from '../../redux/selectors/metadataSelector';
import { shouldFetch } from '../../functions/functions';
import { RENDER_STATUS } from '../../config/consts';
import { setHiddenMenu } from '../../redux/actions/uiActions';
import { fetchClades } from '../../redux/actions/cladeActions';
// import { fetchMeasureScalesDomains } from '../../redux/actions/settingsActions';
import { fetchFitnessModel } from '../../redux/actions/fitnessActions';
import { setAntigenicHiddenAlphaClade, setAntigenicHiddenRhoClade, unhideAlphaClade, unhideRhoClade } from '../../redux/actions/antigenicActions';
import { styles } from './styles';
import FitnessData from '../../components/Fitness/FitnessData';
import FitnessOptions from '../../components/Fitness/FitnessOptions';
import ExportableComponent from '../Export/ExportableComponent';
import HamburgerIcon from '../../components/SvgIcons/HamburgerIcon';
import ScalesLegend from '../../components/ColorLegend/Legends/ScalesLegend';
import ParametersInfo from '../../components/ParametersInfo/ParametersInfo';
import appConfig from '../../config/appConfig';

const Fitness = (props) => {
    const { /*fetchMeasureScalesDomains,*/ lineageStatus, lineage, antigenicFitnessModelId, hiddenMenu,
        fetchClades, cladesStatus, exportMode, dataLoaded, setHiddenMenu,
        fitnessModelStatus, setAntigenicHiddenAlphaClade, setAntigenicHiddenRhoClade,
        unhideRhoClade, unhideAlphaClade, fetchFitnessModel, fitnessDataLoaded, menuRight, renderStatus
    } = props;

    // console.log(props);
    const [deleted, setDeleted] = useState([]);
    const [cellInfo, setCellInfo] = useState();
    // const [antigenicFitness, setAntigenicFitness] = useState();
    // const [rho, setRho] = useState();
    // const [alpha, setAlpha] = useState();

    const classes = styles();

    useEffect(() => {
        return () => {
            setDeleted([]);
            setCellInfo(null);
            // setAntigenicFitness();
            // setRho();
            // setAlpha();
        };
    }, []);

    // useEffect(() => {
    //     if (lineageStatus !== 'loaded')
    //         return;

    //     fetchMeasureScalesDomains({ lineage, colorBy: 'antigenicFitness' })
    // }, [lineageStatus])

    useEffect(() => {
        if (lineageStatus !== 'loaded') return;
        if (shouldFetch(cladesStatus)) fetchClades({ lineage });
        if (!fitnessDataLoaded) initFitnessData();
    });

    const initFitnessData = () => {
        if (cladesStatus !== 'loaded' || (!exportMode && fitnessModelStatus === 'loaded'))
            return;

        if (shouldFetch(fitnessModelStatus))
            fetchFitnessModel({ lineage, antigenicFitnessModelId });
    };

    const setDeletedClades = (clade) => {

        console.log(deleted);
        const newDeleted = {
            type: 'clade',
            id: clade
        };
        setDeleted([...deleted, newDeleted]);
        setAntigenicHiddenAlphaClade(clade);
    };

    const setDeletedRhos = (rho) => {
        const newDeleted = {
            type: 'rho',
            id: rho
        };
        setDeleted([...deleted, newDeleted]);
        setAntigenicHiddenRhoClade(rho);
    };

    const undoDeleting = () => {
        if (deleted.length === 0)
            return;
        const lastDeleted = deleted[deleted.length - 1];
        if (lastDeleted.type === 'rho')
            unhideRhoClade(lastDeleted.id);
        else
            unhideAlphaClade(lastDeleted.id);
        setDeleted(current =>
            current.filter(element => {
                return element.id !== lastDeleted.id;
            })
        );
    };

    const getParameters = () => {
        const pars = [
            { name: 'Lineage', value: lineage },
            { name: 'Model', value: antigenicFitnessModelId },
        ];
        return pars;
    };

    const handleHideClick = () => {
        setHiddenMenu({ hiddenMenu: !hiddenMenu });
    };

    const matrixCellHandleMouseOver = (rho, alpha, antigenicFitness) => {
        setCellInfo({ rho, alpha, antigenicFitness });
        // setRho(rho);
        // setAlpha(alpha);
        // setAntigenicFitness(antigenicFitness);
    };

    const pars = getParameters();
    return (
        <>
            {!exportMode && (
                <div className={classes.root}>
                    <Grid container className={classes.container}>
                        {!menuRight &&
                            <Grid size={hiddenMenu ? 0.3 : 3} className={classes.fitnessSidebarLeft}>
                                {!hiddenMenu ?
                                    <FitnessOptions undoDeleting={undoDeleting} handleHideClick={handleHideClick} cellInfo={cellInfo} />
                                    :
                                    <IconButton className={classes.expandButton} onClick={handleHideClick}>
                                        <HamburgerIcon />
                                    </IconButton>
                                }
                            </Grid>
                        }
                        <Grid size={hiddenMenu ? 11.7 : 9} className={classes.item}>
                            <div className={classes.space}>
                                <ExportableComponent filename="fitness">
                                    <FitnessData
                                        dataInitialized={dataLoaded}
                                        setDeletedRows={setDeletedClades}
                                        setDeletedColumns={setDeletedRhos}
                                        matrixCellHandleMouseOver={matrixCellHandleMouseOver}
                                    />
                                </ExportableComponent>
                            </div>
                        </Grid>
                        {menuRight &&
                            <Grid size={hiddenMenu ? 0.3 : 3} className={classes.fitnessSidebarRight}>
                                {!hiddenMenu ?
                                    <FitnessOptions undoDeleting={undoDeleting} handleHideClick={handleHideClick} cellInfo={cellInfo} />
                                    :
                                    <IconButton className={classes.expandButton} onClick={handleHideClick}>
                                        <HamburgerIcon />
                                    </IconButton>
                                }
                            </Grid>
                        }
                    </Grid>
                </div>
            )}
            {exportMode && dataLoaded && (
                <Grid container className={classes.containerExport}>
                    <Grid size={8} className={classes.item}>
                        <FitnessData dataInitialized={dataLoaded} />
                    </Grid>
                    <Grid size={4}>
                        <div className={classes.legend}>
                            <ParametersInfo parameters={pars} />
                            <div className={classes.dataLegend}>
                                <ScalesLegend
                                    scaleId="antigenicFitness"
                                    measureName="antigenicFitness"
                                    ticks={3}
                                    title="Fitness"
                                    precision={2}
                                />
                            </div>
                        </div>
                    </Grid>

                </Grid>
            )}
            {exportMode === true && renderStatus === RENDER_STATUS.DONE && <div id="exportDone" />}
        </>
    );
};

const mapStateToProps = (state) => {
    const {
        lineage,
        antigenicFitnessModelId,
        exportMode,
    } = state.parameters;
    const { fitnessModelStatus } = state.fitness;
    const { cladesStatus } = state.cladeData;
    const { lineageStatus } = state.lineages;
    const dataLoaded = (cladesStatus === 'loaded' && fitnessModelStatus === 'loaded');
    const fitnessDataLoaded = fitnessModelStatus === 'loaded';

    return {
        lineage: lineage || appConfig.default.lineage,
        antigenicFitnessModelId,
        exportMode,
        lineageStatus,
        fitnessDataLoaded,
        cladesStatus,
        fitnessModelStatus,
        hiddenMenu: state.ui.hiddenMenu,
        menuRight: state.user.menuRight,
        dataLoaded,
        renderStatus: state.render.renderStatus,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setAntigenicHiddenAlphaClade,
    setAntigenicHiddenRhoClade,
    unhideAlphaClade,
    unhideRhoClade,
    fetchFitnessModel,
    // fetchMeasureScalesDomains,
    fetchClades,
    setHiddenMenu,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Fitness);
