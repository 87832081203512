import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import ConfirmDialog from '../../ConfirmDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import config from '../../../../config/envConfig';
import { postAxios } from '../../../../functions/axiosRequests';
import { actionsStyles } from '../../Styles/actionsStyles';
import { fetchAllScales } from '../../../../redux/actions/settingsActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const DeleteScale = ({scale, setInfoDialog, setAction, setInfo, fetchAllScales}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = actionsStyles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const deleteScale = async () => {
        try {
            const url = `${config.serverLink}/api/admin/deleteScale`;
            const body = { scaleId: scale.scaleId, lineage: scale.lineage };
            await postAxios(url, body);
            setInfo(`Scale ${scale.scaleId} deleted sucesfully.`);
        }
        catch (error) {
            console.error(error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during deleting ${scale.scaleId}.: ${message}`);
        }
        finally {
            setAction('Delete scale');
            handleCloseDialog();
            setInfoDialog(true);
            fetchAllScales();
        }
    };


    return (
        <>
            <IconButton onClick={handleClick} className={classes.button}>
                <DeleteIcon />
            </IconButton>
            <ConfirmDialog text="scale" handleCloseDialog={handleCloseDialog} openDialog={openDialog} deleteFunc={deleteScale} name={scale.scaleId} scale={true} />
        </>

    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchAllScales
        },
        dispatch,
    );

export default connect(undefined, mapDispatchToProps)(DeleteScale);
