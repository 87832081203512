import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import Grid from '@mui/material/Grid2';
import { GEO_MAP_KEY, MAP_THEME } from '../../config/geoMapConfig';
import appConfig from '../../config/appConfig';
import { prepareGroupingExpressions } from '../../components/Map/groupsExpressions';
import {
    createSingleMarkerPopup,
    prepareSigCategoriesPopup,
} from '../../components/Map/popupsTemplates';
import { createPieChart } from '../../components/Map/pieChart';
import GeoMapOptions from '../../components/Map/GeoMapOptions';
import { fetchLineages } from '../../redux/actions/lineagesActions';
import { fetchGeoData } from '../../redux/actions/geomapActions';
import { shouldFetch } from '../../functions/functions';
import { styles } from './styles';
import { setAlertStatus } from '../../redux/actions/alertActions';
import { getIsMobile } from '../../redux/selectors/statusSelector';
import ExportableComponent from '../Export/ExportableComponent';
import { setParameters } from '../../redux/actions/parametersActions';
import { fetchMeasureScalesDomains } from '../../redux/actions/settingsActions';
import { getSelectedMeasureGeoMapLegend } from '../../redux/selectors/rangeDataSelector';
import { correctedColorsGeoJson } from '../../redux/selectors/geomapSelector';
import { fetchClades } from '../../redux/actions/cladeActions';
// import { setGeoLoading } from '../../redux/actions/geomapActions';
import MetaInformations from '../../components/MetaInformations/MetaInformations';
import { isNil } from 'lodash';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import { predictionBaselineSelector } from '../../redux/selectors/parametersSelector';
import { dynamicStyles, useWindowSize } from '../../assets/GlobalStyles/dynamicStyles';

const DATA_SOURCE_COUNT = 'data_count';
const DATA_SOURCE_MULTIPLICITY = 'data_multiplicity';
const CIRCLES_LAYER = 'data_circles';

// const latestPropsKeys = ['lineage', 'uniqueCategories', 'geojson', 'geoFreqCategory'];

const GeoMap = (props) => {
    const {
        geoMapStatus,
        cladesStatus,
        lineage,
        caseSpan,
        colorBy,
        predictionBaseline,
        fetchGeoData,
        geojson,
        hiddenMenuMobile,
        cladeType,
        intro,
        geoFreqCategory,
        hiddenMenu,
        menuRight,
        isMobile,
        mutgene,
        setParameters,
        mutposition,
        colorData,
        lineageStatus,
        measureDomainStatus,
        exportMode,
        height,
        width,
        mapParams,
        setAlertStatus,
        fetchClades,
        submissionDate
    } = props;

    const [mapRendered, setMapRendered] = useState(false);
    const { width: windowWidth } = useWindowSize();

    const source = useMemo(() => {
        return geoFreqCategory === 'count'
            ? DATA_SOURCE_COUNT
            : DATA_SOURCE_MULTIPLICITY;
    }, [geoFreqCategory]);

    const mapRootContainerRef = useRef(null);
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const colorDataGlobalRef = useRef({});
    const markersOnScreenRef = useRef({});
    const allMarkersRef = useRef({});

    const latestProps = useRef({ ...props, source });

    // const prevGeoFreqCategory = usePrevious(geoFreqCategory);
    const classes = styles();
    const geoLoading = geoMapStatus == 'loading';

    const nodata =
        geojson.features.length === 0 &&
        geoMapStatus === 'loaded' &&
        (colorBy !== 'genotype' ||
            (measureDomainStatus === 'loaded' &&
                !isNil(mutgene) &&
                !isNil(mutposition)));

    // const cladesGeoReady = colorBy !== 'clade' || cladesStatus === 'loaded';

    const genotypeEmpty = colorBy === 'genotype' && (!mutgene || !mutposition);
    // const geoMapLoaded = geoMapStatus === 'loaded' || genotypeEmpty;
    // const measureDomainsLoaded = measureDomainStatus === 'loaded' || genotypeEmpty;
    // const geoMapReady = geoMapLoaded && measureDomainsLoaded && isGeoMap;

    const geoMapDataLoaded =
        !genotypeEmpty &&
        geoMapStatus === 'loaded' &&
        measureDomainStatus === 'loaded';

    const canFetchGeoData = colorBy !== 'genotype' || (mutgene && mutposition);
    // console.log(`
    // genotypeEmpty = ${genotypeEmpty},
    // measureDomainsLoaded = ${measureDomainsLoaded},
    // geoMapLoaded = ${geoMapLoaded},
    // geoMapDataLoaded = ${geoMapDataLoaded},
    // geoMapReady = ${geoMapReady}`);

    const cleanUpMap = () => {
        // console.log(`[cleanUpMap] lineage = ${lineage}, nodata = ${nodata}`, mapRef.current)
        if (mapRef.current) {
            (async () => {
                // console.log('[cleanUpMap]');
                removeMarkers();
                removeEventHandlers();
                if (mapRef.current.remove) mapRef.current.remove(); // Remove the map instance
                mapRef.current = null;
                setMapRendered(false);
            })();
        }
    };

    useEffect(() => {
        latestProps.current = { ...props, source };
    });

    useEffect(() => {
        return () => {
            cleanUpMap();
        };
    }, []);

    useEffect(() => {
        // console.log('[useEffect] nodata', nodata)
        // return () => {
        if (nodata) cleanUpMap();
        // };
    }, [nodata]);

    useEffect(() => {
        colorDataGlobalRef.current = colorData;
    }, [colorData]);

    useEffect(() => {
        if (!exportMode) return;
        const element = mapRootContainerRef.current;
        if (!element) return;
        element.style.width = `${width - 100}px`;
        element.style.height = `${height - 100}px`;
    }, [exportMode]);

    useEffect(() => {
        // console.log('[useEffect] canFetchGeoData = ', canFetchGeoData, lineage, colorBy, mutgene, mutposition, cladeType)
        if (lineageStatus !== 'loaded') return;
        if (colorBy === 'clade' && shouldFetch(cladesStatus))
            fetchClades({ lineage });
        if (shouldFetch(geoMapStatus) && canFetchGeoData) {
            fetchGeoData({
                lineage,
                colorBy,
                mutgene,
                mutposition,
                cladeType,
                caseSpan,
                predictionBaseline,
                submissionDate
            });
        }
    }, [lineageStatus, colorBy, cladeType, geoMapStatus, canFetchGeoData, submissionDate]);

    useEffect(() => {
        if (lineageStatus !== 'loaded') return;
        if (geojson.features && geojson.features.length > 0 && !geoLoading) {
            updateMap();
        }
    }, [geoFreqCategory]);

    // useEffect(() => {
    // }, [geoMapReady])
    useEffect(() => {
        if (lineageStatus !== 'loaded') return;

        initMapLayers();
    }, [geoMapDataLoaded, hiddenMenu]);

    const getMapDimensions = () => {
        // console.log('GET MAP DIMESIONS');
        const elementDimn = mapContainerRef.current.getBoundingClientRect(); //document.getElementById('mapContainer').getBoundingClientRect();
        const dimensions = {
            width: elementDimn.width + 100,
            height: elementDimn.height + 100,
        };

        return dimensions;
    };

    const setMapParams = () => {
        // console.log('SET MAP PARAMS');
        const dimn = getMapDimensions();
        const mapParams = {
            zoom: mapRef.current.getZoom(),
            center: mapRef.current.getCenter(),
            width: dimn.width,
            height: dimn.height,
        };
        setParameters({ mapParams });

        return mapParams;
    };

    const initMapLayers = async () => {
        // console.log(`[initMapLayers] ${lineage} mapComponent initialized: ${mapContainerRef.current} / ${mapRef.current}, geoMapDataLoaded = ${geoMapDataLoaded}`)
        mapboxgl.accessToken = GEO_MAP_KEY;

        removeMarkers();
        removeLayersAndSources();
        // removeEventHandlers();

        const online = navigator.onLine;
        if (!online) {
            mapContainerRef.current.textContent =
                'Maps are not supported in offline mode';
            return;
        }

        if (mapContainerRef.current && !mapRef.current) {
            mapRef.current = new mapboxgl.Map({
                container: 'mapContainer',
                style: MAP_THEME.LIGHT,
                zoom: exportMode ? mapParams.zoom : 1,
                center: exportMode
                    ? [mapParams.center.lng, mapParams.center.lat]
                    : [19, 52],
                preserveDrawingBuffer: true,
            });
            // Add event listeners, set up the map, etc.

            mapRef.current.on('load', () => {
                updateMap();
            });

            mapRef.current.on('data', (e) => {
                const { source } = latestProps.current;
                //console.log('ON DATA', lineage, source, e, geojson.features, mapRef.current.querySourceFeatures(source));
                if (e.sourceId === source && e.isSourceLoaded) {
                    const sourceFeatures = mapRef.current.querySourceFeatures(
                        source,
                        null
                        // (d) => {
                        //     console.log('callback', d);
                        // }
                    );
                    // console.log(`[on data] ${lineage} source:`, mapRef.current.getSource(source)._data, 'isSourceLoaded:', mapRef.current.isSourceLoaded(source),
                    // 'sourceFeatures:', sourceFeatures, geojson.features);

                    // console.log(
                    //     'data event',
                    //     sourceFeatures.length,
                    //     'Is source added:',
                    //     mapRef.current.getSource(source),
                    //     mapRef.current.isSourceLoaded(source)
                    // );
                    if (
                        mapRef.current.isSourceLoaded(source) &&
                        sourceFeatures.length > 0
                    ) {
                        updateGroupsMarkers();
                        setTimeout(() => setMapRendered(true), 1000);
                    }
                }
            });

            // const handleIdle = () => {
            //     if (mapRef.current.isSourceLoaded(source)) {
            //         const features = mapRef.current.querySourceFeatures(source);
            //         if (features.length > 0) {
            //             // console.log('Features are available and queryable:', features.length);
            //             updateGroupsMarkers();
            //             setTimeout(() => setMapRendered(true), 1000);
            //         }
            //         // else {
            //         // console.log('Source is loaded, but no features found in the current view.');
            //         // }
            //     }
            // };

            // mapRef.current.on('idle', handleIdle);

            // console.log(map)
            // mapRef.current.on('moveend', (e) => {
            //     // console.log('moveend, sourceLoaded: ', e.isSourceLoaded);
            //     updateGroupsMarkers();
            //     setTimeout(() => setMapRendered(true), 1000);
            // });
        } else if (geoMapDataLoaded) {
            updateMap();
        }
    };

    const removeMarkers = () => {
        //const { lineage } = latestProps.current;
        // console.log(`[removeMarkers]: ${lineage}`, Object.keys(allMarkersRef.current).length);
        Object.values(allMarkersRef.current).forEach(async (marker) => {
            marker.remove();
        });
        markersOnScreenRef.current = {};
        allMarkersRef.current = {};
    };

    const removeLayersAndSources = () => {
        if (mapRef.current && mapRef.current.isStyleLoaded()) {
            if (mapRef.current.getLayer(CIRCLES_LAYER))
                mapRef.current.removeLayer(CIRCLES_LAYER);
            if (mapRef.current.getSource(DATA_SOURCE_COUNT))
                mapRef.current.removeSource(DATA_SOURCE_COUNT);
            if (mapRef.current.getSource(DATA_SOURCE_MULTIPLICITY))
                mapRef.current.removeSource(DATA_SOURCE_MULTIPLICITY);
        }
        // console.log(`[removeLayersAndSources]: ${lineage} removedLayers and sources`);
    };

    const removeEventHandlers = () => {
        if (mapRef.current) {
            mapRef.current.off('load');
            mapRef.current.off('data');
            mapRef.current.off('moveend');
        }
    };
    const updateMap = () => {
        const { uniqueCategories, geoFreqCategory, geojson } =
            latestProps.current;
        // console.log('UPDATE MAP', uniqueCategories);
        if (!mapRef.current || !mapRef.current.isStyleLoaded()) return;

        const { groupsCounts, groupsColors, groupsMultiplicity } =
            prepareGroupingExpressions(uniqueCategories, 'groupId');
        const clusterProperties =
            geoFreqCategory === 'count' ? groupsCounts : groupsMultiplicity;

        const colorGroup = groupsColors;
        // console.log(`[updateMap] ${lineage} clusterProperties:`, clusterProperties);
        // console.log(`[updateMap] ${lineage} GEOJSON.FEATURES.LENGTH = `, geojson.features.length, geojson.features)
        removeMarkers();
        // removeEventHandlers();
        removeLayersAndSources();

        // console.log('[updateMap]: addSource', source, geojson);

        mapRef.current.addSource(source, {
            type: 'geojson',
            data: geojson,
            cluster: true,
            clusterRadius: 50,
            clusterProperties: clusterProperties,
        });

        if (geojson.features.length) {
            // console.log(`adding layer `,colorGroup);
            addLayer(colorGroup);
            addPopupForSingleMarkers();
            setAlertStatus({
                status: false,
                model: 'geoMap',
                id: `insufficientData`,
            });
        } else {
            setAlertStatus({
                status: true,
                model: 'geoMap',
                id: `insufficientData`,
            });
        }
    };

    const addLayer = (groupsColors) => {
        if (mapRef.current /*&& map.isStyleLoaded()*/) {
            mapRef.current.addLayer({
                id: CIRCLES_LAYER,
                type: 'circle',
                source: source,
                filter: ['!=', 'cluster', true],
                layout: {
                    visibility: 'visible',
                },
                paint: {
                    'circle-color': ['case', ...groupsColors],
                    'circle-radius': 12,
                },
            });
        }
    };

    const addPopupForSingleMarkers = () => {
        var popup = new mapboxgl.Popup({
            closeButton: true,
            closeOnClick: true,
        });

        mapRef.current.on('click', CIRCLES_LAYER, function (e) {
            mapRef.current.getCanvas().style.cursor = 'pointer';

            const coordinates = e.features[0].geometry.coordinates.slice();
            const groupId = e.features[0].properties.groupId;
            const color = e.features[0].properties[geoFreqCategory];
            const singleColoring = colorDataGlobalRef.current[groupId]?.color;
            const label =
                colorDataGlobalRef.current[groupId]?.label ||
                colorDataGlobalRef.current[groupId]?.value;

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            popup
                .setLngLat(coordinates)
                .setHTML(
                    createSingleMarkerPopup(
                        label,
                        color,
                        singleColoring,
                        geoFreqCategory
                    )
                )
                .addTo(mapRef.current);
        });
    };

    const updateGroupsMarkers = async () => {
        const { source } = latestProps.current;
        // console.log(`[updateGroupsMarkers] 1 ${lineage}, styleLoaded:`, mapRef.current?.isStyleLoaded())
        if (!mapRef.current || !mapRef.current.isStyleLoaded()) return;

        // console.log(`[updateGroupsMarkers] 2 ${lineage}, uniqueCategories: `, uniqueCategories)
        removeMarkers();
        const newMarkers = {};
        const features = mapRef.current.querySourceFeatures(source);
        //console.log('source:', source);
        // console.log(mapRef.current.getSource(source));
        // console.log(`[updateGroupsMarkers] 3 ${lineage}/$ features:`, features, features.filter(feature => feature?.properties?.cluster));
        //console.log('allMarkersRef.current', allMarkersRef.current)
        // console.log('[updateGroupsMarkers]', features.length,  '/', features.filter(feature => feature?.properties?.cluster).length)
        features
            .filter((feature) => feature?.properties?.cluster)
            .forEach((feature) => {
                const {
                    geometry: { coordinates },
                    properties,
                } = feature;
                const groupId = properties.cluster_id;

                let marker = allMarkersRef.current[groupId];
                // console.log(index, `groupId = ${groupId}`, 'marker = ', marker);

                if (!marker) {
                    marker = createMarker(coordinates, properties);
                    allMarkersRef.current[groupId] = marker;
                }
                newMarkers[groupId] = marker;

                if (!markersOnScreenRef.current[groupId])
                    marker.addTo(mapRef.current);
            });

        // Update the reference to the markers on screen
        markersOnScreenRef.current = newMarkers;

        removeNotVisibleMarkers(newMarkers);
        markersOnScreenRef.current = newMarkers;
        // console.log(`[updateGroupsMarkers] 4 ${lineage} allMarkers:`, allMarkersRef.current, Object.keys(allMarkersRef.current).length);
        // console.log(`[updateGroupsMarkers] 5 ${lineage} markersOnScreenRef:`, markersOnScreenRef.current, Object.keys(markersOnScreenRef.current).length);

        // console.log('[updateGroupsMarkers] markersOnScreenRef.current = ', Object.keys(markersOnScreenRef.current).length, markersOnScreen)
    };

    const createMarker = (coordinates, properties) => {
        const { geoFreqCategory, uniqueCategories, colorData } =
            latestProps.current;
        const colors = getUsedColors();
        // console.log('[createMarker]', colors);
        //const genotypeColors = getGenotypeColors();
        const clusterCategories = uniqueCategories.filter(
            (groupId) => properties[groupId]
        );
        const pieChart = createPieChart(properties, colors, uniqueCategories);

        // console.log('[createMarker]', properties, pieChart)
        const pieChartBig = createPieChart(
            properties,
            colors,
            uniqueCategories,
            true
        );
        const coloringToPopup = colorData;
        const popupHtml = prepareSigCategoriesPopup(
            clusterCategories,
            coloringToPopup,
            properties,
            pieChartBig,
            geoFreqCategory
        );
        // console.log('[createMarker] pieChart =', pieChart, pieChartBig);
        return new mapboxgl.Marker({ element: pieChart })
            .setLngLat(coordinates)
            .setPopup(
                new mapboxgl.Popup({ offset: 25, draggable: true }).setHTML(
                    popupHtml
                )
            );
    };

    const removeNotVisibleMarkers = (newMarkers) => {
        Object.keys(markersOnScreenRef.current)
            .filter((id) => !newMarkers[id])
            .forEach((id) => {
                // console.log(
                //     `[removeNotVisibleMarkers] removing not visible marker ${id}`
                // );
                markersOnScreenRef.current[id].remove();
            });
    };

    const getUsedColors = () => {
        const { uniqueCategories, geojson } = latestProps.current;
        //console.log('[getUsedColors]', uniqueCategories);
        const colorMap = geojson.features.reduce((acc, el) => {
            acc[el.properties.groupId] =
                /*colorData[el.properties.groupId].color; //*/ el.properties.color;
            return acc;
        }, {});

        return uniqueCategories.map((groupId) => colorMap[groupId]);
    };

    // console.log(`lineage = ${lineage}, colorBy = ${colorBy}, nodata = ${nodata}, isGeoMap = ${isGeoMap}`)

    // Function to render the GeoMapOptions
    const renderGeoMapOptions = (position) => {
        // console.log(`[renderGeoMapOptions] position=${position}, menuRight=${menuRight}, intro=${intro}`)
        const sideBarClass =
            position == 'left'
                ? classes.mapSidebarLeft
                : classes.mapSidebarRight;
        if (
            (position === 'left' && !menuRight && !intro) ||
            (position === 'right' && (menuRight || intro)) ||
            (isMobile && !hiddenMenuMobile)
        ) {
            return (
                <Grid className={`${sideBarClass} ${hiddenMenu ? classes.hidden : ''}`}>
                    <GeoMapOptions />
                </Grid>
            );
        }
        return null;
    };

    // Function to render the map container
    const renderMapContainer = () => {
        if (nodata) {
            // Render your no data message here
            return (
                <Grid size='grow' className={classes.item}>
                    <svg
                        width='30'
                        height='26'
                        viewBox='0 0 30 26'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className={classes.noDataIcon}
                    >
                        <path
                            d='M24.7614 26H4.31763C2.5643 26 1.16164 25.3048 0.460313 24.123C-0.241017 22.9412 -0.135818 21.4118 0.740845 19.8824L11.0153 2.36364C11.892 0.868984 13.1544 0 14.557 0C15.9597 0 17.2221 0.868984 18.0988 2.36364L28.3031 19.9171C29.1798 21.4118 29.285 22.9759 28.5837 24.1578C27.9174 25.3048 26.5147 26 24.7614 26ZM14.557 2.43316C14.0661 2.43316 13.5752 2.85027 13.1193 3.58021L2.91497 21.1337C2.49417 21.8636 2.38897 22.5241 2.63444 22.9412C2.8799 23.3583 3.5111 23.6016 4.35269 23.6016H24.7965C25.6381 23.6016 26.2693 23.3583 26.5147 22.9412C26.7602 22.5241 26.655 21.8636 26.2342 21.1337L15.9597 3.58021C15.5389 2.85027 15.0129 2.43316 14.557 2.43316Z'
                            fill='#4C4C4D'
                        />
                        <path
                            d='M14.5568 21.238C15.7769 21.238 16.766 20.2575 16.766 19.0481C16.766 17.8387 15.7769 16.8583 14.5568 16.8583C13.3367 16.8583 12.3477 17.8387 12.3477 19.0481C12.3477 20.2575 13.3367 21.238 14.5568 21.238Z'
                            fill='#4C4C4D'
                        />
                        <path
                            d='M15.0481 8.06418C14.7676 8.06418 14.3118 8.06418 14.0663 8.06418H13.3299C13.0494 8.06418 12.874 8.27273 12.874 8.55081L13.0844 10.4973C13.1195 10.7754 13.1546 11.1925 13.1896 11.4706L13.6104 14.9118C13.6455 15.1898 13.8559 15.3984 14.1014 15.3984C14.3468 15.3984 14.7325 15.3984 14.978 15.3984C15.2235 15.3984 15.4339 15.1898 15.4689 14.9118L15.8897 11.4706C15.9248 11.1925 15.9599 10.7754 15.9949 10.4973L16.2053 8.55081C16.2404 8.27273 16.03 8.06418 15.7495 8.06418H15.0481Z'
                            fill='#4C4C4D'
                        />
                    </svg>
                    <p className={classes.noData}>No data available.</p>
                </Grid>
            );
        }

        if (isMobile)
            return (
                <Grid size='grow' className={classes.item}>
                    <div
                        id='mapContainer'
                        ref={mapContainerRef}
                        className={
                            !hiddenMenuMobile
                                ? classes.mapMobileHidden
                                : classes.mapMobile
                        }
                    />
                    <MetaInformations />
                </Grid>
            );
        // Render the map container for normal cases
        return (
            // <Grid container className={classes.container}>
            <Grid size='grow' className={classes.item}>
                <ExportableComponent
                    setMapParams={setMapParams}
                    filename='geomap'
                >
                    <div
                        id='mapContainer'
                        ref={mapContainerRef}
                        className={`${classes.map} ${classes.border}`}
                    ></div>
                </ExportableComponent>
                {!geoLoading && (
                    <div style={{ margin: '0 20px', paddingBottom: '15px' }}>
                        <MetaInformations />
                    </div>
                )}
            </Grid>
            // </Grid>
        );
    };

    // console.log('2. markersOnScreenRef.current = ',markersOnScreenRef.current);
    return (
        <>
            {exportMode && height > 0 ? (
                <div
                    id='map-root'
                    ref={mapRootContainerRef}
                    className={classes.rootExport}
                >
                    <div
                        id='mapContainer'
                        ref={mapContainerRef}
                        className={classes.mapExport}
                    ></div>
                    {exportMode && geoMapStatus === 'loaded' && mapRendered && (
                        <div id='exportDone' />
                    )}
                </div>
            ) : (
                <div style={dynamicStyles(isMobile).root}>
                    <ErrorAlert />
                    {isMobile ? (
                        <>
                            {renderGeoMapOptions('left')}
                            {renderMapContainer()}
                        </>
                    ) : (
                        <Grid container className={classes.container}>
                            {renderGeoMapOptions('left')}
                            {renderMapContainer()}
                            {renderGeoMapOptions('right')}
                        </Grid>
                    )}
                    {geoMapStatus === 'loaded' && mapRendered && (
                        <div id='exportDone' />
                    )}
                </div>
            )}
        </>
    );
};

GeoMap.propTypes = {
    lineage: PropTypes.string,
    colorBy: PropTypes.string,
    modelId: PropTypes.string,
    freqCategory: PropTypes.string,
    treeDataStatus: PropTypes.string,
    geoMapStatus: PropTypes.string,
    strainSubset: PropTypes.string,
    cladeType: PropTypes.string,
    geoFreqCategory: PropTypes.string,
    cladesStatus: PropTypes.string,
    lineageStatus: PropTypes.string,
    mutgene: PropTypes.string,
    // zoomNodeId: PropTypes.number,
    mutposition: PropTypes.number,
    //geoLoading: PropTypes.bool,
    isGeoMap: PropTypes.bool,
    hiddenMenuMobile: PropTypes.bool,
    hiddenMenu: PropTypes.bool,
    menuRight: PropTypes.bool,
    isMobile: PropTypes.bool,
    exportMode: PropTypes.bool,
    uniqueCategories: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    mapParams: PropTypes.shape({
        zoom: PropTypes.number,
        center: PropTypes.shape({
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
    }),
    classes: PropTypes.shape({ formControl: PropTypes.string }),
    geojson: PropTypes.object,
    colorData: PropTypes.object,
    height: PropTypes.number,
    width: PropTypes.number,
    fetchGeoData: PropTypes.func,
    setParameters: PropTypes.func,
    initStrainTree: PropTypes.func,
    fetchClades: PropTypes.func,
    setAlertStatus: PropTypes.func,
};

const mapStateToProps = (state) => {
    const {
        lineage,
        modelId,
        geoMapColorBy,
        freqCategory,
        caseSpan,
        geoFreqCategory,
        // zoomNodeId,
        strainSubset,
        mutgene,
        mutposition,
        mapParams,
        height,
        width,
        exportMode,
        cladeType,
        intro,
    } = state.parameters;
    const { modules } = state.metadata;
    const { /*clades,*/ cladesStatus } = state.cladeData;
    const { treeDataStatus } = state.treeData;
    const { geoMapStatus } = state.geomap;
    const isGeoMap = modules && modules.geomap ? modules.geomap : false;

    const geojson = correctedColorsGeoJson(state);
    // console.log(geojson?.features);
    const uniqueCategories = geojson.features
        ? [
            ...new Set(
                geojson.features.map((el) => el.properties.groupId).sort()
            ),
        ]
        : [];

    // console.log('features = ', geojson.features);
    // console.log('uniqueCategories = ', uniqueCategories);

    // const measures = getMetadataMeasuresWithScales(state);
    // console.log('debug 3');
    // const selectedMeasure = measures[geoMapColorBy] || {};
    const colorData = getSelectedMeasureGeoMapLegend(state); // selectedMeasure.scale.range.data; //visibleRange;
    // console.log('metadata: status', geoMapColorBy, state.metadata.measureDomainStatuses[geoMapColorBy])
    const measureDomainStatus =
        geoMapColorBy === 'clade'
            ? cladesStatus
            : state.metadata.measureDomainStatuses[geoMapColorBy];
    //console.log('[ColorCodingLegend]', colorBy, colorData);

    return {
        lineage: lineage || appConfig.default.lineage,
        caseSpan,
        menuRight: isNil(state.user.menuRight) ? true : state.user.menuRight,
        exportMode,
        measureDomainStatus,
        lineagesStatus: state.lineages.lineagesStatus,
        modelId:
            (modelId || '').split('-').length === 4
                ? modelId
                : appConfig.default.antigenicModelId,
        colorBy: geoMapColorBy,
        freqCategory,
        // zoomNodeId,
        strainSubset,
        lineageStatus: state.lineages.lineageStatus,
        cladesStatus,
        treeDataStatus,
        geojson,
        // clades,
        intro,
        uniqueCategories,
        colorData,
        geoFreqCategory,
        geoMapStatus,
        mutgene,
        mutposition,
        cladeType,
        isGeoMap,
        predictionBaseline: predictionBaselineSelector(state),
        hiddenMenu: state.ui.hiddenMenu,
        hiddenMenuMobile: state.ui.hiddenMenuMobile,
        isMobile: getIsMobile(),
        mapParams,
        height,
        width,
        submissionDate: state.parameters.submissionDate
    };
};

const mapDispatchToProps = {
    fetchGeoData,
    fetchLineages,
    setAlertStatus,
    setParameters,
    fetchMeasureScalesDomains,
    fetchClades,
    // setGeoLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(GeoMap);
