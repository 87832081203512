import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import config from '../../../../config/envConfig';
import { postAxios, fetchAxios } from '../../../../functions/axiosRequests';
import { emptyObject } from '../../../../functions/functions';
import EditIcon from '@mui/icons-material/Edit';
import RoleDialog from '../Dialogs/RoleDialog';

const EditRole = ({ roleName, setInfo, setInfoDialog, setAction }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [role, setRole] = useState({});

    const handleClick = async () => {
        try {
            await fetchRoleDetails();
            setOpenDialog(true);
        }
        catch (error) {
            console.error('Error fetching role details:', error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during fetching role "${roleName}" details: ${message}`);
            setAction('Get role');
            handleCloseDialog();
            setInfoDialog(true);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const fetchRoleDetails = async () => {
        const url = `${config.serverLink}/api/admin/getRole?name=${roleName}`;
        const response = await fetchAxios(url);
        const { role } = response.data; 
        setRole(role);
    };

    const updateRole = async (role) => {
        try {
            const url = `${config.serverLink}/api/admin/editRole`;
            const body = { role };
            await postAxios(url, body);
            setInfo(`Role "${role.name}" updated sucesfully.`);
        }
        catch (error) {
            console.error(error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during updating role: ${message}.`);
        }
        finally {
            setAction('Update role');
            handleCloseDialog();
            setInfoDialog(true);
        }

    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <EditIcon />
            </IconButton>
            {!emptyObject(role) && <RoleDialog role={role} handleCloseDialog={handleCloseDialog} openDialog={openDialog} onSubmit={updateRole} mode={'edit'} />}
        </>
    );


};

export default EditRole;
