import { uniq, uniqBy } from 'lodash';
import { createSelector } from 'reselect';

const getVaccineProtectionValues = (state) => state.vaccines.vaccinesData.vaccineProtectionValues;
const getAllRefStrains = (state) => state.vaccines.vaccinesData.refStrains;
const getClades = (state) => state.cladeData.clades;
const getVaccineSerumType = (state) => state.parameters.vaccinesSerumType;
const getSelectedFerretStrains = (state) => state.parameters.vaccinesFerretRefStrains;
const getSelectedClades = (state) => state.parameters.vaccinesRhos;
const getVaccinesFrequencies = (state) => state.vaccines.vaccinesFrequencies;
const getVaccinesPredictions = (state) => state.vaccines.vaccinesPredictions;

const getCladeIndices = createSelector( 
    ({parameters}) => parameters.vaccinesRhos,
    cladeIds => (cladeIds||[]).reduce((acc, id, index) => {
        acc[id] = index+1;
        return acc;
    }, {})
);

// Select data for selected strains, clades and serum type
export const getDataFilteredBySerumType = createSelector(
    [
        getVaccineProtectionValues,
        getVaccineSerumType,
    ],
    (data, serumType) => {
        if (!data) return null;
        const filteredData = data.filter(elem => elem.type === serumType);
        return filteredData;
    }
);

export const getSelectedData = createSelector(
    [
        getDataFilteredBySerumType,
        getSelectedClades,
        getSelectedFerretStrains,
    ],
    (data, selectedClades, selectedFerretStrains) => {
        const selectedStrainsDict = (selectedFerretStrains||[]).reduce((acc, strainLabId) => {
            acc[strainLabId] = strainLabId;
            return acc;
        }, {});
        
        const selectedCladesDict = (selectedClades||[]).reduce((acc, cladeId) => {
            acc[cladeId] = cladeId;
            return acc;
        }, {});
        const filteredData = (data||[])
            .filter(elem => 
                selectedStrainsDict[`${elem.refid}_${elem.lab}`] 
                && selectedCladesDict[elem.cladeid]
            );
        return filteredData;
    }
);


export const getAllRefStrainsWithLabAndClade = createSelector(
    [
        getDataFilteredBySerumType,
        getAllRefStrains,
        getClades
    ],
    (data, refStrains, clades) => {
        if (!data || !refStrains || !clades || Object.keys(clades).length === 0) return [];

        // Get unique combinations of refid and lab
        const uniqueStrains = uniqBy(data.map(elem => ({
            refid: elem.refid,
            lab: elem.lab
        })), (item) => `${item.refid}_${item.lab}`);

        const result = uniqueStrains.map((strain) => {
            const { clade, name } = refStrains[strain.refid];
           
            const antigenicCladeId = clades[clade]?.cladeMapping?.antigenic_clade?.alpha;
            //console.log('[getAllRefStrainsWithLabAndClade] strain', strain.refid, refStrains[strain.refid], clade, antigenicCladeId, clades[clade]);
            return {
                ...strain,
                name,
                antigenicCladeLabel: antigenicCladeId ? clades[antigenicCladeId].label : ''
            };
        });
        // console.log('[getAllRefStrainsWithLabAndClade] result', result);
        return result;
    }
);
export const selectedFerretStrainsSelector = createSelector(getSelectedData, (selectedData) => {
    if (!selectedData) return null;
    const uniqueStrains = uniq(selectedData.map(item => `${item.refid}_${item.lab}`));
    return uniqueStrains;
});

const getStrainIndices = createSelector(selectedFerretStrainsSelector, (selectedData) => {
    if (!selectedData) return null;
   
    return selectedData.reduce((acc, strainLabId, index) => {
        acc[strainLabId] = index+1;
        return acc;
    }, {});
});



export const vaccinesDataSelector = createSelector([
    getSelectedData, 
    getAllRefStrainsWithLabAndClade, 
    getStrainIndices,
    getCladeIndices,
    getClades
], 
(selectedData, refStrains, strainIndices, cladeIndices, clades) => {
    // console.log('[vaccinesDataSelector]', { selectedData, refStrains, strainIndices, cladeIndices});
    if (!selectedData || !refStrains || !strainIndices || !cladeIndices) return null;
    
    const refStrainsDict = refStrains.reduce((acc, strain) => {
        acc[strain.refid] = strain;
        return acc;
    }, {});
    const indexedData = selectedData
        .map(elem => {
            const { name, antigenicCladeLabel } = refStrainsDict[elem.refid];
            return {
                ...elem, 
                strainIndex: strainIndices[`${elem.refid}_${elem.lab}`],
                cladeIndex: cladeIndices[elem.cladeid],
                name,
                antigenicCladeLabel,
                cladeLabel: clades[elem.cladeid]?.label
            };
        });
    //     .sort((a, b) => a.refid - b.refid || a.lab.localeCompare(b.lab) || a.cladeIndex - b.cladeIndex)
        // .filter(elem => elem.strainIndex === 1); // for testing
    ;
    // console.log('[vaccinesDataSelector] indexedData', indexedData);
    return indexedData;
});

export const vaccinesAntigenicCladeFrequenciesSelector = createSelector(
    [getDataFilteredBySerumType,
        getVaccinesFrequencies,
        getVaccinesPredictions],
    (data, frequencies, predictions) => {
        //console.log('[vaccinesAntigenicCladeFrequenciesSelector] data', {data, frequencies, predictions});
        if (!data || !frequencies || !predictions) return null;
        // console.log('[vaccinesAntigenicCladeFrequenciesSelector] data', data);
        // console.log('[vaccinesAntigenicCladeFrequenciesSelector] frequencies', frequencies);
        // console.log('[vaccinesAntigenicCladeFrequenciesSelector] predictions', predictions);
        // console.log('[vaccinesAntigenicCladeFrequenciesSelector] predictions', predictions);
        const weightedFreqs = data.reduce((acc, elem) => {
            // if (elem.refid === 110) console.log('[vaccinesAntigenicCladeFrequenciesSelector] elem', elem.refid, elem.cladeid, elem.protvalue);
            if (!acc[`${elem.refid}_${elem.lab}`]) acc[`${elem.refid}_${elem.lab}`] = { freqSum: 0, predSum: 0 };
            // console.log('[vaccinesAntigenicCladeFrequenciesSelector] elem', elem.cladeid, frequencies[elem.cladeid]?.y, predictions[elem.cladeid]?.y);
            acc[`${elem.refid}_${elem.lab}`].freqSum += (frequencies[elem.cladeid]?.y || 0) * (elem.protvalue || 0);
            acc[`${elem.refid}_${elem.lab}`].predSum += (predictions[elem.cladeid]?.y || 0) * (elem.protvalue || 0); 
            return acc;
        }, {});
        // console.log('[vaccinesAntigenicCladeFrequenciesSelector] sumFreqs', sumFreqs);
        // const avgFreqs = Object.entries(sumFreqs).reduce((acc, [refid, elem]) => {
        //     console.log('[vaccinesAntigenicCladeFrequenciesSelector] elem', refid, elem);
        //     acc[refid] = {
        //         freq: elem.freqSum / elem.cnt,
        //         pred: elem.predSum / elem.cnt
        //     };
        //     return acc;
        // }, {});
        // console.log('[vaccinesAntigenicCladeFrequenciesSelector] weightedFreqs', weightedFreqs);
        return weightedFreqs;
    }
);

export const vaccinesRefStrainsOptionsSelector = createSelector(
    getAllRefStrains,
    allRefStrains => Object.values(allRefStrains||{}).map(strain => ({ id: strain.id, n: strain.name }))
);

export const vaccinesVisibleCladesSelector = createSelector(
    getVaccineProtectionValues,
    data => {
        return Object.values(data||{}).reduce((acc, elem) => {
            acc[elem.cladeid] = elem.cladeid;
            return acc;
        }, {});
    }
);

export const vaccinesSelectedCladesSelector = createSelector([
    (state) => state.parameters.vaccinesRhos,
    vaccinesVisibleCladesSelector],
(selectedClades, visibleClades) => {
    const selectedSet = new Set(selectedClades);
    // console.log('[vaccinesSelectedCladesSelector] selectedClades', selectedClades);
    // console.log('[vaccinesSelectedCladesSelector] visibleClades', visibleClades);
    return Object.values(visibleClades).reduce((acc, elem) => {
        acc[elem] = selectedSet.has(elem);
        return acc;
    }, {});
}
);
