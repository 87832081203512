import React, { useState } from 'react';
import { Button } from '@mui/material';
import AuthorizeDialog from '../Dialogs/AuthorizeDialog';
import { styles } from '../styles';
import { postAxios } from '../../../../functions/axiosRequests';
import config from '../../../../config/envConfig';

const AuthorizeUsers = ({ unauthorizedUsers, setLoaded, setInfo, setInfoDialog, setAction }) => {
    const [openUnauthorized, setOpenUnauthorized] = useState(false);
    const classes = styles();

    const handleOpenUnauthorized = () => {
        setOpenUnauthorized(true);
    };

    const handleCloseUnauthorized = () => {
        setOpenUnauthorized(false);
    };

    const handleSubmit = (e, email) => {
        const url = `${config.serverLink}/api/admin/authorizeUser`;
        const body = { email };
        postAxios(url, body)
            .then((response) => {
                if (!response.status)
                    setInfo(`An error has occurred during authorizing ${email}.`);
                else
                    setInfo(`User ${email} authorized sucesfully.`);

                setAction('Authorizing user');
                setLoaded(false);
                handleCloseUnauthorized();
                setInfoDialog(true);
            });
    };

    const deleteUser = (e, email) => {
        const url = `${config.serverLink}/api/admin/deleteUser`;
        const body = { user: { email } };
        postAxios(url, body)
            .then((response) => {
                if (!response.status)
                    setInfo(`An error has occurred during deleting ${email}.`);
                else
                    setInfo(`User ${email} deleted sucesfully.`);

                setAction('Delete user');
                setLoaded(false);
                handleCloseUnauthorized();
                setInfoDialog(true);
            });
    };

    return (
        <>
            <Button className={classes.authorize} onClick={handleOpenUnauthorized} >
                Manage pending authorizations
            </Button>

            <AuthorizeDialog openDialog={openUnauthorized} handleCloseDialog={handleCloseUnauthorized} unauthorizedUsers={unauthorizedUsers} handleSubmit={handleSubmit} deleteUser={deleteUser} />
        </>
    );
};

export default AuthorizeUsers;
