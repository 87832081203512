import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid2';
import MutPositionSearch from '../Tree/MutPositionSearch';
import { setParameters } from '../../redux/actions/parametersActions';
import { resetGenotypeStatus, fetchMutationsPositionsDictionary } from '../../redux/actions/genotypeActions';
import { fetchMeasureScalesDomains } from '../../redux/actions/settingsActions';
import { shouldFetch } from '../../functions/functions';
import { bindActionCreators } from 'redux';
import SelectInput from '../Common/SelectInput';


const GenotypeSelector = props => {
    const { lineage, mutgene, mutposition, genotypeFilterGenesList, genotypeDomainStatus,
        lineageStatus, mutationsPositionsDictStatus, colorByVar,
        resetGenotypeStatus, fetchMeasureScalesDomains, setParameters, resetData, fetchMutationsPositionsDictionary } = props;


    // const _genotypeFilterGenesList = useMemo(() => {
    //     return [{ label: '', val: null, order: 0 }, ...genotypeFilterGenesList];
    // }, [genotypeFilterGenesList]);

    const _genotypeFilterGenesList = useMemo(() => {
        genotypeFilterGenesList.map(v => `${v}`);
    }, [genotypeFilterGenesList]);


    const handleMutChange = value => {
        const mutposition = null;
        console.log(`[GenotypeSelector] handleMutChange`, {value});
        setParameters({ mutgene: value, mutposition });
        // resetGenotypeStatus();
        if (resetData) {
            resetData();
        }
    };
    useEffect(() => {
        if (lineageStatus !== 'loaded') return;
        if (shouldFetch(mutationsPositionsDictStatus)) {
            // console.log('fetchMutationsPositionsDictionary ', lineage)
            fetchMutationsPositionsDictionary({ lineage });

        }
    }, [lineageStatus]);

    useEffect(() => {
        if (mutgene && mutposition && shouldFetch(genotypeDomainStatus)) {
            // console.log('genotype selector fetchMeasureScalesDomains')
            fetchMeasureScalesDomains({ lineage, [colorByVar]: 'genotype', mutgene, mutposition });
        }
    }, [mutgene, mutposition]);
    if (mutationsPositionsDictStatus !== 'loaded') return null;
    console.log(`[GenotypeSelector] gene = ${mutgene}, position = ${mutposition}`);
    return <Grid container columnSpacing={2}>
        <Grid size={6}>
            <SelectInput
                id='gene'
                label='Gene'
                value={mutgene || ''}
                onChange={handleMutChange}
                options={genotypeFilterGenesList}
                getOptionValue={el => el.val}
                getOptionLabel={el => el.label}
                required={false}
            />
        </Grid>
        <Grid size={6}>
            <MutPositionSearch context="genotype" resetData={resetData} />
        </Grid>
    </Grid>;
};



GenotypeSelector.propTypes = {
    colorBy: PropTypes.string,
    mutposition: PropTypes.number,
    mutgene: PropTypes.string,
    resetGenotypeStatus: PropTypes.func,
    fetchMeasureScalesDomains: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
    return ({
        lineage: state.parameters.lineage,
        // intro: state.parameters.intro,
        colorBy: ownProps.mapSelector
            ? state.parameters.geoMapColorBy
            : state.parameters.colorBy,
        freqCategory: state.parameters.freqCategory,
        mutgene: state.parameters.mutgene,
        mutPositions: state.genotype.mutPositions,
        mutposition: +state.parameters.mutposition,
        genotypeFilterGenesList: state.genotype.genotypeFilterGenesList,
        lineageStatus: state.lineages.lineageStatus,
        mutationsPositionsDictStatus: state.genotype.mutationsPositionsDictStatus,
        genotypeDomainStatus: state.metadata.measureDomainStatuses?.genotype || 'none'
    });
};

const mapDispatchToProps = dispatch => bindActionCreators({
    resetGenotypeStatus,
    setParameters,
    fetchMeasureScalesDomains,
    fetchMutationsPositionsDictionary
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GenotypeSelector);
