// React imports
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

// Redux imports
import { connect } from 'react-redux';
import { resetSession } from '../../../redux/actions/sessionActions';
import { lineagesSelector } from '../../../redux/selectors/metadataSelector';

// Component imports
import SelectInput from '../../Common/SelectInput';

// Utils & Config
import { emptyObject } from '../../../functions/functions';
import { treeD3 } from '../../Tree/d3/TreeD3';
import appConfig from '../../../config/appConfig';

/**
 * BuildSelector component allows selection of different tree builds for a pathogen
 */
const BuildSelector = ({ lineages, lineagesStatus, lineage, resetSession }) => {
    const regex = /_(.+)/;
    const navigate = useNavigate();

    // Extract pathogen and build from lineage string
    const [pathogen, setPathogen] = useState(lineage ? lineage.split(regex)[0] : '');
    const [build, setBuild] = useState(lineage ? lineage.split(regex)[1] : '');

    // Update local state when lineage prop changes
    useEffect(() => {
        const newPathogen = lineage ? lineage.split(regex)[0] : '';
        const newBuild = lineage ? lineage.split(regex)[1] : '';
        setPathogen(newPathogen);
        setBuild(newBuild);
    }, [lineage]);

    // Create dictionary of pathogens and their builds
    const dictionary = useMemo(() => {
        if (lineagesStatus !== 'loaded') return {};

        return lineages.reduce((acc, item) => {
            const [key, val] = item.id.split(regex);
            if (!acc[key]) acc[key] = [];
            acc[key].push(val);
            return acc;
        }, {});
    }, [lineages, lineagesStatus]);

    // Get available builds for selected pathogen
    const buildOptions = useMemo(() => dictionary[pathogen], [dictionary, pathogen]);

    // Remove lineage parameter from URL
    const resetLineageURLParam = () => {
        const params = new URLSearchParams(location.search);
        params.delete('lineage');
        navigate({
            pathname: location.pathname,
            search: params.toString()
        }, { replace: true });
    };

    // Handle build selection change
    const handleChange = async newBuild => {
        const newLineage = `${pathogen}_${newBuild}`;
        updateLineage(newLineage);
        resetLineageURLParam();
    };

    // Update lineage in Redux store and reset tree scales
    const updateLineage = async (lineage) => {
        treeD3.resetScales();
        resetSession({ lineage });
    };

    return (
        <>
            {!emptyObject(dictionary) && (
                <SelectInput
                    id="tree-selector"
                    label="Tree"
                    value={build}
                    onChange={handleChange}
                    options={buildOptions}
                    getOptionValue={(option) => option}
                    getOptionLabel={(option) => appConfig.buildsLabels[option] || option}
                />
            )}
        </>
    );
};

BuildSelector.propTypes = {
    lineages: PropTypes.array.isRequired,
    lineagesStatus: PropTypes.string.isRequired,
    lineage: PropTypes.string,
    resetSession: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    lineages: lineagesSelector(state),
    lineagesStatus: state.lineages.lineagesStatus,
    lineage: state.parameters.lineage || ''
});

const mapDispatchToProps = (dispatch) => ({
    resetSession: (payload) => dispatch(resetSession(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(BuildSelector);
