import { color } from 'd3-color';
import React, { useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { bindActionCreators } from 'redux';
import { styles } from './styles';
import { getTreeNodeAttrs, getSelectedMutationClasses } from '../../../../redux/selectors/treeDataSelector';
import { getTransformBranchNodes, getXBranch, getY } from './MutationsClassesLabels';
import { getSymbol } from '../../d3/symbolSigns';
import { selectNodeData } from '../../../../redux/actions/nodeActions';
import { merge } from 'lodash';
// import { getScaledValue } from '../../../../helpers/scalesHelper';
const useStyles = makeStyles(styles);

const swidth = 10;

export const getSymbolPosition = (id, startPos, symbolsNumber, number) => {
    const diff = (symbolsNumber - 1) / 2;
    const midTrans = (startPos - diff + (number - 1) / 2) * swidth;
    const xBranchPos = getXBranch(id);
    const yPos = getY(id);
    return {
        xAnchor: xBranchPos + midTrans,
        yAnchor: yPos
    };
};

export const getMutClassId = (id, subType) => `${id}${subType ? `_${subType}` : ''}`;

const MutationsClassesLabelsSymbol = props => {


    const _symbolRef = useRef();
    const { id, mutClass, symbol, fill, startPos, number, setPosition, setDimensions, symbolsNumber, strainTreeWidth, strainTreeHeight, showCladeLabels } = props;


    const diff = (symbolsNumber - 1) / 2;
    const xtrans = (startPos - diff) * swidth;


    useEffect(() => {
        const bBox = _symbolRef.current.getBBox();
        const symbolWidth = bBox.width;
        const symbolHeight = bBox.height;
        setDimensions({ symbolWidth, symbolHeight }, mutClass);
    }, []);


    useEffect(() => {
        //console.log('aaa', id, startPos, symbolsNumber, showCladeLabels);
        // if (id == 79781) console.log(id, '[useEffect]');
        const symbolPosition = getSymbolPosition(id, startPos, symbolsNumber, number);
        setPosition(symbolPosition, mutClass);

    }, [startPos, symbolsNumber, strainTreeWidth, strainTreeHeight, showCladeLabels]);


    return (
        <g ref={_symbolRef}>
            {[...Array(number)].map((_, i) => (
                <path d={getSymbol(symbol, false)()}
                    key={`${id}_${mutClass}_${i}`}
                    fill={fill}
                    stroke={color(fill).darker().formatHex()}
                    transform={`translate(${xtrans + i * swidth}, 0)`} />
            ))}
        </g>
    );
};


const mapStateToPropsLabel = (state) => {
    //const { id, mutClass, classNamePrefix } = ownProps;
    //const labelPos = state.render.labels?.[classNamePrefix]?.[`${id}_${mutClass}`] ||{};

    return {
        strainTreeWidth: state.ui.strainTreeWidth,
        strainTreeHeight: state.ui.strainTreeHeight,
        showCladeLabels: state.parameters.showCladeLabels
    };
};


const _MutationsClassesLabelsSymbol = connect(mapStateToPropsLabel)(MutationsClassesLabelsSymbol);


const MutationsClassesLabelSymbols = (props) => {
    const { id, classNamePrefix, muts, symbolsNumber, selectedMutationClasses, selectNodeData,
        onElementRendered, rerenderLabels } = props;

    // const xBranchPos = getXBranch(id);
    // const yPos = getY(id);

    const transformBranchNode = getTransformBranchNodes(id);

    const handleMouseEnter = () => {
        selectNodeData({ nodeId: +id });
    };

    const handleMouseLeave = () => {

        //if (!this.selectedNode) {
        selectNodeData({ nodeId: null });
        //}
    };

    const classes = useStyles();

    const positions = useRef({});
    const dimensions = useRef({});


    const handleSetPosition = useCallback((pos, subType) => {
        positions.current[getMutClassId(id, subType)] = pos;
    }, []);

    const handleSetDimensions = useCallback((dim, subType) => {
        dimensions.current[getMutClassId(id, subType)] = dim;
    }, []);


    useEffect(() => {
        if (rerenderLabels) onElementRendered(merge(positions.current, dimensions.current));
    }, [onElementRendered]);


    return <g className={classes[`${classNamePrefix}Symbol`]} transform=/*{`translate(${xBranchPos}, ${yPos})`}*/ {transformBranchNode} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {muts.map((m) => (
            <_MutationsClassesLabelsSymbol key={`${m.id}_${m.mutClass}`} {...m}
                id={id}
                symbol={selectedMutationClasses[m.mutClass].symbol_sign}
                fill={selectedMutationClasses[m.mutClass].color}
                startPos={m.startPos}
                classNamePrefix={classNamePrefix}
                symbolsNumber={symbolsNumber}
                setPosition={handleSetPosition}
                setDimensions={handleSetDimensions}
            />)
        )
        }
    </g>;

};

const mapStateToProps = (state, ownProps) => {
    const treeAttrs = getTreeNodeAttrs(state);

    return {
        nodeData: treeAttrs[ownProps.id],
        selectedMutationClasses: getSelectedMutationClasses(state),
        strainTreeWidth: state.ui.strainTreeWidth,
        strainTreeHeight: state.ui.strainTreeHeight,
        showCladeLabels: state.parameters.showCladeLabels,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    selectNodeData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MutationsClassesLabelSymbols);
