import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import ConfirmDialog from '../../ConfirmDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import config from '../../../../config/envConfig';
import { postAxios } from '../../../../functions/axiosRequests';
import { actionsStyles } from '../../Styles/actionsStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAllMeasures } from '../../../../redux/actions/settingsActions';

const DeleteMeasure = ({measure, setInfoDialog, setAction, setInfo, fetchAllMeasures}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = actionsStyles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const deleteMeasure = async () => {
        try {
            const url = `${config.serverLink}/api/admin/deleteMeasure`;
            const body = { measure: measure.measure, lineage: measure?.lineage };
            await postAxios(url, body);
            setInfo(`Measure ${measure.measure} deleted sucesfully.`);
        }
        catch (error) {
            console.error(error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during deleting ${measure.measure}.: ${message}`);
        }
        finally {
            setAction('Delete measure');
            setInfoDialog(true);
            handleCloseDialog();
            fetchAllMeasures();
        }
           
    };

    return (
        <>
            <IconButton onClick={handleClick} className={classes.button}>
                <DeleteIcon />
            </IconButton>
            <ConfirmDialog text="measure" handleCloseDialog={handleCloseDialog} openDialog={openDialog} deleteFunc={deleteMeasure} name={measure.name} scale={false}/>
        </>

    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        { 
            fetchAllMeasures
        },
        dispatch,
    );

export default connect(undefined, mapDispatchToProps)(DeleteMeasure);
