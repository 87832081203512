import { ofType } from 'redux-observable';
import { mergeMap, debounceTime, switchMap, exhaustMap, map } from 'rxjs/operators';
import { fetchAxios } from '../../functions/axiosRequests';

import config from '../../config/envConfig';
import { prepareUrl, dateToDays, reduxDateFormatToDate } from '../../functions/functions';
import {
    FETCH_CUSTOM_TREE_ATTRS_REQUEST,
    FETCH_SELECTED_STRAIN_REQUEST,
    FETCH_SUBSET_TREE_REQUEST,
    FETCH_TREE_FREQS_REQUEST,
    FETCH_TCELL_ANTIGENICITY_OPTIONS_REQUEST,
    FETCH_TCELL_ANTIGENICITY_SCORES_REQUEST,
    // FETCH_VISIBLE_NODES_REQUEST,
    FETCH_VACCINE_CANDIDATES_REQUEST,
    FETCH_VP_METHODS_REQUEST,
    FETCH_STRAINS_LIST_REQUEST,
    FETCH_VP_VALUES_REQUEST,
    FETCH_RECALCULATED_TREE_REQUEST,
    FETCH_NODE_REQUEST,
    FETCH_ANTIGENIC_REFERENCE_STRAIN_REQUEST,
    FETCH_REFERENCE_STRAINS_REQUEST,
    FETCH_BRANCH_NODES_REQUEST,
    INIT_STRAIN_TREE_REQUEST
    // FETCH_COLOR_BY_RULE_STRAINS_REQUEST,
} from '../actions/actionTypes';
import {
    initStrainTreeError, 
    initStrainTreeSuccess,
    fetchRecalculatedTreeSuccess,
    fetchRecalculatedTreeError,
    fetchSubsetTreeSuccess,
    fetchSubsetTreeError,
    fetchCustomTreeAttrsSuccess,
    fetchCustomTreeAttrsError,
    fetchTCellAntigenicityOptionsSuccess,
    fetchTCellAntigenicityOptionsError,
    fetchTCellAntigenicityScoresSuccess,
    fetchTCellAntigenicityScoresError,
    fetchSelectedStrainSuccess,
    fetchSelectedStrainError,
    fetchAntigenicReferenceStrainSuccess,
    fetchAntigenicReferenceStrainError,
    fetchTreeFreqsSuccess,
    fetchTreeFreqsError,
    fetchVaccineCandidatesSuccess,
    fetchVaccineCandidatesError,
    fetchVpMethodsSuccess,
    fetchVpMethodsError,
    fetchReferenceStrainsSuccess,
    fetchReferenceStrainsError,
    // fetchMutationClassesSuccess,
    // fetchMutationClassesError,
    fetchVpValuesSuccess,
    fetchVpValuesError,
    fetchStrainsListSuccess,
    fetchStrainsListError,
    fetchNodeSuccess,
    fetchNodeError,
    fetchBranchNodesSuccess,
    fetchBranchNodesError,
    // fetchColorByRuleStrainsSuccess,
    // fetchColorByRuleStrainsError
} from '../actions/treeDataActions';

import { from } from 'rxjs';
import { handleErrors } from '../operators/error';


export const initStrainTreeEpic = (action$) => action$.pipe(
    ofType(INIT_STRAIN_TREE_REQUEST),
    mergeMap(action => {
        const url = prepareUrl(`${config.serverLink}/api/tree`, action.payload);
        // console.log(`initStrainTree => url = ${url}`);

        return from(fetchAxios(url))
            .pipe(
                map(response => initStrainTreeSuccess(response.data)),
                handleErrors(initStrainTreeError, 'An error has occurred during initializing strain tree')
            );
    })
);

// Fetch tree after showLeafNumber has changed
export const fetchRecalculatedTree = (action$)=> action$.pipe(
    ofType(FETCH_RECALCULATED_TREE_REQUEST),
    mergeMap((action) => {
        const url = prepareUrl(`${config.serverLink}/api/tree/recalculated`, action.payload);
        //console.log(`fetchRecalculatedTree => url = ${url}`);
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchRecalculatedTreeSuccess(response.data)),
                handleErrors(fetchRecalculatedTreeError, 'An error has occurred during initialization of strain tree')
            );
    })
);

export const fetchReferenceStrainsEpic = (action$)=> action$.pipe(
    ofType(FETCH_REFERENCE_STRAINS_REQUEST),
    mergeMap((action) => {
        const url = prepareUrl(`${config.serverLink}/api/tree/referenceStrains`, action.payload);
        console.log(`getReferenceStrains => url = ${url}`);

        return from(fetchAxios(url))
            .pipe(
                map(response => fetchReferenceStrainsSuccess(response.data)),
                handleErrors(fetchReferenceStrainsError, 'An error has occurred during fetching reference strains')
            );
    })
);


export const fetchSubsetTreeEpic = (action$) => action$.pipe(
    ofType(FETCH_SUBSET_TREE_REQUEST),
    mergeMap(action => {
        const { lineage, strainSubset, strainHighlight, zoomNodeId, ignoreStrainCutOffDate } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/tree/subset`, { lineage, strainSubset, strainHighlight, zoomNodeId, ignoreStrainCutOffDate });
    
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchSubsetTreeSuccess(response.data)),
                handleErrors(fetchSubsetTreeError, 'An error has occurred during fetching subset tree')
            );
    })
);

export const fetchTreeFreqsEpic = (action$) => action$.pipe(
    ofType(FETCH_TREE_FREQS_REQUEST),
    mergeMap(action => {
        const { lineage, predictionBaseline, submissionDate, strainSubset, subsetBySubmission } = action.payload;
        const origPredictionBaseline = dateToDays(reduxDateFormatToDate(predictionBaseline));
        //console.log(`[fetchTreeFreqsEpic]: predictionBaseline = ${predictionBaseline} / ${origPredictionBaseline}`);
        const url = prepareUrl(`${config.serverLink}/api/tree/recalculatedFrequencies`, { lineage, predictionBaseline, submissionDate, strainSubset, subsetBySubmission });
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchTreeFreqsSuccess({ ...response.data, origPredictionBaseline })),
                handleErrors(fetchTreeFreqsError, 'An error has occurred during fetching tree frequencies')
            );
    })
);

export const fetchCustomTreeAttrsEpic = (action$) => action$.pipe(
    ofType(FETCH_CUSTOM_TREE_ATTRS_REQUEST),
    mergeMap(action => {

        const { colorBy } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/tree/customAttrs`, action.payload);
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchCustomTreeAttrsSuccess({ ...response.data, colorBy })),
                handleErrors(fetchCustomTreeAttrsError, 'An error has occurred during fetching custom attributes', { colorBy })
            );
    })
);

export const fetchBranchNodesEpic = (action$) => action$.pipe(
    ofType(FETCH_BRANCH_NODES_REQUEST),
    mergeMap(action => {
        const { branchNodes } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/tree/branchNodes`, action.payload);

        return from(fetchAxios(url))
            .pipe(
                map(response => fetchBranchNodesSuccess({ ...response.data, branchNodes })),
                handleErrors(fetchBranchNodesError, 'An error has occurred during fetching branch nodes', { branchNodes })
            );
    })
);

export const fetchTCellAntigenicityOptionsEpic = (action$) => action$.pipe(
    ofType(FETCH_TCELL_ANTIGENICITY_OPTIONS_REQUEST),
    mergeMap(action => {
        const { lineage } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/lineage/tCellAntigenicityOptions'/`, { lineage });
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchTCellAntigenicityOptionsSuccess(response.data)),
                handleErrors(fetchTCellAntigenicityOptionsError, 'An error has occurred during initialization of strain tree')
            );
    })
);

export const fetchVpMethodsEpic = (action$) => action$.pipe(
    ofType(FETCH_VP_METHODS_REQUEST),
    mergeMap(action => {
        const { settings, ...payload } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/lineage/vpMethods/`, payload);
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchVpMethodsSuccess({ ...response.data, settings })),
                handleErrors(fetchVpMethodsError, 'An error has occurred during downloading VP methods')
            );
    })
);

export const fetchVpValuesEpic = (action$) => action$.pipe(
    ofType(FETCH_VP_VALUES_REQUEST),
    mergeMap(action => {
        const url = prepareUrl(`${config.serverLink}/api/tree/vpValues`, action.payload);
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchVpValuesSuccess(response.data)),
                handleErrors(fetchVpValuesError, 'An error has occurred during downloading VP values')
            );
    })
);

export const fetchTCellAntigenicityScoresEpic = (action$) => action$.pipe(
    ofType(FETCH_TCELL_ANTIGENICITY_SCORES_REQUEST),
    mergeMap(action => {
        const url = prepareUrl(`${config.serverLink}/api/fetchTCellAntigenicityScores`, action.payload);
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchTCellAntigenicityScoresSuccess(response.data)),
                handleErrors(fetchTCellAntigenicityScoresError, 'An error has occurred during downloading antigenicity scores')
            );
    })
);



export const fetchSelectedStrainEpic = (action$) => action$.pipe(
    ofType(FETCH_SELECTED_STRAIN_REQUEST),
    mergeMap(action => {
        const { searchId, ...payload } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/tree/selectedStrain`, payload);
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchSelectedStrainSuccess({ ...response.data, searchId })),
                handleErrors(fetchSelectedStrainError, 'An error has occurred during downloading selected strain', { searchId })
            );
    })
);


export const fetchAntigenicReferenceStrainEpic = (action$) => action$.pipe(
    ofType(FETCH_ANTIGENIC_REFERENCE_STRAIN_REQUEST),
    mergeMap(action => {
        const { searchId, ...payload } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/tree/antigenicReferenceStrain`, payload);
        //console.log(`fetchAntigenicReferenceStrain => url = ${url}`);
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchAntigenicReferenceStrainSuccess({ ...response.data, searchId })),
                handleErrors(fetchAntigenicReferenceStrainError, 'An error has occurred during downloading selected strain')
            );
    })
);

export const fetchVaccineCandidatesEpic = (action$) => action$.pipe(
    ofType(FETCH_VACCINE_CANDIDATES_REQUEST),
    mergeMap(action => {
        const { lineage } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/tree/vaccineCandidates`, { lineage });
        // console.log(`getVaccineCandidates => url = ${url}`);
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchVaccineCandidatesSuccess(response.data)),
                handleErrors(fetchVaccineCandidatesError, 'An error has occurred during downloading vaccine candidates')
            );
    })
);

export const fetchStrainsListEpic = (action$) => action$.pipe(
    ofType(FETCH_STRAINS_LIST_REQUEST),
    debounceTime(50),
    switchMap(action => {
        const { lineage, strainsTxt, searchId, resolve, reject } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/tree/search`, { lineage, strainsTxt });
        //console.log(`fetchStrainsList => url = ${url}, lineage: ${lineage}, strainsTxt: ${strainsTxt}`);
        return from(fetchAxios(url))
            .pipe(
                map(response => {
                    const successAction = fetchStrainsListSuccess({
                        strainsList: response.data.strainsList,
                        searchId,
                    });
                    if (resolve) {
                        resolve(response.data.strainsList);
                    }
                    return successAction;
                }),
                handleErrors(fetchStrainsListError, 'An error has occurred during downloading strains list', { searchId }, reject)
            );
    })
);

export const fetchMutationsByNodeIdEpic = (action$) => action$.pipe(
    ofType(FETCH_NODE_REQUEST),
    exhaustMap(action => {
        const { nodeId, lineage } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/mutations/${lineage}/node/${nodeId}`);
        // console.log(`fetchMutationsByNode => url = ${url}`);
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchNodeSuccess({ data: response.data, nodeId })),
                handleErrors(fetchNodeError, 'An error has occurred during downloading node info')
            );
    })
);





