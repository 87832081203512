import React from 'react';
import { symbol, symbols } from 'd3-shape';

export const signOptions = [
    'circle',
    'cross',
    'diamond',
    'square',
    'star',
    'triangle'
];

export const SymbolComponent = ({ shape, color }) => {
    const getSymbol = (shape) => {
        let sym;
        switch (shape) {
            case 'circle': sym = symbol().type(symbols[0]).size(90); break;
            case 'cross': sym = symbol().type(symbols[1]).size(90); break;
            case 'diamond': sym = symbol().type(symbols[2]).size(90); break;
            case 'square': sym = symbol().type(symbols[3]).size(90); break;
            case 'star': sym = symbol().type(symbols[4]).size(90); break;
            case 'triangle': sym = symbol().type(symbols[5]).size(90); break;
            default: sym = symbol().type(symbols[0]).size(90);
        }
        return sym();
    };

    const symbolPath = getSymbol(shape);

    return (
        <div>
            <svg width="30" height="30">
                <path fill={color} d={symbolPath} transform="translate(10,17)" />
            </svg>
        </div>
    );
};
