import { makeStyles } from '@mui/styles';

export const styles = makeStyles(() => ({
    root: {
        backgroundColor: '#e2e2e2',
        height: 'calc(100vh - 170px)',
        maxHeight: 'calc(100vh - 170px)',
        overflow: 'overlay',
    },
    content: {
        margin: '0px 15px 15px 15px',
        padding: '20px',
        backgroundColor: 'white',
        width: 'calc(100% - 30px)',
        boxSizing: 'border-box',
        overflow: 'hidden'
    },
    item: {
        width: 350,
        margin: '0px 20px 10px 15px',
    },
    lineageSelect: {
        textAlign: 'start',
        width: 350,
        margin: '15px 0px 55px 20px'
    },
    divider: {
        margin: '0px 0px 10px 0px',
        backgroundColor: 'gray',
        borderBottomWidth: '2px'
    },
    group: {
        padding: '20px 0px',
        // marginLeft: '5px',
    },
    section: {
        //marginBottom: '80px'
    },
    lastSection: {
        marginBottom: '30px'
    },
    checkbox: {
        width: 360,
        margin: '0px 0px 0px 5px'
    },
    textField: {
        width: 350,
        display: 'block',
        backgroundColor: 'white !important',
        '& > div': {
            width: '100%',
        },
        '& input:-internal-autofill-selected': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            // fontFamily: 'Inter, Verdana',
            fontSize: '16px'
        }
    },
    formControl: {
        textAlign: 'start',
        width: 350,
        margin: '15px 0px 8px 15px'
    },
    number: {
        margin: '15px 0px 8px 15px'
    },
    modelId: {
        margin: '1px 0px 0px 15px',
        paddingLeft: '16px'
    },
    antigenicModelId: {
        margin: '0px 0px 0px 15px',
    },
    buttonUp: {
        // margin: '20px 20px 55px 15px',
        float: 'right'
    },
    buttonDown: {
        margin: '0px 20px 30px 20px',
        float: 'right'
    },
    buttonDialog: {
        marginRight: '15px',
        marginBottom: '10px',
    },
    button: {
        backgroundColor: '#6F6CFF',
        color: 'white',
        textTransform: 'none',
        padding: '5px 12px',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            color: 'white',
        },
    },
    confirm: {
        backgroundColor: '#6F6CFF',
        color: 'white',
        marginBottom: '16px',
        textTransform: 'none',
        marginRight: '16px',
        padding: '5px 12px',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            color: 'white',
        },
    },
    icon: {
        padding: 0,
        marginLeft: '3px',
    },
    clearCache: {
        float: 'left',
        marginBottom: '20px',
        // padding: '5px 12px',
        // marginLeft: '20px',
        backgroundColor: '#e0e0e0',
        color: '#000000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    title: {
        // paddingLeft: '16px'
    },
    text: {
        // paddingLeft: '16px',
        fontSize: '12px'
    },
    tabLabel: {
        margin: '0px',
        fontSize: '1rem',
        lineHeight: '1.5',
    }

}));

export const dialog = {
    '& .MuiDialog-container': {
        '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '300px',
            borderRadius: '12px'
        },
    },
};
