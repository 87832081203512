export const style = {
    // schemaWrapperContainer: {
    //     margin: '50px'
    // },
    schemaWrapper: {
        display: 'inline-block',
        position: 'relative',
        //  float: 'right',
        backgroundColor: '#fff',
        // paddingRight: '6vw',
        paddingRight: '15px',
        paddingLeft: '15px',
        paddingBottom: '15px',
        //marginRight: '10px',
        marginTop: '20px',
        marginBottom: '5px'
    },
    border: {
        boxSizing: 'border-box',
        border: 'solid 1px #969696',
    },
    schemaWrapperEdit: {
        display: 'inline-block',
        position: 'relative',
        float: 'left',
        backgroundColor: '#fff',
        // paddingRight: '6vw',
        paddingRight: '15px',
        paddingLeft: '15px',
        paddingBottom: '15px',
        //marginRight: '10px',
        marginTop: '20px',
        marginBottom: '20px'
    },
    schemaTitle: {
        fontSize: '16px',
        lineHeight: '20px',
        //marginLeft: '15px',
        paddingTop: '15px',
        marginBottom: '15px',
        fontWeight: 400
    }
};
