// React and Redux
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import DatePickerInput from '../Common/DatePickerInput';

// Redux Actions
import { initStrainTree } from '../../redux/actions/treeDataActions';
import { setParameters } from '../../redux/actions/parametersActions';

// Redux Selectors
import { strainCutOffDateSelector } from '../../redux/selectors/parametersSelector';

// Utils
import { daysToDate } from '../../functions/functions';

const StrainCutoffDatePicker = (props) => {
    const {
        strainCutOffDate,
        strainCutoffDateMinMax,
        setParameters,
        initStrainTree,
        lineage,
        colorBy,
        zoomNodeId,
        strainSubset,
        vpMethod
    } = props;

    const handleAccept = (date) => {
        setParameters({ strainCutOffDate: date });
        initStrainTree({
            lineage,
            colorBy,
            zoomNodeId,
            strainSubset,
            strainCutOffDate: date,
            vpMethod,
        });
    };

    return (
       
        <DatePickerInput
            maxDate={strainCutoffDateMinMax &&
                    daysToDate(strainCutoffDateMinMax.max)
            }
            onAccept={handleAccept}
            value={strainCutOffDate}
            label="Show strains from"
            id="strainCutOffDate"
            clearable
            allowSameDateSelection
        />
    );
};

StrainCutoffDatePicker.propTypes = {
    // Data
    colorBy: PropTypes.string,
    lineage: PropTypes.string,
    strainCutOffDate: PropTypes.instanceOf(Date),
    strainSubset: PropTypes.string,
    strainCutoffDateMinMax: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
    }),
    vpMethod: PropTypes.string,
    zoomNodeId: PropTypes.number,

    // Actions
    initStrainTree: PropTypes.func.isRequired,
    setParameters: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    // Parameters
    colorBy: state.parameters.colorBy,
    lineage: state.parameters.lineage,
    strainSubset: state.parameters.strainSubset,
    vpMethod: state.parameters.vpMethod,
    zoomNodeId: state.parameters.zoomNodeId,
    
    // Selectors
    strainCutOffDate: strainCutOffDateSelector(state),
    
    // Tree Data
    strainCutoffDateMinMax: state.treeData.strainCutoffDateMinMax,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setParameters,
            initStrainTree,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(StrainCutoffDatePicker);
