import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { useStyles } from './styles';

const LegendElement = ({
    id,
    selected,
    color,
    label,
    fontSize,
    handleClick,         // single-click callback
    handleDoubleClick,    // double-click callback
    handleEnter,
    handleLeave,
    border,
    textDecoration,
    exportMode,
    size 
}) => {
    const classes = useStyles();

    // Store the timer ID for single-click detection
    const clickTimer = useRef(null);

    // The delay in ms during which a second click would be counted as double-click
    const DOUBLE_CLICK_DELAY = 180;

    const onSingleOrDoubleClick = (event) => {
        // If there's no double-click handler, just call the single-click handler

        if (handleClick && !handleDoubleClick) return handleClick(event);
        // If there's already a pending timer, it means the user clicked once
        // and we're waiting to see if they click again for double-click.
        if (clickTimer.current) {
            // A second click arrived within the delay => it’s a double-click
            clearTimeout(clickTimer.current);
            clickTimer.current = null;
            //console.log('double click');
            if (typeof handleDoubleClick === 'function') {
                handleDoubleClick(event);
            }
        } else {
            // First click => start the timer for single-click
            clickTimer.current = setTimeout(() => {
                // Timer elapsed => it was actually a single-click
                //console.log('single click');
                if (typeof handleClick === 'function') {
                    handleClick(event);
                }
                clickTimer.current = null;
            }, DOUBLE_CLICK_DELAY);
        }
    };

    return (
        <div
            className={classes.legendElement}
            id={id}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
        >
            <div className={exportMode ? classes.legendCircleWrapperExport : classes.legendCircleWrapper}>
                <div
                    className={exportMode ? classes.legendCircleExport : classes.legendCircle}
                    style={{
                        backgroundColor: selected ? color : '#fff',
                        border,
                        width: size,
                        height: size,
                    }}
                    // Use *only* this handler, so we can decide single vs. double
                    onClick={onSingleOrDoubleClick}
                />
            </div>
            <div
                className={exportMode ? classes.legendTextExport : classes.legendText}
                style={{ fontSize, textDecoration }}
            >
                {label}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { exportMode } = state.parameters;
    return { exportMode };
};

export default connect(mapStateToProps)(LegendElement);
