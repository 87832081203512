import React from 'react';
import { makeStyles } from '@mui/styles';

export const styles = makeStyles(() =>({
    root: {
        width: '100%',
        paddingTop: '15px',
        // fontFamily: 'IArial, sans-serif',
    },
    text: {
        marginTop: '0px',
        marginBottom: '4px',
        fontSize: '20px'
    },
    line: {
        width: '100%',
        margin: '0 0 10px 0',
        height: '2px',
        backgroundColor: 'black'
    }
}));


const Category = ({ label }) => {
    const classes = styles();

    return (
        <div className={classes.root}>
            <h2 className={classes.text}>{label}</h2>
            <div className={classes.line} />
        </div>
    );
};

export default Category;
