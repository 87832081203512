import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { actionsStyles } from '../../Styles/actionsStyles';
import { ExpandIcon } from '../../../../components/Alerts/styles';
import { postAxios } from '../../../../functions/axiosRequests';
import config from '../../../../config/envConfig';
import { fetchAllScales } from '../../../../redux/actions/settingsActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ScaleDialog from '../Dialogs/ScaleDialog';

const AddNewScale = ({ colorScales, scalesPalette, fetchAllScales, setInfo, setInfoDialog }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = actionsStyles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const addNewScale = async (scale, domain) => {
        try {
            const url = `${config.serverLink}/api/admin/addNewScale`;
            const body = { scale, domain };

            await postAxios(url, body);
        
            setOpenDialog(false);
            fetchAllScales();
        
        }
        catch (error) {
            console.error(error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during saving scale: ${message}`);
            setInfoDialog(true);
        };
    };

    return (
        <>
            <IconButton className={classes.add} onClick={handleClick}>
                <ExpandIcon color={'#0000FF'} />
            </IconButton>
            {openDialog && <ScaleDialog colorScales={colorScales} scalesPalette={scalesPalette} handleCloseDialog={handleCloseDialog} openDialog={openDialog} submit={addNewScale} />}
        </>
    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchAllScales
        },
        dispatch,
    );

export default connect(undefined, mapDispatchToProps)(AddNewScale);
