import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material';
import './assets/fonts/Inter-Regular.ttf';
import './assets/fonts/Inter-Light.ttf';
import './assets/fonts/IBMPlexSans-Regular.ttf';
import './assets/fonts/Inter-Bold.ttf';
import './assets/css/main.css';
import './assets/css/alert.css';
import theme from './theme';
import Main from './Main';
import config from './config/envConfig';

if (config.NODE_ENV !== 'development' && config.REACT_APP_SHOW_LOGS !== true)
    console.log = () => {};

const warnings = [
    /A textarea element was provided to Autocomplete/,
    /Application state or actions payloads are too large making Redux DevTools serialization slow/,
//     // /Slow network is detected/,
//     // /Listener added for a/,
];

const originalWarn = console.warn;
console.warn = function (...args) {
    const message = args[0];
    if (!warnings.some(e => e.test(message))) {
        originalWarn.apply(console, args);
    }
};

// const errors = [
// /Listener added/
// /Warning: findDOMNode is deprecated and will be removed in the next major release./,
// ];
// const originalError = console.error;
// console.error = function (...args) {
//     const message = args[0];
//     if (!errors.some(e => e.test(message))){
//         originalError.apply(console, args);
//     }
// };
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
            <Main />
        </StyledEngineProvider>
    </ThemeProvider>
);

