import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import { isNil, isNull } from 'lodash';

import { RENDER_STATUS } from '../../config/consts';
import { styles } from './styles';
import ExportableComponent from '../Export/ExportableComponent';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import { Edit } from '../Edit/Edit';
import MetaInformations from '../../components/MetaInformations/MetaInformations';
import CladeSchema from '../../components/CladeSchema/CladeSchema';
import NodeInfo from '../../components/Tree/NodeInfo';
import TreeGraph from '../../components/Tree/TreeGraph';
import TreeGraphOptions from '../../components/Tree/options/TreeGraphOptions';
import TreeLegend from '../../components/ColorLegend/Legends/TreeLegend';
import ScalesLegend from '../../components/ColorLegend/Legends/ScalesLegend';
import { schemaWidth } from '../../components/CladeSchema/helpers';

import { fetchModel } from '../../redux/actions/modelActions';
import { fetchAntigenicModel, fetchAntigenicObservedData, fetchAntigenicRawModel } from '../../redux/actions/antigenicActions';
import { initStrainTree, fetchTCellAntigenicityScores, fetchCustomTreeAttrs, fetchMutationClasses, fetchVpValues, fetchAntigenicReferenceStrain, fetchBranchNodes, fetchSelectedStrain } from '../../redux/actions/treeDataActions';
import { fetchGenotypeData, fetchMutationGroupValues } from '../../redux/actions/genotypeActions';
import { fetchHumanPools, fetchHumanSerologyData } from '../../redux/actions/humanSerologyActions';
import { fetchClades } from '../../redux/actions/cladeActions';
import { dynamicStyles, useWindowSize } from '../../assets/GlobalStyles/dynamicStyles';

import { getCustomMeasures, getIgnoreStrainCutOffDateForColorBy } from '../../redux/selectors/metadataSelector';
import { getIsMobile, getStrainSearchStatus, shouldFetchModelsSelector } from '../../redux/selectors/statusSelector';
import { shouldFetch, isColorByModel, isLoadedOrNA } from '../../functions/functions';


const useStyles = makeStyles(styles);

const TreeSidebar = ({className, intro, hiddenMenu=false, hiddenMenuMobile=false, nodeInfoPosition='top', isMobile=false }) => {
    if (isMobile) 
        return <>
            {!hiddenMenuMobile && <TreeGraphOptions intro={intro} />}
            <NodeInfo />
        </>;

    return (
        <Grid className={className}> 
            {!hiddenMenu && nodeInfoPosition ==='top' && <NodeInfo />}
            <TreeGraphOptions intro={intro} />
            {!hiddenMenu && nodeInfoPosition !=='top' && <NodeInfo />}
        </Grid>
    );
};

const StrainTree = props => {

    const {
        intro = false,
        lineage, modelId, colorBy, antigenicTiterType, antigenicDataType, exportMode, zoomNodeId, strainSubset, exportParams,
        gene, hla, strainCutOffDate, ignoreStrainCutOffDate, vpMethod, mutgene, mutposition, humanPool, humanSerologyDataType, strainId, modelRegionId, modelType, antigenicModelId, mutationsGroup,
        showCladeBar, showMutationsGroups, models, refStrain, predictionBaseline, branchNodes,

        shouldFetchModels, 
        subsetBySubmission, submissionDate,
        treeDataStatus, cladesStatus, modelsStatus, lineageStatus, antigenicModelStatus, antigenicObservedDataStatus, antigenicRawModelStatus, genotypeDataStatus,
        customMeasures, customTreeDataStatus, tcellStatus, mutationClassesStatus, vpValuesStatus, humanPoolsStatus, humanSerologyDataStatus, strainSearchLoading,
        modelStatus, modelTypesStatus, mutationGroupValuesStatus, antigenicReferenceStrainStatus, searchStrainStatus,

        fetchModel, fetchClades, initStrainTree, fetchAntigenicModel, fetchCustomTreeAttrs, fetchTCellAntigenicityScores, fetchMutationClasses, fetchVpValues,
        fetchAntigenicObservedData, fetchGenotypeData,
        fetchAntigenicRawModel,
        fetchHumanPools, fetchHumanSerologyData, fetchMutationGroupValues, fetchAntigenicReferenceStrain, fetchBranchNodes, fetchSelectedStrain

    } = props;

    // const { editMode, menuRight = true, hiddenMenuMobile, isMobile, renderStatus, hiddenMenu } = props;

    const { editMode, menuRight = true, hiddenMenuMobile, isMobile, renderStatus, hiddenMenu } = props;

    const classes = useStyles();
    
    const canRefetchData = useMemo(
        () => treeDataStatus === 'loaded' && !strainSearchLoading && vpValuesStatus === 'loaded', 
        [treeDataStatus, strainSearchLoading, vpValuesStatus]);
    const { width } = useWindowSize();

    useEffect(() => {
        initComponentData();
    });

    const _fetchModel = async () => {

        // console.log(`
        // shouldFetchModels = ${shouldFetchModels},
        // isColorByModel(${colorBy}) = ${isColorByModel(colorBy)}  
        // modelId = ${modelId} 
        // models.includes(modelId) = ${(models||[]).includes(modelId)}
        // modelType = ${modelType} 
        // modelRegionId = ${modelRegionId} 
        // exportMode = ${exportMode}
        // isLoadedOrNA(modelTypesStatus) = ${isLoadedOrNA(modelTypesStatus)}
        // modelsStatus = ${modelsStatus} 
        // canRefetchData = ${canRefetchData} 
        // shouldFetch(modelStatus) = ${shouldFetch(modelStatus)}
        // modelStatus=${modelStatus}
        // `, models)
        const fetchNeeded = isColorByModel(colorBy)
            && modelId
            && (!shouldFetchModels || models.includes(modelId))
            && modelType
            && modelRegionId
            && (!shouldFetchModels || (isLoadedOrNA(modelTypesStatus) && modelsStatus === 'loaded'))
            && canRefetchData
            && shouldFetch(modelStatus);
        
        if (fetchNeeded) return fetchModel({ lineage, colorBy, modelRegionId, modelType, modelId, zoomNodeId, strainSubset });
        return null;
    };

    const _fetchTree = async () => {
        const genotypeParams = colorBy === 'genotype' ? { mutgene, mutposition } : {};
        const humanSerologyParams = colorBy === 'humanSerology' ? { humanPool, humanSerologyDataType } : {};
        const fetchNeeded = shouldFetch(treeDataStatus);
        if (fetchNeeded) {
            return initStrainTree({ lineage, colorBy, zoomNodeId, strainSubset, strainCutOffDate, ignoreStrainCutOffDate, predictionBaseline, vpMethod, ...genotypeParams, strainId, ...humanSerologyParams, ...(exportParams || {}), subsetBySubmission, submissionDate });
        }
        return null;
    };

    const _fetchClades = async () => {
        const cladesNeeded = colorBy === 'clade' || showCladeBar;
        const fetchNeeded = shouldFetch(cladesStatus) && cladesNeeded;
        if (fetchNeeded) {
            return fetchClades({ lineage });
        };
        return null;
    };

    const _fetchVpValues = async () => {
        const initStrainTreeNeeded = shouldFetch(treeDataStatus) || shouldFetch(cladesStatus);
        const fetchNeeded = !initStrainTreeNeeded && shouldFetch(vpValuesStatus);
        if (fetchNeeded)
            return fetchVpValues({ lineage, zoomNodeId, strainSubset, strainCutOffDate, vpMethod });
        return null;
    };

    const _fetchMutationGroupValues = async () => {
        // console.log('[StrainTree] _fetchMutationGroupValues', {mutationGroupValuesStatus, treeDataStatus, showMutationsGroups, mutationsGroup});
        const fetchNeeded = showMutationsGroups && mutationsGroup 
            && shouldFetch(mutationGroupValuesStatus) && treeDataStatus === 'loaded';
        if (fetchNeeded)
            return fetchMutationGroupValues({ lineage, mutationsGroup });
        return null;
    };

    const _fetchMutationClasses = async () => {
        const fetchNeeded = shouldFetch(mutationClassesStatus);
        if (fetchNeeded)
            return fetchMutationClasses({ lineage });
        return null;
    };

    const _fetchCustomTreeAttrs = async () => {
        const fetchNeeded = shouldFetch(customTreeDataStatus[colorBy]) && customMeasures[colorBy] && canRefetchData;
        if (fetchNeeded)
            return fetchCustomTreeAttrs({ lineage, colorBy, strainSubset, zoomNodeId });
        return null;
    };
    const _fetchBranchNodes = async () => {
        const fetchNeeded = 
            branchNodes.some((customAttrId) => shouldFetch(customTreeDataStatus[customAttrId]) && customMeasures[customAttrId])
            && canRefetchData;
        if (fetchNeeded)
            return fetchBranchNodes({ lineage, branchNodes, strainSubset });
        return null;
    };

    const _fetchSelectedNode = async () => {
        const fetchNeeded = !isNil(strainId) && canRefetchData && shouldFetch(searchStrainStatus);
        if (fetchNeeded)
            return fetchSelectedStrain({ lineage, strainId, searchId: 'searchStrain', zoomNodeId, strainSubset });
        return null;
    };

    const _fetchAntigenicModel = async () => {
        if (intro) return null;
        const fetchNeededAntigenicModel = colorBy === 'antigenic' && modelId && antigenicTiterType && shouldFetch(antigenicModelStatus);
        const fetchNeededAntigenicInferredOrObserved = fetchNeededAntigenicModel && (antigenicDataType === 'inferred' || antigenicDataType === 'observed');
        const fetchNeeded = fetchNeededAntigenicInferredOrObserved && (exportMode || modelsStatus === 'loaded');
        if (fetchNeeded)
            return fetchAntigenicModel({ lineage, antigenicModelId, antigenicDataType, antigenicTiterType });
        return null;
    };

    const _fetchAntigenicObservedData = async () => {
        if (intro) return null;
        const fetchNeeded = colorBy === 'antigenic' && modelId && antigenicTiterType 
            && antigenicDataType === 'epitope_clades' && shouldFetch(antigenicObservedDataStatus);
        if (fetchNeeded)
            return fetchAntigenicObservedData({ lineage, antigenicModelId });
        return null;
    };

    const _fetchAntigenicRawModel = async () => {
        const fetchNeeded = colorBy === 'antigenic' && modelId
            && antigenicTiterType
            && (antigenicDataType === 'raw_strain' || antigenicDataType === 'observed_strain')
            && shouldFetch(antigenicRawModelStatus) && treeDataStatus === 'loaded' && cladesStatus === 'loaded';
        if (fetchNeeded)
            return fetchAntigenicRawModel({ lineage, antigenicModelId, antigenicDataType, antigenicTiterType });
        return null;
    };

    const _fetchAntigenicReferenceStrain = async () => {
        const isNullRefStrain = (refStrain) => `${refStrain || ''}`.length === 0;
        const fetchNeeded = colorBy === 'antigenic' && modelId
            && antigenicTiterType
            && antigenicDataType
            && !isNullRefStrain(refStrain)
            && shouldFetch(antigenicReferenceStrainStatus) && antigenicReferenceStrainStatus !== 'not_found' && cladesStatus === 'loaded';
      
        if (fetchNeeded)
            return fetchAntigenicReferenceStrain({ lineage, colorBy, refStrain, antigenicDataType, searchId: 'antigenic', zoomNodeId, ignoreStrainCutOffDate });
        return null;
    };

    const _fetchGenotypeData = async () => {
        // console.log({mutgene, mutposition});
        const fetchNeeded = colorBy === 'genotype' && shouldFetch(genotypeDataStatus) 
            && !isNull(mutgene) && !isNull(mutposition);
        if (fetchNeeded)
            return fetchGenotypeData({ lineage, mutgene, mutposition, zoomNodeId });
        return null;
    };

    const _fetchTCellAntigenicityScores = async () => {
        const fetchNeeded = colorBy === 'tcellAntigenicity' && gene && hla 
            && shouldFetch(tcellStatus[`${gene}_${hla}`]) && !exportMode;
        if (fetchNeeded)
            return fetchTCellAntigenicityScores({ lineage, colorBy, gene, hla, strainSubset });
        return null;
    };

    const _fetchHumanPools = async () => {
        const fetchNeeded = colorBy === 'humanSerology' && shouldFetch(humanPoolsStatus);
        if (fetchNeeded)
            return fetchHumanPools({ lineage });
        return null;
    };

    const _fetchHumanSerologyData = async () => {
        const fetchNeeded = colorBy === 'humanSerology' && canRefetchData && shouldFetch(humanSerologyDataStatus) 
            && humanPool && humanSerologyDataType;
        if (fetchNeeded)
            return fetchHumanSerologyData({ lineage, colorBy, humanPool, humanSerologyDataType });
        return null;
    };

    const initComponentData = async () => {
        if (lineageStatus !== 'loaded') return;
       
        await Promise.all([
            _fetchModel(),
            _fetchTree(),
            _fetchClades(),
            _fetchVpValues(),
            _fetchMutationClasses(),
            _fetchCustomTreeAttrs(),
            _fetchAntigenicModel(),
            _fetchAntigenicObservedData(),
            _fetchAntigenicRawModel(),
            _fetchAntigenicReferenceStrain(),
            _fetchGenotypeData(),
            _fetchTCellAntigenicityScores(),
            _fetchHumanPools(),
            _fetchHumanSerologyData(),
            _fetchMutationGroupValues(),
            _fetchBranchNodes(),
            _fetchSelectedNode()
            // shouldFetch(tcellAntigenicityOptionsStatus) && !exportMode ? fetchTCellAntigenicityOptions({ lineage }) : null,
        ]);
    };

    // console.log('EXPORT DONE:', renderStatus === RENDER_STATUS.DONE )
    // console.log('[StrainTree]', {intro, isMobile, hiddenMenu, hiddenMenuMobile});
    return (
        <>
            {!exportMode && (
                <div style={dynamicStyles(isMobile).root}>
                    <ErrorAlert />
                    <Grid container className={classes.container}>
                        {
                            isMobile ?
                                <Grid size='grow' className={classes.item}>
                                    <TreeGraph />
                                    <TreeSidebar intro={intro} hiddenMenuMobile={hiddenMenuMobile} nodeInfoPosition='bottom' isMobile={isMobile} /> 
                                    <MetaInformations />
                                </Grid>
                                :
                                <>
                                    {!menuRight &&
                                        <TreeSidebar intro={intro} hiddenMenu={hiddenMenu} nodeInfoPosition='top'
                                            className={`${classes.treeSidebarLeft} ${hiddenMenu ? classes.hidden : ''}`} />
                                    }
                                    <Grid size='grow' className={classes.item}>
                                        <ExportableComponent filename="strainTree">
                                            <TreeGraph />
                                        </ExportableComponent>
                                        {!intro && <CladeSchema cladeSchemaWidth={schemaWidth} />}
                                        <MetaInformations />
                                    </Grid>
                                    {menuRight &&
                                        <TreeSidebar intro={intro} hiddenMenu={hiddenMenu} nodeInfoPosition='top'
                                            className={`${classes.treeSidebarRight} ${hiddenMenu ? classes.hidden : ''}`} />
                                    }
                                </>

                        }



                    </Grid>

                </div>
            )}
            {exportMode && !editMode && (
                <div className={classes.rootExport}>
                    <div className={classes.containerExport}>
                        <div id='exportComponent' className={classes.itemExport}>
                            <TreeGraph />
                        </div>
                        <div className={classes.legendExport} >
                            <TreeLegend />
                        </div>
                        <CladeSchema cladeSchemaWidth={schemaWidth} />
                    </div>
                </div>
            )}
            {exportMode && editMode && (
                <Edit />
            )}
            {(renderStatus === RENDER_STATUS.DONE) && (
                <div id="exportDone" />
            )}
        </>
    );

};

StrainTree.propTypes = {
    antigenicDataType: PropTypes.string,
    antigenicModelId: PropTypes.string,
    antigenicObservedDataStatus: PropTypes.string,
    antigenicRawModelStatus: PropTypes.string,
    antigenicTiterType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number]),
    antigenicModelStatus: PropTypes.string,
    antigenicReferenceStrainStatus: PropTypes.string,
    branchNodes: PropTypes.arrayOf(PropTypes.string),
    canRefetchData: PropTypes.bool,
    colorBy: PropTypes.string,
    clades: PropTypes.shape({ label: PropTypes.string }),
    cladesStatus: PropTypes.string,
    customMeasures: PropTypes.object,
    customTreeDataStatus: PropTypes.object,
    editMode: PropTypes.bool,
    exportMode: PropTypes.bool,
    exportParams: PropTypes.shape({ lineage: PropTypes.string, modelId: PropTypes.string, regionId: PropTypes.string }),
    fetchAntigenicModel: PropTypes.func,
    fetchAntigenicObservedData: PropTypes.func,
    fetchAntigenicRawModel: PropTypes.func,
    fetchAntigenicReferenceStrain: PropTypes.func,
    fetchBranchNodes: PropTypes.func,
    fetchClades: PropTypes.func,
    fetchCustomTreeAttrs: PropTypes.func,
    fetchGenotypeData: PropTypes.func,
    fetchHumanPools: PropTypes.func,
    fetchHumanSerologyData: PropTypes.func,
    fetchMutationClasses: PropTypes.func,
    fetchMutationGroupValues: PropTypes.func,
    fetchModel: PropTypes.func,
    fetchSelectedStrain: PropTypes.func,
    fetchTCellAntigenicityScores: PropTypes.func,
    fetchVpValues: PropTypes.func,
    gene: PropTypes.string,
    hla: PropTypes.string,
    hiddenMenu: PropTypes.bool,
    hiddenMenuMobile: PropTypes.bool,
    humanPool: PropTypes.string,
    humanPoolsStatus: PropTypes.string,
    humanSerologyDataStatus: PropTypes.string,
    humanSerologyDataType: PropTypes.string,
    ignoreStrainCutOffDate: PropTypes.bool,
    initializationParams: PropTypes.object,
    initStrainTree: PropTypes.func,
    isMobile: PropTypes.bool,
    lineage: PropTypes.string,
    lineageStatus: PropTypes.string,
    modelId: PropTypes.string,
    modelRegionId: PropTypes.string,
    modelStatus: PropTypes.string,
    modelType: PropTypes.string,
    modelTypesStatus: PropTypes.string,
    models: PropTypes.arrayOf(PropTypes.string),
    modelsStatus: PropTypes.string,
    mutationsGroup: PropTypes.string,
    mutationClassesStatus: PropTypes.string,
    mutationGroupValuesStatus: PropTypes.string,
    mutgene: PropTypes.string,
    mutposition: PropTypes.number,
    predictionBaseline: PropTypes.string,
    refClade: PropTypes.number,
    refStrain: PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
    renderStatus: PropTypes.string,
    strainCutOffDate: PropTypes.string,
    strainId: PropTypes.number,
    strainSearchLoading: PropTypes.bool,
    strainSubset: PropTypes.string,
    searchStrainStatus: PropTypes.string,
    shouldFetchModels: PropTypes.bool,
    showCladeBar: PropTypes.bool,
    showMutationsGroups: PropTypes.bool,
    tcellStatus: PropTypes.object,
    tcellAntigenicityOptionsStatus: PropTypes.string,
    uniqueIdentifiers: PropTypes.object,
    vpMethod: PropTypes.string,
    vpMethodsStatus: PropTypes.string
};

const mapStateToProps = (state) => ({
    // General Status
    renderStatus: state.render.renderStatus,
    shouldFetchModels: shouldFetchModelsSelector(state),
    strainSearchLoading: getStrainSearchStatus(state),

    // Parameters
    lineage: state.parameters.lineage,
    modelId: state.parameters.modelId,
    colorBy: state.parameters.colorBy,
    antigenicTiterType: state.parameters.antigenicTiterType,
    antigenicDataType: state.parameters.antigenicDataType,
    exportMode: state.parameters.exportMode,
    zoomNodeId: state.parameters.zoomNodeId,
    strainSubset: state.parameters.strainSubset,
    exportParams: state.parameters.exportParams,
    gene: state.parameters.gene,
    hla: state.parameters.hla,
    strainCutOffDate: state.parameters.strainCutOffDate,
    ignoreStrainCutOffDate: getIgnoreStrainCutOffDateForColorBy(state),
    vpMethod: state.parameters.vpMethod,
    mutgene: state.parameters.mutgene,
    mutposition: state.parameters.mutposition,
    humanPool: state.parameters.humanPool,
    humanSerologyDataType: state.parameters.humanSerologyDataType,
    strainId: state.parameters.strainId,
    modelRegionId: state.parameters.modelRegionId,
    modelType: state.parameters.modelType,
    antigenicModelId: state.parameters.antigenicModelId,
    mutationsGroup: state.parameters.mutationsGroup,
    predictionBaseline: state.parameters.predictionBaseline,
    refClade: state.parameters.refClade,
    branchNodes: state.parameters.branchNodes,
    refStrain: state.parameters.refStrain,

    // Status Flags
    treeDataStatus: state.treeData.treeDataStatus,
    cladesStatus: state.cladeData.cladesStatus,
    modelStatus: state.modelData.modelStatus[state.parameters.colorBy],
    modelsStatus: state.models.modelsStatus[state.parameters.colorBy],
    modelTypesStatus: state.models.modelTypesStatus[state.parameters.colorBy] || 'NA',
    lineageStatus: state.lineages.lineageStatus,
    antigenicModelStatus: state.antigenic.antigenicModelStatus,
    antigenicObservedDataStatus: state.antigenic.antigenicObservedDataStatus,
    antigenicRawModelStatus: state.antigenic.antigenicRawModelStatus,
    genotypeDataStatus: state.genotype.genotypeDataStatus,
    customTreeDataStatus: state.customTreeData.status,
    tcellStatus: state.customTreeData.tcellStatus,
    vpValuesStatus: state.treeData.vpValuesStatus,
    mutationClassesStatus: state.metadata.mutationClassesStatus,
    mutationGroupValuesStatus: state.genotype.mutationGroupValuesStatus,
    antigenicReferenceStrainStatus: state.treeData.strainSearchStatuses.antigenic,
    searchStrainStatus: state.treeData.strainSearchStatuses.searchStrain,
    showCladeBar: state.parameters.showCladeBar,
    showMutationsGroups: state.parameters.showMutationsGroups,

    // Data
    cladeSchema: state.cladeData.cladeSchema,
    clades: state.cladeData.clades,
    models: state.models.models[state.parameters.colorBy],
    customMeasures: getCustomMeasures(state),

    // UI Flags
    menuRight: state.user.menuRight,
    hiddenMenu: state.ui.hiddenMenu,
    hiddenMenuMobile: state.ui.hiddenMenuMobile,
    isMobile: getIsMobile(),

    // Human Serology
    humanPoolsStatus: state.humanSerology.humanPoolsStatus,
    humanSerologyDataStatus: state.humanSerology.humanSerologyDataStatus,

    // Metadata
    tcellAntigenicityOptionsStatus: state.metadata.tcellAntigenicityOptionsStatus,
    vpMethodsStatus: state.metadata.vpMethodsStatus,
    subsetBySubmission: state.parameters.subsetBySubmission,
    submissionDate: state.parameters.submissionDate
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchModel,
            fetchClades,
            initStrainTree,
            fetchAntigenicModel,
            fetchAntigenicObservedData,
            fetchAntigenicRawModel,
            fetchTCellAntigenicityScores,
            fetchCustomTreeAttrs,
            fetchVpValues,
            fetchGenotypeData,
            fetchMutationClasses,
            fetchHumanPools,
            fetchHumanSerologyData,
            fetchMutationGroupValues,
            fetchAntigenicReferenceStrain,
            fetchBranchNodes,
            fetchSelectedStrain
        },
        dispatch,
    );


export default connect(mapStateToProps, mapDispatchToProps)(StrainTree);

