import { produce } from 'immer';

import {
    FETCH_CLADES_REQUEST,
    FETCH_CLADES_SUCCESS,
    FETCH_CLADES_ERROR,
    FETCH_ACTIVE_CLADES_REQUEST,
    FETCH_ACTIVE_CLADES_SUCCESS,
    FETCH_ACTIVE_CLADES_ERROR,
    RESET_ACTIVE_CLADES,
    RESET_SESSION,
    SIGNOUT_REQUEST
} from '../actions/actionTypes';


let cladesInitialState = {};

export const setCladesInitialState = (state) => {
    cladesInitialState = state;
};

export const cladeDataReducer = (state = cladesInitialState, action) => 
    produce(state, (draft) => {
        switch (action.type) {
            case RESET_SESSION:
            case SIGNOUT_REQUEST: {
            //console.log('cladesInitialState', cladesInitialState)
                return cladesInitialState;
            }
            case FETCH_CLADES_REQUEST: {
                draft.cladesStatus = 'loading',
                draft.clades = cladesInitialState.clades;
                break;
            }
            case FETCH_CLADES_SUCCESS: {
                const { clades } = action.payload;
                draft.clades = clades;
                draft.cladesStatus =  'loaded';
                break;
            }

            case FETCH_CLADES_ERROR: {
                draft.cladesStatus = 'error';
                break;
            }
            case FETCH_ACTIVE_CLADES_REQUEST: {
                draft.activeCladesStatus = 'loading';
                break;
            }

            case FETCH_ACTIVE_CLADES_SUCCESS: {
                const { activeClades } = action.payload;
                draft.activeClades = activeClades;
                draft.activeCladesStatus = 'loaded';
                break;
            }
            case FETCH_ACTIVE_CLADES_ERROR: {
                draft.activeCladesStatus = 'error';
                break;
            }
            case RESET_ACTIVE_CLADES: {
                draft.activeCladesStatus = 'none';
                break;
            }
            default:
                break;
        }
    });

