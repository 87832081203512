import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import {
    Menu,
    MenuItem,
    ListItemIcon,
    Divider,
    IconButton,
    Tooltip,
    Button
} from '@mui/material';
import {
    Help,
    Group,
    Person,
    Settings,
    Info
} from '@mui/icons-material';
import { styles, UserIcon, menuStyles } from './styles';
import PreferencesDialog from './dialogs/Preferences';
import config from '../../config/envConfig';
import { bindActionCreators } from 'redux';
import { signOut } from '../../redux/actions/userActions';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';


const UserMenu = ({ signOut, history, adminRights, dataAdminRights, username, isMobile }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPreferencesDialog, setOpenPreferencesDialog] = useState(false);
    const open = Boolean(anchorEl);
    const classes = styles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenPreferencesDialog = () => {
        setOpenPreferencesDialog(true);
    };

    const handleClosePreferencesDialog = () => {
        setOpenPreferencesDialog(false);
    };

    const handleLinkClick = (link) => {
        handleClose();
        if (link.internal) {
            // Handle internal link scrolling
            const element = document.querySelector(link.value);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            // Handle external links
            window.location.href = link.value;
        }
    };

    const headerLinks = [
        { value: '#data_sources', label: 'Data sources', internal: true },
        { value: 'https://previr.com/analysis#previr-app', label: 'Guide'},
        { value: 'https://previr.com/', label: 'Previr Center'}
    ];
    
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                    <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
                        <UserIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: menuStyles
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem className={classes.tittle}>
                    Hello, {capitalize(username)}!
                </MenuItem>
                <Divider className={classes.dividerUp} />
                {(adminRights || dataAdminRights) && <div>
                    {dataAdminRights &&
                        <Link to={`${config.frontendPrefix}/settings`} className={classes.link}>
                            <MenuItem className={classes.menuItem}>
                                <ListItemIcon className={classes.icon}>
                                    <Settings fontSize="small" />
                                </ListItemIcon>
                                Settings
                            </MenuItem>
                        </Link>
                    }
                    {adminRights &&
                        <Link to={`${config.frontendPrefix}/manage`} className={classes.link}>
                            <MenuItem className={classes.menuItem}>
                                <ListItemIcon className={classes.icon}>
                                    <Group fontSize="small" />
                                </ListItemIcon>
                                Manage users & roles
                            </MenuItem>
                        </Link>
                    }
                    <Divider className={classes.dividerDown} />
                </div>}
               
                <MenuItem onClick={handleOpenPreferencesDialog} className={classes.menuItem}>
                    <ListItemIcon className={classes.icon}>
                        <Person fontSize="small" />
                    </ListItemIcon>
                    Preferences
                </MenuItem>
                <Link to={`https://previr.com/center`} target="_blank" className={classes.link}>
                    <MenuItem className={classes.menuItem}>
                        <ListItemIcon className={classes.icon}>
                            <Help fontSize="small" />
                        </ListItemIcon>

                        Help {'&'} support
                    </MenuItem>
                </Link>
                <Link to={`https://previr.com/analysis#previr-app`} target="_blank" className={classes.link}>
                    <MenuItem className={classes.menuItem}>
                        <ListItemIcon className={classes.icon}>
                            <Info fontSize="small" />
                        </ListItemIcon>
                        About
                    </MenuItem >
                </Link>
                <Divider className={classes.dividerDown} />
                { isMobile &&
                    headerLinks.map((link) => (
                        <MenuItem 
                            key={`mi-${link.value}`}
                            onClick={() => handleLinkClick(link)}
                        >
                            <span className={classes.linkDef}>
                                {link.label}
                            </span>
                        </MenuItem>
                    ))
                }
                <Button className={classes.button} onClick={() => { signOut(history); }}>
                    Sign out
                </Button>
            </Menu>
            <PreferencesDialog handleCloseDialog={handleClosePreferencesDialog} openDialog={openPreferencesDialog} />
        </>
    );
};

const mapStateToProps = ({ user }) => ({
    adminRights: user.permissions.adminRights,
    dataAdminRights: user.permissions.dataAdminRights,
    username: user.username
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ signOut }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
