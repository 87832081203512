// External imports
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

// Internal imports - Components
import SelectInput from '../Common/SelectInput';

// Internal imports - Redux
import { fetchSubsetTree } from '../../redux/actions/treeDataActions';
import { setParameters } from '../../redux/actions/parametersActions';
import { getPredictionsStatus } from '../../redux/selectors/statusSelector';
import { predictionBaselineSelector } from '../../redux/selectors/parametersSelector';

// Internal imports - Utils
import { emptyObject } from '../../functions/functions';

const StrainsHighlightSelector = ({
    strainHighlight,
    strainHighlightOptions,
    strainSubset,
    zoomNodeId,
    lineage,
    setParameters,
    fetchSubsetTree
}) => {
    const handleChange = strainHighlight => {
        setParameters({ strainHighlight });
        console.log(`[highlight]: strainSubset = ${strainSubset}, strainHighlight = ${strainHighlight}`);
        fetchSubsetTree({ strainHighlight, zoomNodeId, lineage, strainSubset });
    };

    return (
        <>
            {!emptyObject(strainHighlightOptions) && (
                <SelectInput
                    id={'strainHighlight'}
                    label={'Highlight strains'}
                    value={strainHighlight}
                    onChange={handleChange}
                    options={strainHighlightOptions}
                    getOptionValue={option => option.key}
                    getOptionLabel={option => option.label}
                />
            )}
        </>
    );
};

StrainsHighlightSelector.propTypes = {
    // Required props
    setParameters: PropTypes.func.isRequired,
    fetchSubsetTree: PropTypes.func.isRequired,
    
    // Optional props
    strainHighlightOptions: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string
    })),
    lineage: PropTypes.string,
    strainHighlight: PropTypes.string,
    strainSubset: PropTypes.string,
    zoomNodeId: PropTypes.number
};

const mapStateToProps = (state) => ({
    // Parameters
    lineage: state.parameters.lineage,
    strainSubset: state.parameters.strainSubset,
    strainHighlight: state.parameters.strainHighlight,
    zoomNodeId: state.parameters.zoomNodeId,
    freqCategory: state.parameters.freqCategory,
    gene: state.parameters.gene,
    hla: state.parameters.hla,
    
    // Metadata
    strainHighlightOptions: state.metadata.strainHighlightOptions,
    
    // Status
    subsetTreeStatus: state.treeData.subsetTreeStatus,
    frequenciesStatus: state.frequenciesData.frequenciesStatus,
    predictionsStatus: getPredictionsStatus(state),
    
    // Other
    predictionBaseline: predictionBaselineSelector(state),
    selectedBins: state.frequenciesData.selectedBins
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
    fetchSubsetTree
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StrainsHighlightSelector);
