import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getMetadataMeasuresWithScales } from '../../../redux/selectors/rangeDataSelector';
// import LegendElement from '../Elements/LegendElement';
import ContinousScale from '../Elements/ContinousScale';
// import { isNil } from 'lodash';
import DiscreteLegend from './DiscreteLegend';

const ScalesLegend = (props) => {
    // console.log('[ScalesLegend]', props);
    const { title, inContainer, precision, ticks,
        measureName, valueGetter, discrete, domain,
        colorLegendVisible = true,
    } = props;
  
 
    // console.log(`colorBy = ${_colorBy}, scaleId = ${ownProps.scaleId}, mode = ${ownProps.mode}, modelType = ${modelType}`);

    if (!colorLegendVisible) return <></>;

    //console.log(scaleId, value, domain, getScaledValue(`${scaleId}ValueScale`, value), getScaledValue(`${scaleId}ColorScale`, value), _domain);
    return (
        <>
            {discrete && 
                <DiscreteLegend {...props}/>  
            }
            {!discrete && domain &&
                <ContinousScale
                    measureName={measureName}
                    precision={precision}
                    title={title}
                    inContainer={inContainer}
                    ticks={ticks}
                    valueGetter={valueGetter}
                    scaleId={measureName}
                />
            }
        </>
    );
};

ScalesLegend.propTypes = {
    // value: PropTypes.number,
    scaleId: PropTypes.string,
    measureName: PropTypes.string.isRequired,
    title: PropTypes.string,
    inContainer: PropTypes.bool,
    precision: PropTypes.number,
    ticks: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => {
    // const { exportMode } = state.parameters;
    const measureName = ownProps.measureName || state.parameters.colorBy;
    // const scaleName = state.parameters.colorScales[measureName];    
    const measuresWithScales = getMetadataMeasuresWithScales(state);
    const measure = measuresWithScales[measureName];
    const discrete = measure?.scale.discrete;
    // const measureDiscrete = measure.discrete || false;

    const domain = measuresWithScales[measureName]?.scale.domain;
    // const colorBins = measure.scale.bins; //state.metadata.measureBins?.[measureName]?.[scaleName]; //colorLegendVisible && getColorBins(/*colorBy*/ measureName /*, modelType*/)(state);
    // console.log({measure});
    return ({
        measureName,
        // scaleName,
        discrete,
        domain,
        // value: ownProps.valueGetter && ownProps.valueGetter(state)
    });
};

export default connect(mapStateToProps)(ScalesLegend);
