// External imports
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormHelperText } from '@mui/material';
import Grid from '@mui/material/Grid2';

// Redux actions
import { fetchModels, fetchModelTypes } from '../../redux/actions/modelActions';
import { setModelRegionId, setModelType } from '../../redux/actions/parametersActions';

// Redux selectors
import { getRegionsWithLabels } from '../../redux/selectors/parametersSelector';
import { getModelLoadingSelector } from '../../redux/selectors/statusSelector';
import { introModelTypesSelector } from '../../redux/selectors/modelsSelector';

// Components
import AntigenicModelIdSelector from './AntigenicModelIdSelector';
import ModelIdSelector from './ModelIdSelector';
import SelectInput from '../Common/SelectInput';

// Utils & Config
import { shouldFetch, isLoadedOrNA } from '../../functions/functions';
import { showSelect } from './functions';
import appConfig from '../../config/appConfig';

/**
 * ModelSelector component handles selection of model parameters including region, type and ID
 */
const ModelSelector = (props) => {
    const { 
        // Model related props
        modelContext, modelVariable, modelLevels, modelTypes, modelType,
        lineage, colorBy, regions, modelRegionId, intro,
        
        // Status props
        lineageStatus, modelsStatus, modelTypesStatus, modelsLoading,
        
        // Action props
        setModelRegionId, setModelType, fetchModels, fetchModelTypes,
    } = props;

    // Fetch model data when dependencies change
    useEffect(() => {
        if (lineageStatus !== 'loaded') return;

        if (modelContext === 'antigenic' || modelContext === 'antigenicFitness') {
            if (shouldFetch(modelsStatus)) {
                fetchModels({ lineage, colorBy: modelContext });
            }
        } else {
            if (shouldFetch(modelTypesStatus)) {
                fetchModelTypes({ lineage, modelRegionId, colorBy });
            }
            if (isLoadedOrNA(modelTypesStatus) && shouldFetch(modelsStatus)) {
                fetchModels({ lineage, modelRegionId, colorBy, modelType });
            }
        }
    });

    // Event handlers
    const handleModelRegionChange = (value) => {
        setModelRegionId(value, modelVariable);
    };

    const handleModelTypeChange = async (value) => {
        setModelType(value, modelVariable);
    };

    // Configuration for different model level inputs
    const levelsConfig = {
        modelRegionId: {
            onChangeHandler: handleModelRegionChange,
            label: 'Model region',
            options: { 
                array: regions, 
                value: elem => elem.id, 
                label: elem => elem.label 
            },
            value: modelRegionId
        },
        modelType: {
            onChangeHandler: handleModelTypeChange,
            label: 'Model type',
            options: { 
                array: modelTypes, 
                value: elem => elem, 
                label: elem => appConfig.modelTypesLabels[elem] || elem 
            },
            value: modelType
        }
    };

    return (
        <>
            {modelLevels && modelLevels.length > 0 && (
                <Grid container columnSpacing={2}>
                    {modelLevels
                        .filter(modelLevel => !intro || appConfig.introModelSelectorInputs.includes(modelLevel))
                        .map((modelLevel, index) => {
                            const { onChangeHandler, label, value, options } = levelsConfig[modelLevel];
                            const showInput = showSelect(value, options.array.map(elem => options.value(elem)));
                            const previousLevels = modelLevels.filter((_, mindex) => mindex < index);
                            const previousLevelsTxt = previousLevels.map(l => `, ${levelsConfig[l].label}: ${levelsConfig[l].value}`).join();
                            
                            return (
                                <Grid size={6} key={`${modelLevel}_grid`}>
                                    {!showInput && !modelsLoading &&
                                        <FormHelperText error={true}>
                                            No models for {colorBy} {previousLevelsTxt}
                                        </FormHelperText>
                                    }
                                    {showInput &&
                                        <SelectInput
                                            id={modelLevel}
                                            label={label}
                                            value={value}
                                            onChange={onChangeHandler}
                                            options={options.array}
                                            getOptionValue={option => options.value(option)}
                                            getOptionLabel={option => options.label(option)}
                                        />  
                                    }
                                </Grid>
                            );
                        })}
                </Grid>
            )}
            {!intro && <ModelIdSelector modelContext={modelContext} />}
            {!intro && <AntigenicModelIdSelector modelContext={modelContext} />}
        </>
    );
};

ModelSelector.propTypes = {
    // Required props
    lineageStatus: PropTypes.string.isRequired,
    modelContext: PropTypes.string.isRequired,
    modelLevels: PropTypes.array.isRequired,
    modelTypesStatus: PropTypes.string.isRequired,
    modelsStatus: PropTypes.string.isRequired,
    fetchModels: PropTypes.func.isRequired,
    fetchModelTypes: PropTypes.func.isRequired,
    setModelRegionId: PropTypes.func.isRequired,
    setModelType: PropTypes.func.isRequired,
    
    // Optional props
    colorBy: PropTypes.string,
    fixedModelType: PropTypes.string,
    intro: PropTypes.bool,
    lineage: PropTypes.string,
    modelId: PropTypes.string,
    modelRegionId: PropTypes.string,
    modelType: PropTypes.string,
    modelTypes: PropTypes.arrayOf(PropTypes.string),
    modelVariable: PropTypes.string,
    modelsLoading: PropTypes.bool,
    regions: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string
    })),
    
    // Optional functions
    fetchModel: PropTypes.func,
    resetAntigenicModel: PropTypes.func,
    resetModelData: PropTypes.func,
    resetPredictions: PropTypes.func,
    setModel: PropTypes.func,
    setParameters: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
    const { lineage, colorBy, intro } = state.parameters;

    // Determine model context and variable based on props and state
    const modelContext = (ownProps.modelContext === 'strainTree' && colorBy === 'antigenic') 
        ? 'antigenic' 
        : ownProps.modelContext;
        
    const modelVariable = (modelContext === 'antigenic' || modelContext === 'antigenicFitness')
        ? modelContext
        : ((modelContext === 'frequencies') ? 'fitness' : colorBy);

    // Get model parameters from props or state
    const modelId = ownProps.modelId || (modelContext === 'antigenic' 
        ? state.parameters.antigenicModelId 
        : state.parameters.modelId);
    const modelRegionId = ownProps.modelRegionId || state.parameters.modelRegionId;
    const modelType = ownProps.modelType || state.parameters.modelType;

    return {
        colorBy,
        intro,
        lineage,
        lineageStatus: state.lineages.lineageStatus,
        modelContext,
        modelId,
        modelLevels: state.metadata.modelsConfig.modelLevels[modelVariable],
        modelRegionId,
        modelType: ownProps.fixedModelType || modelType,
        modelTypes: intro ? introModelTypesSelector(state) : state.models.modelTypes[modelVariable],
        modelVariable,
        modelsLoading: getModelLoadingSelector(state),
        modelsStatus: state.models.modelsStatus[modelVariable],
        modelTypesStatus: state.models.modelTypesStatus[modelVariable],
        regions: getRegionsWithLabels(state)
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setModelType,
    setModelRegionId, 
    fetchModelTypes,
    fetchModels
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModelSelector);
