import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import Header from './layout/Header';
import Content from './layout/Content';
import { getSignedUser } from './redux/actions/userActions';
import { setParameters } from './redux/actions/parametersActions';
import { shouldFetch, transformUrlToParams } from './functions/functions';
import { fetchLineage, fetchLineages } from './redux/actions/lineagesActions';
import LoadProgress from './components/LoadProgress/LoadProgress';
import config from './config/envConfig';
import auth from './functions/auth-helper';
import CookiePopup from './layout/CookiePopup/CookiePopup';
import Error from './pages/Error/Error';
import { bindActionCreators } from 'redux';

const App = (props) => {
    const {
        children,
        error,
        isAuthenticated,
        userStatus,
        defaultLineage,
        lineage,
        lineageStatus,
        lineagesStatus,
        lineages,
        exportMode,
        reduxExportMode,
        settingsLineage,
        settingsLineageStatus,
        fetchLineage,
        fetchLineages,
        getSignedUser,
        setParameters,
    } = props;

  
    const [queryParams, setQueryParams] = useState({});
    const location = useLocation();
    const { search, pathname } = location;

    // Determine current page state
    const isSettingsPath = pathname === `${config.frontendPrefix}/settings`;
    const isExportPath = pathname.startsWith(`${config.frontendPrefix}/export`);
    const isSigninPath = pathname === `${config.frontendPrefix}/signin`;

    const currentLineage = isSettingsPath ? settingsLineage : lineage;
    const currentLineageStatus = isSettingsPath ? settingsLineageStatus : lineageStatus;

    const introPaths = [
        // `${config.frontendPrefix}/signin`,
        `${config.frontendPrefix}/newPassword`,
        `${config.frontendPrefix}/`,
        `${config.frontendPrefix}/intro`,
        `${config.frontendPrefix}/intro-freq`,
        `${config.frontendPrefix}/intro-map`,
    ];
    const isIntroPath = introPaths.includes(pathname);
   

    // Parse query parameters from URL
    useEffect(() => {
        const parseQueryParams = () => {
            const params = {};
            const queryParams = new URLSearchParams(search);

            if (isExportPath) {
                for (const [key, value] of queryParams.entries()) {
                    params[key] = transformUrlToParams[key]
                        ? transformUrlToParams[key](value)
                        : value;
                }
            } else {
                const lineageParam = queryParams.get('lineage');
                if (lineageParam) params.lineage = lineageParam;
            }

            if (Object.keys(params).length > 0) {
                setQueryParams(params);
            }
        };

        parseQueryParams();
    }, [search, isExportPath]);

 
    useEffect(() => {
        if (isExportPath) {
            handleExportMode();
        } else {
            handleRegularMode();
        }
    }, [
        isExportPath,
        queryParams,
        currentLineageStatus,
        reduxExportMode,
        isAuthenticated,
        userStatus,
        lineagesStatus,
        isIntroPath,
        lineages,
        defaultLineage,
        currentLineage,
        search,
        fetchLineage,
        fetchLineages,
        getSignedUser,
        setParameters,
        isSettingsPath,
    ]);

    const handleExportMode = () => {
        const lineageParam = queryParams.lineage;
        if (shouldFetch(currentLineageStatus) && lineageParam) {
            fetchLineage({ lineage: lineageParam, ...queryParams, exportMode: true });
        } else if (currentLineageStatus === 'loaded' && reduxExportMode !== isExportPath) {
            setParameters({ ...queryParams, exportMode: true });
        }
    };
    
    const handleRegularMode = () => {
        if (userStatus === 'loading') return;
        if (isSigninPath || ['signingOut', 'signedOut'].includes(userStatus)) return;
    
        const isIntro = !isAuthenticated && !isExportPath;
    
        if (!isAuthenticated && auth.isAuthenticated()) {
            getSignedUser();
        } else if (shouldFetch(lineagesStatus) && isIntroPath && userStatus === 'none') {
            fetchLineages();
        } else if (lineagesStatus === 'loaded' && userStatus !== 'loading') {
            selectAndFetchLineage(isIntro);
        }
    };

    const selectAndFetchLineage = (isIntro) => {
        const queryParams = new URLSearchParams(search);
        const queryLineage = queryParams.get('lineage');
    
        let selectedLineage =
          queryLineage && lineages.includes(queryLineage)
              ? queryLineage
              : currentLineage || defaultLineage;
    
        if (lineages.length > 0) {
            if (!lineages.includes(selectedLineage)) {
                selectedLineage = lineages[0];
                setParameters({ lineage: selectedLineage });
            }
            if (shouldFetch(currentLineageStatus) && selectedLineage) {
                // console.log(`[selectAndFetchLineage] fetching lineage ${selectedLineage}`, { currentLineage, queryLineage, defaultLineage });
                fetchLineage({
                    lineage: selectedLineage,
                    intro: isIntro,
                    settings: isSettingsPath,
                });
            }
        }
    };
    const retryInit = () => {
        window.location.reload(true);
    };

    if (error) {
        return <Error text={error} retryInit={retryInit} />;
    }

    return (
        <>
            <Header title="Previr.app" />
            <LoadProgress />
            <Content>{children}</Content>
            {!exportMode && <CookiePopup />}
        </>
    );
};

App.propTypes = {
    children: PropTypes.node,
    error: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    userStatus: PropTypes.string.isRequired,
    defaultLineage: PropTypes.string,
    lineage: PropTypes.string,
    lineageStatus: PropTypes.string,
    lineagesStatus: PropTypes.string,
    lineages: PropTypes.arrayOf(PropTypes.string),
    exportMode: PropTypes.bool,
    reduxExportMode: PropTypes.bool,
    settingsLineage: PropTypes.string,
    settingsLineageStatus: PropTypes.string,
    fetchLineage: PropTypes.func.isRequired,
    fetchLineages: PropTypes.func.isRequired,
    getSignedUser: PropTypes.func.isRequired,
    setParameters: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    error: state.alert.error,
    isAuthenticated: state.user.status === 'loaded',//state.user.isAuthenticated,
    userStatus: state.user.status,
    defaultLineage: state.user.defaultLineage,
    lineageStatus: state.lineages.lineageStatus,
    lineagesStatus: state.lineages.lineagesStatus,
    lineage: state.parameters.lineage,
    reduxExportMode: state.parameters.exportMode,
    lineages: state.lineages.lineages,
    settingsLineage: state.settings.parameters.lineage,
    exportMode: state.parameters.exportMode,
    settingsLineageStatus: state.settings.lineages?.lineageStatus,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getSignedUser, // Get the signed user
    setParameters, // Set the parameters
    fetchLineage, // Fetch the lineage
    fetchLineages, // Fetch the lineages
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
