import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid2';
import SelectInput from '../Common/SelectInput';
import NumberInput from '../Common/NumberInput';
import { setParameters } from '../../redux/actions/parametersActions';

const logSpaceOptions = [
    { key: true, label: 'Logarithmic' },
    { key: false, label: 'Linear' },
];

const YScaleSelector = props => {
    const { logSpace, minLogSpace: propMinLogSpace, setParameters } = props;

    const handleYScaleChange = logSpace => {
        setParameters({ logSpace });
    };

    const updateParameter = (type) => (value) => {
        setParameters({ [type]: value });
    };

    const onAccept = updateParameter('minLogSpace');

    return (
        <Grid container spacing={2}>
            <Grid size={logSpace ? 6 : 12}>
                <SelectInput
                    id='logSpace'
                    label='Y scale'
                    value={logSpace}
                    onChange={handleYScaleChange}
                    options={logSpaceOptions}
                    getOptionValue={(option) => option.key}
                    getOptionLabel={(option) => option.label}
                />
            </Grid>
            {logSpace && (
                <Grid size={6}>
                    <NumberInput
                        id="minLogSpace"
                        showButtons={true}
                        label="Min. value"
                        value={propMinLogSpace}
                        allowFloat={true}
                        min={0}
                        max={1}
                        increment={0.01}
                        onAccept={onAccept}
                    />
                </Grid>
            )}
        </Grid>
    );
};

YScaleSelector.propTypes = {
    logSpace: PropTypes.bool.isRequired,
    minLogSpace: PropTypes.number.isRequired,
    setParameters: PropTypes.func.isRequired
};

const mapStateToProps = ({ parameters }) => ({
    logSpace: parameters.logSpace,
    minLogSpace: parameters.minLogSpace
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(YScaleSelector);
